import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from './container';

const today = moment().format();
const format = date => moment(date).startOf('day').format();

const getInfo = data => {
  const { sessions, dayStatistics } = data;

  const minDate = sessions.length ? format(sessions[0].startTime) : '';
  const maxDate = sessions.length
    ? format(sessions[sessions.length - 1].endTime)
    : '';
  const defaultDate = maxDate || today;

  return { defaultDate, minDate, maxDate, dayStatistics };
};

class SimpleSensorPlots extends Component {
  constructor(props) {
    super(props);
    const { defaultDate, minDate, maxDate, dayStatistics } = getInfo(
      props.data
    );
    this.state = {
      defaultDate,
      minDate,
      maxDate,
      dayStatistics
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.prevData) {
      const { defaultDate, minDate, maxDate, dayStatistics } = getInfo(
        props.data
      );
      return {
        defaultDate,
        minDate,
        maxDate,
        dayStatistics,
        // ---
        prevData: props.data
      };
    }
    return null;
  }

  render() {
    const { defaultDate, minDate, maxDate, dayStatistics } = this.state;
    return (
      <Container
        defaultDate={defaultDate}
        minDate={minDate}
        maxDate={maxDate}
        dayStatistics={dayStatistics}
      />
    );
  }
}

SimpleSensorPlots.propTypes = {
  data: PropTypes.object.isRequired
};

export default SimpleSensorPlots;
