import React from 'react';
import {
  Actions,
  EndTime,
  Headers,
  PatientName,
  SensorData,
  SensorLastOnline,
  SensorName,
  StartTime
} from '../elements';

const baseColumns = [
  {
    name: 'patient',
    header: Headers.patient,
    cell: PatientName,
    width: 3
  },
  {
    name: 'start-time',
    header: Headers.startTime,
    cell: StartTime
  },
  {
    name: 'end-time',
    header: Headers.endTime,
    cell: EndTime
  },
  {
    name: 'sensor-name',
    header: Headers.sensorName,
    cell: SensorName
  },
  {
    name: 'sensor-data',
    header: Headers.sensorData,
    cell: SensorData
  },
  {
    name: 'sensor-last-online',
    header: Headers.sensorLastOnline,
    cell: SensorLastOnline
  }
];

const sensorCells = ['sensor-name', 'sensor-data', 'sensor-last-online'];
export const isSensorCell = cellName => sensorCells.includes(cellName);

export const getColumns = ({
  isOpenBulkExportMode,
  setExportData,
  setEditData,
  setDeleteData
}) => {
  const columns = [
    ...baseColumns,
    {
      name: 'measurement-status-ex',
      header: Headers.status,
      cell: ({ data }) => (
        <Actions
          data={data}
          isOpenBulkExportMode={isOpenBulkExportMode}
          setExportData={setExportData}
          setEditData={setEditData}
          setDeleteData={setDeleteData}
        />
      )
    }
  ];

  return columns;
};
