import React from 'react';
import Roles from './roles';

const Name = ({ data }) => <span>{data.name}</span>;

const Description = () => <span>-</span>;

const NumActivePatients = ({ data }) => (
  <span>{data.project.numActivePatients}</span>
);

export { Name, Description, Roles, NumActivePatients };
