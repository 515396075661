import { useMatch } from 'react-router-dom';
import { useTranslate } from 'intl';
import routes from 'routes';

export const useBreadCrumbs = () => {
  const t = useTranslate('icomp-admin');
  const match = useMatch(
    '/r/admin/h/:hospitalId/d/:departmentId/users/:userId'
  );
  const {
    path,
    params: { hospitalId, departmentId, userId }
  } = match || { params: {} };

  const home = {
    label: t('home'),
    link: routes.iCompDepartments()
  };

  // ---
  const admin = {
    label: t('administration'),
    link: routes.iCompAdmin()
  };

  // ---
  const userOveriew = {
    label: t('user-overview'),
    link: routes.iCompAdminUserOverview(hospitalId)
  };
  const userProfile = {
    label: t('user-profile'),
    link: routes.iCompAdminUserProfile(hospitalId, userId)
  };
  const userRegistration = {
    label: t('user-registration'),
    link: routes.iCompAdminUserRegistration(hospitalId)
  };

  // ---
  const sensorOverview = {
    label: t('sensor-overview'),
    link: routes.iCompAdminSensorOverview(hospitalId)
  };

  // ---
  const departmentProfile = {
    label: t('department-profile'),
    link: routes.iCompAdminDepartmentProfile(hospitalId, departmentId)
  };
  const departmentUserOverview = {
    label: t('user-overview'),
    link: routes.iCompAdminDepartmentUserOverview(hospitalId, departmentId)
  };
  const departmentUserProfile = {
    label: t('user-profile'),
    link: routes.iCompAdminDepartmentUserProfile(
      hospitalId,
      departmentId,
      userId
    )
  };
  const departmentUserRegistration = {
    label: t('user-registration'),
    link: routes.iCompAdminDepartmentUserRegistration(
      hospitalId,
      departmentId,
      hospitalId
    )
  };
  const departmentSensorOverview = {
    label: t('sensor-overview'),
    link: routes.iCompAdminDepartmentSensorOverview(hospitalId, departmentId)
  };

  const breadcrumbs = [home, admin];

  switch (path) {
    // --- Users
    case routes.iCompAdminUserOverview(':hospitalId'): {
      breadcrumbs.push(userOveriew);
      break;
    }
    case routes.iCompAdminUserProfile(':hospitalId', ':userId'): {
      breadcrumbs.push(userOveriew, userProfile);
      break;
    }
    case routes.iCompAdminUserRegistration(':hospitalId', ':userId'): {
      breadcrumbs.push(userOveriew, userRegistration);
      break;
    }

    // --- Sensors
    case routes.iCompAdminSensorOverview(':hospitalId'): {
      breadcrumbs.push(sensorOverview);
      break;
    }

    // --- Departments
    case routes.iCompAdminDepartmentProfile(':hospitalId', ':departmentId'): {
      breadcrumbs.push(departmentProfile);
      break;
    }
    case routes.iCompAdminDepartmentSensorOverview(
      ':hospitalId',
      ':departmentId'
    ): {
      breadcrumbs.push(departmentProfile, departmentSensorOverview);
      break;
    }
    case routes.iCompAdminDepartmentUserOverview(
      ':hospitalId',
      ':departmentId'
    ): {
      breadcrumbs.push(departmentProfile, departmentUserOverview);
      break;
    }
    case routes.iCompAdminDepartmentUserProfile(
      ':hospitalId',
      ':departmentId',
      ':userId'
    ): {
      breadcrumbs.push(
        departmentProfile,
        departmentUserOverview,
        departmentUserProfile
      );
      break;
    }
    case routes.iCompAdminDepartmentUserRegistration(
      ':hospitalId',
      ':departmentId'
    ): {
      breadcrumbs.push(
        departmentProfile,
        departmentUserOverview,
        departmentUserRegistration
      );
      break;
    }

    default:
      break;
  }

  return breadcrumbs;
};
