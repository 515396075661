import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import { changeTimezone } from 'api/user';
import ErrorMessage from 'components/error-message';
import TimezoneSelect from 'components/timezone-select';

const ChangeTimezone = ({ defaultValue, onSuccess, onCancel }) => {
  const t = useTranslate('settings');

  const { form, onChanges, onSubmit, submitting, error, onClearError } =
    useForm({
      initialForm: { timezone: defaultValue },
      apiSubmit: form =>
        changeTimezone(form.timezone).then(() => {
          onSuccess(form);
        })
    });

  return (
    <Segment>
      <Header>{t('change-timezone')}</Header>
      <Form onSubmit={onSubmit} error={!!error}>
        <Form.Field>
          <TimezoneSelect
            value={form.timezone}
            onChange={(event, { value }) => onChanges.timezone(value)}
          />
        </Form.Field>
        <ErrorMessage error={error} onDismiss={onClearError} />
        <div>
          <Button type="button" disabled={submitting} onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" color="blue" loading={submitting}>
            {t('change')}
          </Button>
        </div>
      </Form>
    </Segment>
  );
};

ChangeTimezone.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ChangeTimezone;
