import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const PField = ({ label, children, inline }) => (
  <div className={inline ? styles.mainInline : styles.main}>
    <div className={styles.label}>
      {label || <span>&nbsp;</span>}
      {inline ? ': ' : ''}
    </div>
    <div className={styles.value}>{children}</div>
  </div>
);

PField.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node
};

export default PField;
