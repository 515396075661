import type {
  Sensor,
  SensorRaw,
  SensorSession,
  SensorSessionRaw,
  SensorStatus
} from './types';

export const nSensorSession: (raw: SensorSessionRaw) => SensorSession = raw => {
  // const streams = raw.streams.map(item => ({
  //   type: item.type,
  //   rate: item.sample_rate,
  // }));
  return {
    id: raw.id,
    startTime: raw.start_time,
    endTime: raw.end_time,
    rate: 0, // streams.find(item => item.type === 'acc/3ax/4g').rate,
    raw
  };
};

const nSensorStatus: (sensor: SensorRaw) => SensorStatus = sensor => {
  if (sensor.deleted) return 'sensor_status/removed';
  if (sensor.expired) return 'sensor_status/expired';
  if (
    Number.isNaN(sensor.run_state_current) ||
    Number.isNaN(sensor.run_state_requested)
  ) {
    return 'sensor_status/expired'; // TODO: Verify this from return ``
  }
  if (sensor.run_state_current === 0 && sensor.run_state_requested === 0) {
    return 'sensor_status/deep-sleep';
  }

  if (sensor.run_state_current === 0 && sensor.run_state_requested === 1) {
    return 'sensor_status/waking-up';
  } else if (
    sensor.run_state_current === 1 &&
    sensor.run_state_requested === 0
  ) {
    return 'sensor_status/putting-to-sleep';
  } else if (
    sensor.run_state_current === 1 &&
    sensor.run_state_requested === 1
  ) {
    return 'sensor_status/running-ok';
  } else
    return `sensor_status/unknown-${sensor.run_state_current.toString()}_${sensor.run_state_requested.toString()}` as `sensor_status/unknown-${string}`;
};

export const nSensor: (sensor: SensorRaw) => Sensor = sensor => ({
  id: sensor.id,
  name: sensor.short_name,
  place: sensor.place,
  status: nSensorStatus(sensor),
  battery: sensor.battery,
  memory: sensor.memory || 0,
  description: sensor.description,
  // ---
  expired: sensor.expired,
  lifetimeState: sensor.lifetime_state,
  runStateCurrent: sensor.run_state_current,
  runStateRequested: sensor.run_state_requested,
  isSleeping: sensor.run_state_current === 0,
  // ---
  lastSynced: sensor.last_synced_timestamp /* timestamp of the newest data */,
  lastSyncedAt: sensor.last_synced_time /* the time we got the data */,
  lastSeen: sensor.last_seen,
  runtime: sensor.run_time,
  // ---
  raw: sensor
});
