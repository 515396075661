import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown } from 'semantic-ui-react';
import SelectPeriod from './select-period';

const MeasurementPlotsExportDay = ({ date, stream }) => {
  const t = useTranslate('measurement');

  const [exportType, setExportType] = useState('');

  const optionExportPDF = {
    key: 'PDF',
    text: 'PDF Report',
    icon: 'file pdf outline',
    onClick: () => setExportType('PDF')
  };
  const optionExportCSV = {
    key: 'CSV',
    text: 'CSV',
    icon: 'file outline',
    onClick: () => setExportType('CSV')
  };
  const optionExportRaw = {
    key: 'Raw',
    text: 'Raw',
    icon: 'window restore outline',
    onClick: () => setExportType('Raw')
  };

  const options = [optionExportPDF, optionExportCSV, optionExportRaw];

  const onResetExportType = () => setExportType('');

  return (
    <Fragment>
      <Dropdown floating options={options} text={t('export')} />
      {exportType && (
        <SelectPeriod
          exportType={exportType}
          onResetExportType={onResetExportType}
          // ---
          date={date}
          stream={stream}
        />
      )}
    </Fragment>
  );
};

MeasurementPlotsExportDay.propTypes = {
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired
};

export default MeasurementPlotsExportDay;
