import { Action, Headers, Time } from '../elements';

export const columns = [
  {
    name: 'time',
    header: Headers.time,
    cell: Time
  },
  {
    name: 'action',
    header: Headers.action,
    cell: Action
  }
];
