import React from 'react';
import { useTranslate } from 'intl';
import { faFileCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Header, Icon, Message, Popup } from 'semantic-ui-react';
import styles from './styles.module.scss';

const SetupBox = ({
  header,
  fields,
  showFileSizeWarning,
  selectedData,
  onSubmit
}) => {
  const t = useTranslate('browse-data');

  return (
    <div className={styles.main}>
      <Header as="h3">{header}</Header>

      <div className={styles.fields}>
        {fields.map(item => (
          <div key={item.key} className={styles.field}>
            <div className={styles.label}>
              <Header className={styles.text} as="h4">
                {item.label}
              </Header>
              <Popup
                content={item.tooltip}
                trigger={<Icon className={styles.tooltip} name="info circle" />}
              />
            </div>
            <div className={styles.element}>{item.element}</div>
          </div>
        ))}
      </div>

      {showFileSizeWarning && (
        <Message className={styles.warning} color="orange">
          <FontAwesomeIcon icon={faFileCircleExclamation} />
          <span>{t('export-settings.file-size-warning')}</span>
        </Message>
      )}

      <div className={styles.button}>
        {!selectedData.length ? (
          <Popup
            content={t('export-settings.generate-file-tooltip')}
            trigger={
              <Button className={styles.disabled}>
                {t('export-settings.generate-file')}
              </Button>
            }
          />
        ) : (
          <Button positive onClick={onSubmit}>
            {t('export-settings.generate-file')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SetupBox;
