import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const ICompButtonGroup = ({ children }) => {
  return (
    <div className={styles.main}>
      {children.map((button, index) => (
        <div key={index} className={styles.button}>
          {button}
        </div>
      ))}
    </div>
  );
};

ICompButtonGroup.propTypes = {
  children: PropTypes.array.isRequired
};

export default ICompButtonGroup;
