import React from 'react';
import { Header, List } from 'semantic-ui-react';
import styles from './styles.module.scss';

const StaticBox = ({ header, listTitle, listItems, button }) => {
  return (
    <div className={styles.main}>
      <Header as="h3">{header}</Header>
      <div>
        {listTitle}
        {`: `}
      </div>
      <List className={styles.list} bulleted>
        {listItems.map((listItem, index) => (
          <List.Item key={index}>{listItem}</List.Item>
        ))}
      </List>
      {button}
    </div>
  );
};

export default StaticBox;
