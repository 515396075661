import React from 'react';
import { useTranslate } from 'intl';
import { Icon, Dropdown } from 'semantic-ui-react';
import { isDisabledExport } from '../../utils';
import { PatientStatus } from '../patient-status';
import styles from './styles.module.css';

export const Actions = ({
  data,
  isOpenBulkExportMode,
  setExportData,
  setEditData,
  setDeleteData
}) => {
  const t = useTranslate(['measurement-export', 'patient']);

  return (
    <div className={styles.wrapper}>
      <PatientStatus data={data} />

      {!isOpenBulkExportMode && (
        <div className={styles.actions}>
          <Dropdown icon={<Icon name="ellipsis vertical" color="grey" />}>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={isDisabledExport(data)}
                onClick={() => setExportData(data)}
              >
                {t('measurement-export:trigger')}
              </Dropdown.Item>

              <Dropdown.Item onClick={() => setEditData(data)}>
                {t('patient:edit-patient')}
              </Dropdown.Item>

              <Dropdown.Item onClick={() => setDeleteData(data)}>
                {t('patient:delete-patient')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
