import React from 'react';
import PropTypes from 'prop-types';
import { VictoryScatter } from 'victory';

const Scatter = ({ data, stroke, ...otherProps }) => (
  <VictoryScatter
    data={data}
    size={2}
    style={{ data: { stroke, strokeWidth: 1, fill: '#ffffff' } }}
    {...otherProps}
  />
);

Scatter.propTypes = {
  data: PropTypes.array.isRequired,
  stroke: PropTypes.string.isRequired
};

export default Scatter;
