import React, { useEffect, useState } from 'react';
import { useFetch, useSearch } from 'hooks';
import { fetchHospitals } from 'api/icomp-hospitals';
import Empty from './empty';
import Header from './header';
import Table from './table';
import utils from './utils';

const ICompDepartmentDashboard = () => {
  const {
    fetching,
    data: hospitals,
    error
  } = useFetch({
    apiFetch: fetchHospitals,
    source: JSON.stringify({ withProject: true }),
    isJsonSource: true
  });
  const [hospital, setHospital] = useState({});
  const [search, onSearch] = useSearch({ uppercase: true });

  useEffect(() => {
    if (hospitals && hospitals.length > 0 && !hospital.id) {
      setHospital(hospitals[0]);
    }
  }, [hospitals, hospital]);

  const departments = utils.filterData({
    all: hospital.departments || [],
    search
  });

  if (!fetching && !hospital.departments) {
    return <Empty />;
  }

  return (
    <React.Fragment>
      <Header
        hospitals={hospitals}
        hospital={hospital}
        setHospital={setHospital}
        search={search}
        onSearch={onSearch}
      />
      <Table
        fetching={fetching}
        error={error}
        hospital={hospital}
        departments={departments}
      />
    </React.Fragment>
  );
};

export default ICompDepartmentDashboard;
