import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchNearbySensors } from 'api/admin/nearby-sensors';
import Container from './container';

const NearbySensorDashboard = () => {
  const params = useParams();

  const { fetching, data, error } = useFetch({
    source: JSON.stringify({ gatewayId: params.gatewayId }),
    isJsonSource: true,
    apiFetch: fetchNearbySensors
  });

  return <Container fetching={fetching} data={data} error={error} />;
};

export default NearbySensorDashboard;
