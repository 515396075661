import React, { Component } from 'react';
import Fragment from './fragment';

class NearbySensorDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      controlMode: false
    };
    this.onUpdateData = this.onUpdateData.bind(this);
    this.onToggleControlMode = this.onToggleControlMode.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        data: this.props.data
      });
    }
  }

  onUpdateData(data) {
    this.setState({ data });
  }

  onToggleControlMode() {
    this.setState({ controlMode: !this.state.controlMode });
  }

  render() {
    return (
      <Fragment
        {...this.props}
        {...this.state}
        onUpdateData={this.onUpdateData}
        onToggleControlMode={this.onToggleControlMode}
      />
    );
  }
}

export default NearbySensorDashboardContainer;
