import type { UserProfile, UserProfileRaw } from './types';

const phoneRegex = /^\+\d*$/;
const numberOnly = /^\d+$/;

const nPhone = (phone: string) => {
  if (phoneRegex.test(phone)) {
    return phone;
  }
  if (numberOnly.test(phone)) {
    return `+${phone}`;
  }
  return '';
};

export const nUserProfile = (profile: UserProfileRaw): UserProfile => ({
  id: profile.id,
  email: profile.email,
  firstName: profile.first_name,
  lastName: profile.last_name,
  phone: nPhone(profile.phone_number),
  timezone: profile.timezone,
  raw: profile
});
