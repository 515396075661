import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Button, Header } from 'semantic-ui-react';
import { fetchHospitals } from 'api/icomp-hospitals';
import Box from 'components/box';
import AddDepartment from './add-department';
import DepartmentList from './department-list';
import styles from './styles.module.css';

const ICompAdminDepartmentOverview = () => {
  const t = useTranslate('icomp-admin-department');

  const {
    fetching,
    data: hospitals,
    error
  } = useFetch({
    apiFetch: fetchHospitals
  });

  const [hospital, setHospital] = useState({});

  useEffect(() => {
    if (hospitals && hospitals.length > 0 && !hospital.id) {
      setHospital(hospitals[0]);
    }
  }, [hospitals, hospital]);

  return (
    <div>
      <Header as="h2" textAlign="center">
        {t('administration')}
      </Header>

      <div className={styles.buttonGroup}>
        <div className={styles.button}>
          <AddDepartment hospital={hospital} />
        </div>
        <div className={styles.button}>
          <Button
            primary
            as={Link}
            to={routes.iCompAdminUserOverview(hospital.id)}
          >
            {t('user-administration')}
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            primary
            as={Link}
            to={routes.iCompAdminSensorOverview(hospital.id)}
          >
            {t('sensor-administration')}
          </Button>
        </div>
      </div>

      <Box mt="3rem">
        <DepartmentList
          fetching={fetching}
          error={error}
          hospitals={hospitals}
          hospital={hospital}
          setHospital={setHospital}
        />
      </Box>
    </div>
  );
};

export default ICompAdminDepartmentOverview;
