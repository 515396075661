const filterData = ({ data, search, filter }) => {
  const data1 =
    filter === 'all' ? data : data.filter(item => item.status === filter);
  const lowerCaseSearch = search.toLowerCase();
  const data2 = data1.filter(
    item => item.name.toLowerCase().indexOf(lowerCaseSearch) !== -1
  );
  return data2;
};

export default { filterData };
