import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  status: <Translate ns="external-patient" i18nKey="status" />,
  name: <Translate ns="external-patient" i18nKey="name" />,
  average: <Translate ns="external-patient" i18nKey="average" />,
  yesterday: <Translate ns="external-patient" i18nKey="yesterday" />,
  today: <Translate ns="external-patient" i18nKey="today" />,
  sensor: <Translate ns="external-patient" i18nKey="sensor" />
};
