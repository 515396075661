import type { ICompDepartmentRaw } from 'api/icomp-departments';
import type { ProjectRaw } from 'api/projects';
import { fetchScopeProjects, fetchScopes } from 'api/scopes';
import { nHospital } from './normalize';
import type { ICompHospitalRaw } from './types';

const CACHE: Record<string, ProjectRaw[]> = {};

const fetchHospitalProjects = async (departments: ICompDepartmentRaw[]) => {
  const scope_id = departments.map(d => d.id).join(',');
  if (CACHE[scope_id]) return CACHE[scope_id]!;

  const projects = await fetchScopeProjects(scope_id);

  CACHE[scope_id] = projects;
  return projects;
};

export const fetchHospitals = async ({
  withProject
}: {
  withProject?: boolean;
} = {}) => {
  const scopes = await fetchScopes();
  const iCompScopes = scopes.filter(s => s.scope_class === 'scope/icomp_org');

  let hospitals = iCompScopes.map(h => ({
    ...h,
    departments: scopes.filter(s => s.parent_id === h.id)
  }));

  if (withProject) {
    const departments: ICompDepartmentRaw[] = hospitals.reduce(
      (prev: ICompDepartmentRaw[], curr: ICompHospitalRaw) => [
        ...prev,
        ...curr.departments
      ],
      []
    );

    const projects = await fetchHospitalProjects(departments);

    hospitals = hospitals
      .map(h => ({
        ...h,
        departments: h.departments
          .map(d => ({
            ...d,
            project: projects.find(p => p.parent_id === d.id)
          }))
          .filter(d => d.project)
      }))
      .filter(h => h.departments.length);
  }

  return hospitals.map((item, index) => ({
    ...nHospital(item),
    idx: index + 1
  }));
};
