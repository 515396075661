import React from 'react';
import { useEventHandler, useFetch } from 'hooks';
import { fetchGateways } from 'api/admin/gateways';
import Fragment from './fragment';

const GatewayDashboard = () => {
  const [view, setView] = useEventHandler('site');
  const { fetching, data, error } = useFetch({
    source: view,
    apiFetch: fetchGateways
  });

  return (
    <Fragment
      view={view}
      setView={setView}
      fetching={fetching}
      data={data}
      error={error}
    />
  );
};

export default GatewayDashboard;
