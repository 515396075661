/* eslint-disable no-return-assign, operator-assignment, no-console */
import { useCallback, useEffect, useReducer, useRef } from 'react';
import * as Sentry from '@sentry/browser';

const RETRY_LIMIT = 6;

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetch':
      return { ...state, fetching: true, data: undefined, error: null };
    case 'retry':
      return { ...state, retrying: true, error: null };
    case 'success':
      return {
        fetching: false,
        retrying: false,
        data: action.payload,
        error: null
      };
    case 'error':
      return {
        fetching: false,
        retrying: false,
        data: undefined,
        error: action.payload
      };
    case 'update':
      return {
        ...state,
        data: action.payload
      };
    default:
      throw new Error();
  }
};

const useFetch = ({
  source, // string
  isJsonSource, // boolean
  apiFetch, // function
  refresh, // number:  auto refresh after `refresh` milliseconds
  hardRefresh, // boolean
  handleClearCache, // function executed before fetch/re-fetch
  fetchOnInit = true // boolean
}) => {
  const initialState = {
    fetching: fetchOnInit,
    retrying: false,
    data: undefined,
    error: null
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const cancel = useRef(null);
  const refreshInterval = useRef(null);
  const retryCount = useRef(0);

  const fetchData = useCallback(
    async ({ isRefresh } = {}) => {
      if (handleClearCache) {
        handleClearCache();
      }

      if (hardRefresh) {
        dispatch({ type: 'fetch' });
      }
      const params = isJsonSource ? JSON.parse(source) : source;
      const setCancel = c => (cancel.current = c);

      try {
        const data = await apiFetch(params, setCancel);
        dispatch({ type: 'success', payload: data });
      } catch (error) {
        if (error.key === 'REQUEST_CANCELLED') {
          return;
        }

        if (
          error.key === 'GENERATING_RESOURCE' &&
          retryCount.current < RETRY_LIMIT
        ) {
          dispatch({ type: 'retry' });
          retryCount.current = retryCount.current + 1;
          fetchData();
        } else {
          console.error('API error: ', error);
          Sentry.captureException(error);

          if (!isRefresh) {
            dispatch({ type: 'error', payload: error });
          }
        }
      }
    },
    [apiFetch, source, isJsonSource, hardRefresh]
  );

  useEffect(() => {
    // Fetch data
    if (fetchOnInit) {
      fetchData();
    }

    // Auto refresh after `refresh` milliseconds
    if (refresh) {
      retryCount.current = 0;
      refreshInterval.current = setInterval(() => {
        fetchData({ isRefresh: true });
      }, refresh);
    }

    // Clean up after this effect
    return () => {
      // Cancel previous request
      if (cancel.current) {
        cancel.current();
      }
      // Clear auto refresh interval
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, [refresh, fetchData]);

  const onUpdate = useCallback(data => {
    dispatch({ type: 'update', payload: data });
  }, []);

  return {
    ...state,
    refetch: fetchData,
    onUpdate
  };
};

export default useFetch;
