import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const options = {
  'dd-mm-yyyy': { year: 'numeric', month: '2-digit', day: '2-digit' },
  'dd-mm-yyyy hh:mm:ss': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  },
  'dd-mm-yyyy hh:mm': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  },
  'mm-yyyy': { year: 'numeric', month: '2-digit' },
  'hh:mm': {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
};
export const formats = Object.keys(options);
export const defaultFormat = 'dd-mm-yyyy';

export const useFormatDate = () => {
  const { t } = useTranslation();

  const formatDate = useCallback(
    (value, format = defaultFormat) => {
      if (value === null || value === undefined) {
        return '—';
      }

      if (formats.indexOf(format) === -1) {
        /* eslint-disable-next-line no-console */
        console.error('Invalid date format!');
        return value;
      }

      const data = {
        value: new Date(value),
        options: options[format]
      };

      return t('{{data, formatDate}}', {
        data: data
      });
    },
    [t]
  );

  return formatDate;
};

export default useFormatDate;
