import React from 'react';
import { useTranslate } from 'intl';
import { Header } from 'semantic-ui-react';

const ExternalPatientDashboardHeader = () => {
  const t = useTranslate('external-patient');

  return <Header as="h2">{t('header')}</Header>;
};

export default ExternalPatientDashboardHeader;
