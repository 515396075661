import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Box from 'components/box';

const PlotLayout = ({
  type,
  timeSelect,
  exportButton,
  streamSelect,
  children
}) => {
  return (
    <section style={type === 'hour' ? { marginTop: '2rem' } : {}}>
      <Grid columns={2} verticalAlign="middle">
        <Grid.Column>{timeSelect}</Grid.Column>
        <Grid.Column textAlign="right">
          {exportButton}
          <Box as="span" ml="2rem">
            {streamSelect}
          </Box>
        </Grid.Column>
      </Grid>
      <div
        style={
          timeSelect || exportButton || streamSelect
            ? { marginTop: '2rem' }
            : {}
        }
      >
        {children}
      </div>
    </section>
  );
};

PlotLayout.propTypes = {
  type: PropTypes.oneOf(['day', 'hour']).isRequired,
  timeSelect: PropTypes.node,
  exportButton: PropTypes.node,
  streamSelect: PropTypes.node,
  children: PropTypes.node
};

export default PlotLayout;
