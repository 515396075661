import React, { Component } from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import { addPatient, deletePatient, editPatient } from 'api/ispine-patients';
import Fragment from './fragment';

class ISpinePatientDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
    this.onAdd = this.onAdd.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }
  }

  onAdd(form) {
    const { params } = this.props;
    return addPatient({ params, form }).then(response => {
      const data = this.state.data.concat(response);
      this.setState({ data });
    });
  }

  onEdit(form, patient) {
    const { params } = this.props;
    return editPatient({ params, patient, form }).then(response => {
      const data = this.state.data.slice();
      const index = data.findIndex(item => item.id === response.id);
      data[index] = response;
      this.setState({ data });
    });
  }

  onDelete(patient) {
    const { params } = this.props;
    return deletePatient({ params, patient }).then(response => {
      const data = this.state.data.slice();
      const index = data.findIndex(item => item.id === response.id);
      data.splice(index, 1);
      this.setState({ data });
    });
  }

  onRowClick(rowData) {
    const { id: measurementId } = rowData;
    const { organizationId, projectId } = this.props.params;
    const path = routes.iSpinePatientDetails(
      organizationId,
      projectId,
      measurementId
    );
    this.props.navigate(path);
  }

  render() {
    return (
      <Fragment
        {...this.props}
        {...this.state}
        onAdd={this.onAdd}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        onRowClick={this.onRowClick}
      />
    );
  }
}

ISpinePatientDashboardContainer.propTypes = {
  params: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default ISpinePatientDashboardContainer;
