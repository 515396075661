import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

const PatientDeleteModal = ({ data, onSubmit, onCancel }) => {
  const t = useTranslate('patient');
  const [deleting, setDeleting] = useState(false);

  const onConfirm = () => {
    setDeleting(true);
    return onSubmit(data).finally(() => {
      setDeleting(false);
      onCancel();
    });
  };

  return (
    <Modal open size="tiny">
      <Header>
        <Icon name="trash alternate outline" />
        <Header.Content>{t('delete-patient')}</Header.Content>
      </Header>
      <Modal.Content>
        <p>{t('delete-confirmation')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{t('common:cancel')}</Button>
        <Button
          icon
          labelPosition="right"
          color="red"
          loading={deleting}
          onClick={onConfirm}
        >
          <Icon name={'checkmark'} />
          {t('common:delete')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

PatientDeleteModal.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default PatientDeleteModal;
