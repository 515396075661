import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchAlgorithms } from 'api/algorithms';

export const AlgorithmsContext = createContext([]);

export const useAlgorithms = () => {
  return useContext(AlgorithmsContext);
};

export const withAlgorithms = WrappedComponent => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithAlgorithms = props => {
    const params = useParams();
    const { organizationId, projectId } = params;
    const { data: algorithms = [] } = useFetch({
      source: JSON.stringify({ organizationId, projectId }),
      isJsonSource: true,
      apiFetch: fetchAlgorithms
    });

    return (
      <AlgorithmsContext.Provider value={algorithms}>
        <WrappedComponent {...props} />
      </AlgorithmsContext.Provider>
    );
  };

  WithAlgorithms.displayName = displayName;

  return WithAlgorithms;
};
