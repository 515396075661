import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Line, Scatter } from '../elements';
import utils1 from '../utils/hour';
import utils2 from './utils';

const utils = { ...utils1, ...utils2 };

const TemperatureHour = ({ date, streams }) => {
  const t = useTranslate('plot');

  const xDomain = utils.getXDomain(date);

  const data = [];
  const { ts: timestamps, v: values } = streams;
  values.forEach((value, index) => {
    const timestamp = timestamps[index];
    if (timestamp >= xDomain[0] && timestamp <= xDomain[1]) {
      data.push({
        x: timestamp,
        y: value
      });
    }
  });

  const yDomain = utils.getYDomain(data);

  const domain = {
    x: xDomain,
    y: yDomain
  };
  const xTicks = utils.getXTicks(domain);
  const yTicks = utils.getYTicks(domain, 10);

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        label: t('time-in-minutes')
      })}
      {AxisY({ tickValues: yTicks, label: '°C' })}

      {Line({ data, stroke: '#FF0000' })}
      {Scatter({ data, stroke: '#FF0000' })}
    </Chart>
  );
};

TemperatureHour.propTypes = {
  date: PropTypes.string.isRequired,
  streams: PropTypes.object.isRequired
};

export default TemperatureHour;
