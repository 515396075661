import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';
import { Form, Input } from 'semantic-ui-react';
import { editNameEmail } from 'api/icomp-users';
import Box from 'components/box';

const EditNameEmail = ({ user, open, onClose, onCompleted }) => {
  const t = useTranslate('icomp-admin-user');

  const { hospitalId, departmentId } = useParams();
  const scopeId = departmentId || hospitalId;

  const { form, submitting, error, onChanges, onSubmit, onReset } = useForm({
    initialForm: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    },
    apiSubmit: form => editNameEmail({ scopeId, user, form }).then(onCompleted)
  });

  return (
    <Modal
      size="tiny"
      open={open}
      title={
        <div style={{ textAlign: 'center' }}>
          {t('edit-name-email')}
          <br />
          {user.name}
        </div>
      }
      body={
        <Form onSubmit={onSubmit}>
          <Form.Field
            control={Input}
            label={t('first-name')}
            placeholder={t('first-name')}
            value={form.firstName}
            onChange={(event, { value }) => onChanges.firstName(value)}
            required
          />
          <Form.Field
            control={Input}
            label={t('last-name')}
            placeholder={t('last-name')}
            value={form.lastName}
            onChange={(event, { value }) => onChanges.lastName(value)}
            required
          />
          <Form.Field
            control={Input}
            type="email"
            label={t('email')}
            placeholder={t('email')}
            value={form.email}
            onChange={(event, { value }) => onChanges.email(value)}
            required
          />
          <Box mt="2.5rem">
            <ButtonGroup>
              <ButtonCancel onClick={onClose} />
              <ButtonOk type="submit" loading={submitting} />
            </ButtonGroup>
          </Box>
        </Form>
      }
      error={error}
      onUnmount={onReset}
    />
  );
};

EditNameEmail.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired
};

export default EditNameEmail;
