import { axiosClient } from 'api/axios-client';
import type { ICompDepartment, ICompDepartmentForm } from './types';

export const editDepartment = async ({
  department,
  form
}: {
  department: ICompDepartment;
  form: ICompDepartmentForm;
}) => {
  const payload = {
    scope_id: department.id,
    name: form.name,
    long_name: form.description
  };
  return axiosClient.post('/api/1.0/access_scopes/edit_scope', payload);
};
