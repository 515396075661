import React from 'react';
import Body from 'containers/project/dashboard';
import Layout from 'layouts/sens';

const PageProjects = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageProjects;
