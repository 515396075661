import { useState } from 'react';
import moment from 'moment';

export const LIMIT_DAY = 14;

/*
  Default `EndTime` is current selected day.
  Default `StartTime` is 7 days before `EndTime`. 
  Limit 14 days export. 
*/

export const useDates = date => {
  const [startDate, setStartDate] = useState(() =>
    moment(date).startOf('day').add(-7, 'days').format()
  );
  const [endDate, setEndDate] = useState(() =>
    moment(date).startOf('day').add(1, 'days').format()
  );

  const onChangeStartDate = (event, { value }) => {
    const startDateObj = moment(value);
    const endDateObj = moment(endDate);
    const diff = endDateObj.diff(startDateObj, 'days');
    setStartDate(startDateObj.format());
    if (diff > LIMIT_DAY || diff < 1) {
      setEndDate(moment(startDateObj).add(LIMIT_DAY, 'days').format());
    }
  };

  const onChangeEndDate = (event, { value }) => {
    const startDateObj = moment(startDate);
    const endDateObj = moment(value);
    const diff = endDateObj.diff(startDateObj, 'days');
    if (diff > LIMIT_DAY || diff < 1) {
      setStartDate(moment(endDateObj).add(-LIMIT_DAY, 'days').format());
    }
    setEndDate(endDateObj.format());
  };

  const minDate = moment(startDate).add(1, 'days').format();
  const maxDate = moment(startDate).add(LIMIT_DAY, 'days').format();

  return {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    limit: LIMIT_DAY,
    minDate,
    maxDate
  };
};
