/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, Chart, Stack2, ZoomIn, ZoomOut } from '../elements';
import { useZoom } from '../hooks';
import utils1 from '../utils/hour';
import { colors, fallbackColor, heights } from './configs';
import utils2 from './utils';

const utils = { ...utils1, ...utils2 };

const DerviedHour = ({ date, streams, categories: rawCategories }) => {
  const t = useTranslate('plot');

  const { zoomId, onZoomIn, onZoomOut } = useZoom();

  const zoomed = zoomId !== undefined;

  const categories = rawCategories.filter(
    item => item !== 'activity/dummy/time'
  ); // Hard code to remove this as request in issue #282

  const domain = {
    x: utils.getXDomain(date, zoomId),
    y: [0, 4]
  };

  const xTicks = utils.getXTicks(domain, zoomed);
  const values = streams['activity/category'];

  const stacks = utils.getStacks(categories).map(category => ({
    color: colors[category] || fallbackColor,
    data: (function () {
      const result = [];

      values.forEach(v => {
        const [idx, ts1, ts2] = v;
        if (
          ts2 >= domain.x[0] &&
          ts1 <= domain.x[1] &&
          rawCategories[idx] === category
        ) {
          result.push({
            x: ts1,
            y: 0
          });
          result.push({
            x: ts1,
            y: heights[category]
          });
          result.push({
            x: ts2,
            y: heights[category]
          });
          result.push({
            x: ts2,
            y: 0
          });
        }
      });

      return result;
    })()
  }));

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        zoomed,
        label: zoomed ? t('time-in-seconds') : t('time-in-minutes')
      })}

      {Stack2({ stacks })}

      {zoomed
        ? ZoomOut({
            domain,
            onSelectTick: onZoomOut
          })
        : ZoomIn({
            range: 60,
            xstep: 60 * 1000,
            xcount: 60,
            domain,
            onSelectTick: onZoomIn
          })}
    </Chart>
  );
};

DerviedHour.propTypes = {
  date: PropTypes.string.isRequired,
  streams: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
};

export default DerviedHour;
