import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Grid, Header, Input } from 'semantic-ui-react';
import Add from './add';

const ISpinePatientDashboardHeader = ({ search, onSearch, onAdd }) => {
  const t = useTranslate('ispine-patient');

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={4}>
          <Header as="h2">{t('header')}</Header>
        </Grid.Column>
        <Grid.Column width={12} textAlign="right">
          <Input
            value={search}
            onChange={onSearch}
            placeholder={t('common:search-by-name')}
            style={{ verticalAlign: 'top', marginRight: '1rem' }}
          />
          <Add onSubmit={onAdd} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ISpinePatientDashboardHeader.propTypes = {
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  // ---
  onAdd: PropTypes.func.isRequired
};

export default ISpinePatientDashboardHeader;
