import axios from 'axios';
import { axiosClient, CancelExecutor } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { MeasurementPlotStream } from '../types';

const fetchRaw = async ({
  type,
  organizationId,
  projectId,
  measurementId,
  date,
  stream,
  setCancel
}: Pick<RouteParams, 'organizationId' | 'projectId' | 'measurementId'> & {
  type: 'day' | 'hour';
  date: string;
  stream: string;
  setCancel: CancelExecutor;
}) => {
  const params = {
    org_id: organizationId,
    project_id: projectId,
    measurement_id: measurementId,
    window_type: type,
    streams: stream,
    start_time: date
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const { values: streams } = await axiosClient
    .get('/api/1.0/measurement/data/raw', options)
    .then(response => response.data.value.data.streams[0]);

  return { streams };
};

const fetchDerived = async ({
  type,
  organizationId,
  projectId,
  measurementId,
  date,
  setCancel
}: Pick<RouteParams, 'organizationId' | 'projectId' | 'measurementId'> & {
  type: 'day' | 'hour';
  date: string;
  stream: string;
  setCancel: CancelExecutor;
}) => {
  const params = {
    org_id: organizationId,
    project_id: projectId,
    measurement_id: measurementId,
    window_type: type,
    start_time: date
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const { values: streams, categories } = await axiosClient
    .get('/api/1.0/measurement/data/derived', options)
    .then(response => response.data.value.data.streams[0]);

  return { streams, categories };
};

const mapFetch = {
  raw: fetchRaw,
  derived: fetchDerived
};

const createFetchPlot =
  (type: 'day' | 'hour') =>
  async (
    source: Pick<
      RouteParams,
      'organizationId' | 'projectId' | 'measurementId'
    > & {
      date: string;
      stream: MeasurementPlotStream;
    },
    setCancel: CancelExecutor
  ) => {
    const { date, stream, ...routeParams } = source;
    const [streamType, streamValue] = stream.split(':') as [
      'raw' | 'derived',
      string
    ];
    const fetchStream = mapFetch[streamType];
    return fetchStream({
      ...routeParams,
      type: type,
      date: date,
      stream: streamValue,
      setCancel: setCancel
    }).then(values => ({ type, date, stream, values }));
  };

export default createFetchPlot;
