import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Divider } from 'semantic-ui-react';
import { fetchStreamOptions } from 'api/measurements';
import {
  DateSelect,
  Layout,
  StreamSelect,
  getStreamType,
  withStreamOptions
} from 'components/plot';
import PlotDerived from './plot-derived';
import PlotRaw from './plot-raw';

const PatientPlot = ({ patient, streamOptions }) => {
  const { startTime, endTime, sensors } = patient;

  const [date, setDate] = useState(() => {
    const today = moment().startOf('day');
    if (!endTime) {
      return today.format();
    }
    const endDate = moment(endTime).startOf('day');
    const date = endDate.isAfter(today) ? today : endDate;
    return date.format();
  });
  const [stream, setStream] = useState(streamOptions[0]);

  const minDate = useMemo(
    () => moment(startTime).startOf('day').format(),
    [startTime]
  );
  const maxDate = useMemo(
    () => moment(endTime).startOf('day').format(),
    [endTime]
  );

  const streamType = getStreamType(stream);

  return (
    <Layout
      timeSelect={
        <DateSelect
          value={date}
          onChange={setDate}
          minDate={minDate}
          maxDate={maxDate}
        />
      }
      streamSelect={
        <StreamSelect
          value={stream}
          onChange={setStream}
          options={streamOptions}
        />
      }
    >
      {streamType === 'derived' ? (
        <PlotDerived date={date} stream={stream} />
      ) : (
        sensors.map((sensor, index) => (
          <>
            <PlotRaw
              key={index}
              date={date}
              stream={stream}
              sensor={sensor}
              streamOptions={streamOptions.filter(
                item => getStreamType(item) === 'raw'
              )}
            />
            {index < sensors.length - 1 && <Divider section />}
          </>
        ))
      )}
    </Layout>
  );
};

export default withStreamOptions(PatientPlot, fetchStreamOptions);
