import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslate } from 'intl';
import { Message } from 'semantic-ui-react';
import Block from './block';
import { useOptions, usePlotState } from './hooks';
import Info from './info';
import Plot from './plot';
import Select from './select';
import styles from './styles.module.css';
import Treatments from './treatments';

const ICompPatientPlotsFragment = ({ patientDetails }) => {
  const t = useTranslate('icomp-patient');
  const location = useLocation();
  const { tracks } = patientDetails;
  const {
    track,
    setTrack,
    // ---
    measurement,
    setMeasurement,
    // ---
    streams,
    toggleStream,
    // ---
    startDate,
    setStartDate,
    // ---
    endDate,
    setEndDate,
    // ---
    dateRange
  } = usePlotState({ location, tracks });

  const { trackOptions, measurementOptions, trackData, measurementData } =
    useOptions({
      tracks,
      track,
      measurement
    });

  return (
    <div>
      {/* Patient Data & Treatment Status */}
      <Info
        patientDetails={{ ...patientDetails, department: trackData.department }}
      />

      {/* Treatments */}
      <div className={styles.section}>
        <Treatments patientDetails={patientDetails} />
      </div>

      {/* Display Treatment */}
      <div className={styles.section}>
        {track && measurement ? (
          <Block
            data-cy="patient-details___display-treatment-section"
            header={t('display-treatment')}
          >
            <Select
              track={track}
              setTrack={setTrack}
              trackOptions={trackOptions}
              // ---
              measurement={measurement}
              measurementData={measurementData}
              setMeasurement={setMeasurement}
              measurementOptions={measurementOptions}
              // ---
              streams={streams}
              toggleStream={toggleStream}
              // ---
              startDate={startDate}
              setStartDate={setStartDate}
              // ---
              endDate={endDate}
              setEndDate={setEndDate}
              // ---
              dateRange={dateRange}
            />

            {Object.keys(streams)
              .filter(s => streams[s])
              .map(stream => (
                <Plot
                  key={stream}
                  {...{
                    track,
                    measurement,
                    stream,
                    startDate,
                    endDate: moment(endDate).add(1, 'days').startOf('day')
                  }}
                  measurements={trackData.measurements}
                />
              ))}
          </Block>
        ) : (
          <Message>{t('no-treatment')}</Message>
        )}
      </div>
    </div>
  );
};

ICompPatientPlotsFragment.propTypes = {
  patientDetails: PropTypes.object.isRequired
};

export default ICompPatientPlotsFragment;
