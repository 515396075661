const keys = {
  locale: 'SENS-Locale',
  userId: 'SENS-User-ID',
  organizationId: 'SENS-Organization-ID',
  projectId: 'SENS-Project-ID'
};

const set = (userId, key, value, isJSON) => {
  const name = userId ? `${userId}-${key}` : key;
  const data = isJSON ? JSON.stringify(value) : value;
  window.localStorage.setItem(name, data);
};

const get = (userId, key, isJSON) => {
  const name = userId ? `${userId}-${key}` : key;
  const data = window.localStorage.getItem(name);
  return isJSON ? JSON.parse(data) : data;
};

const remove = (userId, key) => {
  const name = userId ? `${userId}-${key}` : key;
  window.localStorage.removeItem(name);
};

export { keys };
export default { get, set, remove };
