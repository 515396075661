import type { ICompDepartmentRaw } from 'api/icomp-departments';
import { nICompDepartment } from 'api/icomp-departments';
import { nSensor } from 'api/sensors';
import type {
  ICompActiveMeasurement,
  ICompActiveMeasurementRaw,
  ICompMeasurement,
  ICompMeasurementMeta,
  ICompMeasurementMetaRaw,
  ICompMeasurementRaw,
  ICompMeasurementSensor,
  ICompMeasurementSensorRaw,
  ICompPatient,
  ICompPatientRaw,
  ICompTrack,
  ICompTrackRaw
} from './types';

const nCpr = (cpr: string) => {
  if (!cpr) {
    return '';
  }
  const p1 = cpr.slice(0, 6);
  const p2 = cpr.slice(6, 10);
  return `${p1}-${p2}`;
};

const statusMapping = {
  'track_status/active': 'active',
  'track_status/closed': 'closed'
};

const nICompMeasurementMeta = (
  raw: ICompMeasurementMetaRaw
): ICompMeasurementMeta => ({
  circumsphere: raw.icompression_circumsphere,
  diagnose: raw.icompression_diagnose,
  note: raw.icompression_note,
  side: raw.icompression_side === 'l' ? 'left' : 'right',
  bandage: raw.icompression_type,
  raw
});

const nICompMeasurementSensor = (
  raw: ICompMeasurementSensorRaw
): ICompMeasurementSensor => ({
  id: raw.id,
  name: raw.short_name,
  place: raw.place
});

const nICompMeasurement = (raw: ICompMeasurementRaw): ICompMeasurement => ({
  id: raw.id,
  method: raw.profile,
  startTime: raw.start_time,
  endTime: raw.end_time,
  sensor: raw.places[0] ? nICompMeasurementSensor(raw.places[0]) : undefined,
  meta: raw.meta ? nICompMeasurementMeta(raw.meta) : undefined,
  raw
});

export const nICompTrack = (raw: ICompTrackRaw): ICompTrack => ({
  id: raw.id,
  startTime: raw.start_time,
  endTime: raw.end_time,
  status: statusMapping[raw.status],
  treatments: raw.count_measurements,
  department: raw.department,
  measurements: raw.measurements.map((item, index) => ({
    ...nICompMeasurement(item),
    idx: index + 1
  })),
  raw
});

export const nICompActiveMeasurement = (
  raw: ICompActiveMeasurementRaw,
  tracks: ICompTrackRaw[]
): ICompActiveMeasurement => {
  const track = raw.track
    ? tracks.find(i => i.id === raw.track.track_id)
    : undefined;
  return {
    id: raw.id,
    startTime: raw.start_time,
    endTime: raw.end_time,
    track: track ? nICompTrack(track) : undefined,
    sensor: raw.places[0] ? nSensor(raw.places[0].sensor) : undefined,
    meta: raw.meta ? nICompMeasurementMeta(raw.meta) : undefined,
    raw
  };
};

export const nICompPatient = (
  raw: ICompPatientRaw,
  departments: ICompDepartmentRaw[] = []
): ICompPatient => {
  const department = departments.find(i => i.id === raw.active_department_id);
  return {
    id: raw.id,
    name: `${raw.first_name} ${raw.last_name}`,
    firstName: raw.first_name,
    lastName: raw.last_name,
    phone: raw.phone_number,
    cpr: nCpr(raw.cpr),
    viewed: raw.viewed,
    status: raw.status,
    nextMeasCnt: raw.next_meas,
    nextTrackCnt: raw.next_track,
    therapist: raw.responsible_user_name,
    department: department ? nICompDepartment(department) : undefined,
    raw
  };
};
