import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  name: <Translate ns="icomp-admin-user" i18nKey="name" />,
  email: <Translate ns="icomp-admin-user" i18nKey="email" />,
  initials: <Translate ns="icomp-admin-user" i18nKey="initials" />,
  departments: <Translate ns="icomp-admin-user" i18nKey="departments" />,
  roles: <Translate ns="icomp-admin-user" i18nKey="roles" />
};
