import moment from 'moment';
import type { Gateway, GatewayRaw, GatewayStatus } from './types';

const nGatewayStatus = (gateway: GatewayRaw): GatewayStatus => {
  if (
    !gateway.last_online ||
    moment.utc(gateway.last_online).add(1, 'hour') < moment.utc()
  ) {
    return 'offline';
  }
  return 'ok';
};

export const nGateway = (gateway: GatewayRaw): Gateway => ({
  id: gateway.id,
  name: gateway.name,
  platform: gateway.platform,
  version: gateway.version,
  lastOnline: gateway.last_online,
  lastReport: gateway.last_report?.timestamp || undefined,
  background: gateway.last_report?.meta?.background || undefined,
  powerState: gateway.last_report?.meta?.powered === 'true' || undefined,
  batteryLevel: gateway.last_report?.meta?.battery_level || undefined,
  nearby: gateway.nearby,
  stats: gateway.stats,
  status: nGatewayStatus(gateway),
  raw: gateway
});
