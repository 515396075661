import { axiosClient } from 'api/axios-client';
import { nUserProfile } from './normalize';
import type { UserProfileRaw } from './types';

export const fetchUserProfile = async () => {
  const { profile }: { profile: UserProfileRaw } = await axiosClient
    .get('/api/1.0/user/profile')
    .then(response => response.data.value);

  return nUserProfile(profile);
};
