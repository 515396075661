import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { controlSensors } from 'api/sensors';
import { getSensorParams } from 'utils/vendor';
import Fragment from './fragment';

class SensorDashboardTableControlContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      error: null,
      data: props.data.map(item => ({
        ...item,
        changed: false
      }))
    };
    this.onToggle = this.onToggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({
      submitting: true,
      error: null
    });
    controlSensors({
      params: getSensorParams(this.props.params),
      sensors: this.state.data.filter(item => item.changed)
    })
      .then(response => {
        this.props.onUpdate(response);
        this.props.onExit();
      })
      .catch(error => {
        this.setState({
          submitting: true,
          error
        });
      });
  }

  onToggle(row) {
    const data = this.state.data.slice();
    const index = data.findIndex(item => item.id === row.id);
    data[index] = {
      ...row,
      changed: !row.changed
    };
    this.setState({ data });
  }

  render() {
    return (
      <Fragment
        fetching={this.props.fetching}
        parentError={this.props.error}
        {...this.state}
        onExit={this.props.onExit}
        onToggle={this.onToggle}
        onSubmit={this.onSubmit}
      />
    );
  }
}

SensorDashboardTableControlContainer.propTypes = {
  params: PropTypes.object.isRequired,
  // ---
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array,
  // ---
  onExit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default SensorDashboardTableControlContainer;
