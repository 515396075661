import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import { nPatient } from 'api/patients';
import type { RouteParams } from 'app/types';
import { nSensor, nSensorSession } from './normalize';
import type {
  SensorDayStatistics,
  SensorDetails,
  SensorDetailsRaw
} from './types';

export const fetchSensor = async (
  {
    organizationId,
    projectId,
    sensorId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'sensorId'>,
  setCancel: CancelExecutor
) => {
  const params = {
    org_id: organizationId,
    project_id: projectId,
    sensor_id: sensorId,
    include_data_sessions: 1
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const raw: SensorDetailsRaw = await axiosClient
    .get('/api/1.0/sensor/details', options)
    .then(response => response.data.value);

  const { sensor, attached_patients, data_sessions, day_statistics } = raw;

  const attachedPatients = attached_patients
    .filter(patient => patient.measurement_status !== 'status/measurement/done')
    .filter(patient => patient.attached_sensors.includes(sensor.id))
    .map((patient, index) => ({
      ...nPatient(patient),
      idx: index + 1
    }));

  const sessions = data_sessions.map((item, index) => ({
    ...nSensorSession(item),
    idx: index + 1
  }));

  const dayStatistics =
    day_statistics && day_statistics.length
      ? day_statistics.reduce(
          (prev: SensorDayStatistics, curr) => ({
            ...prev,
            [curr.date]: curr
          }),
          {}
        )
      : undefined;

  const sensorDetails: SensorDetails = {
    ...nSensor(sensor),
    attachedPatients,
    sessions,
    dayStatistics,
    raw
  };

  return sensorDetails;
};
