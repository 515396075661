import { axiosClient } from 'api/axios-client';
import type { ICompUser, ICompUserEditForm } from './types';

export const editNameEmail = async ({
  scopeId,
  user,
  form
}: {
  scopeId: string;
  user: ICompUser;
  form: ICompUserEditForm;
}) => {
  const payload = {
    scope_id: scopeId,
    user_id: user.id,
    email: form.email,
    first_name: form.firstName,
    last_name: form.lastName
  };
  return axiosClient.post('/api/1.0/members/profile/edit', payload);
};
