import React from 'react';
import PropTypes from 'prop-types';
import Item from './item';

const getRanges = length => {
  const v = Array(length);
  for (let i = 0; i < length; i++) {
    v[i] = i;
  }
  return v;
};

const ZoomIn = ({ range, xstep, xcount, domain, onSelectTick }) =>
  getRanges(range).map(index => (
    <Item
      key={index}
      index={index}
      xstart={domain.x[0]}
      xstep={xstep}
      xcount={xcount}
      ydomain={domain.y}
      onSelectTick={onSelectTick}
    />
  ));

ZoomIn.propTypes = {
  range: PropTypes.number.isRequired,
  xstep: PropTypes.number.isRequired,
  xcount: PropTypes.number.isRequired,
  domain: PropTypes.shape({
    x: PropTypes.array.isRequired,
    y: PropTypes.array.isRequired
  }).isRequired,
  onSelectTick: PropTypes.func.isRequired
};

export default ZoomIn;
