import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';

const EndTrackConfirmation = ({ onCancel, onConfirm, submitting, error }) => {
  const t = useTranslate('icomp-patient');

  return (
    <Modal
      size="tiny"
      open
      title={t('end-track-confirmation')}
      description={t('end-track-description')}
      footer={
        <ButtonGroup>
          <ButtonCancel
            data-cy="patient-details__cancel-end-track-button"
            onClick={onCancel}
          />
          <ButtonOk
            loading={submitting}
            data-cy="patient-details__confirm-end-track-button"
            onClick={onConfirm}
          />
        </ButtonGroup>
      }
      error={error}
    />
  );
};

EndTrackConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.object
};

export default EndTrackConfirmation;
