import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import styles from './styles.module.scss';

const UnauthorizedMessage = () => {
  const t = useTranslate('401');

  return (
    <div className={styles.main}>
      <Segment raised padded="very" className={styles.center}>
        <Icon name="warning sign" size="huge" className={styles.negative} />
        <Header className={styles.header}>{t('header')}</Header>
        <p className={styles.negative}>{t('message')}</p>
        <div className={styles.buttons}>
          <Button primary fluid as={Link} to={routes.login()}>
            {t('login-again')}
          </Button>
          <Button fluid onClick={() => window.location.reload()}>
            {t('retry')}
          </Button>
        </div>
      </Segment>
    </div>
  );
};

export default UnauthorizedMessage;
