import React from 'react';
import { useTranslate } from 'intl';
import TextField from 'components/text-field';
import { getFullName } from 'utils/patient';
import Block from '../block';
import Field from '../field';
import EditPatient from './edit-patient';
import styles from './styles.module.css';

const PatientData = ({ patientDetails }) => {
  const t = useTranslate('icomp-patient');
  const { patient } = patientDetails;

  return (
    <Block header={t('patient-data')}>
      <div className={styles.group}>
        <Field label={t('name')}>
          <TextField data-cy="patient-details__name-data">
            {getFullName(patient)}
          </TextField>
        </Field>
        <div className={`${styles.fixed} ${styles.mr}`}>
          <EditPatient patientDetails={patientDetails} />
        </div>
      </div>
      <div className={styles.group}>
        <Field label={t('cpr')}>
          <TextField data-cy="patient-details__cpr-data">
            {patient.cpr}
          </TextField>
        </Field>
        <Field label={t('phone')}>
          <TextField data-cy="patient-details__phone-data">
            {patient.phone}
          </TextField>
        </Field>
      </div>
      <div className={styles.group}>
        <Field label={t('department')}>
          <TextField>{patient.department.name}</TextField>
        </Field>
        <Field label={t('therapist')}>
          <TextField>{patient.therapist}</TextField>
        </Field>
      </div>
    </Block>
  );
};

export default PatientData;
