import React, { Component } from 'react';
import Fragment from './fragment';

class SettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: {
        name: false,
        timezone: false,
        password: false,
        phone: false
      },
      // ---
      success: {
        name: false,
        timezone: false,
        password: false,
        phone: false
      }
    };
    this.onToggle = {
      name: this.onToggle.bind(this, 'name'),
      timezone: this.onToggle.bind(this, 'timezone'),
      password: this.onToggle.bind(this, 'password'),
      phone: this.onToggle.bind(this, 'phone')
    };
    this.onSuccess = {
      name: this.onSuccess.bind(this, 'name'),
      timezone: this.onSuccess.bind(this, 'timezone'),
      password: this.onSuccess.bind(this, 'password'),
      phone: this.onSuccess.bind(this, 'phone')
    };
    this.onDismiss = {
      name: this.onDismiss.bind(this, 'name'),
      timezone: this.onDismiss.bind(this, 'timezone'),
      password: this.onDismiss.bind(this, 'password'),
      phone: this.onDismiss.bind(this, 'phone')
    };
  }

  onToggle(key) {
    const edit = { ...this.state.edit, [key]: !this.state.edit[key] };
    this.setState({ edit });
  }

  onSuccess(key, value) {
    if (value) {
      this.props.onUpdate(value);
    }
    const edit = { ...this.state.edit, [key]: false };
    const success = { ...this.state.success, [key]: true };
    this.setState({ edit, success });
    window.setTimeout(() => {
      const success = { ...this.state.success, [key]: false };
      this.setState({ success });
    }, 2000);
  }

  onDismiss(key) {
    const success = { ...this.state.success, [key]: false };
    this.setState({ success });
  }

  render() {
    return (
      <Fragment
        {...this.props}
        {...this.state}
        // ---
        onToggle={this.onToggle}
        onSuccess={this.onSuccess}
        onDismiss={this.onDismiss}
      />
    );
  }
}

export default SettingsContainer;
