import React, { useEffect, useState } from 'react';
import Zendesk from 'react-zendesk';

const setting = {
  chat: {
    connectOnPageLoad: true
  }
};

const allowedHosts = ['beta-edge4.sens.dk', 'localhost:3000'];

const ZendeskController = () => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const { host } = window.location;
    const found = allowedHosts.find(item => item === host);
    if (found) {
      setEnabled(true);
    }
  }, []);

  if (!enabled) return null;

  return (
    <Zendesk
      defer
      zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
      {...setting}
    />
  );
};

export default ZendeskController;
