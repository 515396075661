import React from 'react';
import Body from 'containers/nfa-subject/dashboard';
import Layout from 'layouts/nfa';

const PageNFASubjects = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageNFASubjects;
