import React from 'react';
import Table from 'components/table';
import { CustomHeader, columns } from './configs';

const MonitorTable = ({ fetching, data, error }) => {
  return (
    <Table
      structured
      fetching={fetching}
      data={data}
      error={error}
      columns={columns}
      CustomHeader={CustomHeader}
    />
  );
};

export default MonitorTable;
