import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { Header } from 'semantic-ui-react';
import { fetchSensor } from 'api/simple-sensors';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';
import Info from './info';
import Plots from './plots';

const SimpleSensorDetails = () => {
  const t = useTranslate('simple-sensor');

  const { organizationId, projectId, sensorId } = useParams();
  const { fetching, data, error } = useFetch({
    source: JSON.stringify({ organizationId, projectId, sensorId }),
    isJsonSource: true,
    apiFetch: fetchSensor,
    refresh: 120000
  });

  if (fetching) {
    return <LoadingSection />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div>
      <Header as="h2">{t('header')}</Header>
      <Info data={data} />
      <Plots data={data} />
    </div>
  );
};

export default SimpleSensorDetails;
