import React from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import WebAppVersion from 'fragments/web-app-version';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { resetPassword } from 'api/user';
import ErrorMessage from 'components/error-message';
import Success from './success';
import utils from './utils';

const ResetPassword = () => {
  const t = useTranslate('reset-password');
  const location = useLocation();

  const {
    form,
    onChanges,
    onSubmit,
    submitting,
    success,
    error,
    onClearError
  } = useForm({
    initialForm: {
      newPassword: '',
      newPassword2: ''
    },
    apiSubmit: form => {
      const { token } = utils.parseQuery(location.search);
      return resetPassword({ form, token });
    }
  });

  if (success) {
    return <Success />;
  }

  return (
    <div className="reset-password-form">
      <style>{`body > div,body > div > div,body > div > div > div.reset-password-form {height: 100%;}`}</style>
      <Grid
        textAlign="center"
        style={{ height: '100%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="blue" textAlign="center">
            {t('header')}
          </Header>

          <Form size="large" onSubmit={onSubmit}>
            <Segment stacked>
              <Form.Input
                fluid
                required
                icon="lock"
                iconPosition="left"
                placeholder={t('new-password')}
                type="password"
                value={form.newPassword}
                onChange={(event, { value }) => onChanges.newPassword(value)}
              />
              <Form.Input
                fluid
                required
                icon="lock"
                iconPosition="left"
                placeholder={t('new-password2')}
                type="password"
                value={form.newPassword2}
                onChange={(event, { value }) => onChanges.newPassword2(value)}
              />
              <Button
                type="submit"
                fluid
                color="blue"
                size="large"
                loading={submitting}
                content={t('reset')}
              />
            </Segment>
          </Form>

          <ErrorMessage
            error={error}
            onDismiss={onClearError}
            attached="bottom"
          />

          <WebAppVersion />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ResetPassword;
