import React from 'react';
import { useParams } from 'react-router-dom';
import Container from './container';

const ICompPatientDashboardSearch = props => {
  const params = useParams();

  return <Container params={params} {...props} />;
};

export default ICompPatientDashboardSearch;
