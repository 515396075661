import { Created, Headers, Name, ProjectClass, Status } from '../elements';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'class',
    header: Headers.class,
    cell: ProjectClass,
    sortable: true
  },
  {
    name: 'created',
    header: Headers.created,
    cell: Created,
    sortable: true
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status
  }
];
