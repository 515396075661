const filterData = ({ data, filter }) => {
  const lowerCaseCpr = filter.cpr.toLowerCase();
  const lowerCaseName = filter.name.toLowerCase();

  const result = data.filter(
    item =>
      item.cpr.toLowerCase().indexOf(lowerCaseCpr) !== -1 &&
      item.name.toLowerCase().indexOf(lowerCaseName) !== -1
  );
  return result;
};

export default { filterData };
