import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import VersionMessage from 'fragments/version-message';
import WebAppVersion from 'fragments/web-app-version';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { getSessionKey, setAuthToken, setSessionKey } from 'api/auth-utils';
import { login } from 'api/authenticate';
import Box from 'components/box';
import ErrorMessage from 'components/error-message';
import Logo from 'components/logo';
import { getHomeRoute } from 'utils/vendor';
import { isDevVersion } from 'utils/version';

const Login = () => {
  const t = useTranslate('login');
  const navigate = useNavigate();
  const { form, onChanges, onSubmit, submitting, error, onClearError } =
    useForm({
      initialForm: {
        email: '',
        password: ''
      },
      apiSubmit: async form => {
        const { authToken, sessionKey } = await login({
          ...form,
          sessionKey: getSessionKey(form.email)
        });
        setAuthToken(authToken);
        setSessionKey(form.email, sessionKey);
        const homeRoute = getHomeRoute();
        navigate(homeRoute, { state: { from: 'login' } });
      }
    });

  return (
    <div className="login-form">
      <style>{`body > div,body > div > div,body > div > div > div.login-form {height: 100%;}`}</style>
      <Grid
        textAlign="center"
        style={{ height: '100%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Logo size="large" />

          <Box mt="1rem">
            <Form size="large" onSubmit={onSubmit}>
              <Segment stacked>
                <Form.Input
                  id="login-form-email"
                  data-cy="login__email-input"
                  fluid
                  required
                  icon="user"
                  iconPosition="left"
                  placeholder={t('email')}
                  type="email"
                  value={form.email}
                  onChange={(event, { value }) => onChanges.email(value)}
                />
                <Form.Input
                  id="login-form-password"
                  data-cy="login__password-input"
                  fluid
                  required
                  icon="lock"
                  iconPosition="left"
                  placeholder={t('password')}
                  type="password"
                  value={form.password}
                  onChange={(event, { value }) => onChanges.password(value)}
                />
                <Button
                  id="login-form-submit"
                  data-cy="login__submit-button"
                  type="submit"
                  fluid
                  color="blue"
                  size="large"
                  loading={submitting}
                  content={t('login')}
                />
                <Link
                  to={routes.forgotPassword()}
                  style={{ display: 'inline-block', marginTop: '1rem' }}
                >
                  {t('forgot-password')}
                </Link>
              </Segment>
            </Form>
          </Box>

          <ErrorMessage
            data-cy="login-error-message"
            error={error}
            onDismiss={onClearError}
            attached="bottom"
          />

          <WebAppVersion />

          {isDevVersion() && <VersionMessage position="login" />}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Login;
