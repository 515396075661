import useDatesCSV from './use-dates-csv';
import useDatesRaw from './use-dates-raw';

const useDatesMap = {
  CSV: useDatesCSV,
  Raw: useDatesRaw
};

const useDates = ({ exportType, date, stream }) => {
  return useDatesMap[exportType](date, stream);
};

export default useDates;
