import React, { useContext } from 'react';
import moment from 'moment';
import { UserContext } from 'contexts';
import { useForm, useStep } from 'hooks';
import { useTranslate } from 'intl';
import { Grid, Header } from 'semantic-ui-react';
import NewTreatmentForm from '../new-treatment-modal/form';
import NewPatientForm from './form';

const NewPatientModalBody = ({ defaultCpr, onSubmit: apiSubmit, onCancel }) => {
  const t = useTranslate('icomp-patient');
  const { user } = useContext(UserContext);
  const { form, submitting, error, onChanges, onSubmit } = useForm({
    initialForm: {
      cpr: defaultCpr || '',
      phone: '',
      firstName: '',
      lastName: '',
      // ---
      session: '1',
      treatment: '1',
      therapist: user.firstName ? `${user.firstName} ${user.lastName}` : '',
      date: moment().format(),
      // ---
      sensor: '',
      // ---
      bandage: '',
      side: '',
      // ---
      diagnose: '',
      circumsphere: '',
      note: ''
    },
    apiSubmit,
    onSuccess: onCancel
  });

  const { currentStep, onNextStep, onBackStep } = useStep(1);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3" textAlign="center">
            {t('create-patient')}
            {` `}
            {`(${
              currentStep === 1
                ? t('create-patient-step1')
                : t('create-patient-step2')
            })`}
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column computer="14" tablet="16">
          {currentStep === 1 ? (
            <NewPatientForm
              form={form}
              onChanges={onChanges}
              submitting={submitting}
              error={error}
              onSubmit={onNextStep}
              onCancel={onCancel}
            />
          ) : (
            <NewTreatmentForm
              form={form}
              onChanges={onChanges}
              submitting={submitting}
              error={error}
              onSubmit={onSubmit}
              onCancel={onBackStep}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NewPatientModalBody;
