import { axiosClient } from 'api/axios-client';

export const deleteUser = async ({
  scopeId,
  userId
}: {
  scopeId: string;
  userId: string;
}) => {
  const payload = {
    scope_id: scopeId,
    user_id: userId
  };
  return axiosClient.post('/api/1.0/members/profile/delete', payload);
};
