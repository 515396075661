import React from 'react';
import { useTranslate } from 'intl';
import { PatientSensorSelect } from 'containers/patient/dashboard';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import DatePicker from 'components/date-picker';
import PhoneInput from 'components/phone-input';
import TimezoneSelect from 'components/timezone-select';
import Section from './section';

const SubjectForm = ({ mode, form, onChanges, validations }) => {
  const t = useTranslate('nfa-subjects');

  return (
    <React.Fragment>
      <Section>
        <Form.Group widths="equal">
          <Form.Field
            label={t('subject-id')}
            required
            style={{ minWidth: 0 }}
            control={Input}
            placeholder={t('subject-id')}
            value={form.subjectId}
            onChange={(event, { value }) => onChanges.subjectId(value)}
            error={
              validations?.subjectId
                ? { content: validations?.subjectId }
                : false
            }
          />
          <Form.Field
            label={t('phone-number')}
            required={mode === 'add'}
            style={{ minWidth: 0 }}
            control={PhoneInput}
            countryCodeList="NFA-2"
            value={form.phoneNumber}
            onChange={(event, { value }) => onChanges.phoneNumber(value)}
            locked={form.phoneLocked}
            onToggleLocked={value => onChanges.phoneLocked(value)}
            error={
              validations?.phoneNumber
                ? { content: validations?.phoneNumber }
                : false
            }
          />
        </Form.Group>

        <Form.Field
          label={t('timezone')}
          required
          control={TimezoneSelect}
          value={form.timezone}
          onChange={(event, { value }) => onChanges.timezone(value)}
          error={
            validations?.timezone ? { content: validations?.timezone } : false
          }
        />
      </Section>

      <Section>
        <Form.Field
          label={t('measurement-method')}
          control={Dropdown}
          selection
          placeholder={t('measurement-method')}
          options={['1-sensor', '2-sensors'].map(item => ({
            key: item,
            text: t(`measurement-method/${item}`),
            value: item
          }))}
          value={form.measurementMethod}
          onChange={(event, { value }) => onChanges.measurementMethod(value)}
          error={
            validations?.measurementMethod
              ? { content: validations?.measurementMethod }
              : false
          }
        />

        {['1-sensor', '2-sensors'].includes(form.measurementMethod) && (
          <PatientSensorSelect
            label={t('sensor/thigh')}
            placeholder={t('select-sensor')}
            value={form.sensorThigh}
            onChange={(event, { value }) => onChanges.sensorThigh(value)}
            required
            error={
              validations?.sensorThigh
                ? { content: validations?.sensorThigh }
                : false
            }
          />
        )}

        {['2-sensors'].includes(form.measurementMethod) && (
          <PatientSensorSelect
            label={t('sensor/chest')}
            placeholder={t('select-sensor')}
            value={form.sensorChest}
            onChange={(event, { value }) => onChanges.sensorChest(value)}
            required
            error={
              validations?.sensorChest
                ? { content: validations?.sensorChest }
                : false
            }
          />
        )}
      </Section>

      <Section>
        <Form.Field
          label={t('expected-project-start')}
          required
          control={DatePicker}
          isClearable={true}
          value={form.expectedStartTime}
          onChange={(event, { value }) => onChanges.expectedStartTime(value)}
          error={
            validations?.expectedStartTime
              ? { content: validations?.expectedStartTime }
              : false
          }
        />

        <Form.Group widths="equal">
          <Form.Field
            label={t('project-start')}
            control={DatePicker}
            type="date-time"
            isClearable={true}
            value={form.startTime}
            onChange={(event, { value }) => onChanges.startTime(value)}
            error={
              validations?.startTime
                ? { content: validations?.startTime }
                : false
            }
          />
          <Form.Field
            label={t('project-end')}
            control={DatePicker}
            type="date-time"
            isClearable={true}
            value={form.endTime}
            onChange={(event, { value }) => onChanges.endTime(value)}
            error={
              validations?.endTime ? { content: validations?.endTime } : false
            }
          />
        </Form.Group>
      </Section>
    </React.Fragment>
  );
};

export default SubjectForm;
