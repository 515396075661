import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import Dash from 'components/dash';

export const MeasurementMethod = ({ data }) => {
  const t = useTranslate('browse-data');

  if (!data.measurementMethod) return <Dash />;

  return <div>{t(`alg_profile:${data.measurementMethod}`)}</div>;
};

MeasurementMethod.propTypes = {
  data: PropTypes.object.isRequired
};
