import React from 'react';
import PropTypes from 'prop-types';
import Plot from '../plot';

const Average = ({ data }) => {
  return <Plot data={data.average} />;
};

Average.propTypes = {
  data: PropTypes.object.isRequired
};

export default Average;
