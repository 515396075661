import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import { ProjectClass } from 'containers/project';
import { Dropdown, Header } from 'semantic-ui-react';
import { fetchOrganization } from 'api/organizations';

const getDefaultProjectView = (organizationId, project) => {
  const view = project.views[0];
  const path = view.route(organizationId, project.id);
  return path;
};

const ProjectSelect = () => {
  const t = useTranslate('menu');
  const { organizationId, projectId } = useParams();
  const { data: organization = {} } = useFetch({
    source: organizationId,
    apiFetch: fetchOrganization
  });

  const projects = organization?.projects || [];
  const active = projects.find(item => item.id === projectId);

  return (
    <Dropdown
      item
      position="left"
      disabled={!projectId}
      trigger={
        <div>
          <div style={{ fontWeight: 700 }}>{t('project-select')}</div>
          <div style={{ marginTop: '0.25rem' }}>
            {active ? active.name : <i>{t('please-select')}</i>}
          </div>
        </div>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Header>
          {t('projects', { organization: organization?.name || '' })}
        </Dropdown.Header>
        <Dropdown.Item as={Link} to={routes.organizations()}>
          <i>{t('change-organization')}</i>
        </Dropdown.Item>
        {projects.map(p => (
          <Dropdown.Item
            key={p.id}
            active={p.id === projectId}
            as={Link}
            to={getDefaultProjectView(organizationId, p)}
          >
            <Header>
              <Header.Content as="h5" style={{ margin: 0 }}>
                {p.name}
              </Header.Content>
              <Header.Subheader as="h6" style={{ fontSize: '0.75rem' }}>
                <ProjectClass data={p} />
              </Header.Subheader>
            </Header>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProjectSelect;
