import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useFetch } from 'hooks';
import { fetchMeasurement } from 'api/measurements';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';
import Plots from './plots';

const MeasurmentDetails = () => {
  const { organizationId, projectId, measurementId } = useParams();

  const {
    fetching,
    data = {},
    error
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId, measurementId }),
    isJsonSource: true,
    apiFetch: fetchMeasurement
  });

  const { sensors, startTime, endTime } = data;

  const defaultDate = useMemo(() => {
    const today = moment().startOf('day');
    if (!endTime) {
      return today.format();
    }
    const endDate = moment(endTime).startOf('day');
    const date = endDate.isAfter(today) ? today : endDate;
    return date.format();
  }, [endTime]);

  const minDate = useMemo(
    () => moment(startTime).startOf('day').format(),
    [startTime]
  );
  const maxDate = useMemo(
    () => moment(endTime).startOf('day').format(),
    [endTime]
  );

  if (fetching) {
    return <LoadingSection />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Plots
      defaultDate={defaultDate}
      minDate={minDate}
      maxDate={maxDate}
      sensors={sensors}
    />
  );
};

export default MeasurmentDetails;
