import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import DateSelect from 'components/plot/date-select';
import StreamSelect from 'components/plot/stream-select';
import PlotDerived from './plot-derived';
import PlotRaw from './plot-raw';
import Sensor from './sensor';

const getStreamType = stream => stream.split(':')[0];

const MeasurmentDetailsPlotsFragment = ({
  defaultDate,
  minDate,
  maxDate,
  sensors,
  streamOptions
}) => {
  const t = useTranslate('measurement');

  const [date, setDate] = useState(defaultDate);
  const [streams, setStreams] = useState(streamOptions);

  const derivedStreams = useMemo(
    () => streams.filter(item => getStreamType(item) === 'derived'),
    [streams]
  );
  const rawStreams = useMemo(
    () => streams.filter(item => getStreamType(item) === 'raw'),
    [streams]
  );
  const rawStreamOptions = useMemo(
    () => streamOptions.filter(item => getStreamType(item) === 'raw'),
    [streamOptions]
  );

  return (
    <div>
      <Grid columns={2} verticalAlign="middle">
        <Grid.Column>
          <DateSelect
            value={date}
            onChange={setDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <StreamSelect
            multiple
            value={streams}
            onChange={setStreams}
            options={streamOptions}
          />
        </Grid.Column>
      </Grid>

      {derivedStreams.map(stream => {
        const [, streamValue] = stream.split(':');
        return (
          <Segment padded raised key={stream} style={{ marginTop: '4rem' }}>
            <Header as="h4">{t(`alg_profile:${streamValue}`)}</Header>
            <PlotDerived date={date} stream={stream} />
          </Segment>
        );
      })}

      {rawStreams.map(stream => {
        const [, streamValue] = stream.split(':');
        return (
          <Segment padded raised key={stream} style={{ marginTop: '4rem' }}>
            {sensors.map((sensor, index) => (
              <div key={sensor.id}>
                <Header as="h4">
                  {t(`alg_profile:${streamValue}`)}
                  {` - `}
                  <Sensor data={sensor} />
                </Header>
                <PlotRaw
                  date={date}
                  stream={stream}
                  sensor={sensor.id}
                  streamOptions={rawStreamOptions}
                />
                {sensors.length > 0 && index < sensors.length - 1 && (
                  <Divider section />
                )}
              </div>
            ))}
          </Segment>
        );
      })}
    </div>
  );
};

MeasurmentDetailsPlotsFragment.propTypes = {
  defaultDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  sensors: PropTypes.array.isRequired,
  streamOptions: PropTypes.array.isRequired
};

export default MeasurmentDetailsPlotsFragment;
