import React from 'react';
import Body from 'containers/settings';
import Layout from 'layouts/sens';

const PageSettings = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageSettings;
