import React from 'react';
import styles from './styles.module.css';

const ICompAdminUserProfileField = ({ label, value }) => {
  return (
    <div className={styles.field}>
      <div className={styles.label}>{label}:</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default ICompAdminUserProfileField;
