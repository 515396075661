import React from 'react';
import { i18n, useIntl } from 'intl';
import { Dropdown, Flag } from 'semantic-ui-react';
import { setCookie } from 'utils/cookies';

const flags = {
  en: 'us',
  da: 'dk'
};
const texts = {
  en: 'English',
  da: 'Danish'
};
const options = ['en', 'da'].map(locale => ({
  key: locale,
  value: locale,
  flag: flags[locale],
  text: texts[locale]
}));

const LanguageSelect = () => {
  const { language } = useIntl();

  const onChangeLanguage = language => {
    i18n.changeLanguage(language);
    setCookie('i18next', language);
  };

  return (
    <Dropdown
      item
      trigger={
        <div>
          <Flag name={flags[language]} />
          {texts[language]}
        </div>
      }
      options={options}
      value={language}
      onChange={(event, { value }) => onChangeLanguage(value)}
    />
  );
};

export default LanguageSelect;
