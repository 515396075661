import React from 'react';
import { Container, Loader } from 'semantic-ui-react';

const MonitorLoader = () => {
  return (
    <Container style={{ padding: '10rem 0' }}>
      <Loader active inline="centered" />
    </Container>
  );
};

export default MonitorLoader;
