import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch, useForm } from 'hooks';
import { useTranslate } from 'intl';
import { ButtonCancel, ButtonGroup, ButtonOk } from 'fragments/icomp-modal';
import { Form, Header, Input, Message, Select } from 'semantic-ui-react';
import { fetchDepartments } from 'api/icomp-departments';
import { addUser } from 'api/icomp-users';
import Box from 'components/box';
import ErrorMessage from 'components/error-message';

const ICompAdminUserRegistration = () => {
  const t = useTranslate('icomp-admin-user');

  const navigate = useNavigate();

  const { hospitalId, departmentId } = useParams();
  const scopeId = departmentId || hospitalId;

  const onBack = () => navigate(-1);

  const { data: departments = [] } = useFetch({
    apiFetch: fetchDepartments,
    source: JSON.stringify({ hospitalId }),
    isJsonSource: true
  });

  const { form, submitting, error, onChanges, onSubmit } = useForm({
    initialForm: {
      name: '',
      initials: '',
      email: '',
      departments: [],
      roles: []
    },
    apiSubmit: payload => addUser({ scopeId, ...payload }).then(onBack)
  });

  return (
    <div>
      <Header as="h2" textAlign="center">
        {t('registration-header')}
      </Header>

      <Box mt="4rem">
        <Form error={!!error} onSubmit={onSubmit}>
          <Form.Group widths={3}>
            <Form.Field
              control={Input}
              label={t('name')}
              placeholder={t('name')}
              required
              value={form.name}
              onChange={(event, { value }) => onChanges.name(value)}
            />
            <Form.Field
              control={Input}
              label={t('initials')}
              placeholder={t('initials')}
              required
              value={form.initials}
              onChange={(event, { value }) => onChanges.initials(value)}
            />
            <Form.Field
              control={Input}
              label={t('email')}
              placeholder={t('email')}
              type="email"
              required
              value={form.email}
              onChange={(event, { value }) => onChanges.email(value)}
            />
          </Form.Group>

          <Form.Group widths={3}>
            <Form.Field
              control={Select}
              multiple
              label={t('assign-departments')}
              placeholder={t('select-departments')}
              options={departments.map(d => ({
                key: d.id,
                text: d.name,
                value: d.id
              }))}
              required
              error={error && error.key === 'departments-is-required'}
              value={form.departments}
              onChange={(event, { value }) => onChanges.departments(value)}
            />
            <Form.Field
              control={Select}
              multiple
              label={t('assign-roles')}
              placeholder={t('select-roles')}
              options={['admin', 'therapist', 'researcher'].map(role => ({
                key: role,
                text: t(`icomp-role:role/${role}`),
                value: `role/${role}`
              }))}
              required
              error={error && error.key === 'roles-is-required'}
              value={form.roles}
              onChange={(event, { value }) => onChanges.roles(value)}
            />
          </Form.Group>

          <Box mt="10rem">
            {error && (
              <Box mb="2rem">
                <ErrorMessage error={error} />
              </Box>
            )}
            <ButtonGroup>
              <ButtonCancel onClick={onBack} />
              <ButtonOk type="submit" loading={submitting}>
                {t('common:create')}
              </ButtonOk>
            </ButtonGroup>
          </Box>

          <Box mt="2rem">
            <Message info>
              <Message.Header>{t('registration-title')}</Message.Header>
              <p>{t('registration-description')}</p>
            </Message>
          </Box>
        </Form>
      </Box>
    </div>
  );
};

export default ICompAdminUserRegistration;
