import { useRef, useState } from 'react';

function useCountdown({
  value,
  interval,
  loop
}: {
  value: number;
  interval: number;
  loop: boolean;
}) {
  const [countdownValue, setCountdownValue] = useState(value);

  const intervalRef = useRef<number>();

  function start() {
    intervalRef.current = window.setInterval(() => {
      const nextCountdownValue = countdownValue - 1;
      if (nextCountdownValue < 0) {
        setCountdownValue(loop ? value : 0);
      } else {
        setCountdownValue(nextCountdownValue);
      }
    }, interval);
  }

  function stop() {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }

  function forceComplete() {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
    setCountdownValue(0);
  }

  return {
    value: countdownValue,
    progress: ((value - countdownValue) * 100) / value,
    start,
    stop,
    forceComplete
  };
}
export default useCountdown;
