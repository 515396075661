import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown, Grid, Header } from 'semantic-ui-react';

const views = ['3h', '24h', '7d'];

const NearbySensorDetailsHeader = ({ filter, onFilter, disabledFilter }) => {
  const t = useTranslate('sens-admin-nearby-sensor');

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={6}>
          <Header as="h2">{t('seen-events')}</Header>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Dropdown
            value={filter}
            onChange={onFilter}
            selection
            disabled={disabledFilter}
            options={views.map(view => ({
              text: t(`nearby-sensor.view-${view}`),
              value: view
            }))}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

NearbySensorDetailsHeader.propTypes = {
  filter: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  disabledFilter: PropTypes.bool.isRequired
};

export default NearbySensorDetailsHeader;
