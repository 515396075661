import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Fragment from './fragment';

const today = moment().format();
const format = date => moment(date).startOf('day').format();

class SensorDetailsBodyContainer extends Component {
  constructor(props) {
    super(props);

    const { sessions, dayStatistics, ...info } = props.data;

    const minDate = sessions.length ? format(sessions[0].startTime) : '';
    const maxDate = sessions.length
      ? format(sessions[sessions.length - 1].endTime)
      : '';
    const date = maxDate || today;

    this.state = { info, sessions, dayStatistics, date, minDate, maxDate };
    this.onUpdateInfo = this.onUpdate.bind(this, 'info');
    this.onUpdateDate = this.onUpdate.bind(this, 'date');
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.prevData) {
      const { sessions, ...info } = props.data;
      return { info, sessions, prevData: props.data };
    }
    return null;
  }

  onUpdate(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <Fragment
        {...this.state}
        onUpdateInfo={this.onUpdateInfo}
        onUpdateDate={this.onUpdateDate}
      />
    );
  }
}

SensorDetailsBodyContainer.propTypes = {
  data: PropTypes.object.isRequired
};

export default SensorDetailsBodyContainer;
