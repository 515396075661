import React, { useState } from 'react';
import { ProjectContext, UserContext } from 'contexts';
import 'intl/i18n';
import ErrorBoundary from './error-boundary';
import AppRouter from './router';
import ZendeskController from './zendesk-controller';

const App = () => {
  const [user, setUser] = useState(undefined);
  const [project, setProject] = useState({});
  return (
    <ErrorBoundary>
      <UserContext.Provider value={{ user, setUser }}>
        <ProjectContext.Provider value={{ project, setProject }}>
          <AppRouter />
          <ZendeskController />
        </ProjectContext.Provider>
      </UserContext.Provider>
    </ErrorBoundary>
  );
};

export default App;
