import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Header, Menu, Segment } from 'semantic-ui-react';
import Box from 'components/box';

const AdminNavbar = ({ children }) => {
  const t = useTranslate('menu');
  const { gatewayId } = useParams();
  if (!gatewayId) return null;

  return (
    <div>
      <Header as="h2">
        <NavLink to={routes.gateways()}>{t('gateways')}</NavLink>
      </Header>

      <Box mt="1rem">
        <Menu pointing secondary>
          <Menu.Item as={NavLink} to={routes.gatewayDetails(gatewayId)}>
            {t('details')}
          </Menu.Item>
          <Menu.Item as={NavLink} to={routes.gatewayReports(gatewayId)}>
            {t('reports')}
          </Menu.Item>
          <Menu.Item as={NavLink} to={routes.gatewayNearbySensors(gatewayId)}>
            {t('nearby-sensors')}
          </Menu.Item>
        </Menu>
      </Box>

      <Box mt="1rem">
        <Segment>{children}</Segment>
      </Box>
    </div>
  );
};

AdminNavbar.propTypes = {
  match: PropTypes.object.isRequired
};

export default AdminNavbar;
