import React from 'react';
import PropTypes from 'prop-types';
import { VictoryAxis, VictoryLabel } from 'victory';
import configs from './configs';

const getStyle = verticalLabel => ({
  axis: { stroke: configs.axisColor },
  axisLabel: verticalLabel
    ? { padding: 30, angle: -90 }
    : { padding: 40, angle: 0 },
  grid: { stroke: configs.gridColor, strokeWidth: configs.strokeWidthGrid },
  ticks: { stroke: configs.gridColor, strokeWidth: 1, size: 10 }
});

const AxisY = ({ tickValues, tickFormat, label, verticalLabel }) => (
  <VictoryAxis
    dependentAxis
    label={label}
    offsetX={50}
    style={getStyle(verticalLabel)}
    tickValues={tickValues}
    tickFormat={tickFormat}
    tickLabelComponent={
      <VictoryLabel
        style={{ fontSize: '10px' }}
        textAnchor="end"
        verticalAnchor="middle"
        dx={5}
      />
    }
  />
);

AxisY.propTypes = {
  tickValues: PropTypes.array,
  tickFormat: PropTypes.func,
  label: PropTypes.string,
  verticalLabel: PropTypes.bool
};

export default AxisY;
