import React, { useState } from 'react';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Dropdown, Modal, Radio } from 'semantic-ui-react';
import { useExportController } from '../context';
import DeleteAllFiles from './delete-all-files';
import styles from './styles.module.scss';
import Table from './table';
import { filterData } from './utils';

const SORT_VALUES = ['generation-date', 'due-date'];

export const ExportControllerModal = ({ open, onClose }) => {
  const t = useTranslate('export-controller');
  const [sort, onChangeSort] = useState('generation-date');
  const [descOrder, toggleDescOrder] = useToggle(true);
  const { isFetchingExportEntries, exportEntriesError, exportEntries } =
    useExportController();

  return (
    <Modal size="large" open={open} onClose={onClose}>
      <div className={styles.header}>
        <h2>{t('header')}</h2>

        <div className={styles.row}>
          <div className={styles.sort}>
            <div className={styles.dropdown}>
              <label>{`${t('common:sort-by')}: `}</label>
              <Dropdown
                selection
                options={SORT_VALUES.map(item => ({
                  key: item,
                  value: item,
                  text: t(`sort-by/${item}`)
                }))}
                value={sort}
                onChange={(e, { value }) => onChangeSort(value)}
              />
            </div>
            <div className={styles.toggle}>
              <Radio toggle checked={descOrder} onChange={toggleDescOrder} />
              <label>
                {descOrder ? t('common:order/desc') : t('common:order/asc')}
              </label>
            </div>
          </div>

          <div className={styles.delete}>
            <DeleteAllFiles />
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <Table
          fetching={isFetchingExportEntries}
          error={exportEntriesError}
          data={filterData(exportEntries, sort, descOrder)}
        />
      </div>

      <div className={styles.actions}>
        <Button primary onClick={onClose}>
          {t('common:close')}
        </Button>
      </div>
    </Modal>
  );
};
