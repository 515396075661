/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { VictoryArea, VictoryGroup } from 'victory';

const Dividers = ({ data }) => {
  // Draw dividers for bandaga changes.

  const makeDivider = (ts, pre, post) => {
    const chartData1 = [
      { x: ts - pre * 60 * 1000, y: 0 },
      { x: ts - pre * 60 * 1000, y: 1000 },
      { x: ts + post * 60 * 1000, y: 1000 },
      { x: ts + post * 60 * 1000, y: 0 }
    ];
    const chartData2 = [
      { x: ts - 5 * 60 * 1000, y: 0 },
      { x: ts - 5 * 60 * 1000, y: 1000 },
      { x: ts + 5 * 60 * 1000, y: 1000 },
      { x: ts + 5 * 60 * 1000, y: 0 }
    ];

    return (
      <VictoryGroup>
        <VictoryArea
          key={ts}
          style={{ data: { fill: '#2185d0', opacity: 0.25 } }}
          data={chartData1}
        />
        <VictoryArea
          key={ts + 1}
          style={{ data: { fill: '#2185d0' } }}
          data={chartData2}
        />
      </VictoryGroup>
    );
  };

  // Create a list of timestamps. Also add the final removal of bandage if it has been closed.
  const ts_list = data
    .map(meas => moment(meas.startTime).valueOf())
    .concat(
      data[data.length - 1].endTime
        ? moment(data[data.length - 1].endTime).valueOf()
        : [null]
    );

  return ts_list.map((ts, idx) => {
    if (ts) {
      return makeDivider(
        ts,
        idx === 0 ? 0 : 90,
        idx === ts_list.length - 1 ? 0 : 30
      );
    } else {
      return null;
    }
  });
};

Dividers.propTypes = {
  data: PropTypes.array.isRequired
};

export default Dividers;
