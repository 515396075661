import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Line } from 'components/plot/victory/elements';
import utils1 from 'components/plot/victory/temperature/utils';
import Dividers from './dividers';
import utils2 from './utils';

const utils = { ...utils1, ...utils2 };

const ICompTemperature = ({ startDate, endDate, values, measurements }) => {
  const t = useTranslate('plot');

  const { ts, v } = values.streams;

  const data = ts.map((t, i) => ({
    x: t,
    y: v[i]
  }));

  const diff = utils.getDiff(startDate, endDate);

  const domain = {
    x: utils.getXDomain(diff, startDate, endDate),
    y: utils.getYDomain(data)
  };

  const xTicks = utils.getXTicks(diff, domain);
  const xTickFormat = utils.getXTickFormat(diff);
  const xLabel = diff > 1 ? '' : t('time-in-hours');

  const yTicks = utils.getYTicks(domain, 10);

  return (
    <Chart domain={domain} width={800} height={400}>
      {AxisX({ tickValues: xTicks, label: xLabel, tickFormat: xTickFormat })}
      {AxisY({ tickValues: yTicks, label: '°C' })}
      {Line({ data, stroke: '#FF0000' })}
      {Dividers({ data: measurements })}
    </Chart>
  );
};

ICompTemperature.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  measurements: PropTypes.array.isRequired
};

export default ICompTemperature;
