import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'components/time-ago';

const getLastSyncedSortValue = data => {
  if (data.expired) return +Infinity;
  return data.runStateCurrent === 1
    ? new Date(data.lastSynced).getTime()
    : +Infinity;
};

const LastSynced = ({ data }) => {
  if (data.expired) {
    return <span>N/A</span>;
  }

  return (
    <TimeAgo value={data.runStateCurrent === 1 ? data.lastSynced : null} />
  );
};

LastSynced.propTypes = {
  data: PropTypes.shape({
    runStateCurrent: PropTypes.number,
    lastSynced: PropTypes.string
  }).isRequired
};

export { getLastSyncedSortValue };
export default LastSynced;
