import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  name: <Translate ns="monitor" i18nKey="name" />,
  'sensor-name': <Translate ns="monitor" i18nKey="sensor-name" />,
  days: <Translate ns="monitor" i18nKey="days" />,
  'total-activity': <Translate ns="monitor" i18nKey="total-activity" />,
  'today-activity': <Translate ns="monitor" i18nKey="today-activity" />,
  'yesterday-activity': <Translate ns="monitor" i18nKey="yesterday-activity" />,
  'total-steps': <Translate ns="monitor" i18nKey="total-steps" />,
  'today-steps': <Translate ns="monitor" i18nKey="today-steps" />,
  'yesterday-steps': <Translate ns="monitor" i18nKey="yesterday-steps" />,
  'last-mobilised': <Translate ns="monitor" i18nKey="last-mobilised" />,
  action: <Translate ns="monitor" i18nKey="action" />
};
