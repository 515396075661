import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Input } from 'semantic-ui-react';

const ICompDepartmentDashboard = ({ search, onSearch }) => {
  const t = useTranslate('icomp-admin-user');

  return (
    <Input
      value={search}
      onChange={onSearch}
      placeholder={t('common:search-by-name')}
      style={{ verticalAlign: 'top' }}
    />
  );
};

ICompDepartmentDashboard.propTypes = {
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default ICompDepartmentDashboard;
