import { fetchProjects } from './fetch-projects';

export const fetchProject = async ({
  organizationId,
  projectId
}: {
  organizationId: string;
  projectId: string;
}) => {
  const project = await fetchProjects({ organizationId })
    .then(projects => projects.find(item => item.id === projectId))
    .catch(() => null);
  return project;
};
