import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Line, ZoomIn } from '../elements';
import utils from '../utils/day';

const BatteryDay = ({ date, streams, onSelectTick }) => {
  const t = useTranslate('plot');

  const domain = {
    x: utils.getXDomain(date),
    y: [1, 3.5]
  };

  const xTicks = utils.getXTicks(domain);
  const yTicks = utils.getYTicks(domain, 0.5);

  const data = streams.v.map((e, i) => ({ x: streams.ts[i], y: e }));

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        label: t('time-in-hours')
      })}
      {AxisY({
        tickValues: yTicks,
        label: 'V',
        tickFormat: v => v.toFixed(1)
      })}

      {Line({ data, stroke: '#FF0000' })}

      {ZoomIn({
        range: 24,
        xstep: 60 * 60 * 1000,
        xcount: 24,
        domain,
        onSelectTick
      })}
    </Chart>
  );
};

BatteryDay.propTypes = {
  date: PropTypes.string.isRequired,
  streams: PropTypes.object.isRequired,
  onSelectTick: PropTypes.func.isRequired
};

export default BatteryDay;
