import React from 'react';

const Name = ({ data }) => <span>{data.name}</span>;

const Description = ({ data }) => <span>{data.description}</span>;

const NumOfSensors = ({ data }) => <span>{data.numSensors} </span>;

const NumOfUsers = ({ data }) => <span>{data.numUsers}</span>;

export { Name, Description, NumOfSensors, NumOfUsers };
