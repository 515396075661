import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import da from 'translations/da.json';
import en from 'translations/en.json';

i18n
  .use(detector)
  .use(initReactI18next)
  .on('languageChanged', lng => {
    document.documentElement.setAttribute('lang', lng);
  })
  .init({
    debug: true,
    resources: { en, da },
    fallbackLng: 'da',
    defaultNS: 'common',
    detection: {
      order: ['cookie']
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (data, format, locale) => {
        const { value, unit, options } = data;
        switch (format) {
          case 'formatDate':
            return new Intl.DateTimeFormat(locale, options).format(value);
          case 'formatRelativeTime':
            return new Intl.RelativeTimeFormat(locale, options).format(
              value,
              unit
            );
          case 'formatNumber':
            return new Intl.NumberFormat(locale, options).format(value);
          case 'formatPercent':
            return new Intl.NumberFormat(locale, options).format(value);
          default: {
            return value;
          }
        }
      }
    }
  });

export { i18n };
