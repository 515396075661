import { axiosClient } from 'api/axios-client';
import type { NearbySensor } from './types';

export const activateNearbySensor = async (sensor: NearbySensor) =>
  axiosClient
    .post('/gwapi/1.0/adminx/sensor/activate', {
      sensor_id: sensor.id
    })
    .then(() => {
      const response = { ...sensor };
      response.runStateRequested = 1;
      response.status = 'sensor_status/running-ok';
      return response;
    });

export const deactivateNearbySensor = async (sensor: NearbySensor) =>
  axiosClient
    .post('/gwapi/1.0/adminx/sensor/deactivate', {
      sensor_id: sensor.id
    })
    .then(() => {
      const response = { ...sensor };
      response.runStateRequested = 0;
      response.status = 'sensor_status/putting-to-sleep';
      return response;
    });

export const terminateNearbySensor = async (sensor: NearbySensor) =>
  axiosClient
    .post('/gwapi/1.0/adminx/sensor/terminate', {
      sensor_id: sensor.id
    })
    .then(() => {
      const response = { ...sensor };
      response.lifetimeState = 2;
      response.status = 'sensor_status/expired';
      return response;
    });
