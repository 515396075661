import moment from 'moment';
import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import { normalizeExportQueueEntry, type ExportQueueEntry } from 'api/export';
import type { SensorExportAction, SensorExportPayload } from '../types';

export const exportPDF = async (
  exportPayload: SensorExportPayload,
  exportAction?: SensorExportAction
) => {
  const {
    organizationId,
    projectId,
    sensorId,
    lang,
    startDate,
    endDate,
    stream
  } = exportPayload;

  const [, streamValue] = stream.split(':');

  if (exportAction === 'modal') {
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      sensor_id: sensorId,
      lang,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      alg_profile: streamValue,
      tz: 'Europe/Copenhagen'
    };
    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .get('/api/1.0/export/sensor/report', { params })
        .then(response => response.data);
    return normalizeExportQueueEntry(response.value.queue_entry);
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      sensor_id: sensorId,
      lang,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      alg_profile: streamValue,
      tz: 'Europe/Copenhagen',
      dt: downloadToken
    };
    const exportUrl = axiosClient.getUri({
      url: '/export/sensor/report',
      params
    });

    return exportUrl;
  }
};
