import React from 'react';
import PropTypes from 'prop-types';
import { useFilter, useSearch } from 'hooks';
import { Placeholder, Header as SHeader } from 'semantic-ui-react';
import Header from './header';
import Table from './table';
import utils from './utils';

const ProjectDashboardFragment = ({
  fetching,
  error,
  organizationName,
  data
}) => {
  const [filter, onFilter] = useFilter('all');
  const [search, onSearch] = useSearch({ uppercase: true });
  return (
    <React.Fragment>
      <Header
        search={search}
        onSearch={onSearch}
        filter={filter}
        onFilter={onFilter}
      />
      <SHeader as="h3">
        {fetching ? (
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line length="very short" />
            </Placeholder.Header>
          </Placeholder>
        ) : (
          organizationName
        )}
      </SHeader>
      <Table
        fetching={fetching}
        error={error}
        data={utils.filterData({ data, search, filter })}
      />
    </React.Fragment>
  );
};

ProjectDashboardFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array,
  organizationName: PropTypes.string
};

export default ProjectDashboardFragment;
