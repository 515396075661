import React from 'react';
import Body from 'containers/browse-data/dashboard';
import Layout from 'layouts/sens';

const BrowseDataPage = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default BrowseDataPage;
