import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { ActivePatient } from './types';

export const deletePatient = async ({
  params,
  patient
}: {
  patient: ActivePatient;
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
}) => {
  const payload = {
    org_id: params.organizationId,
    project_id: params.projectId,
    patient_id: patient.id
  };
  return axiosClient.post('/api/1.0/patients/delete', payload).then(() => ({
    id: patient.id
  }));
};
