import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Table from './table';

const NearbySensorDetailsFragment = ({
  fetching,
  error,
  data,
  view,
  setView
}) => (
  <React.Fragment>
    <Header filter={view} onFilter={setView} disabledFilter={fetching} />
    <Table fetching={fetching} error={error} data={data} />
  </React.Fragment>
);

NearbySensorDetailsFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array,
  // ---
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired
};

export default NearbySensorDetailsFragment;
