import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import styles from './styles.module.css';

const LoadingSection = ({ height, children }) => (
  <div style={{ height }} className={styles.center}>
    <Loader active inline="centered">
      {children}
    </Loader>
  </div>
);

LoadingSection.defaultProps = {
  height: '4rem'
};

LoadingSection.propTyeps = {
  height: PropTypes.string,
  children: PropTypes.node
};

export default LoadingSection;
