import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, useFilter, useSearch } from 'hooks';
import { useTranslate } from 'intl';
import { Header } from 'semantic-ui-react';
import { fetchDepartments } from 'api/icomp-departments';
import { fetchSensors } from 'api/icomp-sensors';
import Box from 'components/box';
import Filter from './filter';
import Table from './table';
import utils from './utils';

const ICompAdminSensors = () => {
  const t = useTranslate('icomp-admin-sensor');

  const { hospitalId, departmentId } = useParams();
  const scopeId = departmentId || hospitalId;

  const { data: departments = [] } = useFetch({
    source: JSON.stringify({ hospitalId }),
    isJsonSource: true,
    apiFetch: fetchDepartments
  });
  const {
    fetching,
    data: sensors = [],
    error
  } = useFetch({
    source: JSON.stringify({ scopeId }),
    isJsonSource: true,
    apiFetch: fetchSensors
  });

  const [search, onSearch] = useSearch({ uppercase: true });
  const [filter, onFilter] = useFilter('all');

  const filteredSensors = utils.filterData({ sensors, search, filter });

  const department = departmentId
    ? departments.find(i => i.id === departmentId)
    : null;

  return (
    <div>
      <Header as="h2" textAlign="center">
        {t('header')}
        {department && <Header.Subheader>{department.name}</Header.Subheader>}
      </Header>

      <Box align="right" mt="3rem">
        <Filter
          search={search}
          onSearch={onSearch}
          filter={filter}
          onFilter={onFilter}
        />
      </Box>

      <Box mt="1rem">
        <Table fetching={fetching} error={error} sensors={filteredSensors} />
      </Box>
    </div>
  );
};

export default ICompAdminSensors;
