import React from 'react';
import { Translate } from 'intl';

export { Patient } from './patient';
export { StartTime, EndTime, Duration } from './time';
export { MeasurementMethod } from './measurement-method';
export { SensorName } from './sensor-name';

export const Headers = {
  patient: <Translate ns="browse-data" i18nKey="patient" />,
  startTime: <Translate ns="browse-data" i18nKey="start-time" />,
  endTime: <Translate ns="browse-data" i18nKey="end-time" />,
  duration: <Translate ns="browse-data" i18nKey="duration" />,
  sensorName: <Translate ns="browse-data" i18nKey="sensor-name" />,
  measurementMethod: <Translate ns="browse-data" i18nKey="measurement-method" />
};
