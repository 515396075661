import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Icon, Modal } from 'semantic-ui-react';
import Body from './body';

const NewPatientModal = ({ defaultCpr, onSubmit }) => {
  const t = useTranslate('icomp-patient');
  const [open, toggleOpen] = useToggle(false);

  return (
    <Modal
      open={open}
      size="small"
      trigger={
        <Button
          icon
          labelPosition="right"
          color="blue"
          data-cy="patients__create-button"
          onClick={toggleOpen}
        >
          {t('common:create')}
          <Icon name="add" />
        </Button>
      }
    >
      <div style={{ padding: '2em 0' }}>
        <Body
          defaultCpr={defaultCpr}
          onSubmit={onSubmit}
          onCancel={toggleOpen}
        />
      </div>
    </Modal>
  );
};

NewPatientModal.propTypes = {
  defaultCpr: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default NewPatientModal;
