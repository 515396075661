import { useParams } from 'react-router-dom';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { editSubject } from 'api/nfa-subjects';
import { validateForm } from '../subject-create-modal/use-add-subject';

export const useEditSubject = ({ subject, onSuccess }) => {
  const t = useTranslate('nfa-subjects');
  const params = useParams();
  const { form, onChanges, validations, onSubmit, submitting, error } = useForm(
    {
      initialForm: {
        subjectId: subject.name,
        measurementMethod:
          subject.sensorThigh && subject.sensorChest ? '2-sensors' : '1-sensor',
        sensorThigh:
          subject.sensors.find(item => item.place === 'person/thigh')?.id || '',
        sensorChest:
          subject.sensors.find(item => item.place === 'person/chest')?.id || '',
        timezone: subject.timezone || 'Europe/Copenhagen',
        phoneNumber: subject.phoneNumber,
        phoneLocked: subject.phoneLocked,
        startTime: subject.startTime || '',
        endTime: subject.endTime || '',
        expectedStartTime: subject.expectedStartTime || '',
        note: subject.note || ''
      },
      validates: validateForm(t, 'edit'),
      apiSubmit: form =>
        editSubject({ params, subject, form }).then(response => {
          onSuccess(response);
        })
    },
    [onSuccess]
  );

  return { form, onChanges, validations, onSubmit, submitting, error };
};
