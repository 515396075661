import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown, Grid, Header } from 'semantic-ui-react';

const views = ['site', 'all24h', 'all1w'];

const GatewayDashboardHeader = ({ filter, onFilter, disabledFilter }) => {
  const t = useTranslate('sens-admin-gateway');

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={6}>
          <Header as="h2">{t('header')}</Header>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Dropdown
            value={filter}
            onChange={onFilter}
            selection
            disabled={disabledFilter}
            options={views.map(view => ({
              text: t(`gateway.view-${view}`),
              value: view
            }))}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

GatewayDashboardHeader.propTypes = {
  filter: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  disabledFilter: PropTypes.bool.isRequired
};

export default GatewayDashboardHeader;
