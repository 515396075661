import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Line, ZoomIn } from '../elements';
import utils from '../utils/day';

const PostureAngleDay = ({ date, streams, onSelectTick }) => {
  const t = useTranslate('plot');

  const domain = {
    x: utils.getXDomain(date),
    y: [-1, 1]
  };

  const xTicks = utils.getXTicks(domain);
  const yTicks = [-1, -0.5, 0, 0.5, 1];

  const values = streams;
  const dataA = values.map(e => ({
    x: e.ts,
    y: e.summary['angle/x/scalar']
  }));
  const dataB = values.map(e => ({
    x: e.ts,
    y: e.summary['angle/y/scalar']
  }));
  const dataC = values.map(e => ({
    x: e.ts,
    y: e.summary['angle/z/scalar']
  }));

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        label: t('time-in-hours')
      })}
      {AxisY({ tickValues: yTicks, label: 'A' })}

      {Line({ data: dataA, stroke: '#FF0000' })}
      {Line({ data: dataB, stroke: '#00FF00' })}
      {Line({ data: dataC, stroke: '#0000FF' })}

      {ZoomIn({
        range: 24,
        xstep: 60 * 60 * 1000,
        xcount: 24,
        domain,
        onSelectTick
      })}
    </Chart>
  );
};

PostureAngleDay.propTypes = {
  date: PropTypes.string.isRequired,
  streams: PropTypes.object.isRequired,
  onSelectTick: PropTypes.func.isRequired
};

export default PostureAngleDay;
