import React from 'react';
import PropTypes from 'prop-types';
import { VictoryChart } from 'victory';

const Chart = ({ domain, width, height, children }) => (
  <VictoryChart
    domain={domain}
    width={width}
    height={height}
    padding={{ top: 10, bottom: 80, left: 60, right: 20 }}
  >
    {children}
  </VictoryChart>
);

Chart.defaultProps = {
  width: 800,
  height: 210
};

Chart.propTypes = {
  domain: PropTypes.shape({
    x: PropTypes.array.isRequired,
    y: PropTypes.array.isRequired
  }),
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node
};

export default Chart;
