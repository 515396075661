import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nNotificationLog } from './normalize';
import type { NotificationLogRaw } from './types';

interface Params extends Pick<RouteParams, 'organizationId' | 'projectId'> {
  subjectId: string;
  content: string;
}

export const fetchNotificationLogs = async ({
  organizationId,
  projectId,
  subjectId
}: Params) => {
  const { notifications }: { notifications: NotificationLogRaw[] } =
    await axiosClient
      .get('/api/1.0/patients/notification_log', {
        params: {
          org_id: organizationId,
          project_id: projectId,
          patient_id: subjectId
        }
      })
      .then(response => response.data.value);

  return notifications.map(nNotificationLog);
};
