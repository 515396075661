import React from 'react';
import Body from 'containers/icomp-admin/sensor-overview';
import Layout from 'layouts/icomp-admin';

const PageICompAdminDepartmentSensorOverview = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageICompAdminDepartmentSensorOverview;
