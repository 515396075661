import { axiosClient } from 'api/axios-client';
import { nNearbySensor } from './normalize';
import type { NearbySensorRaw } from './types';

export const fetchNearbySensors = async ({
  gatewayId
}: {
  gatewayId: string;
}) => {
  const params = {
    gateway_id: gatewayId,
    nearby_sensors_hours: '48'
  };

  const { sensors }: { sensors: NearbySensorRaw[] } = await axiosClient
    .get('/gwapi/1.0/adminx/gateway/nearby_sensors', { params })
    .then(response => response.data.value);

  return sensors
    .map((item, index) => ({
      ...nNearbySensor(item),
      idx: index + 1
    }))
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
};
