import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchSensors, clearCacheSensors } from 'api/sensors';
import { getSensorParams } from 'utils/vendor';
import Container from './container';

const SensorDashboard = () => {
  const params = useParams();

  const {
    fetching,
    data = [],
    error
  } = useFetch({
    source: JSON.stringify(getSensorParams(params)),
    isJsonSource: true,
    apiFetch: fetchSensors,
    refresh: 20000,
    handleClearCache: () => {
      clearCacheSensors(getSensorParams(params));
    }
  });

  return <Container fetching={fetching} data={data} error={error} />;
};

export default SensorDashboard;
