/* eslint-disable */
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'intl';
import { useExportController } from 'fragments/export-controller';
import { getSensorParams } from 'utils/vendor';

const usePlotExport = ({
  startDate,
  endDate,
  stream,
  exportFormat,
  params: additionalParams,
  apiExport,
  exportAction = 'url' // 'url' | 'modal'
}) => {
  const { language } = useIntl();
  const params = useParams();

  const { addExportEntry, onOpenExportModal } = useExportController();
  const [exporting, setExporting] = useState(false);
  const [error, setError] = useState(null);

  const onExport = useCallback(() => {
    const payload = {
      ...getSensorParams(params),
      sensorId: params.sensorId,
      ...additionalParams,
      lang: language,
      stream: stream,
      startDate: startDate,
      ...(endDate ? { endDate: endDate } : {}),
      exportFormat: exportFormat
    };

    setExporting(true);

    if (exportAction === 'modal') {
      return apiExport(payload, exportAction)
        .then(entry => {
          addExportEntry(entry);
          onOpenExportModal();
        })
        .catch(error => {
          setExporting(false);
          setError(error);
          return Promise.reject(error);
        });
    }
    // exportAction === 'url'
    else {
      return apiExport(payload)
        .then(url => {
          setExporting(false);
          window.open(url);
        })
        .catch(error => {
          setExporting(false);
          setError(error);
          return Promise.reject(error);
        });
    }
  }, [
    additionalParams,
    startDate,
    endDate,
    stream,
    exportFormat,
    apiExport,
    language,
    params
  ]);

  return { exporting, onExport, error };
};

export default usePlotExport;
