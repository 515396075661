import React from 'react';
import PropTypes from 'prop-types';
import { VictoryBar, VictoryStack } from 'victory';

const Stack = ({ stacks }) => (
  <VictoryStack>
    {stacks.map((item, index) => (
      <VictoryBar
        key={index}
        data={item.data}
        style={{ data: { fill: item.color, strokeWidth: 0 } }}
        barRatio={0.8}
        alignment="start"
      />
    ))}
  </VictoryStack>
);

Stack.propTypes = {
  stacks: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      data: PropTypes.array
    })
  ).isRequired
};

export default Stack;
