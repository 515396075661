import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { exportMeasurement } from 'api/measurements';
import { useBulkExport } from './use-bulk-export';

const ExportMeasurement = ({
  interval,
  patients,
  onCompleted,
  disabled,
  children
}) => {
  const [exporting, onExport] = useBulkExport({
    exportAction: 'modal',
    apiExport: (exportParams, exportAction) => {
      return exportMeasurement({ ...exportParams, interval }, exportAction);
    },
    patients,
    onCompleted
  });

  return (
    <Button
      primary
      compact
      icon="download"
      content={children}
      loading={exporting}
      disabled={disabled}
      onClick={onExport}
    />
  );
};

ExportMeasurement.propTypes = {
  patients: PropTypes.array.isRequired,
  interval: PropTypes.oneOf(['1h', '15m', '5s']).isRequired
};

export default ExportMeasurement;
