import React, { useState } from 'react';
import clsx from 'clsx';
import { useFormatDate, useTranslate } from 'intl';
import { Form, Icon, Label } from 'semantic-ui-react';
import styles from './styles.module.css';

const getStatusColor = status => {
  switch (status) {
    case 'notification/status/delivered':
      return 'blue';
    case 'notification/status/failed':
      return 'orange';
    default:
      return 'grey';
  }
};

const NotificationLogs = ({ logs }) => {
  const t = useTranslate('nfa-subjects');
  const formatDate = useFormatDate();
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <Form className={styles.timeline}>
      <Form.Field>
        <label>{t('timeline')}</label>

        <div className={styles.logs}>
          {logs.map((item, index) => {
            const isActive = activeIndex === index;
            return (
              <div
                key={index}
                className={clsx(
                  styles['log-item'],
                  isActive && styles['log-item-active']
                )}
                onClick={() => setActiveIndex(isActive ? null : index)}
              >
                <div className={styles.time}>
                  {`${formatDate(
                    item.sentTimestamp,
                    'dd-mm-yyyy'
                  )} - ${formatDate(item.sentTimestamp, 'hh:mm')}`}
                </div>
                <div className={styles.status}>
                  <Label
                    size="small"
                    color={getStatusColor(item.status)}
                    style={{ borderRadius: '16px' }}
                  >
                    {t(item.status)}
                  </Label>
                </div>
                <div className={styles.message}>{item.content}</div>
                <Icon
                  className={styles.icon}
                  name={isActive ? 'caret up' : 'caret down'}
                />
              </div>
            );
          })}
        </div>
      </Form.Field>
    </Form>
  );
};

export default NotificationLogs;
