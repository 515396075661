import { axiosClient } from 'api/axios-client';
import type { ICompDepartmentForm } from './types';

export const addDepartment = async ({
  hospitalId,
  form
}: {
  hospitalId: string;
  form: ICompDepartmentForm;
}) => {
  const payload = {
    parent_scope_id: hospitalId,
    name: form.name,
    long_name: form.description,
    scope_class: 'scope/icomp_dept'
  };
  return axiosClient.post('/api/1.0/access_scopes/create_scope', payload);
};
