import React from 'react';
import { useTranslate } from 'intl';
import { Button, Icon, Modal } from 'semantic-ui-react';

const BulkExportEmptyWarningModal = ({ open, onClose }) => {
  const t = useTranslate('measurement-bulk-export');

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>{t('empty-warning-title')}</Modal.Header>
      <Modal.Content>
        <p style={{ fontSize: 'larger' }}>{t('empty-warning-desc')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button icon primary labelPosition="right" onClick={onClose}>
          {t('common:ok')}
          <Icon name="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BulkExportEmptyWarningModal;
