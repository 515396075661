/* eslint-disable */
import { useReducer } from 'react';
import orderBy from 'lodash.orderby';

function sortData({ data, sorted, direction, sorter }) {
  if (!sorted || !direction) {
    return data;
  }

  // Using custom sort function
  if (sorter) {
    let result = data.slice().sort(sorter);
    if (direction === 'descending') {
      result.reverse();
    }
    return result;
  }

  // Using default sort by sorted column
  return orderBy(data, [sorted], [direction === 'descending' ? 'desc' : 'asc']);
}

function init({ fetching, data, columns }) {
  const column = columns.find(item => item.sortable);
  const sorted = column ? column.name : null;
  const direction = column ? 'ascending' : null;
  const sorter = column ? column.sorter : null;

  return {
    fetching: fetching,
    data: sortData({ data, sorted, direction, sorter }),
    sorted: sorted,
    direction: direction
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_STATE': {
      const {
        state: { data, ...otherState },
        columns
      } = action.payload;
      if (data) {
        const column = columns.find(item => item.name === state.sorted);
        return {
          ...state,
          ...otherState,
          data: sortData({
            data: data,
            sorted: state.sorted,
            direction: state.direction,
            sorter: column ? column.sorter : null
          })
        };
      }
      return {
        ...state,
        ...otherState
      };
    }
    case 'UPDATE_SORT': {
      const { sorted, columns } = action.payload;

      const direction =
        sorted === state.sorted && state.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      const column = columns.find(item => item.name === sorted);

      return {
        ...state,
        sorted,
        direction,
        data: sortData({
          data: state.data,
          sorted,
          direction,
          sorter: column.sorter
        })
      };
    }
    default: {
      throw new Error();
    }
  }
}

function useTableReducer({ fetching, data, columns }) {
  const [state, dispatch] = useReducer(
    reducer,
    { fetching, data, columns },
    init
  );

  // Update state
  const onUpdate = state => {
    dispatch({
      type: 'UPDATE_STATE',
      payload: { state, columns }
    });
  };

  // Update sort
  const onSort = columnName => {
    dispatch({ type: 'UPDATE_SORT', payload: { sorted: columnName, columns } });
  };

  return {
    ...state,
    onUpdate,
    onSort
  };
}

export default useTableReducer;
