import { axiosClient } from 'api/axios-client';
import type { ScopeRaw } from 'api/scopes';
import type { SensorRaw } from 'api/sensors';
import { nICompSensor } from './normalize';

export const fetchSensors = async ({ scopeId }: { scopeId: string }) => {
  const params = { org_id: scopeId };

  const {
    sensors,
    scopes: departments
  }: { sensors: SensorRaw[]; scopes: ScopeRaw[] } = await axiosClient
    .get('/api/1.0/icompression/sensors', { params })
    .then(response => response.data.value);

  return sensors.map((sensor, index) => ({
    ...nICompSensor(sensor, departments),
    idx: index + 1
  }));
};
