import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFetch } from 'hooks';
import { fetchStreamOptions } from 'api/sensors';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';
import { getSensorParams } from 'utils/vendor';
import Fragment from './fragment';

const SensorDetailsPlots = ({
  defaultDate,
  minDate,
  maxDate,
  dayStatistics
}) => {
  const params = useParams();

  const {
    fetching,
    data: streamOptions = [],
    error
  } = useFetch({
    apiFetch: fetchStreamOptions,
    source: JSON.stringify(getSensorParams(params)),
    isJsonSource: true
  });

  if (fetching) {
    return <LoadingSection />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Fragment
      defaultDate={defaultDate}
      minDate={minDate}
      maxDate={maxDate}
      streamOptions={streamOptions}
      dayStatistics={dayStatistics}
    />
  );
};

SensorDetailsPlots.propTypes = {
  defaultDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  dayStatistics: PropTypes.object.isRequired
};

export default SensorDetailsPlots;
