import React, { useState } from 'react';
import { useToggleOpen } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { commandExportQueue } from 'api/export';
import { useExportController } from '../../context';

const DeleteAllFiles = () => {
  const t = useTranslate('export-controller');
  const [isOpenConfirmModal, onOpenConfirmModal, onCloseConfirmModal] =
    useToggleOpen(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { setExportEntries, onCloseExportModal } = useExportController();

  const onDeleteAll = () => {
    setIsDeleting(true);
    commandExportQueue({ resource_uuid: '', command: 'clear' })
      .then(() => {
        setExportEntries([]);
        onCloseExportModal();
        setIsDeleting(false);
        onCloseConfirmModal();
      })
      .catch(() => {
        setIsDeleting(false);
      });
  };

  return (
    <Modal
      size="tiny"
      open={isOpenConfirmModal}
      trigger={
        <Button color="orange" onClick={onOpenConfirmModal}>
          {t('delete-all-files')}
        </Button>
      }
    >
      <Modal.Header>{t('delete-all-files/confirm-title')}</Modal.Header>
      <Modal.Content>
        <p style={{ fontSize: 'larger' }}>
          {t('delete-all-files/confirm-desc')}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseConfirmModal}>{t('common:cancel')}</Button>
        <Button negative loading={isDeleting} onClick={onDeleteAll}>
          <Icon name="checkmark" />
          {t('common:ok')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteAllFiles;
