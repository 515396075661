import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import PatientData from './patient-data';
import TreatmentStatus from './treatment-status';

const PInfo = ({ patientDetails }) => {
  return (
    <Grid columns="equal">
      <Grid.Column stretched>
        <PatientData patientDetails={patientDetails} />
      </Grid.Column>

      <Grid.Column stretched>
        <TreatmentStatus patientDetails={patientDetails} />
      </Grid.Column>
    </Grid>
  );
};

PInfo.propTypes = {
  patientDetails: PropTypes.object.isRequired
};

export default PInfo;
