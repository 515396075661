// @ts-nocheck
import moment from 'moment';
import type {
  ICompPlotData,
  ICompPlotStream,
  ICompPlotValueRaw
} from './types';

const getTransitionX = (arr: number[]) => {
  const last = arr[arr.length - 1];
  return last + 1000;
};

export const nICompPlot = ({
  values,
  endDate,
  stream
}: {
  values: ICompPlotValueRaw[];
  endDate: string;
  stream: ICompPlotStream;
}): ICompPlotData => {
  const ts: number[] = [];
  const v: (number | null)[] = [];
  const flags: (number | null)[] = [];

  values.forEach(item => {
    const { values } = item.streams[0];

    if (values.ts.length) {
      ts.push(...values.ts);
      v.push(...values.v);
      if (stream === 'capacitanceDayNight') {
        flags.push(...values.is_day);
      }

      ts.push(getTransitionX(values.ts));
      v.push(null);
      if (stream === 'capacitanceDayNight') {
        flags.push(null);
      }
    }
  });

  // Issue #515
  const endDateTs = moment(endDate).valueOf();
  if (ts[ts.length - 1] < endDateTs) {
    ts.push(endDateTs);
    v.push(null);
    if (stream === 'capacitanceDayNight') {
      flags.push(null);
    }
  }

  const streams = { ts, v };

  if (stream === 'capacitanceDayNight') {
    return { streams, flags };
  }

  return { streams };
};
