import React from 'react';
import Body from 'containers/ble';
import Layout from 'layouts/sens';

const BLEPage = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default BLEPage;
