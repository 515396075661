import React from 'react';
import { useTranslate } from 'intl';
import { Container, Message } from 'semantic-ui-react';
import Logo from 'components/logo';

const MonitorEmty = ({ deviceId }) => {
  const t = useTranslate('monitor');
  return (
    <Container style={{ padding: '2rem 0' }}>
      <Logo />

      <Message size="large">
        <Message.Header>
          <h3> {`ID: ${deviceId}`}</h3>
          <p>{t('empty-title')}</p>
        </Message.Header>
        <Message.Content>
          <p>{t('empty-desc')}</p>
        </Message.Content>
      </Message>
    </Container>
  );
};

export default MonitorEmty;
