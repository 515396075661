import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from 'contexts';
import routes from 'routes';
import { fetchProject } from 'api/projects';
import localStore, { keys } from 'utils/local-store';
import { getVendor } from 'utils/vendor';

const useRedirect = organizations => {
  const navigate = useNavigate();
  const location = useLocation();
  const vendor = getVendor();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (
      vendor !== 'sens' ||
      location?.state?.from !== 'login' ||
      !user ||
      !organizations.length
    ) {
      return;
    }

    // Handle navigate to previous selected organization & project
    const handleNavigate = async () => {
      // Get previous stored organization & project
      const organizationId = localStore.get(user.id, keys.organizationId);
      const projectId = localStore.get(user.id, keys.projectId);

      // Validate project
      const project = await fetchProject({ organizationId, projectId });

      // If the project is valid, navigate to default route of that project
      if (project) {
        const view = project.views[0];
        const path = view.route(organizationId, project.id);
        navigate(path);
        return;
      }

      // Otherwise, navigate to Project Select of first organization
      const projectPath = routes.projects(organizations[0].id);
      navigate(projectPath);
    };

    handleNavigate();
  }, [vendor, organizations, navigate, location, user]);
};

export default useRedirect;
