import React from 'react';
import { Table } from 'semantic-ui-react';
import { Headers } from './headers';

const unsortableStyles = {
  pointerEvents: 'none',
  cursor: 'default'
};

const Cell = ({ config, sorted, direction, onSort, ...otherProps }) => (
  <Table.HeaderCell
    key={config.name}
    width={config.width || null}
    textAlign={config.align || null}
    sorted={sorted === config.name ? direction : null}
    onClick={config.sortable ? onSort.bind(null, config.name) : null}
    style={!config.sortable ? unsortableStyles : null}
    {...otherProps}
  >
    {config.header}
  </Table.HeaderCell>
);

export const CustomHeader = ({
  columns,
  selectable,
  selectableData,
  selectedData,
  onSelect,
  ...otherProps
}) => {
  return (
    <>
      <Table.Row>
        <Cell
          config={columns.find(item => item.name === 'name')}
          rowSpan="2"
          {...otherProps}
        />
        <Cell
          config={columns.find(item => item.name === 'sensor-name')}
          rowSpan="2"
          {...otherProps}
        />
        <Cell
          config={columns.find(item => item.name === 'days')}
          rowSpan="2"
          {...otherProps}
        />
        <Table.HeaderCell
          colSpan="2"
          style={unsortableStyles}
          textAlign="center"
        >
          {Headers['total-activity']}
        </Table.HeaderCell>
        <Table.HeaderCell
          colSpan="2"
          style={unsortableStyles}
          textAlign="center"
        >
          {Headers['total-steps']}
        </Table.HeaderCell>
        <Cell
          config={columns.find(item => item.name === 'last-mobilised')}
          rowSpan="2"
          {...otherProps}
        />
        <Cell
          config={columns.find(item => item.name === 'action')}
          rowSpan="2"
          {...otherProps}
        />
      </Table.Row>

      <Table.Row>
        <Cell
          config={columns.find(item => item.name === 'today-activity')}
          {...otherProps}
        />
        <Cell
          config={columns.find(item => item.name === 'yesterday-activity')}
          {...otherProps}
        />
        <Cell
          config={columns.find(item => item.name === 'today-steps')}
          {...otherProps}
        />
        <Cell
          config={columns.find(item => item.name === 'yesterday-steps')}
          {...otherProps}
        />
      </Table.Row>
    </>
  );
};
