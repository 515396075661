import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BATTERY_COLORS, BATTERY_ICONS } from 'consts/sensor';
import { Icon, Popup } from 'semantic-ui-react';
import styles from './styles.module.css';

const Name = ({ data, fluid }) => {
  return (
    <span className={fluid && styles.fluid}>
      {data.name}

      {data?.battery?.status &&
      data?.status !== 'sensor_status/expired' &&
      data?.status !== 'sensor_status/removed' ? (
        <span className={styles.battery}>
          <Popup
            content={`${data.battery.voltage} mV - ${moment(
              data.battery.timestamp
            ).fromNow()}`}
            trigger={
              <Icon
                name={BATTERY_ICONS[data.battery.status]}
                style={{ color: BATTERY_COLORS[data.battery.status] }}
              />
            }
          />
        </span>
      ) : (
        <></>
      )}
    </span>
  );
};

Name.propTypes = {
  data: PropTypes.object.isRequired
};

export default Name;
