import React from 'react';
import { LastOnline, LastSynced, Runtime, Status } from 'fragments/sensor';
import Control from './control';

// NOTE: control: need pass data from parent

const Name = ({ data }) => <span>{data.name}</span>;

const Delivery = ({ data }) => <span>{data.delivery}</span>;

export { Name, Status, Delivery, LastSynced, LastOnline, Runtime, Control };
