import React from 'react';
import { useTranslate } from 'intl';

const Name = ({ data }) => <span>{`${data.firstName} ${data.lastName}`}</span>;

const Email = ({ data }) => <span>{data.email}</span>;

const Initals = ({ data }) => <span>{data.initials}</span>;

const Departments = ({ data }) => (
  <span>
    {data.departmentsAndRoles.map(item => item.department.name).join(', ')}
  </span>
);

const Roles = ({ data, hospitalId }) => {
  const t = useTranslate('icomp-admin-user');
  const currentDepartment = data.departmentsAndRoles.find(
    item => item.department.id === hospitalId
  );
  return currentDepartment ? (
    <span>
      {currentDepartment.roles.map(role => t(`icomp-role:${role}`)).join(', ')}
    </span>
  ) : null;
};

export { Name, Email, Initals, Departments, Roles };
