import { useState } from 'react';
import moment from 'moment';

/*
  Default `StartTime` is current selected day at 00:00.
  Default `EndTime` is current selected day +24h.
  Max interval is 14 days.
*/

const useDatesCSV = date => {
  const [startDate, setStartDate] = useState(() =>
    moment(date).startOf('day').format()
  );

  const [endDate, setEndDate] = useState(() =>
    moment(date).startOf('day').add(24, 'hours').format()
  );

  const onChangeStartDate = (event, { value }) => {
    const startDateObj = moment(value);
    const endDateObj = moment(endDate);
    const diff = endDateObj.diff(startDateObj, 'days');
    setStartDate(startDateObj.format());
    if (diff > 14 || diff < 1) {
      setEndDate(moment(startDateObj).add(14, 'days').format());
    }
  };

  const onChangeEndDate = (event, { value }) => {
    const startDateObj = moment(startDate);
    const endDateObj = moment(value);
    const diff = endDateObj.diff(startDateObj, 'days');
    if (diff > 14 || diff < 1) {
      setStartDate(moment(endDateObj).add(-14, 'days').format());
    }
    setEndDate(endDateObj.format());
  };

  return {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate
  };
};

export default useDatesCSV;
