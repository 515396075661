import React from 'react';
import { useForm, useToggle } from 'hooks';
import { useTranslate } from 'intl';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';
import { Button, Form, Icon, Input } from 'semantic-ui-react';
import { editDepartment } from 'api/icomp-departments';
import Box from 'components/box';

const EditDepartment = ({ department }) => {
  const t = useTranslate('icomp-admin-department');

  const [open, toggleOpen] = useToggle(false);
  const { form, submitting, error, onChanges, onSubmit, onReset } = useForm({
    initialForm: {
      name: department.name || '',
      description: department.description || ''
    },
    apiSubmit: form => {
      return editDepartment({ department, form }).then(() => {
        window.location.reload();
      });
    }
  });

  return (
    <React.Fragment>
      <Button
        icon
        labelPosition="left"
        primary
        style={{ width: '13rem' }}
        onClick={toggleOpen}
      >
        <Icon name="edit" />
        {t('common:edit')}
      </Button>
      {open && (
        <Modal
          open
          size="tiny"
          title={
            <div style={{ textAlign: 'center' }}>{t('edit-department')}</div>
          }
          body={
            <Form onSubmit={onSubmit}>
              <Form.Field
                control={Input}
                label={t('name')}
                placeholder={t('name')}
                value={form.name}
                onChange={(event, { value }) => onChanges.name(value)}
                required
              />
              <Form.Field
                control={Input}
                label={t('description')}
                placeholder={t('description')}
                value={form.description}
                onChange={(event, { value }) => onChanges.description(value)}
                required
              />

              <Box mt="2.5rem">
                <ButtonGroup>
                  <ButtonCancel onClick={toggleOpen} />
                  <ButtonOk type="submit" loading={submitting} />
                </ButtonGroup>
              </Box>
            </Form>
          }
          error={error}
          onUnmount={onReset}
        />
      )}
    </React.Fragment>
  );
};

export default EditDepartment;
