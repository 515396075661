import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchPatients } from 'api/ispine-patients';
import Container from './container';

const ISpinePatientDashboard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { organizationId, projectId } = params;

  const {
    fetching,
    data = [],
    error
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId }),
    isJsonSource: true,
    apiFetch: fetchPatients
  });

  return (
    <Container
      params={params}
      navigate={navigate}
      fetching={fetching}
      data={data}
      error={error}
    />
  );
};

export default ISpinePatientDashboard;
