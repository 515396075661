import { useCallback, useState } from 'react';

function useZoom() {
  const [zoomId, setZoomId] = useState(undefined);
  const onZoomIn = useCallback((x, index) => {
    setZoomId(index);
  }, []);
  const onZoomOut = useCallback(() => {
    setZoomId(undefined);
  }, []);
  return { zoomId, onZoomIn, onZoomOut };
}

export default useZoom;
