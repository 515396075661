import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  seen: <Translate ns="icomp-patient" i18nKey="seen" />,
  status: <Translate ns="icomp-patient" i18nKey="status" />,
  cpr: <Translate ns="icomp-patient" i18nKey="cpr" />,
  name: <Translate ns="icomp-patient" i18nKey="name" />,
  department: <Translate ns="icomp-patient" i18nKey="department" />
};
