import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';

const ICompButtonOk = ({
  type,
  loading,
  disabled,
  children,
  'data-cy': dataCy,
  onClick
}) => {
  const t = useTranslate('common');

  return (
    <Button
      primary
      style={{ width: '7em' }}
      type={type}
      loading={loading}
      disabled={disabled}
      data-cy={dataCy}
      onClick={onClick}
    >
      {children || t('ok')}
    </Button>
  );
};

ICompButtonOk.defaultProps = {
  type: 'button'
};

ICompButtonOk.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.string,
  onClick: PropTypes.func
};

export default ICompButtonOk;
