import React from 'react';
import PropTypes from 'prop-types';
import Capacitance from './capacitance';
import Capacitance24h from './capacitance24h';
import CapacitanceDayNight from './capacitance-day-night';
import Temperature from './temperature';

const Plots = {
  capacitance: Capacitance,
  capacitance6h: Capacitance24h,
  capacitance6hadjust: Capacitance24h,
  capacitance24h: Capacitance24h,
  capacitanceDayNight: CapacitanceDayNight,
  temperature: Temperature
};

const ICompPlotVictory = ({
  stream,
  startDate,
  endDate,
  values,
  measurements
}) => {
  const Plot = Plots[stream];
  return (
    <Plot
      startDate={startDate}
      endDate={endDate}
      values={values}
      measurements={measurements}
    />
  );
};

ICompPlotVictory.propTypes = {
  stream: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  values: PropTypes.object.isRequired
};

export default ICompPlotVictory;
