import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import type { SensorRaw } from 'api/sensors';
import type { RouteParams } from 'app/types';
import { nActivePatient } from './normalize';
import type { ActivePatientRaw } from './types';

export const fetchPatients = async (
  {
    organizationId,
    projectId
  }: Pick<RouteParams, 'organizationId' | 'projectId'>,
  setCancel: CancelExecutor
) => {
  const params = {
    org_id: organizationId,
    project_id: projectId
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const {
    patients,
    sensors
  }: { patients: ActivePatientRaw[]; sensors: SensorRaw[] } = await axiosClient
    .get('/api/1.0/patients/extended', options)
    .then(response => response.data.value[0]);

  return patients.map((patient, index) => ({
    ...nActivePatient(patient, sensors),
    idx: index + 1
  }));
};
