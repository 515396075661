import React from 'react';
import clsx from 'clsx';
import { useTranslate } from 'intl';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

const Help = () => {
  const t = useTranslate('menu');

  return (
    <a
      className={clsx('ui item', styles.help)}
      href="https://support.sens.dk/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('help')}
      <i className="icon">
        <FontAwesomeIcon icon={faCircleQuestion} />
      </i>
    </a>
  );
};

export default Help;
