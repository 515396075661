const getYDomain = data => {
  const values = data.map(item => item.y).filter(item => item !== null);

  // let min = Math.min(...values);
  let max = Math.max(...values);

  // override to static interval
  // return [0, 150];

  if (max === Infinity) {
    return [0, 160];
  }

  // min = Math.floor(min / 100) * 100;
  max = Math.ceil(max / 20) * 20;

  if (max < 160) {
    return [0, 160];
  }

  return [0, max];
};

const getYStep = () => {
  return 20;
};

export default { getYDomain, getYStep };
