import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const Runtime = ({ data }) => {
  const t = useTranslate('time-ago');

  if (data.expired) {
    return <span>N/A</span>;
  }

  const minutes = Math.floor(data.runtime / 60);
  const hours = Math.floor(data.runtime / (60 * 60));
  const days = Math.floor(data.runtime / (60 * 60 * 24));
  let runtime = t('day', { count: days });
  if (days === 0) {
    runtime = t('hour', { count: hours });
  }
  if (hours === 0) {
    runtime = t('min', { count: minutes });
  }
  return <span>{runtime}</span>;
};

Runtime.propTypes = {
  data: PropTypes.object.isRequired
};

export default Runtime;
