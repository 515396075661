import React, { useCallback, useContext } from 'react';
import { UserContext } from 'contexts';
import Container from './container';

const Settings = () => {
  const { user, setUser } = useContext(UserContext);
  const onUpdate = useCallback(
    value => {
      const nextValue = { ...user, ...value };
      setUser(nextValue);
    },
    [user, setUser]
  );

  return <Container fetching={!user.email} data={user} onUpdate={onUpdate} />;
};

export default Settings;
