import { nSensor } from 'api/sensors';
import type {
  ExternalPatientMeasurement,
  ExternalPatientMeasurementRaw
} from './types';

export const nExternalPatient = (
  measurement: ExternalPatientMeasurementRaw
): ExternalPatientMeasurement => ({
  id: measurement.id,
  name: measurement.name,
  status: measurement.status,
  admittedTime: measurement.admitted_time,
  sensor: nSensor(measurement.sensor),
  today: measurement.data.today,
  yesterday: measurement.data.yesterday,
  average: measurement.data.average,
  raw: measurement
});
