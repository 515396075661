import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFetch, useSearch } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Button, Header, Icon } from 'semantic-ui-react';
import { fetchDepartments } from 'api/icomp-departments';
import { fetchUsers } from 'api/icomp-users';
import Box from 'components/box';
import Filter from './filter';
import Table from './table';
import utils from './utils';

const ICompAdminUsers = () => {
  const t = useTranslate('icomp-admin-user');

  const { hospitalId, departmentId } = useParams();
  const scopeId = departmentId || hospitalId;

  const { data: departments = [] } = useFetch({
    source: JSON.stringify({ hospitalId }),
    isJsonSource: true,
    apiFetch: fetchDepartments
  });
  const {
    fetching,
    data: users = [],
    error
  } = useFetch({
    source: JSON.stringify({ scopeId }),
    isJsonSource: true,
    apiFetch: fetchUsers
  });

  const [search, onSearch] = useSearch({ uppercase: true });

  const filteredUsers = utils.filterData(users, search);

  const department = departmentId
    ? departments.find(i => i.id === departmentId)
    : null;

  return (
    <div>
      <Header as="h2" textAlign="center">
        {t('user-oreverview')}
        {department && <Header.Subheader>{department.name}</Header.Subheader>}
      </Header>

      <Box align="right" mt="2rem">
        <Button
          primary
          icon
          labelPosition="right"
          as={Link}
          to={
            departmentId
              ? routes.iCompAdminDepartmentUserRegistration(
                  hospitalId,
                  departmentId
                )
              : routes.iCompAdminUserRegistration(hospitalId)
          }
        >
          {t('common:create')}
          <Icon name="add" />
        </Button>
      </Box>

      <Box align="right" mt="1rem">
        <Filter search={search} onSearch={onSearch} />
      </Box>

      <Box mt="1rem">
        <Table fetching={fetching} error={error} users={filteredUsers} />
      </Box>
    </div>
  );
};

export default ICompAdminUsers;
