import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchPlotDay, fetchPlotHour } from 'api/patients';
import {
  Fetcher,
  HourSelect,
  Layout,
  PlotName,
  StreamSelect
} from 'components/plot';

const MeasurmentPlotRaw = ({ date, stream, sensor, streamOptions }) => {
  const [hour, setHour] = useState('');
  const [streamPlotHour, setStreamPlotHour] = useState(stream);

  const apiFetchPlotDay = useCallback(
    (source, setCancel) =>
      fetchPlotDay({ ...source, sensorId: sensor.id }, setCancel),
    [sensor.id]
  );

  const apiFetchPlotHour = useCallback(
    (source, setCancel) =>
      fetchPlotHour({ ...source, sensorId: sensor.id }, setCancel),
    [sensor.id]
  );

  return (
    <>
      <PlotName stream={stream} sensor={sensor} />

      <Fetcher
        type="day"
        apiFetch={apiFetchPlotDay}
        date={date}
        stream={stream}
        onSelectTick={setHour}
      />

      {hour && (
        <Layout
          type="hour"
          timeSelect={<HourSelect value={hour} onChange={setHour} />}
          streamSelect={
            <StreamSelect
              value={streamPlotHour}
              onChange={setStreamPlotHour}
              options={streamOptions}
            />
          }
        >
          <Fetcher
            type="hour"
            apiFetch={apiFetchPlotHour}
            date={hour}
            stream={streamPlotHour}
          />
        </Layout>
      )}
    </>
  );
};

MeasurmentPlotRaw.propTypes = {
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired,
  sensor: PropTypes.string.isRequired,
  streamOptions: PropTypes.array.isRequired
};

export default MeasurmentPlotRaw;
