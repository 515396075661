import { createContext, useContext } from 'react';

export const ExportControllerContext = createContext({
  isOpenExportModal: false,
  onOpenExportModal: () => {},
  onCloseExportModal: () => {},
  isFetchingExportEntries: false,
  exportEntriesError: null,
  exportEntries: [],
  refetchExportEntries: () => {},
  setExportEntries: () => {},
  addExportEntry: () => {},
  removeExportEntry: () => {},
  updateExportEntry: () => {},
  downloadedUuids: [],
  setDownloadedUuids: () => {}
});

export const useExportController = () => {
  return useContext(ExportControllerContext);
};
