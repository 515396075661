import React from 'react';
import moment from 'moment';
import { FormatDate } from 'intl';
import Dash from 'components/dash';
import TimeDuration from 'components/duration';
import styles from './styles.module.css';

const today = moment();

export const StartTime = ({ data }) => {
  if (!data.startTime) return <Dash />;

  return (
    <span>
      <FormatDate value={data.startTime} format="dd-mm-yyyy" />
      <br />
      <span className={styles.sub}>
        <FormatDate value={data.startTime} format="hh:mm" />
      </span>
    </span>
  );
};

export const EndTime = ({ data }) => {
  if (!data.endTime) return <Dash />;

  return (
    <span>
      <FormatDate value={data.endTime} format="dd-mm-yyyy" />
      <br />
      <span className={styles.sub}>
        <FormatDate value={data.endTime} format="hh:mm" />
      </span>
    </span>
  );
};

export const Duration = ({ data }) => {
  if (!data.endTime) return <Dash />;

  return (
    <span className={styles.lowercase}>
      <TimeDuration
        from={data.startTime}
        to={
          // If the start time is past the current day,
          // show the duration from the start day until the current day
          // (even if there is no end time set).
          moment(data.startTime).isBefore(today)
            ? today.toISOString()
            : data.endTime
        }
      />
    </span>
  );
};
