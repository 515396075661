import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';
import Table from 'components/table';
import { columns } from './configs';

const OrganizationDashboardTable = props => {
  const navigate = useNavigate();

  const onRowClick = useCallback(
    rowData => {
      const { id: organizationId, projects } = rowData;

      if (projects.length === 1) {
        const p = projects[0];
        const view = p.views[0];
        const path = view.route(organizationId, p.id);
        navigate(path);
        return;
      }

      const path = routes.projects(organizationId);
      navigate(path);
    },
    [navigate]
  );

  return <Table {...props} columns={columns} onRowClick={onRowClick} />;
};

OrganizationDashboardTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default OrganizationDashboardTable;
