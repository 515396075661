import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { usePlotExport } from 'hooks';
import { exportCsvPlotHour, exportRawPlotHour } from 'api/measurements';
import Modal from '../../modal';
import useDates from './use-dates';

const apiExports = {
  CSV: exportCsvPlotHour,
  Raw: exportRawPlotHour
};

const MeasurementPlotsExportHourSelectPeriod = ({
  exportType,
  onResetExportType,
  // ---
  date,
  stream
}) => {
  const params = useParams();

  const { startDate, onChangeStartDate, endDate, onChangeEndDate } = useDates({
    exportType,
    date
  });

  const { exporting, onExport, error } = usePlotExport({
    startDate,
    endDate,
    stream,
    params: {
      measurementId: params.measurementId
    },
    apiExport: apiExports[exportType]
  });

  const inputType = 'date-time';

  const limit = { value: 30, unit: 'hour' };

  const onSubmit = () => onExport().then(onResetExportType);

  return (
    <Modal
      open
      // ---
      exportType={exportType}
      inputType={inputType}
      // ---
      limit={limit}
      // ---
      startDate={startDate}
      onChangeStartDate={onChangeStartDate}
      endDate={endDate}
      onChangeEndDate={onChangeEndDate}
      // ---
      onExport={onSubmit}
      onCancel={onResetExportType}
      exporting={exporting}
      error={error}
    />
  );
};

MeasurementPlotsExportHourSelectPeriod.propTypes = {
  exportType: PropTypes.oneOf(['CSV', 'Raw']).isRequired,
  onResetExportType: PropTypes.func.isRequired,
  // ---
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired
};

export default MeasurementPlotsExportHourSelectPeriod;
