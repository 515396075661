/* eslint-disable no-param-reassign */
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { addSubject } from 'api/nfa-subjects';

export const validateForm = (t, mode) => ({
  subjectId: form => {
    if (!form.subjectId) {
      return t('error:please-enter', { field: t('subject-id') });
    }
    return '';
  },
  measurementMethod: form => {
    if (!form.measurementMethod) {
      return t('error:please-select', { field: t('measurement-method') });
    }
    return '';
  },
  sensorThigh: form => {
    if (
      ['1-sensor', '2-sensors'].includes(form.measurementMethod) &&
      !form.sensorThigh
    ) {
      return t('error:please-select', { field: t('sensor-thigh') });
    }
    return '';
  },
  sensorChest: form => {
    if (['2-sensors'].includes(form.measurementMethod) && !form.sensorChest) {
      return t('error:please-select', { field: t('sensor-chest') });
    }
    return '';
  },
  phoneNumber: form => {
    if (mode === 'add' && !form.phoneNumber) {
      return t('error:please-enter', {
        field: t('phone-number')
      });
    }
    return '';
  },
  timezone: form => {
    if (!form.timezone) {
      return t('error:please-select', {
        field: t('timezone')
      });
    }
    return '';
  },
  expectedStartTime: form => {
    if (!form.expectedStartTime) {
      return t('error:please-select', { field: t('end-time') });
    }
    return '';
  }
});

export const useAddSubject = ({
  countRef,
  submitTypeRef,
  onClose,
  onSuccess
}) => {
  const t = useTranslate('nfa-subjects');
  const params = useParams();
  const { form, onChanges, onSubmit, validations, submitting, error, onReset } =
    useForm(
      {
        initialForm: {
          subjectId: '',
          measurementMethod: '1-sensor',
          sensorThigh: '',
          sensorChest: '',
          phoneNumber: '',
          phoneLocked: false,
          timezone: 'Europe/Copenhagen',
          startTime: '',
          endTime: '',
          expectedStartTime: moment().toISOString(),
          note: ''
        },
        validates: validateForm(t, 'add'),
        apiSubmit: form =>
          addSubject({ params, form }).then(response => {
            onSuccess(response);
            if (submitTypeRef.current === 2) {
              countRef.current += 1;
              onReset();
            } else {
              onClose();
            }
          })
      },
      [onSuccess]
    );

  return { form, onChanges, validations, onSubmit, submitting, error };
};
