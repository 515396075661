import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Grid, Header } from 'semantic-ui-react';
import { addTreatment } from 'api/icomp-patients';
import NewTreatmentForm from './form';
import { migrateForm } from './utils';

const NewTreatmentModal = ({
  header,
  patient,
  treatment,
  onSubmit: postSubmit,
  onCancel
}) => {
  const t = useTranslate('icomp-patient');
  const params = useParams();
  const { form, submitting, error, onChanges, onSubmit } = useForm(
    {
      initialForm: migrateForm({ patient, treatment }),
      apiSubmit: form => {
        const payload = { params, patient, treatment, form };
        return addTreatment(payload).then(postSubmit);
      }
    },
    [params, patient, treatment]
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3" textAlign="center">
            {header}
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column computer="14" tablet="16">
          <NewTreatmentForm
            form={form}
            onChanges={onChanges}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitting={submitting}
            error={error}
            cancelLabel={t('common:cancel')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NewTreatmentModal;
