import { createSorter } from 'utils/sort';

const getPatientValue = data => data.patient.name;

const getStartTimeValue = data => data.monitoring.startTime;

const getFeedbackValue = data => data.feedback.startTime;

const getSensorValue = data => data.sensor?.name;

export const sorters = {
  patient: createSorter(getPatientValue),
  phone: createSorter('phone'),
  monitoring: createSorter(getStartTimeValue),
  feedback: createSorter(getFeedbackValue),
  sensor: createSorter(getSensorValue),
  latestEvent: createSorter('latestEvent')
};
