import { nSensor } from 'api/sensors';
import type {
  HospitalPatient,
  HospitalPatientMeasurement,
  HospitalPatientMeasurementRaw,
  HospitalPatientRaw
} from './types';

const nHospitalPatient = (raw: HospitalPatientRaw): HospitalPatient => ({
  id: raw.id,
  name: raw.first_name,
  status: raw.meta?.discharged === '1' ? 'status/discharged' : 'status/active',
  startTime: raw.start_time,
  endTime: raw.end_time || undefined
});

export const nHospitalPatientMeasurement = (
  raw: HospitalPatientMeasurementRaw
): HospitalPatientMeasurement => ({
  id: raw.id,
  patient: nHospitalPatient(raw.patient),
  sensor: raw.places[0] ? nSensor(raw.places[0].sensor) : undefined,
  startTime: raw.start_time,
  endTime: raw.end_time,
  raw
});
