import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { RouteParams } from 'app/types';
import type {
  PatientExportLang,
  PatientExportSummarize,
  PatientPlotStream
} from '../types';

const createExportRaw =
  (dataFormat: PatientExportSummarize) =>
  async ({
    organizationId,
    projectId,
    measurementId,
    lang,
    startDate,
    endDate,
    stream
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'measurementId'> & {
    lang: PatientExportLang;
    startDate: string;
    endDate: string;
    stream: PatientPlotStream;
  }) => {
    const downloadToken = await fetchDownloadToken();
    const [streamType, streamValue] = stream.split(':');
    const params = {
      org_id: organizationId,
      project_id: projectId,
      measurement_id: measurementId,
      lang,
      start_time: startDate,
      end_time: endDate,
      ...(streamType === 'derived' ? { alg_profile: streamValue } : {}),
      ...(streamType === 'raw' ? { stream_name: streamValue } : {}),
      data_format: dataFormat,
      dt: downloadToken,
      show: 1
    };
    const exportUrl = axiosClient.getUri({
      url: `/export/measurement`,
      params
    });
    return exportUrl;
  };

export const exportRawDay = createExportRaw('summary1h');

export const exportRawHour = createExportRaw('detailed');
