const filterData = (users, search) => {
  const lowerCaseSearch = search.toLowerCase();
  const result = users.filter(item => {
    const name = `${item.firstName} ${item.lastName}`;
    return name.toLowerCase().indexOf(lowerCaseSearch) !== -1;
  });
  return result;
};

export default { filterData };
