import { createSorter } from 'utils/sort';

const getRolesValue = data => data.roles.join(', ');

const getNumActivePatientsValue = data => data.project.numActivePatients;

export const sorters = {
  name: createSorter('name'),
  roles: createSorter(getRolesValue),
  numActivePatients: createSorter(getNumActivePatientsValue)
};
