import React from 'react';
import PropTypes from 'prop-types';

const Name = ({ data }) => <span>{data.name}</span>;

Name.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default Name;
