import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/table';
import { columns } from './configs';

const ExternalPatientDashboardTable = props => {
  return <Table {...props} columns={columns} />;
};

ExternalPatientDashboardTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array.isRequired
};

export default ExternalPatientDashboardTable;
