import {
  Average,
  Headers,
  Name,
  Sensor,
  Status,
  Today,
  Yesterday
} from '../elements';

export const columns = [
  {
    name: 'status',
    header: Headers.status,
    cell: Status,
    sortable: true
  },
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    align: 'center',
    sortable: true
  },
  {
    name: 'average',
    header: Headers.average,
    cell: Average,
    align: 'center',
    width: 3,
    sortable: true
  },
  {
    name: 'yesterday',
    header: Headers.yesterday,
    cell: Yesterday,
    align: 'center',
    width: 3,
    sortable: true
  },
  {
    name: 'today',
    header: Headers.today,
    cell: Today,
    align: 'center',
    width: 3,
    sortable: true
  },
  {
    name: 'sensor',
    header: Headers.sensor,
    cell: Sensor,
    align: 'center',
    sortable: true
  }
];
