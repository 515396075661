import type { MeasurementMethod, MeasurementStatus } from 'api/measurements';
import type { SensorPlace } from 'api/sensors';

export type PatientProfile = 'person/default';

export type PatientStatus =
  | 'status/started'
  | 'status/not_started'
  | 'status/completed';

// ---

export interface PatientRaw {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  description: string;
  timezone: string;
  patient_type: string;
  profile: PatientProfile;
  alg_profile: MeasurementMethod;
  start_time: string;
  end_time: string;
  status: PatientStatus;
  attached_places: SensorPlace[];
  attached_sensors: string[];
  measurement_status: MeasurementStatus;
  latest_pp_event: string;
  meta: {
    age: string;
    weight: string;
    feedback_start_time: string;
    feedback_end_time: string;
  };
}

// ---

export interface Patient {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  description: string;
  timezone: string;
  age?: string;
  weight?: string;
  raw: PatientRaw;
}

// ---

export const nPatient = (raw: PatientRaw): Patient => ({
  id: raw.id,
  name: `${raw.first_name} ${raw.last_name}`,
  firstName: raw.first_name,
  lastName: raw.last_name,
  description: raw.description,
  timezone: raw.timezone,
  age: raw.meta?.age,
  weight: raw.meta?.weight,
  raw
});
