import React from 'react';
import PropTypes from 'prop-types';
import { VictoryLine } from 'victory';

const Line = ({ data, stroke, ...otherProps }) => (
  <VictoryLine
    data={data}
    style={{ data: { stroke, strokeWidth: 1 } }}
    {...otherProps}
  />
);

Line.propTypes = {
  data: PropTypes.array.isRequired,
  stroke: PropTypes.string.isRequired
};

export default Line;
