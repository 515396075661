import React from 'react';
import {
  Actions,
  Export,
  Feedback,
  Headers,
  LatestEvent,
  Monitoring,
  Patient,
  Phone,
  Sensor,
  sorters
} from '../elements';

const baseColumns = [
  {
    name: 'patient',
    header: Headers.patient,
    cell: Patient,
    sortable: true,
    sorter: sorters.patient
  },
  {
    name: 'phone',
    header: Headers.phone,
    cell: Phone,
    sortable: true,
    sorter: sorters.phone
  },
  {
    name: 'monitoring',
    header: Headers.monitoring,
    cell: Monitoring,
    sortable: true,
    sorter: sorters.monitoring
  },
  {
    name: 'feedback',
    header: Headers.feedback,
    cell: Feedback,
    sortable: true,
    sorter: sorters.feedback
  },
  {
    name: 'sensor',
    header: Headers.sensor,
    cell: Sensor,
    sortable: true,
    sorter: sorters.sensor
  },
  {
    name: 'latestEvent',
    header: Headers.latestEvent,
    cell: LatestEvent,
    sortable: true,
    sorter: sorters.latestEvent
  },
  {
    name: 'export',
    header: Headers.export,
    cell: Export,
    align: 'center'
  }
];

export const getColumns = ({ editMode, onEdit, onDelete }) => {
  if (!editMode) {
    return baseColumns;
  }

  return [
    ...baseColumns,
    {
      name: 'actions',
      header: Headers.actions,
      cell: ({ data }) => (
        <Actions data={data} onEdit={onEdit} onDelete={onDelete} />
      ),
      align: 'center'
    }
  ];
};
