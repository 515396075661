import { useCallback, useState } from 'react';

const useStep = initialStep => {
  const [currentStep, setCurrentStep] = useState(initialStep);

  const onNextStep = useCallback(() => {
    setCurrentStep(currentStep => currentStep + 1);
  }, []);

  const onBackStep = useCallback(() => {
    setCurrentStep(currentStep => currentStep - 1);
  }, []);

  return {
    currentStep,
    onSetStep: setCurrentStep,
    onNextStep,
    onBackStep
  };
};

export default useStep;
