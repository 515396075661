import React from 'react';
import { Translate } from 'intl';

export * from './patient-name';
export * from './time';
export * from './sensor';
export * from './patient-status';
export * from './actions';

export const Headers = {
  patientName: <Translate ns="hospital-patient" i18nKey="patient-name" />,
  startTime: <Translate ns="hospital-patient" i18nKey="start-time" />,
  endTime: <Translate ns="hospital-patient" i18nKey="end-time" />,
  sensor: <Translate ns="hospital-patient" i18nKey="sensor" />,
  patientStatus: <Translate ns="hospital-patient" i18nKey="patient-status" />,
  actions: <Translate ns="hospital-patient" i18nKey="actions" />
};
