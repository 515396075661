import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Divider, Grid, List, Placeholder } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import { LastOnline, LastReport, Name, Platform, Sentry } from '../elements';

const Loading = () => (
  <Placeholder>
    <Placeholder.Line length="very short" />
  </Placeholder>
);

const Row = ({ label, children }) => {
  const t = useTranslate('sens-admin-gateway');

  return (
    <Grid.Row>
      <Grid.Column width={2} style={{ fontWeight: 600 }}>
        {t(`gateway.${label}`)}
        {`: `}
      </Grid.Column>
      <Grid.Column width={14}>{children}</Grid.Column>
    </Grid.Row>
  );
};

const GatewayDetailsFragment = ({ fetching, error, data }) => {
  if (error) {
    return <ErrorMessage error={error} />;
  }
  return (
    <Grid>
      <Row label="name">{fetching ? <Loading /> : <Name data={data} />}</Row>
      <Row label="platform">
        {fetching ? <Loading /> : <Platform data={data} />}
      </Row>
      <Row label="sentry">
        {fetching ? <Loading /> : <Sentry data={data} />}
      </Row>
      <Row label="lastOnline">
        {fetching ? <Loading /> : <LastOnline data={data} />}
      </Row>
      <Row label="lastReport">
        {fetching ? <Loading /> : <LastReport data={data} />}
      </Row>

      <Divider />

      <Row label="parameters">
        <List relaxed>
          {fetching ? (
            <Loading />
          ) : (
            data.parameters &&
            Object.keys(data.parameters).map((key, index) => (
              <List.Item key={index}>
                <List.Header>{key}</List.Header>
                {data.parameters[key]}
              </List.Item>
            ))
          )}
        </List>
      </Row>

      <Divider />

      <Row label="stats">
        <List relaxed>
          {fetching ? (
            <Loading />
          ) : (
            data.stats &&
            Object.keys(data.stats).map((key, index) => (
              <List.Item key={index}>
                <List.Header>{key}</List.Header>
                {data.stats[key]}
              </List.Item>
            ))
          )}
        </List>
      </Row>
    </Grid>
  );
};

GatewayDetailsFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  data: PropTypes.object,
  // ---
  error: PropTypes.object
};

export default GatewayDetailsFragment;
