import React from 'react';
import { useTranslate } from 'intl';
import { Button, Icon, Modal } from 'semantic-ui-react';

const EmptyWarningModal = ({ open, onClose }) => {
  const t = useTranslate('browse-data');
  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>{t('pdf-report.empty-warning-title')}</Modal.Header>
      <Modal.Content>
        <p style={{ fontSize: 'larger' }}>
          {t('pdf-report.empty-warning-desc')}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button icon primary labelPosition="right" onClick={onClose}>
          {t('common:ok')}
          <Icon name="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EmptyWarningModal;
