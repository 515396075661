import routes from 'routes';

export const getVendor = () => {
  switch (process.env.REACT_APP_VENDOR) {
    case 'icomp':
      return 'icomp';
    case 'nfa':
      return 'nfa';
    case 'demos':
      return 'demos';
    case 'dynamic':
      if (window.location.host.includes('icomp')) {
        return 'icomp';
      } else if (window.location.host.includes('demos10')) {
        return 'demos';
      } else if (
        window.location.host.includes('nfa') ||
        window.location.host.includes('motus')
      ) {
        return 'nfa';
      } else if (window.location.host.includes('fibion')) {
        return 'fibion';
      } else {
        return 'sens';
      }
    default:
      return 'sens';
  }
};

export const getSensorParams = params => {
  const vendor = getVendor();
  switch (vendor) {
    case 'icomp':
      return {
        organizationId: params.departmentId,
        projectId: params.projectId
      };
    default:
      return {
        organizationId: params.organizationId,
        projectId: params.projectId
      };
  }
};

export const getSensorDetailsRoute = (params, sensor) => {
  const vendor = getVendor();
  switch (vendor) {
    case 'icomp': {
      const { hospitalId, departmentId, projectId } = params;
      const route = routes.iCompSensorDetails(
        hospitalId,
        departmentId,
        projectId,
        sensor.id
      );
      return route;
    }
    default: {
      const { organizationId, projectId } = params;
      const route = routes.sensorDetails(organizationId, projectId, sensor.id);
      return route;
    }
  }
};

export const getHomeRoute = () => {
  const vendor = getVendor();
  switch (vendor) {
    case 'icomp': {
      return routes.iCompDepartments();
    }
    default: {
      return routes.organizations();
    }
  }
};
