import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button, Segment } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import Table from 'components/table';
import { getColumns } from './configs';

const NearbySensorDashboardTableControlFragment = ({
  fetching,
  parentError,
  data,
  submitting,
  target,
  error,
  onExit,
  onActivate,
  onDeactivate,
  onTerminate
}) => {
  const t = useTranslate('sens-admin-nearby-sensor');
  const columns = getColumns({
    submitting,
    target,
    onActivate,
    onDeactivate,
    onTerminate
  });

  return (
    <React.Fragment>
      <Table
        fetching={fetching}
        error={parentError}
        data={data}
        columns={columns}
      />
      <ErrorMessage error={error} />
      <Segment basic clearing style={{ padding: '0' }}>
        <Button
          content={t('exit-control-mode')}
          icon="cancel"
          floated="right"
          onClick={onExit}
        />
      </Segment>
    </React.Fragment>
  );
};

NearbySensorDashboardTableControlFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  parentError: PropTypes.node,
  data: PropTypes.array.isRequired,
  // ---
  onExit: PropTypes.func.isRequired,
  // ---
  submitting: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onTerminate: PropTypes.func.isRequired,
  // ---
  error: PropTypes.object
};

export default NearbySensorDashboardTableControlFragment;
