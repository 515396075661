import React from 'react';
import { Header } from 'semantic-ui-react';
import styles from './styles.module.css';

const PBlock = ({ 'data-cy': dataCy, header, children }) => (
  <div className={styles.main} data-cy={dataCy}>
    <div className={styles.header}>
      <Header as="h5">{header}</Header>
    </div>
    <div className={styles.body}>{children}</div>
  </div>
);

export default PBlock;
