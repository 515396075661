import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslate } from 'intl';
import routes from 'routes';
import Dash from 'components/dash';

export const SensorName = ({ data }) => {
  const t = useTranslate('browse-data');
  const { organizationId, projectId } = useParams();

  if (data.status === 'status/no_sensor') {
    return <Dash />;
  }

  return (
    <div>
      {data.sensors.map(sensor => (
        <div key={sensor.id}>
          {t(`alg_profile:${sensor.place}`)}:{' '}
          <Link to={routes.sensorDetails(organizationId, projectId, sensor.id)}>
            {sensor.name}
          </Link>
        </div>
      ))}
    </div>
  );
};
