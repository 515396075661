import { useCallback, useEffect, useRef, useState } from 'react';

const useLongPress = ({
  onCompleted,
  timeout
}: {
  onCompleted: () => void;
  timeout: number;
}) => {
  const [startLongPress, setStartLongPress] = useState(false);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  function clearTimerId() {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
      timerIdRef.current = null;
    }
  }

  useEffect(() => {
    if (startLongPress) {
      timerIdRef.current = setTimeout(() => {
        onCompleted();
      }, timeout);
    } else {
      clearTimerId();
    }
    return () => {
      clearTimerId();
    };
  }, [startLongPress, onCompleted, timeout]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  return {
    eventHandlers: {
      onMouseDown: start,
      onMouseUp: stop,
      onMouseLeave: stop,
      onTouchStart: start,
      onTouchEnd: stop
    }
  };
};

export default useLongPress;
