import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { exportReport } from 'api/ispine-patients';

const Export = ({ data }) => {
  const params = useParams();
  const [exporting, setExporting] = useState(false);

  const onExport = event => {
    event.stopPropagation();

    setExporting(true);

    const { organizationId, projectId } = params;
    const measurementId = data.id;
    const payload = {
      organizationId,
      projectId,
      measurementId
    };

    exportReport(payload)
      .then(url => {
        window.open(url);
        setExporting(false);
      })
      .catch(() => {
        setExporting(false);
      });
  };

  return (
    <Button compact icon="download" loading={exporting} onClick={onExport} />
  );
};

Export.propTypes = {
  data: PropTypes.shape({
    measurementId: PropTypes.string
  }).isRequired
};

export default Export;
