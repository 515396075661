import { Sensor, SensorRaw, nSensor } from 'api/sensors';
import type {
  ActivePatient,
  ActivePatientRaw,
  Algorithm,
  AlgorithmRaw
} from './types';

export const nActivePatient: (
  patient: ActivePatientRaw,
  sensors: SensorRaw[]
) => ActivePatient = (patient, sensors = []) => ({
  id: patient.id,
  firstName: patient.first_name,
  lastName: patient.last_name,
  phoneNumber: patient.phone_number,
  timezone: patient.timezone,
  startTime: patient.start_time,
  endTime: patient.end_time,
  status: patient.status,
  measurementMethod: patient.alg_profile,
  measurementStatusEx: patient.measurement_status_ex,
  meta: {
    startTimeType: patient.meta?.start_time_type,
    endTimeType: patient.meta?.end_time_type,
    endTimeDuration: patient.meta?.end_time_duration
  },
  sensors: patient.attached_sensors
    ? patient.attached_sensors.map((sensorId, index) => {
        const sensor = sensors.find(s => s.id === sensorId);
        if (sensor) {
          return nSensor({
            ...sensor,
            place: patient.attached_places && patient.attached_places[index]
          });
        } else
          return {
            id: sensorId
          } as Sensor;
      })
    : [],
  raw: patient
});

export const nAlgorithm: (
  algorithm: AlgorithmRaw
) => Algorithm = algorithm => ({
  name: algorithm.name,
  places: algorithm.places,
  raw: algorithm
});
