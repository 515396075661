import React from 'react';
import PropTypes from 'prop-types';
import { VictoryArea, VictoryGroup } from 'victory';

const Stack2 = ({ stacks }) => (
  <VictoryGroup>
    {stacks.map((item, index) => {
      if (item.data.length !== 0) {
        return (
          <VictoryArea
            key={index}
            data={item.data}
            style={{
              data: { fill: item.color, strokeWidth: 0 }
            }}
            alignment="start"
          />
        );
      } else {
        return null;
      }
    })}
  </VictoryGroup>
);

Stack2.propTypes = {
  stacks: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      data: PropTypes.array
    })
  ).isRequired
};

export default Stack2;
