import React from 'react';
import PropTypes from 'prop-types';
import { useSearch, useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Segment } from 'semantic-ui-react';
import Header from './header';
import Table from './table';
import utils from './utils';

const ISpinePatientDashboardFragment = ({
  fetching,
  error,
  data,
  onAdd,
  onEdit,
  onDelete,
  onRowClick
}) => {
  const t = useTranslate('ispine-patient');
  const [open, toggleOpen] = useToggle(false);
  const [search, onSearch] = useSearch();
  const filtered = utils.filterData({ data, search });

  return (
    <React.Fragment>
      <Header search={search} onSearch={onSearch} onAdd={onAdd} />
      <Table
        fetching={fetching}
        error={error}
        data={filtered}
        editMode={open}
        onRowClick={onRowClick}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <Segment basic clearing style={{ padding: '0' }}>
        {open ? (
          <Button
            content={t('exit-edit-mode')}
            icon="close"
            floated="right"
            onClick={toggleOpen}
          />
        ) : (
          <Button
            content={t('edit-mode')}
            icon="setting"
            color="blue"
            floated="right"
            onClick={toggleOpen}
          />
        )}
      </Segment>
    </React.Fragment>
  );
};

ISpinePatientDashboardFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array,
  // ---
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired
};

export default ISpinePatientDashboardFragment;
