import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFetch } from 'hooks';
import { SENSOR_COLORS } from 'consts/sensor';
import { Status } from 'fragments/sensor';
import { Dropdown } from 'semantic-ui-react';
import { fetchSensors } from 'api/sensors';
import { getSensorParams } from 'utils/vendor';
import styles from './styles.module.css';

const SensorSelect = ({
  value,
  onChange,
  placeholder,
  required,
  disabled,
  // --- ,
  showExpiredSensors,
  filter,
  renderStatus,
  customContent,
  customRender,
  'data-cy': dataCy
}) => {
  const params = useParams();
  const { data: sensors = [] } = useFetch({
    source: JSON.stringify(getSensorParams(params)),
    isJsonSource: true,
    apiFetch: fetchSensors
  });
  const availableSensors = showExpiredSensors
    ? sensors
    : sensors.filter(item => item.status !== 'sensor_status/expired');
  const displaySensors = filter ? filter(availableSensors) : availableSensors;
  const options = customContent
    ? displaySensors.map(sensor => ({
        text: sensor.name,
        value: sensor.id,
        content: customContent(sensor)
      }))
    : displaySensors.map(sensor => ({
        text: sensor.name,
        value: sensor.id,
        label: {
          color: SENSOR_COLORS[sensor.status],
          empty: true,
          circular: true
        }
      }));

  const input = (
    <Dropdown
      selection
      fluid
      search
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      data-cy={dataCy}
    />
  );

  if (customRender) {
    const sensor = displaySensors.find(item => item.id === value);
    return customRender(input, sensor);
  }

  if (renderStatus) {
    const sensor = displaySensors.find(item => item.id === value);
    return (
      <div className={styles.main}>
        {input}
        {sensor && (
          <span className={styles.status}>
            <Status data={sensor} />
          </span>
        )}
      </div>
    );
  }

  return input;
};

SensorSelect.defaultProps = {
  disabled: false
};

SensorSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  // ---
  renderStatus: PropTypes.bool,
  filter: PropTypes.func,
  customContent: PropTypes.func
};

export default SensorSelect;
