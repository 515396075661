import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';
import Table from 'components/table';
import { columns } from './configs';

const GatewayDashboardTable = props => {
  const navigate = useNavigate();

  const onRowClick = useCallback(
    rowData => {
      const { id: gatewayId } = rowData;
      const url = routes.gatewayDetails(gatewayId);
      navigate(url);
    },
    [navigate]
  );

  return <Table {...props} columns={columns} onRowClick={onRowClick} />;
};

GatewayDashboardTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default GatewayDashboardTable;
