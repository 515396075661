import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, useToggleOpen } from 'hooks';
import {
  BulkExportEmptyWarningModal,
  BulkExportModal,
  BulkExportTrigger
} from 'fragments/measurement-bulk-export';
import { fetchPatients, editPatient } from 'api/hospital-patients';
import Header from './header';
import PatientEditModal from './patient-edit-modal';
import Table from './table';
import { filterData } from './utils';

const HospitalPatients = () => {
  const params = useParams();
  const { organizationId, projectId } = params;
  const {
    fetching,
    data: patients = [],
    error,
    onUpdate
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId }),
    isJsonSource: true,
    apiFetch: fetchPatients,
    refresh: 20000
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState([]);
  const [sortAccessor, setSortAccessor] = useState('create-date');
  const [sortDirection, setSortDirection] = useState('asc');

  const [exportData, setExportData] = useState(null);
  const [bulkExportData, setBulkExportData] = useState([]);
  const [editData, setEditData] = useState(null);

  const [
    isOpenBulkExportMode,
    // eslint-disable-next-line no-unused-vars
    onOpenBulkExportMode,
    onCloseBulkExportMode,
    onToggleBulkExportMode
  ] = useToggleOpen(false);
  const [isOpenBulkExportModal, onOpenBulkExportModal, onCloseBulkExportModal] =
    useToggleOpen(false);
  const [
    isOpenBulkExportEmptyWarningModal,
    onOpenBulkExportEmptyWarningModal,
    onCloseBulkExportEmptyWarningModal
  ] = useToggleOpen(false);

  const onEdit = async (form, patient) => {
    return editPatient({ params, patient, form }).then(response => {
      const newData = patients.slice();
      const index = newData.findIndex(item => item.id === response.id);
      newData[index] = response;
      onUpdate(newData);
    });
  };

  return (
    <>
      <Header
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        sortAccessor={sortAccessor}
        setSortAccessor={setSortAccessor}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        onToggleBulkExportMode={onToggleBulkExportMode}
      />

      {isOpenBulkExportMode && (
        <BulkExportTrigger
          onClick={() => {
            if (bulkExportData.length === 0) {
              onOpenBulkExportEmptyWarningModal(true);
            } else {
              onOpenBulkExportModal(true);
            }
          }}
        />
      )}

      <Table
        fetching={fetching}
        error={error}
        data={filterData({
          data: patients,
          sortAccessor,
          sortDirection,
          searchQuery,
          filterStatus
        })}
        isOpenBulkExportMode={isOpenBulkExportMode}
        bulkExportData={bulkExportData}
        setBulkExportData={setBulkExportData}
        setExportData={setExportData}
        setEditData={setEditData}
      />

      {editData && (
        <PatientEditModal
          data={editData}
          onSubmit={onEdit}
          onCancel={() => setEditData(null)}
        />
      )}

      {exportData && (
        <BulkExportModal
          singleExport
          patients={[exportData.patient]}
          open={!!exportData}
          onClose={() => {
            onCloseBulkExportMode();
            onCloseBulkExportModal();
            setExportData(null);
          }}
        />
      )}

      {isOpenBulkExportEmptyWarningModal && (
        <BulkExportEmptyWarningModal
          open={isOpenBulkExportEmptyWarningModal}
          onClose={onCloseBulkExportEmptyWarningModal}
        />
      )}

      {bulkExportData && (
        <BulkExportModal
          patients={bulkExportData.map(item => item.patient)}
          open={isOpenBulkExportModal}
          onClose={() => {
            onCloseBulkExportMode();
            onCloseBulkExportModal();
            setBulkExportData([]);
          }}
        />
      )}
    </>
  );
};

export default HospitalPatients;
