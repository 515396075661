import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { RouteParams } from 'app/types';

export const exportReport = async ({
  organizationId,
  projectId,
  measurementId
}: Pick<RouteParams, 'organizationId' | 'projectId'> & {
  measurementId: string;
}) => {
  const downloadToken = await fetchDownloadToken();

  const exportUrl = axiosClient.getUri({
    url: '/export/measurement',
    params: {
      org_id: organizationId,
      project_id: projectId,
      measurement_id: measurementId,
      window_type: 'days',
      unit: 'hours',
      dt: downloadToken
    }
  });

  return exportUrl;
};
