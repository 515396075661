import { useState } from 'react';
import moment from 'moment';

/*
  Default `EndTime` is current selected day.
  If stream is `raw:acc/3ax/4g`
    - Default `StartTime` is 1 day before `EndTime`
    - Limit 2 days export
  Other
    - Default `StartTime` is 7 days before `EndTime`
    - Limit 31 days export
*/

const useDatesCSV = (date, stream) => {
  const [startDate, setStartDate] = useState(() =>
    moment(date)
      .startOf('day')
      .add(stream === 'raw:acc/3ax/4g' ? -1 : -7, 'days')
      .format()
  );

  const [endDate, setEndDate] = useState(() =>
    moment(date).startOf('day').format()
  );

  const limit = stream === 'raw:acc/3ax/4g' ? 2 : 31;

  const onChangeStartDate = (event, { value }) => {
    const startDateObj = moment(value);
    const endDateObj = moment(endDate);
    const diff = endDateObj.diff(startDateObj, 'days');
    setStartDate(startDateObj.format());
    if (diff > limit || diff < 1) {
      setEndDate(moment(startDateObj).add(limit, 'days').format());
    }
  };

  const onChangeEndDate = (event, { value }) => {
    const startDateObj = moment(startDate);
    const endDateObj = moment(value);
    const diff = endDateObj.diff(startDateObj, 'days');
    if (diff > limit || diff < 1) {
      setStartDate(moment(endDateObj).add(-limit, 'days').format());
    }
    setEndDate(endDateObj.format());
  };

  const minDate = moment(startDate).add(1, 'days').format();
  const maxDate = moment(startDate).add(limit, 'days').format();

  return {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    limit,
    minDate,
    maxDate
  };
};

export default useDatesCSV;
