import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import type { ScopeRaw } from 'api/scopes';
import type { NetworkError, RouteParams } from 'app/types';
import { nICompPatient } from './normalize';
import type { ICompSearchPatientRaw } from './types';

export const searchPatients = async (
  {
    params: { departmentId, projectId },
    searchText
  }: {
    params: Pick<RouteParams, 'departmentId' | 'projectId'>;
    searchText: string;
  },
  setCancel: CancelExecutor
) => {
  const params = {
    org_id: departmentId,
    project_id: projectId,
    dk_cpr: searchText.split('-').join('')
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  try {
    const {
      patient,
      scopes
    }: { patient: ICompSearchPatientRaw; scopes: ScopeRaw[] } =
      await axiosClient
        .get('/api/1.0/icompression/patients/search', options)
        .then(response => response.data.value[0]);

    if (patient.org_id !== departmentId) return [];

    const scope = scopes.find(s => s.id === departmentId);
    return [
      {
        ...nICompPatient(patient),
        department: scope?.name
      }
    ];
  } catch (_e) {
    const error = _e as NetworkError;
    if (error.message === 'Patient not found') {
      return [];
    }
    /* eslint-disable-next-line @typescript-eslint/no-throw-literal */
    throw error;
  }
};
