import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { registerMonitorToken } from 'api/monitor';
import Fetcher from './fetcher';
import Loader from './loader';

const STORED_DEVICE_KEY = 'public-patients-device-key';

const Monitor = ({ language, uuid, overrideToday }) => {
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const [mToken, setMToken] = useState(false);
  useEffect(() => {
    let deviceKey = uuid || window.localStorage.getItem(STORED_DEVICE_KEY);
    if (!deviceKey) {
      deviceKey = uuidv4();
      window.localStorage.setItem(STORED_DEVICE_KEY, deviceKey);
    }

    const deviceName = 'web';
    const deviceInfo = window.navigator.userAgent;

    registerMonitorToken({
      deviceKey,
      deviceName,
      deviceInfo,
      overrideToday
    }).then(response => {
      setMToken(response.mToken);
    });
  }, []);

  if (!mToken) {
    return <Loader />;
  }

  return <Fetcher mToken={mToken} />;
};

export default Monitor;
