import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import {
  ExportControllerContextProvider,
  ExportControllerTrigger
} from 'fragments/export-controller';
import Menu from 'fragments/menu';
import { Container } from 'semantic-ui-react';
import styles from '../styles.module.scss';
import Navbar from './navbar';
import ProjectSelect from './project-select';

const NFALayout = ({ children }) => (
  <ExportControllerContextProvider>
    <Menu
      homeUrl={routes.organizations()}
      appSelect={<ProjectSelect />}
      navbar={<Navbar />}
      action={<ExportControllerTrigger />}
    />
    <Container className={styles.container}>{children}</Container>
  </ExportControllerContextProvider>
);

NFALayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default NFALayout;
