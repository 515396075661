import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const Status = ({ data: { status } }) => {
  const t = useTranslate('project');
  return <span>{t(`status-${status}`)}</span>;
};

Status.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string.isRequired
  }).isRequired
};

export default Status;
