import React from 'react';
import { useFormatDate, useTranslate } from 'intl';
import {
  LastSynced as SensorLastSynced,
  Status as SensorStatus
} from 'fragments/sensor';
import TextField from 'components/text-field';
import Block from '../block';
import Field from '../field';
import EndTrack from './end-track';
import NewTrack from './new-track';
import styles from './styles.module.css';

const TreatmentStatus = ({ patientDetails }) => {
  const t = useTranslate('icomp-patient');
  const formatDate = useFormatDate();

  const { activeMeasurement } = patientDetails;
  const { track, sensor } = activeMeasurement || {};
  const trackStartTime = (() => {
    if (!track || track.status === 'closed') return '';
    return track.startTime ? formatDate(track.startTime) : t('none');
  })();

  return (
    <Block header={t('treatment-status')}>
      <div className={styles.group}>
        <Field label={t('active-treatment')}>
          <TextField data-cy="patient-details__active-treatment-data">
            {track && track.status !== 'closed'
              ? formatDate(activeMeasurement.startTime)
              : t('none')}
          </TextField>
        </Field>
        <Field>
          <NewTrack patientDetails={patientDetails} />
        </Field>
      </div>
      <div className={styles.group}>
        <Field label={t('active-track')}>
          <TextField data-cy="patient-details__active-track-data">
            {track && track.status !== 'closed' ? trackStartTime : t('none')}
          </TextField>
        </Field>
        <Field>
          <EndTrack patientDetails={patientDetails} />
        </Field>
      </div>

      {sensor ? (
        <div className={styles.group}>
          <Field label={t('active-sensor')}>
            <div className={styles.sensor}>
              <TextField data-cy="patient-details__sensor-data">
                {sensor.name}
              </TextField>
              <div className={styles.sensorStatus}>
                <SensorStatus data={sensor} />
              </div>
            </div>
          </Field>
          <Field label={t('pending-data')}>
            <TextField>
              <SensorLastSynced data={sensor} />
            </TextField>
          </Field>
        </div>
      ) : (
        <Field label={t('active-sensor')}>
          <TextField>{t('none')}</TextField>
        </Field>
      )}
    </Block>
  );
};

export default TreatmentStatus;
