import { extractPhoneNumber } from 'utils/patient';
import type { NFAForm, NFASubject } from './types';

export const transformCreateTime = (time?: string) => {
  if (!time) return null;
  return time;
};

// To delete start-time or end-time (Setting not defined yet)
// the start_time or end_time field in the API request
// should include an empty string ""
export const transformEditTime = (newTime?: string, oldTime?: string) => {
  if (oldTime && !newTime) return '';
  return newTime || null;
};

export const transformCreatePhoneNumber = (form: NFAForm) => {
  if (extractPhoneNumber(form.phoneNumber).number) {
    return form.phoneLocked ? `${form.phoneNumber}@` : form.phoneNumber;
  }
  return null;
};

export const transformEditPhoneNumber = (
  form: NFAForm,
  subject: NFASubject
) => {
  if (
    form.phoneNumber === subject.phoneNumber &&
    form.phoneLocked === subject.phoneLocked
  )
    return null;

  if (extractPhoneNumber(form.phoneNumber).number) {
    return form.phoneLocked ? `${form.phoneNumber}@` : form.phoneNumber;
  }

  return '';
};
