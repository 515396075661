import React from 'react';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Segment } from 'semantic-ui-react';
import Flex from 'components/flex';
import Field from '../field';
import Modal from './modal';

const ICompAdminUserProfileInfo1 = ({ user }) => {
  const t = useTranslate('icomp-admin-user');
  const [open, toggleOpen] = useToggle(false);
  const onCompleted = () => window.location.reload();

  return (
    <div>
      <Segment basic clearing style={{ padding: 0, margin: 0 }}>
        <Flex flex justify="space-between">
          <div>
            <Field
              label={t('name')}
              value={`${user.firstName} ${user.lastName}`}
            />
            <Field label={t('initials')} value={user.initials} />
            <Field label={t('email')} value={user.email} />
          </div>
          <div>
            <Button
              primary
              content={t('common:edit')}
              icon="edit"
              labelPosition="left"
              floated="right"
              onClick={toggleOpen}
            />
          </div>
        </Flex>
      </Segment>

      <Modal
        open={open}
        onClose={toggleOpen}
        user={user}
        onCompleted={onCompleted}
      />
    </div>
  );
};

export default ICompAdminUserProfileInfo1;
