import React from 'react';
import Table from 'components/table';
import { isDisabledExport } from '../utils';
import TableBody from './body';
import { getColumns } from './configs';

const NFASubjectsTable = ({
  fetching,
  error,
  data,
  // ---
  isOpenBulkExportMode,
  bulkExportData: selectedData,
  setBulkExportData: onSelect,
  setSendNotifData,
  setEditData,
  setDeleteData
}) => {
  const columns = getColumns({
    isOpenBulkExportMode,
    setSendNotifData,
    setEditData,
    setDeleteData
  });

  return (
    <Table
      fetching={fetching}
      error={error}
      data={data}
      selectableData={data.filter(item => !isDisabledExport(item))}
      columns={columns}
      CustomBody={TableBody}
      selectable={isOpenBulkExportMode}
      selectedData={selectedData}
      onSelect={onSelect}
    />
  );
};

export default NFASubjectsTable;
