import { CPR, Department, Headers, Name, Seen, Status } from './elements';

const columns1 = [
  {
    name: 'seen',
    header: Headers.seen,
    cell: Seen,
    align: 'center'
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status,
    align: 'center'
  },
  {
    name: 'cpr',
    header: Headers.cpr,
    cell: CPR,
    sortable: true
  },
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  }
];

const columns2 = columns1.concat({
  name: 'department',
  header: Headers.department,
  cell: Department,
  sortable: true
});

export const getColumns = searchMode => {
  return searchMode ? columns2 : columns1;
};
