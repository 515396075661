import React from 'react';
import Body from 'containers/ispine-patient/details';
import Layout from 'layouts/sens';

const PageISpinePatientDetails = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageISpinePatientDetails;
