import React from 'react';
import Body from 'containers/nfa-sensor/dashboard';
import Layout from 'layouts/nfa';

const PageNFASensors = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageNFASensors;
