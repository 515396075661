import { useState } from 'react';

const useExportFormat = () => {
  const [exportFormat, setExportFormat] = useState('hex');
  const onChangeExportFormat = (event, { value }) => {
    setExportFormat(value);
  };

  return { exportFormat, onChangeExportFormat };
};

export default useExportFormat;
