import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

const colorStatusMapping = {
  'status/ok': 'green',
  'status/warn': 'yellow',
  'status/critical': 'red',
  'status/red': 'red',
  'status/yellow': 'yellow'
};

export const Seen = ({ data }) => (
  <span data-cy="patients__seen-cell">
    {data.viewed ? <Icon name="eye" /> : null}
  </span>
);

export const Status = ({ data }) =>
  data.status !== 'status/none' && (
    <Label circular color={colorStatusMapping[data.status]} empty />
  );

export const CPR = ({ data }) => <span>{data.cpr}</span>;

export const Name = ({ data }) => <span>{data.name}</span>;

export const Department = ({ data }) => <span>{data.department}</span>;
