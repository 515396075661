import moment from 'moment';

export const getDuration = (date1, date2) => {
  const d1 = moment(date1);
  const d2 = date2 ? moment(date2) : moment();
  const diff = d2.diff(d1);
  const duration = moment.duration(diff);
  return {
    year: duration.get('year'),
    month: duration.get('month'),
    day: duration.get('day'),
    hour: duration.get('hour'),
    minute: duration.get('minute'),
    second: duration.get('second')
  };
};
