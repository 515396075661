import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const MAX_ITEMS_PER_ROW = 8;

const makeArray = n => [...Array(n).keys()];

const Legend = ({ data }) => {
  const rows = Math.ceil(data.length / MAX_ITEMS_PER_ROW);
  const itemsPerRow = Math.ceil(data.length / rows);

  const groups = makeArray(itemsPerRow).map(i =>
    data.filter((item, index) => index % itemsPerRow === i)
  );

  return (
    <div className={styles.main}>
      <div className={styles.legend}>
        {groups.map((group, idx1) => (
          <div key={idx1} className={styles.group}>
            {group.map((item, idx2) => (
              <div key={idx2} className={styles.item}>
                <div
                  className={styles.icon}
                  style={{ background: item.symbol.fill }}
                />
                <div className={styles.text}>{item.name}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Legend.propTypes = {
  data: PropTypes.array.isRequired
};

export default Legend;
