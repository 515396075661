import React from 'react';
import { useTranslate } from 'intl';
import { Button, Icon, Dropdown, Popup } from 'semantic-ui-react';
import { isDisabledExport } from '../../utils';
import styles from './styles.module.css';

export const Actions = ({
  data,
  isOpenBulkExportMode,
  setExportData,
  setEditData
}) => {
  const t = useTranslate('hospital-patient');

  return (
    <div className={styles.wrapper}>
      <Popup
        trigger={
          <Button
            icon="download"
            size="mini"
            disabled={isDisabledExport(data)}
            onClick={() => setExportData(data)}
          />
        }
      >
        {t('export-patient-data')}
      </Popup>

      {!isOpenBulkExportMode && (
        <div className={styles.actions}>
          <Dropdown icon={<Icon name="ellipsis vertical" color="grey" />}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setEditData(data)}>
                {t('edit-patient')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
