import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { isDateChange } from 'utils/date';
import type { HospitalPatientForm, HospitalPatientMeasurement } from './types';

export const editPatient = async ({
  form,
  patient: _patient,
  params
}: {
  form: HospitalPatientForm;
  patient: HospitalPatientMeasurement;
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
}) => {
  const requests = [];

  const isStartTimeChanged = isDateChange(form.startTime, _patient.startTime);
  const isEndTimeChanged = isDateChange(form.endTime, _patient.endTime);

  if (isStartTimeChanged || isEndTimeChanged) {
    const payload = {
      org_id: params.organizationId,
      project_id: params.projectId,
      measurement_id: _patient.id,
      // ---
      ...(isStartTimeChanged ? { start_time: form.startTime } : null),
      ...(isEndTimeChanged ? { end_time: form.endTime } : null)
    };
    requests.push(axiosClient.post('/api/1.0/measurements/edit', payload));
  }

  if (form.patient !== _patient.patient.name) {
    const payload = {
      org_id: params.organizationId,
      project_id: params.projectId,
      patient_id: _patient.patient.id,
      // ---
      first_name: form.patient
    };
    requests.push(axiosClient.post('/api/1.0/patients/edit', payload));
  }

  return Promise.all(requests).then(() => {
    const response = {
      ..._patient,
      patient: {
        ..._patient.patient,
        name: form.patient
      },
      startTime: form.startTime,
      endTime: form.endTime
    };

    return response;
  });
};
