import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Export } from 'fragments/sensor';
import { fetchPlotDay, fetchPlotHour } from 'api/sensors';
import Fetcher from 'components/plot/fetcher';
import HourSelect from 'components/plot/hour-select';
import Layout from 'components/plot/layout';
import StreamSelect from 'components/plot/stream-select';

const MeasurmentPlotRaw = ({ date, stream, sensor, streamOptions }) => {
  const [datePlotHour, setDatePlotHour] = useState('');
  const [streamPlotHour, setStreamPlotHour] = useState(stream);

  const apiFetchPlotDay = useCallback(
    (source, setCancel) =>
      fetchPlotDay({ ...source, sensorId: sensor }, setCancel),
    [sensor]
  );

  const apiFetchPlotHour = useCallback(
    (source, setCancel) =>
      fetchPlotHour({ ...source, sensorId: sensor }, setCancel),
    [sensor]
  );

  return (
    <div>
      <Layout
        type="day"
        exportButton={
          <Export type="day" date={date} stream={stream} sensorId={sensor} />
        }
      >
        <Fetcher
          type="day"
          apiFetch={apiFetchPlotDay}
          date={date}
          stream={stream}
          onSelectTick={setDatePlotHour}
        />
      </Layout>

      {datePlotHour && (
        <Layout
          type="hour"
          timeSelect={
            <HourSelect value={datePlotHour} onChange={setDatePlotHour} />
          }
          exportButton={
            <Export
              type="hour"
              date={datePlotHour}
              stream={streamPlotHour}
              sensorId={sensor}
            />
          }
          streamSelect={
            <StreamSelect
              value={streamPlotHour}
              onChange={setStreamPlotHour}
              options={streamOptions}
            />
          }
        >
          <Fetcher
            type="hour"
            apiFetch={apiFetchPlotHour}
            date={datePlotHour}
            stream={streamPlotHour}
          />
        </Layout>
      )}
    </div>
  );
};

MeasurmentPlotRaw.propTypes = {
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired,
  sensor: PropTypes.string.isRequired,
  streamOptions: PropTypes.array.isRequired
};

export default MeasurmentPlotRaw;
