import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Icon } from 'semantic-ui-react';

const mapStatusColor = {
  'not-started': 'yellow',
  active: 'green',
  completed: 'grey'
};

const Status = ({ data }) => {
  const t = useTranslate('external-patient');
  const { status } = data;

  return (
    <span>
      <Icon name="circle" color={mapStatusColor[status]} />
      {` `}
      {t(`status-${status}`)}
    </span>
  );
};

Status.propTypes = {
  data: PropTypes.object.isRequired
};

export default Status;
