import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nISpinePatientMeasurement } from './normalize';
import type { ISpinePatientForm, ISpinePatientMeasurementRaw } from './types';

export const addPatient = async ({
  params,
  form
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  form: ISpinePatientForm;
}) => {
  if (!form.sensor) {
    return Promise.reject({
      type: 'intl',
      message: 'sensor-is-required'
    });
  }

  const payload = {
    org_id: params.organizationId,
    project_id: params.projectId,
    algorithm: 'person/activity',
    start_time: form.monitoringStartTime,
    end_time: form.monitoringEndTime,
    name: form.patient,
    phone_number: form.phone,
    sensors: [
      {
        id: form.sensor,
        place: 'person/thigh'
      }
    ],
    meta: {
      feedback_start_time: form.feedbackStartTime,
      feedback_end_time: form.feedbackEndTime
    }
  };

  const { measurement }: { measurement: ISpinePatientMeasurementRaw } =
    await axiosClient
      .post('/api/1.0/measurements/create_anonymous', payload)
      .then(response => response.data.value);

  return nISpinePatientMeasurement(measurement);
};
