import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'intl';
import { Button } from 'semantic-ui-react';
import { withConfirm } from 'components/hoc-components';

const ButtonWithConfirm = withConfirm(Button, {
  headerIcon: 'trash alternate outline',
  headerText: <Translate ns="ispine-patient" i18nKey="delete-patient" />,
  // ---
  bodyText: <Translate ns="ispine-patient" i18nKey="delete-confirmation" />,
  // ---
  confirmText: <Translate ns="ispine-patient" i18nKey="common:delete" />,
  confirmColor: 'red'
});

const ISpinePatientDashboardDeleteFragment = ({ submitting, onSubmit }) => (
  <ButtonWithConfirm
    icon="trash alternate outline"
    loading={submitting}
    onConfirm={onSubmit}
  />
);

ISpinePatientDashboardDeleteFragment.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ISpinePatientDashboardDeleteFragment;
