import React from 'react';
import PropTypes from 'prop-types';

const labels = {
  'project/standard': 'Standard',
  'project/standard_sleep': 'Sleep',
  'project/simple': 'Simple',
  'project/hospital1': 'Motivation 1',
  'project/only_raw': 'Accelerometer',
  'project/twosensor': 'Dual Sensor',
  'project/standard_beta': 'Standard (v5.2)',
  'project/demos': 'DEMOS',
  'project/ispine': 'Motivation 2',
  'project/nfa': 'NFA',
  'project/icompression': 'iCompression',
  'project/back': 'Back Worn Sensor'
};

const ProjectClass = ({ data }) => {
  return <span>{labels[data.class] || data.class}</span>;
};

ProjectClass.propTypes = {
  data: PropTypes.shape({
    class: PropTypes.string.isRequired
  }).isRequired
};

export default ProjectClass;
