import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { ICompPatient, ICompPatientForm } from './types';

export const editPatient = async ({
  form,
  params,
  patient
}: {
  form: ICompPatientForm;
  params: Pick<RouteParams, 'departmentId' | 'projectId'>;
  patient: ICompPatient;
}) => {
  const payload: any = {
    org_id: params.departmentId,
    project_id: params.projectId,
    patient_id: patient.id
  };

  if (form.cpr !== patient.cpr) {
    payload.dk_cpr = form.cpr.split('-').join('');
  }
  if (form.phone !== patient.phone) {
    payload.phone_number = form.phone;
  }
  if (form.firstName !== patient.firstName) {
    payload.first_name = form.firstName;
  }
  if (form.lastName !== patient.lastName) {
    payload.last_name = form.lastName;
  }

  return axiosClient.post('/api/1.0/icompression/patients/edit', payload);
};
