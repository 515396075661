import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/table';
import { columns } from './configs';

const BLETable = props => {
  return <Table {...props} columns={columns} />;
};

BLETable.defaultProps = {
  fetching: false,
  error: null,
  data: [
    {
      id: 1,
      column1: 'Row 1 / Column 1',
      column2: 'Row 1 / Column 2',
      column3: 'Row 1 / Column 3'
    },
    {
      id: 2,
      column1: 'Row 2 / Column 1',
      column2: 'Row 2 / Column 2',
      column3: 'Row 2 / Column 3'
    },
    {
      id: 3,
      column1: 'Row 3 / Column 1',
      column2: 'Row 3 / Column 2',
      column3: 'Row 3 / Column 3'
    }
  ]
};

BLETable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default BLETable;
