import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchPlotDay, fetchPlotHour } from 'api/measurements';
import Fetcher from 'components/plot/fetcher';
import HourSelect from 'components/plot/hour-select';
import Layout from 'components/plot/layout';
import Export from './export';

const MeasurmentPlotDerived = ({ date, stream }) => {
  const [datePlotHour, setDatePlotHour] = useState('');

  return (
    <div>
      <Layout
        type="day"
        exportButton={<Export type="day" date={date} stream={stream} />}
      >
        <Fetcher
          type="day"
          apiFetch={fetchPlotDay}
          date={date}
          stream={stream}
          onSelectTick={setDatePlotHour}
        />
      </Layout>

      {datePlotHour && (
        <Layout
          type="hour"
          timeSelect={
            <HourSelect value={datePlotHour} onChange={setDatePlotHour} />
          }
          exportButton={
            <Export type="hour" date={datePlotHour} stream={stream} />
          }
        >
          <Fetcher
            type="hour"
            apiFetch={fetchPlotHour}
            date={datePlotHour}
            stream={stream}
          />
        </Layout>
      )}
    </div>
  );
};

MeasurmentPlotDerived.propTypes = {
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired
};

export default MeasurmentPlotDerived;
