import { useMemo } from 'react';
import { useFormatDate } from 'intl';

function useOptions({ tracks, track, measurement }) {
  const formatDate = useFormatDate();

  const trackOptions = useMemo(() => {
    return tracks.map((item, index) => ({
      key: item.id,
      value: item.id,
      text: `#${index + 1} - ${formatDate(item.startTime)}`
    }));
  }, [formatDate, tracks]);

  const trackData = tracks.find(item => item.id === track) || {
    measurements: []
  };
  const measurementData =
    trackData.measurements.find(item => item.id === measurement) || {};

  const measurementOptions = useMemo(() => {
    const options = trackData.measurements.map((item, index) => ({
      key: item.id,
      value: item.id,
      text: `#${index + 1} - ${formatDate(item.startTime)}`
    }));

    return [
      {
        key: 'all',
        value: 'all',
        text: 'All'
      }
    ].concat(options);
  }, [formatDate, trackData]);

  return { trackOptions, measurementOptions, trackData, measurementData };
}

export default useOptions;
