import { nMeasurementState } from 'api/measurements';
import { nPatient } from 'api/patients';
import { nSensor } from 'api/sensors';
import type {
  ISpinePatientMeasurement,
  ISpinePatientMeasurementRaw
} from './types';

export const nISpinePatientMeasurement = (
  raw: ISpinePatientMeasurementRaw
): ISpinePatientMeasurement => ({
  id: raw.id,
  state: nMeasurementState(raw),
  patient: nPatient(raw.patient),
  sensor: raw.places[0]?.sensor ? nSensor(raw.places[0]?.sensor) : undefined,
  phone: raw.patient.phone_number,
  monitoring: {
    startTime: raw.start_time,
    endTime: raw.end_time
  },
  feedback: {
    startTime: raw.patient.meta.feedback_start_time,
    endTime: raw.patient.meta.feedback_end_time
  },
  latestEvent: raw.patient.latest_pp_event,
  raw
});
