import moment from 'moment';

export const isExceedDuration = ({
  startTime,
  endTime,
  durtaion
}: {
  startTime: string;
  endTime: string | null | undefined;
  durtaion: number; // Num of days
}) => {
  const startDate = moment(startTime);
  const endDate = endTime ? moment(endTime) : moment();
  const diff = endDate.diff(startDate, 'days');
  return diff > durtaion;
};
