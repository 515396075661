import React, { useContext } from 'react';
import { ProjectContext } from 'contexts';
import { useTranslate } from 'intl';
import { Form, Input } from 'semantic-ui-react';
import PhoneInput from 'components/phone-input';
import TimezoneSelect from 'components/timezone-select';
import Section from './section';

const PatientInfo = ({ form, onChanges, validations }) => {
  const t = useTranslate('patient');
  const { project } = useContext(ProjectContext);

  return (
    <Section title={t('form.patient-info')}>
      <Form.Group widths={2}>
        <Form.Field
          required
          label={t('form.patient-first-name')}
          control={Input}
          value={form.firstName}
          onChange={(event, { value }) => onChanges.firstName(value)}
          error={
            validations?.firstName ? { content: validations?.firstName } : false
          }
        />
        <Form.Field
          label={t('form.patient-last-name')}
          control={Input}
          value={form.lastName}
          onChange={(event, { value }) => onChanges.lastName(value)}
          error={
            validations?.lastName ? { content: validations?.lastName } : false
          }
        />
      </Form.Group>

      <Form.Group widths={2}>
        <Form.Field
          label={t('form.phone-number')}
          control={PhoneInput}
          countryCodeList={project.class === 'project/nfa' ? 'NFA' : 'SENS-2'}
          value={form.phoneNumber}
          onChange={(event, { value }) => onChanges.phoneNumber(value)}
          error={
            validations?.phoneNumber
              ? { content: validations?.phoneNumber }
              : false
          }
        />
        <Form.Field
          label={t('form.timezone')}
          required
          control={TimezoneSelect}
          value={form.timezone}
          onChange={(event, { value }) => onChanges.timezone(value)}
          error={
            validations?.timezone ? { content: validations?.timezone } : false
          }
        />
      </Form.Group>
    </Section>
  );
};

export default PatientInfo;
