import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Segment } from 'semantic-ui-react';
import RadioSelect from './radio-select';

const ICompChartConfig = ({ name, yRange, setYRange }) => {
  const t = useTranslate('icomp-plot');

  return (
    <Segment style={{ marginLeft: '2rem' }}>
      <RadioSelect
        label="Zoom"
        name={`${name}-y-range`}
        value={yRange}
        onChange={setYRange}
        options={[
          { label: t('full-scale'), value: 'full' },
          { label: t('medium-scale'), value: 'large' },
          { label: t('small-scale'), value: 'small' }
        ]}
      />
    </Segment>
  );
};

ICompChartConfig.propTypes = {
  name: PropTypes.string.isRequired,
  yRange: PropTypes.string.isRequired,
  setYRange: PropTypes.func.isRequired
};

export default ICompChartConfig;
