import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import TimeAgo from 'components/time-ago';

const LastReport = ({ data, title }) => {
  const t = useTranslate('sens-admin-gateway');

  return (
    <span>
      {title && (
        <span>
          {t('scanned')}
          {`: `}
        </span>
      )}
      <TimeAgo value={data.lastReport} ago />
    </span>
  );
};

LastReport.propTypes = {
  data: PropTypes.shape({
    lastReport: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.bool
};

export default LastReport;
