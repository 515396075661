import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Grid, Header } from 'semantic-ui-react';
import { editTreatment } from 'api/icomp-patients';
import EditTreatmentForm from './form';

const EditTreatmentModal = ({
  patient,
  treatment,
  onSubmit: postSubmit,
  onCancel
}) => {
  const t = useTranslate('icomp-patient');
  const params = useParams();
  const { form, submitting, error, onChanges, onSubmit } = useForm(
    {
      initialForm: {
        startTime: treatment.startTime,
        endTime: treatment.endTime,
        // ---
        sensor: treatment?.sensor?.id || '',
        // ---
        bandage: treatment?.meta?.bandage || '',
        diagnose: treatment?.meta?.diagnose || '',
        side: treatment?.meta?.side || '',
        circumsphere: treatment?.meta?.circumsphere || '',
        note: treatment?.meta?.note || ''
      },
      apiSubmit: form => {
        const payload = { params, patient, treatment, form };
        return editTreatment(payload).then(postSubmit);
      }
    },
    [params, patient, treatment]
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3" textAlign="center">
            {t('edit-treatment')}
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column computer="14" tablet="16">
          <EditTreatmentForm
            form={form}
            onChanges={onChanges}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitting={submitting}
            error={error}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EditTreatmentModal;
