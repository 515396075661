import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import {
  ExportControllerContextProvider,
  ExportControllerTrigger
} from 'fragments/export-controller';
import Menu from 'fragments/menu';
import VersionMessage from 'fragments/version-message';
import { Container } from 'semantic-ui-react';
import { isDevVersion } from 'utils/version';
import styles from '../styles.module.scss';
import Navbar from './navbar';
import ProjectSelect from './project-select';

const SensLayout = ({ children }) => {
  const devVersion = isDevVersion();

  return (
    <ExportControllerContextProvider>
      <Menu
        homeUrl={routes.organizations()}
        appSelect={<ProjectSelect />}
        navbar={<Navbar />}
        action={<ExportControllerTrigger />}
        message={devVersion && <VersionMessage position="menu" />}
      />
      <Container className={devVersion ? styles.container2 : styles.container}>
        {children}
      </Container>
    </ExportControllerContextProvider>
  );
};

SensLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default SensLayout;
