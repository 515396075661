import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Modal } from 'semantic-ui-react';
import Form from '../form';

const ISpinePatientDashboardEdit = ({ data, onSubmit }) => {
  const t = useTranslate('ispine-patient');
  const [open, toggleOpen] = useToggle(false);

  return (
    <Modal
      open={open}
      size="small"
      trigger={<Button icon="edit outline" onClick={toggleOpen} />}
    >
      <Modal.Header>{t('edit-patient')}</Modal.Header>
      <Modal.Content>
        <Form data={data} onSubmit={onSubmit} onCancel={toggleOpen} />
      </Modal.Content>
    </Modal>
  );
};

ISpinePatientDashboardEdit.propTypes = {
  toggle: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  // ---
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ISpinePatientDashboardEdit;
