import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Form } from 'semantic-ui-react';
import CprInput from 'components/cpr-input';
import PhoneInput from 'components/phone-input';

const NewPatientForm = ({ form, onChanges, onCancel, onSubmit }) => {
  const t = useTranslate('icomp-patient');

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group widths="2">
        <CprInput
          label={t('cpr')}
          data-cy="new-patient__cpr-input"
          required
          value={form.cpr}
          onChange={(event, { value }) => onChanges.cpr(value)}
        />
        <Form.Field>
          <label>{t('phone')}</label>
          <PhoneInput
            data-cy="new-patient__phone-input"
            value={form.phone}
            onChange={(event, { value }) => onChanges.phone(value)}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="2">
        <Form.Input
          label={t('first-name')}
          placeholder={t('first-name')}
          data-cy="new-patient__first-name-input"
          required
          value={form.firstName}
          onChange={(event, { value }) => onChanges.firstName(value)}
        />
        <Form.Input
          label={t('last-name')}
          placeholder={t('last-name')}
          data-cy="new-patient__last-name-input"
          required
          value={form.lastName}
          onChange={(event, { value }) => onChanges.lastName(value)}
        />
      </Form.Group>

      <Form.Group style={{ marginTop: '2rem' }}>
        <Form.Button
          type="button"
          onClick={onCancel}
          data-cy="new-patient__cancel-button"
          style={{ margin: 0, width: '6rem' }}
        >
          {t('common:cancel')}
        </Form.Button>
        <Form.Button
          type="submit"
          color="blue"
          data-cy="new-patient__next-button"
          style={{ margin: 0, width: '6rem' }}
        >
          {t('common:next')}
        </Form.Button>
      </Form.Group>
    </Form>
  );
};

NewPatientForm.propTypes = {
  form: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  // ---
  onChanges: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default NewPatientForm;
