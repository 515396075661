import { axiosClient } from 'api/axios-client';
import type { SensorRaw } from 'api/sensors';
import type { RouteParams } from 'app/types';
import { nNfaSubject } from './normalize';
import type { NFAForm, NFASubjectRaw } from './types';
import { transformCreateTime, transformCreatePhoneNumber } from './utils';

export const addSubject = async ({
  params: { organizationId, projectId },
  form
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  form: NFAForm;
}) => {
  if (
    (form.measurementMethod && !form.sensorThigh) ||
    (form.measurementMethod === '2-sensors' && !form.sensorChest)
  ) {
    return Promise.reject({
      type: 'intl',
      message: 'sensor-is-required'
    });
  }
  if (!form.expectedStartTime) {
    return Promise.reject({
      type: 'intl',
      message: 'expected-project-start-is-required'
    });
  }

  const { sensors }: { sensors: SensorRaw[] } = await axiosClient
    .get('/api/1.0/sensors', {
      params: {
        org_id: organizationId,
        project_id: projectId
      },
      cache: true
    })
    .then(response => response.data.value);

  const { patient }: { patient: NFASubjectRaw } = await axiosClient
    .post('/api/1.0/patient/create2', {
      org_id: organizationId,
      project_id: projectId,
      patient_type: 'patient/nfa',
      first_name: form.subjectId,
      timezone: form.timezone,
      phone_number: transformCreatePhoneNumber(form),
      ...(form.measurementMethod && {
        sensor_id: form.sensorThigh
      }),
      ...(form.measurementMethod === '2-sensors' && {
        sensor2_id: form.sensorChest
      }),
      start_time: transformCreateTime(form.startTime),
      end_time: transformCreateTime(form.endTime),
      meta: {
        expected_start_time: form.expectedStartTime,
        note: form.note
      }
    })
    .then(response => response?.data?.value);

  return nNfaSubject(patient, sensors);
};
