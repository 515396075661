import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchPatients } from 'api/external-patients';
import Fragment from './fragment';

const ExternalPatientDashboard = () => {
  const params = useParams();

  const { organizationId, projectId } = params;
  const {
    fetching,
    data = [],
    error
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId }),
    isJsonSource: true,
    apiFetch: fetchPatients
  });

  return <Fragment fetching={fetching} data={data} error={error} />;
};

export default ExternalPatientDashboard;
