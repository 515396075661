import React from 'react';
import { useTranslate } from 'intl';
import { Icon } from 'semantic-ui-react';
import styles from './styles.module.scss';

const BackButton = ({ onClick }) => {
  const t = useTranslate('common');

  return (
    <button className={styles.button} onClick={onClick}>
      <Icon name="reply" />
      {t('go-back')}
    </button>
  );
};

export default BackButton;
