import { axiosClient } from 'api/axios-client';
import type { ICompSensor, ICompSetDepartmentForm } from './types';

export const setDepartment = async ({
  sensors,
  scopeId,
  form
}: {
  sensors: ICompSensor[];
  scopeId: string;
  form: ICompSetDepartmentForm;
}) => {
  if (!form.department) {
    return Promise.reject({
      type: 'intl',
      message: 'department-is-required'
    });
  }

  const payload = {
    org_id: scopeId,
    sensor_ids: sensors.map(s => s.id),
    new_org_id: form.department
  };
  return axiosClient.post('/api/1.0/icompression/sensors/edit', payload);
};
