import type { CancelExecutor } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { fetchPatients } from './fetch-patients';

export const fetchPatient = async (
  {
    organizationId,
    projectId,
    patientId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'patientId'>,
  setCancel: CancelExecutor
) => {
  const patients = await fetchPatients(
    { organizationId, projectId },
    setCancel
  );
  return patients.find(patient => patient.id === patientId);
};
