import React from 'react';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Segment } from 'semantic-ui-react';
import Flex from 'components/flex';
import Field from '../field';
import Modal from './modal';
import styles from './styles.module.css';

const ICompAdminUserProfileInfo2 = ({ user }) => {
  const t = useTranslate('icomp-admin-user');
  const [open, toggleOpen] = useToggle(false);
  const onCompleted = () => window.location.reload();

  return (
    <div>
      <Segment basic clearing style={{ padding: 0, margin: 0 }}>
        <Flex flex justify="space-between">
          <Field
            label={t('departments-and-roles')}
            value={
              <div>
                {user.departmentsAndRoles.map(item => {
                  const { department, roles } = item;
                  return (
                    <div key={department.id} className={styles.department}>
                      <p className={styles.name}>{department.name}</p>
                      <p className={styles.roles}>
                        {roles.map(role => t(`icomp-role:${role}`)).join(', ')}
                      </p>
                    </div>
                  );
                })}
              </div>
            }
          />
          <div>
            <Button
              primary
              content={t('common:edit')}
              icon="edit"
              labelPosition="left"
              floated="right"
              onClick={toggleOpen}
            />
          </div>
        </Flex>
      </Segment>

      <Modal
        open={open}
        onClose={toggleOpen}
        user={user}
        onCompleted={onCompleted}
      />
    </div>
  );
};

export default ICompAdminUserProfileInfo2;
