import moment from 'moment';

const TS = {
  DAY: 24 * 60 * 60 * 1000,
  HOUR: 60 * 60 * 1000
};

const formatDay = t => moment(t).format('MM/DD/YYYY');
const formatHour = t => moment(t).format('HH:mm');

const getDiff = (startDate, endDate) => {
  if (!endDate) {
    return 0;
  }
  return moment(endDate).diff(startDate, 'days');
};

const getXDomain = (diff, startDate, endDate) => {
  const timestamp = moment(startDate).valueOf();
  return diff > 1
    ? [timestamp, moment(endDate).valueOf()]
    : [timestamp, timestamp + TS.DAY];
};

const getXTicks = (diff, domain) => {
  const ticks = [];
  const x = domain.x[0];
  if (diff > 1) {
    for (let i = 0; i <= diff; i++) {
      ticks.push(x + i * TS.DAY);
    }
  } else {
    for (let i = 0; i <= 24; i++) {
      ticks.push(x + i * TS.HOUR);
    }
  }
  return ticks;
};

const getXTickFormat = diff => {
  return diff > 1 ? formatDay : formatHour;
};

const getYTicks = (domain, step) => {
  const ticks = [];
  for (let i = domain.y[0]; i <= domain.y[1]; i += step) {
    ticks.push(i);
  }
  return ticks;
};

const getYDomainCapacitance = (data, yRange) => {
  const values = data.map(item => item.y).filter(item => item !== null);

  switch (yRange) {
    case 'full': {
      if (values.length === 0) {
        return [0, 160];
      }

      // find max value
      let max = Math.max(...values);

      // handle infinity case
      if (max === Infinity) {
        return [0, 160];
      }

      // round up to nearest 10
      max = Math.ceil(max / 10) * 10;
      return [0, max];
    }
    case 'large': {
      const sum = values.reduce((prev, curr) => prev + curr, 0);
      const median = Math.ceil(sum / values.length);
      return [median - 40, median + 40];
    }
    case 'small': {
      const sum = values.reduce((prev, curr) => prev + curr, 0);
      const median = Math.ceil(sum / values.length);
      return [median - 10, median + 10];
    }
    default:
      return [0, 160];
  }
};

export default {
  getDiff,
  getXDomain,
  getXTicks,
  getXTickFormat,
  getYTicks,
  getYDomainCapacitance
};
