import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useFormatPercent = () => {
  const { t } = useTranslation();

  const formatPercent = useCallback(
    (value, decimals) => {
      if (value === null || value === undefined) {
        return '—';
      }

      const formatted = t('{{data, formatPercent}}', {
        data: {
          value,
          options: {
            style: 'percent',
            minimumFractionDigits: decimals
          }
        }
      });

      if (formatted.includes('NaN')) {
        return formatted.replace('NaN', ' —');
      }

      return formatted;
    },
    [t]
  );

  return formatPercent;
};
