import React, { useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useCountdown } from 'hooks';

const Countdown = () => {
  const countdown = useCountdown({
    value: 60,
    interval: 1000,
    loop: true
  });

  useEffect(() => {
    countdown.start();
    return () => countdown.stop();
  }, [countdown]);

  return (
    <CircularProgressbar
      styles={buildStyles({
        textColor: '#2185d0',
        pathColor: '#2185d0',
        trailColor: '#eaeaea'
      })}
      value={(countdown.value / 60) * 100}
      text={`${countdown.value}s`}
    />
  );
};

export default Countdown;
