import React from 'react';
import { useTranslate } from 'intl';
import { Modal } from 'semantic-ui-react';
import PatientForm from '../patient-form';

const PatientCreateModal = ({ open, onSubmit, onCancel }) => {
  const t = useTranslate('patient');

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>{t('create-patient')}</Modal.Header>
      <Modal.Content>
        <PatientForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal.Content>
    </Modal>
  );
};

export default PatientCreateModal;
