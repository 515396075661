import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Button, Divider, Grid, Header, Segment } from 'semantic-ui-react';

const ForgotPasswordSuccess = () => {
  const t = useTranslate('forgot-password');

  return (
    <div id="forgot-password-success">
      <style>{`body > div,body > div > div,body > div > div > div.forgot-password-success {height: 100%;}`}</style>
      <Grid
        textAlign="center"
        style={{ height: '100%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Segment stacked>
            <Header as="h2" color="green">
              {t('success')}
              <Header.Subheader style={{ marginTop: '1rem' }}>
                {t('success-message')}
              </Header.Subheader>
            </Header>
            <Divider />
            <Button color="blue" as={Link} to={routes.login()}>
              {t('login')}
            </Button>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ForgotPasswordSuccess;
