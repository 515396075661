import React from 'react';
import { useTranslate } from 'intl';
import { Divider, Grid, Segment } from 'semantic-ui-react';
import DataFile from './data-file';
import PdfREport from './pdf-report';

const ExportType = ({ selectedData, onSetupDataFile }) => {
  const t = useTranslate('common');

  return (
    <Segment>
      <Divider vertical>{t('or')}</Divider>

      <Grid columns={2} stackable>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <PdfREport selectedData={selectedData} />
          </Grid.Column>

          <Grid.Column>
            <DataFile onSetupDataFile={onSetupDataFile} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ExportType;
