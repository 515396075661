import React from 'react';
import PropTypes from 'prop-types';
import {
  Headers,
  LastOnline,
  LastSynced,
  PatientAttached,
  Runtime
} from 'fragments/sensor';
import { Grid } from 'semantic-ui-react';

const Row = ({ label, children }) => (
  <Grid.Row>
    <Grid.Column width={4} style={{ fontWeight: 600 }}>
      {label}
      {`: `}
    </Grid.Column>
    <Grid.Column width={12}>{children}</Grid.Column>
  </Grid.Row>
);

const SensorDetailsInfoDetails = ({ data }) => (
  <Grid>
    <Row label={Headers.patientAttached}>
      <PatientAttached data={data} />
    </Row>
    <Row label={Headers.lastSynced}>
      <LastSynced data={data} />
    </Row>
    <Row label={Headers.lastOnline}>
      <LastOnline data={data} />
    </Row>
    <Row label={Headers.runtime}>
      <Runtime data={data} />
    </Row>
  </Grid>
);

SensorDetailsInfoDetails.propTypes = {
  data: PropTypes.object.isRequired
};

export default SensorDetailsInfoDetails;
