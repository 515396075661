import { fetchDownloadToken } from 'api/download-token';
import type { RouteParams } from 'app/types';

type Params = Pick<RouteParams, 'projectId' | 'patientId'>;

type ExportAction = 'modal' | 'url';

export const exportFibionMeasurement = async (
  { projectId, patientId }: Params,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    return Promise.reject({ message: 'Export by API is not supported yet' });
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    /* eslint-disable prefer-template */
    const reportUrl =
      'https://beta.fibion.com/upload/sens/?resource=' +
      encodeURIComponent(
        `https://api-0003.sens.dk/export/measurement?project_id=${projectId}&patient_id=${patientId}&data_format=detailed_fibion&dt=${downloadToken}&file_format=csv.gz`
      );
    return reportUrl;
  }
};
