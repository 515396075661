import React from 'react';
import PropTypes from 'prop-types';
import { useFormatDate, useTranslate } from 'intl';
import {
  SensorDisplay,
  SensorSelect,
  Status as SensorStatus
} from 'fragments/sensor';
import { Divider, Dropdown, Form, Icon } from 'semantic-ui-react';
import Box from 'components/box';
import ErrorMessage from 'components/error-message';
import TextField from 'components/text-field';
import styles from './styles.module.css';

const sideOptions = ['left', 'right'];
const bandageOptions = [
  'bandage_type_coban2',
  'bandage_type_profore',
  'bandage_type_coban_light',
  'bandage_type_compriland',
  'bandage_type_dauerband'
];
const diagnoseOptions = [
  'diagnose_primary_lymphedema',
  'diagnose_secondary_lymphedema',
  'diagnose_venous_insufficiency',
  'diagnose_reactive_edema',
  'diagnose_other'
];

const NewTreatmentForm = ({
  form,
  submitting,
  error,
  onChanges,
  onSubmit,
  onCancel,
  cancelLabel
}) => {
  const t = useTranslate('icomp-patient');
  const formatDate = useFormatDate();

  return (
    <Form error={!!error} onSubmit={onSubmit}>
      <div className={styles.group}>
        <Form.Field>
          <label>{t('cpr')}</label>
          <TextField>{form.cpr}</TextField>
        </Form.Field>
        <Form.Field>
          <label>{t('name')}</label>
          <TextField>{`${form.firstName} ${form.lastName}`}</TextField>
        </Form.Field>
        <Form.Field>
          <label>{t('phone')}</label>
          <TextField>{form.phone}</TextField>
        </Form.Field>
      </div>

      <Divider />

      <div className={styles.group}>
        <div className={styles.group}>
          <Form.Field>
            <label>{t('session')}</label>
            <TextField>{form.session}</TextField>
          </Form.Field>
          <Form.Field>
            <label>{t('treatment')}</label>
            <TextField>{form.treatment}</TextField>
          </Form.Field>
        </div>
        <Form.Field>
          <label>{t('therapist')}</label>
          <TextField>{form.therapist}</TextField>
        </Form.Field>
        <Form.Field>
          <label>{t('date')}</label>
          <TextField>
            <Icon name="calendar" color="grey" />
            &nbsp;&nbsp;
            {formatDate(form.date)}
          </TextField>
        </Form.Field>
      </div>

      <div className={styles.group}>
        <Form.Field required>
          <label>{t('sensor')}</label>
          <SensorSelect
            data-cy="new-treatment__sensor-dropdown"
            placeholder={t('select-sensor')}
            required
            value={form.sensor}
            onChange={(event, { value }) => onChanges.sensor(value)}
          />
        </Form.Field>
        <Form.Field className={styles.two}>
          <label>&nbsp;</label>
          <Box mt="1.25em">
            <SensorDisplay
              sensorId={form.sensor}
              render={sensor => sensor && <SensorStatus data={sensor} />}
            />
          </Box>
        </Form.Field>
      </div>

      <div className={styles.group}>
        <Form.Field required>
          <label>{t('bandage')}</label>
          <Dropdown
            data-cy="new-treatment__bandage-dropdown"
            placeholder={t('select-bandage')}
            fluid
            selection
            required
            options={bandageOptions.map(bandage => ({
              text: t(bandage),
              value: bandage
            }))}
            value={form.bandage}
            onChange={(event, { value }) => onChanges.bandage(value)}
          />
        </Form.Field>
        <Form.Field className={styles.two} required>
          <label className={styles.radioHeadline}>{t('side')}</label>
          <Form.Group inline>
            {sideOptions.map(side => (
              <Form.Radio
                data-cy="new-treatment__side-dropdown"
                inline
                key={side}
                label={t(`side-${side}`)}
                value={side}
                checked={form.side === side}
                onChange={(event, { value }) => onChanges.side(value)}
              />
            ))}
          </Form.Group>
        </Form.Field>
      </div>

      <div className={styles.group}>
        <div>
          <Form.Field required>
            <label>{t('diagnose')}</label>
            <Dropdown
              data-cy="new-treatment__diagnose-dropdown"
              placeholder={t('select-diagnose')}
              fluid
              selection
              required
              options={diagnoseOptions.map(diagnose => ({
                text: t(diagnose),
                value: diagnose
              }))}
              value={form.diagnose}
              onChange={(event, { value }) => onChanges.diagnose(value)}
            />
          </Form.Field>
          <Form.Input
            data-cy="new-treatment__circumsphere-input"
            placeholder={t('input-circumsphere')}
            type="number"
            label={t('circumsphere')}
            required
            value={form.circumsphere}
            onChange={(event, { value }) => onChanges.circumsphere(value)}
          />
        </div>
        <div className={styles.two}>
          <Form.TextArea
            data-cy="new-treatment__note-input"
            label={t('note')}
            rows={5}
            value={form.note}
            onChange={(event, { value }) => onChanges.note(value)}
          />
        </div>
      </div>

      <ErrorMessage error={error} />

      <Form.Group style={{ marginTop: '2rem' }}>
        <Form.Button
          type="button"
          onClick={onCancel}
          style={{ margin: 0, width: '6rem' }}
          data-cy="new-treatment__cancel-button"
        >
          {cancelLabel || t('common:back')}
        </Form.Button>
        <Form.Button
          type="submit"
          color="blue"
          loading={submitting}
          style={{ margin: 0, width: '6rem' }}
          data-cy="new-treatment__submit-button"
        >
          {t('common:submit')}
        </Form.Button>
      </Form.Group>
    </Form>
  );
};

NewTreatmentForm.propTypes = {
  form: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.object,
  // ---
  onChanges: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default NewTreatmentForm;
