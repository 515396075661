import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFetch, useForm } from 'hooks';
import { useTranslate } from 'intl';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';
import { Form, Select } from 'semantic-ui-react';
import { fetchDepartments } from 'api/icomp-departments';
import { setDepartmentAndRoles } from 'api/icomp-users';
import Box from 'components/box';

const ROLES = ['role/admin', 'role/therapist', 'role/researcher'];

const EditDeparmentsRoles = ({ user, open, onClose, onCompleted }) => {
  const t = useTranslate('icomp-admin-user');

  const { hospitalId } = useParams();

  const { data: departments = [] } = useFetch({
    source: JSON.stringify({ hospitalId }),
    isJsonSource: true,
    apiFetch: fetchDepartments
  });

  const { form, submitting, error, onChanges, onSubmit, onReset } = useForm({
    initialForm: {
      department: '',
      roles: []
    },
    apiSubmit: payload =>
      setDepartmentAndRoles({ user, ...payload }).then(onCompleted)
  });

  const roleOptions = ROLES.map(role => ({
    key: role,
    value: role,
    text: t(`icomp-role:${role}`)
  }));

  const departmentOptions = departments.map(department => ({
    key: department.id,
    text: department.name,
    value: department.id
  }));

  return (
    <Modal
      open={open}
      title={
        <div style={{ textAlign: 'center' }}>
          {t('edit-departments-and-roles')}
          <br />
          {user.name}
        </div>
      }
      body={
        <Form onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Field
              control={Select}
              options={departmentOptions}
              label={t('select-department')}
              placeholder={t('choose-department')}
              value={form.department}
              onChange={(event, { value }) => onChanges.department(value)}
              required
            />
            <Form.Field
              control={Select}
              multiple
              options={roleOptions}
              label={t('select-role')}
              placeholder={t('choose-role')}
              value={form.roles}
              onChange={(event, { value }) => onChanges.roles(value)}
              required
            />
          </Form.Group>
          <Box mt="2.5rem">
            <ButtonGroup>
              <ButtonCancel onClick={onClose} />
              <ButtonOk type="submit" loading={submitting} />
            </ButtonGroup>
          </Box>
        </Form>
      }
      error={error}
      onUnmount={onReset}
    />
  );
};

EditDeparmentsRoles.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired
};

export default EditDeparmentsRoles;
