import { useCallback, useState } from 'react';

const useSearch = ({ uppercase } = {}) => {
  const [filter, setFilter] = useState('');
  const onFilter = useCallback(
    (e, { value }) => {
      setFilter(uppercase ? value.toUpperCase() : value);
    },
    [uppercase]
  );
  return [filter, onFilter];
};

export default useSearch;
