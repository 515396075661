import React from 'react';
import { useTranslate } from 'intl';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Header } from 'semantic-ui-react';
import styles from './styles.module.scss';

const BulkExportTrigger = ({ onClick }) => {
  const t = useTranslate('measurement-bulk-export');

  return (
    <div className={styles.main}>
      <Header size="medium" className={styles.header}>
        {t('description')}
      </Header>
      <Button color="green" className={styles.button} onClick={onClick}>
        <FontAwesomeIcon icon={faFileExport} />
        {t('action')}
      </Button>
    </div>
  );
};

export default BulkExportTrigger;
