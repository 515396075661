import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFetch, useForm } from 'hooks';
import { useTranslate } from 'intl';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';
import { Form, Header, Select } from 'semantic-ui-react';
import { fetchDepartments } from 'api/icomp-departments';
import { setDepartment } from 'api/icomp-sensors';
import Box from 'components/box';

const SetDepartment = ({ sensors, open, onClose, onCompleted }) => {
  const t = useTranslate('icomp-admin-sensor');

  const { hospitalId, departmentId } = useParams();
  const scopeId = departmentId || hospitalId;

  const { data: departments = [] } = useFetch({
    apiFetch: fetchDepartments,
    source: JSON.stringify({ hospitalId }),
    isJsonSource: true
  });

  const departmentOptions = departments.map(d => ({
    key: d.id,
    text: d.name,
    value: d.id
  }));

  const { form, submitting, error, onChanges, onSubmit, onReset } = useForm(
    {
      initialForm: {
        department: ''
      },
      apiSubmit: form =>
        setDepartment({ sensors, scopeId, form }).then(onCompleted)
    },
    [sensors, hospitalId]
  );

  return (
    <Modal
      size="tiny"
      open={open}
      title={
        <div>
          {t('set-department', { count: sensors.length })}
          <Header.Subheader>
            {sensors.map(s => s.name).join(', ')}
          </Header.Subheader>
        </div>
      }
      body={
        <Form onSubmit={onSubmit}>
          <Form.Field
            control={Select}
            options={departmentOptions}
            label={t('select-department')}
            placeholder={t('choose-department')}
            value={form.department}
            onChange={(event, { value }) => onChanges.department(value)}
            required
          />
          <Box mt="2.5rem">
            <ButtonGroup>
              <ButtonCancel onClick={onClose} />
              <ButtonOk type="submit" loading={submitting} />
            </ButtonGroup>
          </Box>
        </Form>
      }
      error={error}
      onUnmount={onReset}
    />
  );
};

SetDepartment.propTypes = {
  sensors: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired
};

export default SetDepartment;
