import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { SelectCell } from 'components/table';
import { isDisabledExport } from '../utils';
import { isSensorCell } from './configs';

const clickableStyles = { cursor: 'pointer' };

const TabelRow = ({
  rowData,
  rowIndex,
  columns,
  onRowClick,
  selectable,
  onSelect,
  selectedData,
  data,
  lastCheckedIndex
}) => {
  const numSensors = rowData.sensors.length;

  if (numSensors <= 1) {
    return (
      <Table.Row
        key={rowData.id}
        onClick={onRowClick ? onRowClick.bind(null, rowData) : null}
        style={onRowClick ? clickableStyles : null}
      >
        {selectable && (
          <SelectCell
            rowData={rowData}
            rowIndex={rowIndex}
            lastCheckedIndex={lastCheckedIndex}
            data={data}
            selectedData={selectedData}
            onSelect={onSelect}
            disabled={isDisabledExport(rowData)}
          />
        )}
        {columns.map(config => {
          const Cell = config.cell;
          return (
            <Table.Cell
              key={config.name}
              width={config.width || null}
              textAlign={config.align || null}
            >
              {Cell ? <Cell data={rowData} /> : rowData[config.name]}
            </Table.Cell>
          );
        })}
      </Table.Row>
    );
  }

  return (
    <>
      {rowData.sensors.map((sensor, index) => (
        <Table.Row
          key={sensor.id}
          onClick={onRowClick ? onRowClick.bind(null, rowData) : null}
          style={onRowClick ? clickableStyles : null}
        >
          {index === 0 && selectable && (
            <SelectCell
              rowData={rowData}
              rowIndex={rowIndex}
              lastCheckedIndex={lastCheckedIndex}
              data={data}
              selectedData={selectedData}
              onSelect={onSelect}
              rowSpan={numSensors}
            />
          )}
          {columns.map(config => {
            const Cell = config.cell;
            if (isSensorCell(config.name)) {
              return (
                <Table.Cell
                  key={config.name}
                  width={config.width || null}
                  textAlign={config.align || null}
                >
                  <Cell data={{ ...rowData, sensors: [sensor] }} />
                </Table.Cell>
              );
            }

            return index === 0 ? (
              <Table.Cell
                key={config.name}
                width={config.width || null}
                textAlign={config.align || null}
                rowSpan={numSensors}
              >
                {Cell ? <Cell data={rowData} /> : rowData[config.name]}
              </Table.Cell>
            ) : null;
          })}
        </Table.Row>
      ))}
    </>
  );
};

const PatientsTableBody = ({
  columns,
  data,
  selectable,
  selectedData,
  onSelect,
  onRowClick
}) => {
  const lastCheckedIndex = useRef(undefined);

  return (
    <React.Fragment>
      {data.map(rowData => (
        <TabelRow
          key={rowData.id}
          rowData={rowData}
          columns={columns}
          onRowClick={onRowClick}
          // ---
          selectable={selectable}
          selectedData={selectedData}
          onSelect={onSelect}
          lastCheckedIndex={lastCheckedIndex}
          data={data}
        />
      ))}
    </React.Fragment>
  );
};

PatientsTableBody.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      header: PropTypes.node,
      cell: PropTypes.func,
      sortable: PropTypes.bool,
      sorter: PropTypes.func
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func
};

export default PatientsTableBody;
