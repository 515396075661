import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';
import Logo from 'components/logo';
import Help from './help';
import LanguageSelect from './language-select';
import styles from './styles.module.scss';
import UserMenu from './user-menu';

const AppMenu = ({ homeUrl, appSelect, navbar, action, message }) => {
  return (
    <Menu fixed="top" borderless className={styles.menu}>
      <Container>
        <Menu.Item as={Link} to={homeUrl}>
          <Logo />
        </Menu.Item>

        {appSelect}

        <Menu.Menu position="right">
          <Help />
          <LanguageSelect />
          <UserMenu />
        </Menu.Menu>
      </Container>

      <Menu
        borderless
        style={{
          background: '#f9fafb',
          boxShadow: 'none',
          border: 'none',
          borderRadius: '0px',
          borderTop: '1px solid rgba(34,36,38,.15)'
        }}
      >
        <Container>
          {navbar}

          <Menu.Menu position="right">{action}</Menu.Menu>
        </Container>
      </Menu>

      {message}
    </Menu>
  );
};

export default AppMenu;
