/* eslint-disable no-alert */
import React from 'react';
import { useTranslate } from 'intl';
import { Button, Grid, Header } from 'semantic-ui-react';

const BLEHeader = () => {
  const t = useTranslate('ble');

  const onClickButton1 = () => {
    alert('Button 1 Clicked');
  };

  const onClickButton2 = () => {
    alert('Button 2 Clicked');
  };

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={4}>
          <Header as="h2">{t('header')}</Header>
        </Grid.Column>
        <Grid.Column width={12} textAlign="right">
          <Button primary onClick={onClickButton1}>
            {t('button1')}
          </Button>
          <Button secondary onClick={onClickButton2}>
            {t('button2')}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BLEHeader;
