import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Box = ({
  align,
  // ---
  m,
  mt,
  mb,
  ml,
  mr,
  mx,
  my,
  // ---
  p,
  pt,
  pb,
  pl,
  pr,
  px,
  py,
  // ---
  as,
  children
}) => {
  const Tag = as || 'div';
  return (
    <Tag
      className={`${align ? `${styles.flex} ${styles[`align-${align}`]}` : ''}`}
      style={{
        ...(m && { margin: m }),
        ...(mx && { marginLeft: mx, marginRight: mx }),
        ...(my && { marginTop: my, marginBottom: my }),
        ...(mt && { marginTop: mt }),
        ...(mb && { marginBottom: mb }),
        ...(ml && { marginLeft: ml }),
        ...(mr && { marginRight: mr }),
        // ---
        ...(p && { padding: p }),
        ...(px && { paddingLeft: px, paddingRight: px }),
        ...(py && { paddingTop: py, paddingBottom: py }),
        ...(pt && { paddingTop: pt }),
        ...(pb && { paddingBottom: pb }),
        ...(pl && { paddingLeft: pl }),
        ...(pr && { paddingRight: pr })
      }}
    >
      {children}
    </Tag>
  );
};

Box.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  // ---
  m: PropTypes.string,
  mt: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
  mr: PropTypes.string,
  mx: PropTypes.string,
  my: PropTypes.string,
  // ---
  p: PropTypes.string,
  pt: PropTypes.string,
  pb: PropTypes.string,
  pl: PropTypes.string,
  pr: PropTypes.string,
  px: PropTypes.string,
  py: PropTypes.string,
  // ---
  as: PropTypes.string,
  children: PropTypes.node
};

export default Box;
