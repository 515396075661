import React from 'react';
import { useTranslate } from 'intl';
import {
  SensorDisplay,
  SensorSelect,
  Status as SensorStatus
} from 'fragments/sensor';
import { Dropdown, Form } from 'semantic-ui-react';
import Box from 'components/box';
import DatePicker from 'components/date-picker';
import ErrorMessage from 'components/error-message';

const sideOptions = ['left', 'right'];
const bandageOptions = [
  'bandage_type_coban2',
  'bandage_type_profore',
  'bandage_type_coban_light',
  'bandage_type_compriland',
  'bandage_type_dauerband'
];
const diagnoseOptions = [
  'diagnose_primary_lymphedema',
  'diagnose_secondary_lymphedema',
  'diagnose_venous_insufficiency',
  'diagnose_reactive_edema',
  'diagnose_other'
];

const EditTreatmentForm = ({
  form,
  submitting,
  error,
  onChanges,
  onSubmit,
  onCancel
}) => {
  const t = useTranslate('icomp-patient');

  return (
    <Form error={!!error} onSubmit={onSubmit}>
      <Form.Group widths="2">
        <Form.Field>
          <label>{t('treatment-start-time')}</label>
          <DatePicker
            type="date-time"
            timeIntervals={1}
            value={form.startTime}
            onChange={(event, { value }) => onChanges.startTime(value)}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('treatment-end-time')}</label>
          <DatePicker
            type="date-time"
            timeIntervals={1}
            value={form.endTime}
            onChange={(event, { value }) => onChanges.endTime(value)}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="3">
        <Form.Field required>
          <label>{t('sensor')}</label>
          <SensorSelect
            placeholder={t('select-sensor')}
            required
            disabled={!form.endTime}
            value={form.sensor}
            onChange={(event, { value }) => onChanges.sensor(value)}
          />
        </Form.Field>
        <Form.Field>
          <label>&nbsp;</label>
          <Box mt="1.25em">
            <SensorDisplay
              sensorId={form.sensor}
              render={sensor => sensor && <SensorStatus data={sensor} />}
            />
          </Box>
        </Form.Field>
      </Form.Group>

      <Form.Group widths="2">
        <Form.Field required>
          <label>{t('bandage')}</label>
          <Dropdown
            placeholder={t('select-bandage')}
            fluid
            selection
            required
            options={bandageOptions.map(bandage => ({
              text: t(bandage),
              value: bandage
            }))}
            value={form.bandage}
            onChange={(event, { value }) => onChanges.bandage(value)}
          />
        </Form.Field>
        <Form.Field required>
          <label>{t('side')}</label>
          <Form.Group inline style={{ marginTop: '1em' }}>
            {sideOptions.map(side => (
              <Form.Radio
                inline
                key={side}
                label={t(`side-${side}`)}
                value={side}
                checked={form.side === side}
                onChange={(event, { value }) => onChanges.side(value)}
              />
            ))}
          </Form.Group>
        </Form.Field>
      </Form.Group>

      <Form.Group widths="2">
        <Form.Field required>
          <label>{t('diagnose')}</label>
          <Dropdown
            placeholder={t('select-diagnose')}
            fluid
            selection
            required
            options={diagnoseOptions.map(diagnose => ({
              text: t(diagnose),
              value: diagnose
            }))}
            value={form.diagnose}
            onChange={(event, { value }) => onChanges.diagnose(value)}
          />
        </Form.Field>
        <Form.Input
          type="number"
          label={t('circumsphere')}
          placeholder={t('input-circumsphere')}
          required
          value={form.circumsphere}
          onChange={(event, { value }) => onChanges.circumsphere(value)}
        />
      </Form.Group>

      <Form.TextArea
        rows={5}
        label={t('note')}
        value={form.note}
        onChange={(event, { value }) => onChanges.note(value)}
      />

      <ErrorMessage error={error} />

      <Form.Group style={{ marginTop: '2rem' }}>
        <Form.Button
          type="button"
          onClick={onCancel}
          style={{ margin: 0, width: '6rem' }}
        >
          {t('common:cancel')}
        </Form.Button>
        <Form.Button
          type="submit"
          color="blue"
          loading={submitting}
          style={{ margin: 0, width: '6rem' }}
        >
          {t('common:submit')}
        </Form.Button>
      </Form.Group>
    </Form>
  );
};

export default EditTreatmentForm;
