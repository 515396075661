import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const getIssueUrl = data => {
  return `https://sentry.io/sens-innovation-aps/sensmotionandroid-debug/?query=user%3Aid%3A${data.localId}`;
};

const Sentry = ({ data }) => {
  const t = useTranslate('sens-admin-gateway');
  return (
    <a target="_blank" rel="noopener noreferrer" href={getIssueUrl(data)}>
      {t('issues')}
    </a>
  );
};

Sentry.propTypes = {
  data: PropTypes.shape({
    localId: PropTypes.string
  }).isRequired
};

export default Sentry;
