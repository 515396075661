import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Table from './table';

const ExternalPatientDashboardFragment = ({ fetching, error, data }) => {
  return (
    <React.Fragment>
      <Header />
      <Table fetching={fetching} error={error} data={data} />
    </React.Fragment>
  );
};

ExternalPatientDashboardFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array.isRequired
};

export default ExternalPatientDashboardFragment;
