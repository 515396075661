import React from 'react';
import Body from 'containers/icomp-department/dashboard';
import Layout from 'layouts/icomp';

const PageICompDepartments = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageICompDepartments;
