import React from 'react';
import Body from 'containers/icomp-patient/dashboard';
import Layout from 'layouts/icomp';

const PageICompPatients = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageICompPatients;
