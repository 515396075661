import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const Action = ({ data }) => {
  const t = useTranslate('sens-admin-nearby-sensor');
  const { action, accepted, connected, completed, interrupted } = data;

  return (
    <span>
      {t(`action-${action}`)}
      {`: ${accepted}.${connected}.${completed}.${interrupted}`}
    </span>
  );
};

Action.propTypes = {
  data: PropTypes.object.isRequired
};

export default Action;
