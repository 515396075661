import { useContext, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { UserContext } from 'contexts';
import localStore, { keys } from 'utils/local-store';

const RouteSubscriber = () => {
  const { user } = useContext(UserContext);

  const match1 = useMatch('/r/o/:organizationId/*');
  const match2 = useMatch('/r/o/:organizationId/p/:projectId/*');

  useEffect(() => {
    if (!user) return;

    const { organizationId } = match1?.params || {};
    const { projectId } = match2?.params || {};
    if (organizationId) {
      localStore.set(user.id, keys.organizationId, organizationId);
    }
    if (projectId) {
      localStore.set(user.id, keys.projectId, projectId);
    } else {
      localStore.remove(user.id, keys.projectId);
    }
  }, [match1, match2, user]);

  return null;
};

export default RouteSubscriber;
