import React from 'react';
import { FormatDate } from 'intl';
import Dash from 'components/dash';
import styles from './styles.module.css';

export const StartTime = ({ data }) => {
  if (!data.startTime) return <Dash />;

  return (
    <span>
      <FormatDate value={data.startTime} format="dd-mm-yyyy" />
      <br />
      <span className={styles.sub}>
        <FormatDate value={data.startTime} format="hh:mm" />
      </span>
    </span>
  );
};

export const EndTime = ({ data }) => {
  if (!data.startTime) return <Dash />;

  if (data.endTime) {
    return (
      <span>
        <FormatDate value={data.endTime} format="dd-mm-yyyy" />
        <br />
        <span className={styles.sub}>
          <FormatDate value={data.endTime} format="hh:mm" />
        </span>
      </span>
    );
  }

  return <Dash />;
};
