import React from 'react';
import PropTypes from 'prop-types';
import Delete from 'containers/ispine-patient/dashboard/delete';
import Edit from 'containers/ispine-patient/dashboard/edit';

const Actions = ({ data, onEdit, onDelete }) => (
  <div>
    <Edit data={data} onSubmit={onEdit} />
    <Delete data={data} onSubmit={onDelete} />
  </div>
);

Actions.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Actions;
