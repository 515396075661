import React from 'react';
import moment from 'moment';
import { FormatDate } from 'intl';
import Dash from 'components/dash';
import Duration from 'components/duration';
import styles from './styles.module.css';

export const StartTime = ({ data }) => {
  if (!data.startTime) return <Dash />;

  return (
    <span>
      <FormatDate value={data.startTime} format="dd-mm-yyyy" />
      <br />
      <span className={styles.sub}>
        <FormatDate value={data.startTime} format="hh:mm" />
      </span>
    </span>
  );
};

export const StartTimeDetails = ({ data }) => {
  if (!data.startTime) return <Dash />;

  return (
    <span>
      <FormatDate value={data.startTime} format="dd-mm-yyyy" />
      -
      <FormatDate value={data.startTime} format="hh:mm" />
    </span>
  );
};

export const EndTime = ({ data }) => {
  if (!data.startTime) return <Dash />;

  if (data.endTime) {
    return (
      <span>
        <FormatDate value={data.endTime} format="dd-mm-yyyy" />
        <br />
        <span className={styles.sub}>
          <FormatDate value={data.endTime} format="hh:mm" />
          {data.startTime !== data.endTime && (
            <span>
              {` - `}
              <Duration from={data.startTime} to={data.endTime} level={2} />
            </span>
          )}
        </span>
      </span>
    );
  }

  return (
    <span>
      <span>&nbsp;</span>
      <br />
      <span className={styles.sub}>
        <Duration from={data.startTime} to={moment().toISOString()} level={2} />
      </span>
    </span>
  );
};

export const EndTimeDetails = ({ data }) => {
  if (!data.endTime) return <Dash />;

  return (
    <span>
      <FormatDate value={data.endTime} format="dd-mm-yyyy" />
      -
      <FormatDate value={data.endTime} format="hh:mm" />
    </span>
  );
};

export const DurationDetails = ({ data }) => {
  if (!data.startTime) return <Dash />;

  if (data.endTime) {
    return <Duration from={data.startTime} to={data.endTime} />;
  }

  return <Duration from={data.startTime} to={moment().toISOString()} />;
};
