import { nSensor } from 'api/sensors';
import type {
  NearbySensor,
  NearbySensorAction,
  NearbySensorEvent,
  NearbySensorEventRaw,
  NearbySensorRaw
} from './types';

const actions: NearbySensorAction[] = [
  'unknown',
  'start',
  'stop',
  'interrogate',
  'sync',
  'ignoresync',
  'ignore'
];

export const nEvent = (event: NearbySensorEventRaw): NearbySensorEvent => ({
  id: event.id,
  time: event.timestamp,
  action: actions[event.action_type] as NearbySensorAction,
  accepted: event.status_accepted,
  connected: event.status_connected,
  completed: event.status_completed,
  interrupted: event.status_interrupted,
  raw: event
});

export const nNearbySensor = (sensor: NearbySensorRaw): NearbySensor => ({
  ...nSensor(sensor),
  delivery: sensor.delivery
});
