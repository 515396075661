import React from 'react';
import PropTypes from 'prop-types';
import { FormatDate, useTranslate } from 'intl';
import { List } from 'semantic-ui-react';

const Period = ({ data: { startTime, endTime } }) => {
  const t = useTranslate('ispine-patient');

  return (
    <List>
      <List.Item style={{ whiteSpace: 'nowrap' }}>
        <b>{`${t('start-time')}: `}</b>
        <FormatDate value={startTime} />
      </List.Item>
      <List.Item style={{ whiteSpace: 'nowrap' }}>
        <b>{`${t('end-time')}: `}</b>
        <FormatDate value={endTime} />
      </List.Item>
    </List>
  );
};

Period.propTypes = {
  data: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string
  }).isRequired
};

export default Period;
