import type { ICompUserCreateForm } from './types';

export const addUser = async (form: ICompUserCreateForm) => {
  if (!form.departments.length) {
    return Promise.reject({
      type: 'intl',
      message: 'departments-is-required'
    });
  }
  if (!form.roles.length) {
    return Promise.reject({
      type: 'intl',
      message: 'roles-is-required'
    });
  }
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(form);
    }, 1000);
  });
};
