import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/table';
import { columns } from './configs';

const ReportDashboardTable = props => <Table {...props} columns={columns} />;

ReportDashboardTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default ReportDashboardTable;
