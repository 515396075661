import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate } from 'intl';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DurationDetails,
  EndTimeDetails,
  SensorLastOnlineDetails,
  SensorMemoryStatusDetails,
  SensorNameDetails,
  SensorPendingDataDetails,
  StartTimeDetails,
  StatusDetails
} from 'containers/patient/dashboard/elements';
import Edit from 'containers/patient/dashboard/patient-edit-modal';
import { Button, Divider } from 'semantic-ui-react';
import { editPatient } from 'api/patients';
import Dash from 'components/dash';
import Timezone from 'components/timezone';
import { getFullName } from 'utils/patient';
import Field from './field';
import Group from './group';
import styles from './styles.module.css';

const PatientDetailsInfo = ({ patient, onUpdate }) => {
  const t = useTranslate('patient');
  const params = useParams();
  const [openEdit, setOpenEdit] = useState(false);
  const onEdit = (form, patient) => {
    return editPatient({ params, patient, form }).then(response => {
      onUpdate(response);
    });
  };

  return (
    <>
      <div className={styles.main}>
        <div>
          <Field label={t('patient-name')}>{getFullName(patient)}</Field>
          <Field label={t('phone-number')}>
            {patient.phoneNumber || <Dash />}
          </Field>
          <Field label={t('timezone')}>
            {patient.timezone ? (
              <Timezone value={patient.timezone} />
            ) : (
              <Dash />
            )}
          </Field>

          <Divider />

          <Field label={t('measurement-method')}>
            {t(`alg_profile:${patient.measurementMethod}`)}
          </Field>

          <div className={styles.row}>
            {patient.sensors.map(sensor => (
              <div key={sensor.id} className={styles.row2}>
                <Field
                  label={`${t('sensor-name')} (${t(
                    `alg_profile:${sensor.place}`
                  )})`}
                >
                  <SensorNameDetails
                    patientStatus={patient.status}
                    sensor={sensor}
                  />
                </Field>

                <Group>
                  <Field label={t('sensor-pending-data')}>
                    <SensorPendingDataDetails
                      patientStatus={patient.status}
                      measurementStatusEx={patient.measurementStatusEx}
                      sensor={sensor}
                    />
                  </Field>

                  <Field label={t('sensor-memory-status')}>
                    <SensorMemoryStatusDetails
                      patientStatus={patient.status}
                      measurementStatusEx={patient.measurementStatusEx}
                      sensor={sensor}
                    />
                  </Field>

                  <Field label={t('sensor-last-online')}>
                    <SensorLastOnlineDetails
                      patientStatus={patient.status}
                      measurementStatusEx={patient.measurementStatusEx}
                      sensor={sensor}
                    />
                  </Field>
                </Group>
              </div>
            ))}
          </div>

          <Group>
            <Field label={t('start-time')}>
              <StartTimeDetails data={patient} />
            </Field>
            <Field label={t('end-time')}>
              <EndTimeDetails data={patient} />
            </Field>
            <Field label={t('duration')}>
              <DurationDetails data={patient} />
            </Field>
          </Group>
        </div>

        <div className={styles.status}>
          <StatusDetails data={patient} />
        </div>

        <div className={styles.edit}>
          <Button
            icon
            primary
            onClick={() => setOpenEdit(true)}
            style={{ margin: 0 }}
          >
            <FontAwesomeIcon icon={faUserPen} />
          </Button>
        </div>
      </div>

      {openEdit && (
        <Edit
          data={patient}
          onSubmit={onEdit}
          onCancel={() => setOpenEdit(false)}
        />
      )}
    </>
  );
};

export default PatientDetailsInfo;
