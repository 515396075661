import moment from 'moment';

const UNITS = ['year', 'month', 'day', 'hour', 'minute'];
const DISPLAY_RANGE = 2; // 2 years 1 month, 5 month 6 days, 20 days 1 hour ...

const getDuration = value => {
  const now = moment();
  const target = moment(value);
  const diff = now.diff(target);
  const duration = moment.duration(diff);
  return {
    year: duration.get('year'),
    month: duration.get('month'),
    day: duration.get('day'),
    hour: duration.get('hour'),
    minute: duration.get('minute')
  };
};

export const getDisplay = value => {
  const duration = getDuration(value);
  const result = [];
  let count = 0;
  for (let i = 0; i < UNITS.length; i++) {
    if (count >= DISPLAY_RANGE) {
      break;
    }
    const unit = UNITS[i];
    const value = duration[unit];
    if (value > 0) {
      result.push({ unit, value });
      count++;
    }
  }
  return result;
};
