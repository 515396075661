import React from 'react';
import {
  Grid,
  Message,
  Placeholder,
  Segment,
  Transition
} from 'semantic-ui-react';

export const Loading = () => (
  <Placeholder>
    <Placeholder.Line length="medium" />
  </Placeholder>
);

export const Row = ({ label, children }) => {
  return (
    <Grid.Row>
      <Grid.Column width={4} style={{ fontWeight: 600 }}>
        {label}
        {': '}
      </Grid.Column>
      <Grid.Column width={12}>{children}</Grid.Column>
    </Grid.Row>
  );
};

export const Wrapper = ({ children }) => (
  <Grid>
    <Grid.Column mobile={16} computer={10} style={{ margin: 'auto' }}>
      <Segment padded="very">
        <Grid>{children}</Grid>
      </Segment>
    </Grid.Column>
  </Grid>
);

export const Success = ({ visible, message, onDismiss }) => (
  <Transition visible={visible} unmountOnHide animation="fade" duration={400}>
    <div>
      <Message
        success
        onDismiss={onDismiss}
        style={{
          display: 'inline-block',
          paddingRight: '2rem',
          marginTop: '1rem'
        }}
      >
        {message}
      </Message>
    </div>
  </Transition>
);
