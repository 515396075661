import { useCallback, useState } from 'react';

function useEventHandler(initialValue) {
  const [state, setState] = useState(initialValue);
  const onChange = useCallback(
    (event, { value }) => {
      setState(value);
    },
    [setState]
  );
  return [state, onChange];
}

export default useEventHandler;
