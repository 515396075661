import React from 'react';
import { useTranslate } from 'intl';

const MemoryStatus = ({ data }) => {
  const t = useTranslate('patient');

  return <span>{t('memory-used', { percentage: `${data.memory}%` })}</span>;
};

export default MemoryStatus;
