import React from 'react';
import Body from 'containers/ispine-patient/dashboard';
import Layout from 'layouts/sens';

const PageISpinePatients = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageISpinePatients;
