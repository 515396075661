import uniq from 'lodash.uniq';

const supportedCountryCodes = uniq([
  ...[
    '+1',
    '+31',
    '+32',
    '+33',
    '+34',
    '+39',
    '+41',
    '+43',
    '+44',
    '+45',
    '+46',
    '+47',
    '+48',
    '+49',
    '+61',
    '+64',
    '+65',
    '+354',
    '+358',
    '+372',
    '+99'
  ],
  ...['+45', '+82', '+99']
]);

export const getFullName = ({
  name,
  firstName,
  lastName
}: {
  name: string;
  firstName: string;
  lastName: string;
}) => {
  if (name) return name;
  return `${firstName} ${lastName}`.trim();
};

export const extractPhoneNumber = (
  phoneNumber: string
): {
  countryCode: string;
  number: string;
} => {
  if (phoneNumber) {
    const countryCode =
      supportedCountryCodes.find(item => phoneNumber.startsWith(item)) || '';
    return {
      countryCode,
      number: phoneNumber.substring(countryCode.length)
    };
  }

  return {
    countryCode: '',
    number: ''
  };
};
