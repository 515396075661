import React from 'react';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import StaticBox from '../../static-box';

const AccelerometerData = ({ onSetupAccelerometer }) => {
  const t = useTranslate('browse-data');

  return (
    <StaticBox
      header={t('accelerometer-data.header')}
      listTitle={t('accelerometer-data.list-title')}
      listItems={[
        t('accelerometer-data.file-type'),
        t('accelerometer-data.timestamp-format')
      ]}
      button={
        <Button primary onClick={onSetupAccelerometer}>
          {t('accelerometer-data.setup-report')}
        </Button>
      }
    />
  );
};

export default AccelerometerData;
