import React from 'react';
import PropTypes from 'prop-types';
import { usePlotExport } from 'hooks';
import { useTranslate } from 'intl';
import { Modal, Form, Header } from 'semantic-ui-react';
import { exportDailyRythmReport } from 'api/simple-sensors';
import Box from 'components/box';
import DatePicker from 'components/date-picker';
import ErrorMessage from 'components/error-message';
import { useDates } from './use-dates';

const ExportDayModal = ({ date, stream, onClose }) => {
  const t = useTranslate('simple-sensor');

  const {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    limit,
    minDate,
    maxDate
  } = useDates(date);

  const { exporting, error, onExport } = usePlotExport({
    startDate,
    endDate,
    stream: stream,
    apiExport: exportDailyRythmReport,
    exportAction: 'modal'
  });

  const onSubmit = () => onExport().then(onClose);

  return (
    <Modal open size="small">
      <Modal.Header>{t('daily-rythm-report')}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit} error={error}>
          <Box mb="1em">
            <Header as="h4" style={{ margin: '0px' }}>
              {t('select-export-period')}
            </Header>
            <p>{t('limit', { value: limit })}</p>
          </Box>

          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t('from')}</label>
              <DatePicker
                type="date-time"
                value={startDate}
                onChange={onChangeStartDate}
              />
            </Form.Field>
            <Form.Field required>
              <label>{t('to')}</label>
              <DatePicker
                type="date-time"
                value={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={onChangeEndDate}
              />
            </Form.Field>
          </Form.Group>

          {error && (
            <div style={{ marginTop: '2rem' }}>
              <ErrorMessage error={error} />
            </div>
          )}

          <Form.Group style={{ marginTop: '3rem' }}>
            <Form.Button
              type="button"
              onClick={onClose}
              content={t('common:cancel')}
              style={{ margin: 0 }}
            />
            <Form.Button
              type="submit"
              color="blue"
              loading={exporting}
              content={t('export')}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

ExportDayModal.propTypes = {
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired
};

export default ExportDayModal;
