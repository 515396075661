import React from 'react';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import Table from 'components/table';
import Block from '../block';
import { getColumns } from './configs';
import styles from './styles.module.css';

const Treatments = ({ patientDetails }) => {
  const t = useTranslate('icomp-patient');
  const [editMode, toggleEditMode] = useToggle(false);
  const { patient, tracks } = patientDetails;

  const treatments = tracks.reduce((prev, curr, index1) => {
    const { measurements, ...track } = curr;
    return [
      ...prev,
      ...measurements.map((item, index2) => {
        return {
          ...item,
          index: index2,
          track: {
            ...track,
            index: index1
          }
        };
      })
    ];
  }, []);

  const columns = getColumns({ editMode, patient });

  return (
    <Block header={t('treatments')}>
      <Table data={treatments} columns={columns} />

      <div className={styles.button}>
        {editMode ? (
          <Button
            content={t('exit-edit-mode')}
            icon="close"
            onClick={toggleEditMode}
          />
        ) : (
          <Button
            content={t('edit-mode')}
            icon="setting"
            color="blue"
            onClick={toggleEditMode}
          />
        )}
      </div>
    </Block>
  );
};

export default Treatments;
