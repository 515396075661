import { fetchHospital } from 'api/icomp-hospitals';

export const fetchDepartments = async ({
  hospitalId,
  withProject = false
}: {
  hospitalId: string;
  withProject?: boolean;
}) => {
  return fetchHospital({ hospitalId, withProject }).then(
    hospital => (hospital && hospital.departments) || []
  );
};

export const fetchDepartment = async ({
  hospitalId,
  departmentId,
  withProject = false
}: {
  hospitalId: string;
  departmentId: string;
  withProject?: boolean;
}) => {
  return fetchDepartments({ hospitalId, withProject }).then(departments =>
    departments.find(d => d.id === departmentId)
  );
};
