import type { ICompDepartmentRaw } from 'api/icomp-departments';
import { nICompDepartment } from 'api/icomp-departments';
import { nSensor } from 'api/sensors';
import type { SensorRaw } from 'api/sensors';
import type { ICompSensor } from './types';

export const nICompSensor = (
  sensor: SensorRaw,
  departments: ICompDepartmentRaw[]
): ICompSensor => {
  const department = departments.find(d => d.id === sensor.scope_id);
  return {
    ...nSensor(sensor),
    department: department ? nICompDepartment(department) : undefined
  };
};
