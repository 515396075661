import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  activateNearbySensor,
  deactivateNearbySensor,
  terminateNearbySensor
} from 'api/admin/nearby-sensors';
import Fragment from './fragment';

const api = {
  activate: activateNearbySensor,
  deactivate: deactivateNearbySensor,
  terminate: terminateNearbySensor
};

class NearbySensorDashboardTableControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: '',
      target: '',
      error: null
    };
    this.onActivate = this.onAction.bind(this, 'activate');
    this.onDeactivate = this.onAction.bind(this, 'deactivate');
    this.onTerminate = this.onAction.bind(this, 'terminate');
  }

  onAction(action, sensor) {
    this.setState({
      submitting: action,
      target: sensor.id
    });
    api[action](sensor)
      .then(response => {
        const data = this.props.data.slice();
        const index = data.findIndex(item => item.id === response.id);
        data[index] = response;
        this.props.onUpdate(data);
      })
      .catch(error => {
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ submitting: '', target: '' });
      });
  }

  render() {
    return (
      <Fragment
        fetching={this.props.fetching}
        parentError={this.props.error}
        data={this.props.data}
        {...this.state}
        onExit={this.props.onExit}
        onActivate={this.onActivate}
        onDeactivate={this.onDeactivate}
        onTerminate={this.onTerminate}
      />
    );
  }
}

NearbySensorDashboardTableControl.propTypes = {
  // ---
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array,
  // ---
  onExit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default NearbySensorDashboardTableControl;
