import React from 'react';
import Body from 'containers/icomp-sensor/details';
import Layout from 'layouts/nfa';

const PageNFASensorDetails = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageNFASensorDetails;
