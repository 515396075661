import React, { Component } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash.orderby';
import { getDisplayName } from './utils';

const mapDirections = {
  ascending: 'asc',
  descending: 'desc'
};
const defaultSortFunction = (data, column, direction) => {
  if (!column || !direction) {
    return data;
  }
  const dir = mapDirections[direction];
  return orderBy(data, [column], [dir]);
};

const customSortFunction = (data, customSort, direction) => {
  const result = data.slice();
  result.sort(customSort);
  if (direction === 'descending') {
    result.reverse();
  }
  return result;
};

function withSort(WrappedComponent) {
  class WithSort extends Component {
    constructor(props) {
      super(props);
      const firstSortItem = props.configs.find(item => item.sort);
      const defaultSortColumn = firstSortItem.key || '';
      const defaultDirection = defaultSortColumn ? 'ascending' : '';
      this.state = {
        column: defaultSortColumn,
        direction: defaultDirection
      };
      this.onSort = this.onSort.bind(this);
    }

    onSort(clickedColumn) {
      const { column, direction } = this.state;
      const nextDirection =
        column === clickedColumn && direction === 'ascending'
          ? 'descending'
          : 'ascending';

      this.setState({
        column: clickedColumn,
        direction: nextDirection
      });
    }

    render() {
      const { data, ...otherProps } = this.props;
      const { column, direction } = this.state;

      const config = this.props.configs.find(item => item.key === column);
      const sortedData =
        typeof config.sort === 'function'
          ? customSortFunction(data, config.sort, direction)
          : defaultSortFunction(data, column, direction);

      return (
        <WrappedComponent
          {...otherProps}
          data={sortedData}
          sortColumn={column}
          sortDirection={direction}
          onSort={this.onSort}
        />
      );
    }
  }

  WithSort.propTypes = {
    data: PropTypes.array.isRequired,
    configs: PropTypes.array.isRequired
  };

  WithSort.displayName = `WithSort(${getDisplayName(WrappedComponent)})`;

  return WithSort;
}

export default withSort;
