import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Modal } from 'semantic-ui-react';
import { getFullName } from 'utils/patient';
import PatientForm from '../patient-form';

const PatientEditModal = ({ data, onSubmit, onCancel }) => {
  const t = useTranslate('patient');

  return (
    <Modal open size="tiny">
      <Modal.Header>
        {t('edit-patient')}
        <h4 style={{ color: 'rgba(0,0,0,.6)', marginTop: '0.25em' }}>
          {getFullName(data)}
        </h4>
      </Modal.Header>
      <Modal.Content>
        <PatientForm patient={data} onSubmit={onSubmit} onCancel={onCancel} />
      </Modal.Content>
    </Modal>
  );
};

PatientEditModal.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default PatientEditModal;
