import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown } from 'semantic-ui-react';

const PlotStreamSelect = ({ value, onChange, options, multiple, disabled }) => {
  const t = useTranslate('plot');

  const handleChange = useCallback(
    (event, { value }) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Dropdown
      selection
      multiple={multiple}
      value={value}
      onChange={handleChange}
      options={options.map(item => {
        const [, streamValue] = item.split(':');
        return {
          text: t(`alg_profile:${streamValue}`),
          value: item
        };
      })}
      disabled={disabled}
    />
  );
};

PlotStreamSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool
};

export default PlotStreamSelect;
