import React from 'react';
import LastData from './last-data';

const Time = ({ subject, sensor }) => {
  switch (subject.status) {
    case 'status/started':
    case 'status/not_started': {
      return <LastData sensor={sensor} />;
    }
    default:
      return null;
  }
};

export default Time;
