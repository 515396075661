import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor, GetOptions } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nSensor } from './normalize';
import type { SensorsResponse } from './types';

const CACHE_STORE: Record<string, Promise<SensorsResponse>> = {};

export const fetchSensors = async (
  {
    organizationId,
    projectId
  }: Pick<RouteParams, 'organizationId' | 'projectId'>,
  setCancel?: CancelExecutor
) => {
  const params = {
    org_id: organizationId,
    project_id: projectId
  };
  const options: GetOptions = { params };
  if (setCancel) {
    options.cancelToken = new axios.CancelToken(setCancel);
  }

  const CACHE_KEY = JSON.stringify(params);
  if (!CACHE_STORE[CACHE_KEY]) {
    CACHE_STORE[CACHE_KEY] = axiosClient
      .get('/api/1.0/sensors', options)
      .then(response => response.data.value);
  }

  const { sensors, attached_patients }: SensorsResponse = await CACHE_STORE[
    CACHE_KEY
  ]!;

  return sensors.map((sensor, index) => ({
    ...nSensor(sensor),
    attachedPatients: attached_patients
      .filter(patient => patient.attached_sensors.includes(sensor.id))
      .map(patient => ({
        firstName: patient.first_name,
        lastName: patient.last_name
      })),
    idx: index + 1
  }));
};

export const clearCacheSensors = ({
  organizationId,
  projectId
}: Pick<RouteParams, 'organizationId' | 'projectId'>) => {
  const params = {
    org_id: organizationId,
    project_id: projectId
  };
  const CACHE_KEY = JSON.stringify(params);
  delete CACHE_STORE[CACHE_KEY];
};
