import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { controlSensor } from 'api/sensors';
import { getSensorParams } from 'utils/vendor';
import Fragment from './fragment';

const SensorDetailsControl = ({ data, onUpdate }) => {
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const onToggle = () => {
    setSubmitting(true);
    setError(null);
    controlSensor({ params: getSensorParams(params), sensor: data })
      .then(response => {
        setSubmitting(false);
        onUpdate(response);
      })
      .catch(error => {
        setSubmitting(false);
        setError(error);
      });
  };

  return (
    <Fragment
      data={data}
      submitting={submitting}
      error={error}
      onToggle={onToggle}
    />
  );
};

SensorDetailsControl.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default SensorDetailsControl;
