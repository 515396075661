import React from 'react';
import {
  Control,
  Headers,
  LastOnline,
  Name,
  Status,
  sorters
} from 'fragments/sensor';

export const getColumns = props => [
  {
    name: 'name',
    header: Headers.name,
    cell: ({ data }) => <Name fluid data={data} />,
    sortable: true,
    sorter: sorters.name
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status,
    sortable: true,
    sorter: sorters.status
  },
  {
    name: 'lastOnline',
    header: Headers.lastOnline,
    cell: LastOnline,
    sortable: true,
    sorter: sorters.lastOnline
  },
  {
    name: 'control',
    header: Headers.control,
    cell: ({ data }) => <Control data={data} {...props} />,
    align: 'center'
  }
];
