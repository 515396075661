import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslate } from 'intl';
import routes from 'routes';
import { LastOnline } from 'fragments/sensor';
import Dash from 'components/dash';
import MemoryStatus from './memory-status';
import PendingData from './pending-data';

const stopPropagation = e => e.stopPropagation();

export const SensorName = ({ data }) => {
  const t = useTranslate('patient');
  const { organizationId, projectId } = useParams();

  if (data.status === 'status/no_sensor') {
    return <Dash />;
  }

  return (
    <div>
      {data.sensors.map(sensor => (
        <div key={sensor.id} onClick={stopPropagation}>
          {t(`alg_profile:${sensor.place}`)}:{' '}
          <Link to={routes.sensorDetails(organizationId, projectId, sensor.id)}>
            {sensor.name}
          </Link>
        </div>
      ))}
    </div>
  );
};

export const SensorNameDetails = ({ patientStatus, sensor }) => {
  const { organizationId, projectId } = useParams();

  if (patientStatus === 'status/no_sensor') {
    return <Dash />;
  }

  return (
    <Link to={routes.sensorDetails(organizationId, projectId, sensor.id)}>
      {sensor.name}
    </Link>
  );
};

export const SensorData = ({ data }) => {
  if (
    data.status === 'status/no_sensor' ||
    data.measurementStatusEx === 'status/measurement_ex/completed_ok'
  ) {
    return <Dash />;
  }

  return (
    <div>
      {data.sensors.map(sensor => (
        <div key={sensor.id}>
          <PendingData data={sensor} />
          <div style={{ fontSize: 'smaller' }}>
            <MemoryStatus data={sensor} />
          </div>
        </div>
      ))}
    </div>
  );
};

export const SensorPendingDataDetails = ({
  patientStatus,
  measurementStatusEx,
  sensor
}) => {
  if (
    patientStatus === 'status/no_sensor' ||
    measurementStatusEx === 'status/measurement_ex/completed_ok'
  ) {
    return <Dash />;
  }

  return <PendingData data={sensor} />;
};

export const SensorMemoryStatusDetails = ({
  patientStatus,
  measurementStatusEx,
  sensor
}) => {
  if (
    patientStatus === 'status/no_sensor' ||
    measurementStatusEx === 'status/measurement_ex/completed_ok'
  ) {
    return <Dash />;
  }

  return <MemoryStatus data={sensor} />;
};

export const SensorLastOnline = ({ data }) => {
  if (
    data.status === 'status/no_sensor' ||
    data.measurementStatusEx === 'status/measurement_ex/completed_ok'
  ) {
    return <Dash />;
  }

  return (
    <div>
      {data.sensors.map(sensor => (
        <div key={sensor.id}>
          <LastOnline data={sensor} />
        </div>
      ))}
    </div>
  );
};

export const SensorLastOnlineDetails = ({
  patientStatus,
  measurementStatusEx,
  sensor
}) => {
  if (
    patientStatus === 'status/no_sensor' ||
    measurementStatusEx === 'status/measurement_ex/completed_ok'
  ) {
    return <Dash />;
  }

  return <LastOnline data={sensor} />;
};
