import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Grid, Header, Input } from 'semantic-ui-react';

const OrganizationDashboardHeader = ({ search, onSearch }) => {
  const t = useTranslate('organization');

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <Header as="h2">{t('header')}</Header>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Input
            value={search}
            onChange={onSearch}
            placeholder={t('common:search-by-name')}
            style={{ verticalAlign: 'top' }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

OrganizationDashboardHeader.propTypes = {
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default OrganizationDashboardHeader;
