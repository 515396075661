import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { NFASubject } from './types';

export const deleteSubject = async ({
  params,
  subject
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  subject: NFASubject;
}) => {
  const payload = {
    org_id: params.organizationId,
    project_id: params.projectId,
    patient_id: subject.id
  };
  return axiosClient.post('/api/1.0/patients/delete', payload).then(() => ({
    id: subject.id
  }));
};
