import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  trackId: <Translate ns="icomp-patient" i18nKey="track-id" />,
  treatmentId: <Translate ns="icomp-patient" i18nKey="treatment-id" />,
  treatmentStartTime: (
    <Translate ns="icomp-patient" i18nKey="treatment-start-time" />
  ),
  treatmentEndTime: (
    <Translate ns="icomp-patient" i18nKey="treatment-end-time" />
  ),
  sensor: <Translate ns="icomp-patient" i18nKey="sensor" />,
  bandage: <Translate ns="icomp-patient" i18nKey="bandage" />,
  diagnose: <Translate ns="icomp-patient" i18nKey="diagnose" />,
  circumsphere: <Translate ns="icomp-patient" i18nKey="circumsphere" />,
  side: <Translate ns="icomp-patient" i18nKey="side" />,
  note: <Translate ns="icomp-patient" i18nKey="note" />,
  edit: <Translate ns="icomp-patient" i18nKey="edit" />
};
