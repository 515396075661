import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown } from 'semantic-ui-react';
import SelectPeriod from './select-period';

const SensorPlotsExportHour = ({
  date,
  stream,
  disabled,
  // ---
  sensorId, // to share with Measurement Raw Plot
  dayStatistics
}) => {
  const t = useTranslate('sensor');

  const [exportType, setExportType] = useState('');

  const optionExportCSV = {
    key: 'CSV',
    text: 'CSV',
    icon: 'file outline',
    onClick: () => setExportType('CSV')
  };
  const optionExportRaw = {
    key: 'Raw',
    text: 'Raw',
    icon: 'window restore outline',
    onClick: () => setExportType('Raw')
  };

  const options = [optionExportCSV, optionExportRaw];

  const onResetExportType = () => setExportType('');

  return (
    <Fragment>
      <Dropdown
        floating
        options={options}
        text={t('export')}
        disabled={disabled}
      />
      {exportType && (
        <SelectPeriod
          exportType={exportType}
          onResetExportType={onResetExportType}
          // ---
          date={date}
          stream={stream}
          sensorId={sensorId} // to share with Measurement Raw Plot
          dayStatistics={dayStatistics}
        />
      )}
    </Fragment>
  );
};

SensorPlotsExportHour.propTypes = {
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  // ---
  sensorId: PropTypes.string // to share with Measurement Raw Plot
};

export default SensorPlotsExportHour;
