/* eslint-disable */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Table } from 'semantic-ui-react';
import { getMinMax } from './utils';

const clickableStyles = { cursor: 'pointer' };
const unlickableStyles = { cursor: 'auto' };

export const SelectCell = ({
  rowData,
  rowIndex,
  lastCheckedIndex,
  data,
  selectedData,
  onSelect,
  rowSpan,
  disabled
}) => {
  const selected = Boolean(
    selectedData.find(selected => selected.id === rowData.id)
  );
  return (
    <Table.Cell
      width={1}
      textAlign="center"
      rowSpan={rowSpan}
      style={unlickableStyles}
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <Checkbox
        checked={selected}
        disabled={disabled}
        onClick={event => {
          if (disabled) return;

          let nextData;
          // Handle deselect
          if (selected) {
            nextData = selectedData.filter(item => item.id !== rowData.id);
            lastCheckedIndex.current = undefined;
          }
          // Handle select
          else {
            // Hold down SHIFT to select a range of rows
            if (event.shiftKey && lastCheckedIndex.current !== undefined) {
              const [min, max] = getMinMax(lastCheckedIndex.current, rowIndex);
              const rangeData = data.filter(
                (d, i) =>
                  i >= min && i <= max && !selectedData.find(s => s.id === d.id)
              );
              nextData = selectedData.concat(...rangeData);
            } else {
              nextData = selectedData.concat(rowData);
            }
            lastCheckedIndex.current = rowIndex;
          }
          // Execute
          onSelect(nextData);
        }}
      />
    </Table.Cell>
  );
};

const TableBody = ({
  columns,
  data,
  onRowClick,
  selectable,
  selectedData,
  onSelect
}) => {
  const lastCheckedIndex = useRef(undefined);
  return (
    <React.Fragment>
      {data.map((rowData, index) => (
        <Table.Row
          key={rowData.id}
          onClick={onRowClick ? onRowClick.bind(null, rowData) : null}
          style={onRowClick ? clickableStyles : null}
        >
          {selectable && (
            <SelectCell
              key={rowData.id}
              rowData={rowData}
              rowIndex={index}
              lastCheckedIndex={lastCheckedIndex}
              data={data}
              selectedData={selectedData}
              onSelect={onSelect}
            />
          )}
          {columns.map(config => {
            const Cell = config.cell;
            return (
              <Table.Cell
                key={config.name}
                width={config.width || null}
                textAlign={config.align || null}
              >
                {Cell ? <Cell data={rowData} /> : rowData[config.name]}
              </Table.Cell>
            );
          })}
        </Table.Row>
      ))}
    </React.Fragment>
  );
};

TableBody.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      header: PropTypes.node,
      cell: PropTypes.func,
      sortable: PropTypes.bool,
      sorter: PropTypes.func
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func
};

export default TableBody;
