import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { ISpinePatientMeasurement } from './types';

export const deletePatient = async ({
  params,
  patient
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  patient: ISpinePatientMeasurement;
}) => {
  const payload = {
    org_id: params.organizationId,
    project_id: params.projectId,
    measurement_id: patient.id
  };
  return axiosClient
    .post('/api/1.0/measurements/delete', payload)
    .then(() => patient);
};
