import React from 'react';
import { Status } from 'fragments/sensor';
import { Checkbox } from 'semantic-ui-react';

const Name = ({ data }) => <span>{data.name}</span>;

const Department = ({ data }) => <span>{data.department.name}</span>;

const Select = ({ data, selected, onToggleSelect }) => (
  <div onClick={e => e.stopPropagation()}>
    <Checkbox checked={selected} onChange={onToggleSelect.bind(null, data)} />
  </div>
);

export { Name, Status, Department, Select };
