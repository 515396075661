import React from 'react';
import { useTranslate } from 'intl';
import WebAppVersion from 'fragments/web-app-version';
import { Header } from 'semantic-ui-react';
import ConfirmationCodeForm from './confirmation-code-form';
import Countdown from './countdown';
import styles from './styles.module.scss';

const VerifyConfirmationCode = () => {
  const t = useTranslate('verify-confirmation-code');

  return (
    <div className={styles.main}>
      <Header as="h2" color="blue" textAlign="center">
        {t('enter-confirmation-code/title')}
        <Header.Subheader style={{ marginTop: '1rem' }}>
          {t('enter-confirmation-code/description')}
        </Header.Subheader>
      </Header>

      <div className={styles.codes}>
        <ConfirmationCodeForm />
        <WebAppVersion />
      </div>

      <Header as="h2" style={{ marginTop: '4rem' }}>
        <Header.Subheader>{t('you-will-receive-a-code-in')}</Header.Subheader>
      </Header>

      <div
        style={{
          width: 160,
          marginTop: '2rem',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <Countdown />
      </div>
    </div>
  );
};

export default VerifyConfirmationCode;
