import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import { fetchScopes } from 'api/scopes';
import {
  nICompActiveMeasurement,
  nICompPatient,
  nICompTrack
} from './normalize';
import type { ICompPatientDetailsRaw } from './types';

export const fetchPatient = async (
  {
    departmentId,
    projectId,
    patientId
  }: { departmentId: string; projectId: string; patientId: string },
  setCancel: CancelExecutor
) => {
  const departments = await fetchScopes();

  const params = {
    org_id: departmentId,
    project_id: projectId,
    patient_id: patientId
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };
  const raw: ICompPatientDetailsRaw = await axiosClient
    .get('/api/1.0/icompression/patients/details', options)
    .then(response => response.data.value[0]);

  const { patient, tracks, active_measurement: activeMeasurement } = raw;

  return {
    patient: nICompPatient(patient, departments),
    tracks: tracks.map((item, index) => ({
      ...nICompTrack(item),
      idx: index + 1
    })),
    activeMeasurement: activeMeasurement
      ? nICompActiveMeasurement(activeMeasurement, tracks)
      : null,
    raw
  };
};
