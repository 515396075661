import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'components/table';
import { getSensorDetailsRoute } from 'utils/vendor';
import { columns } from './configs';

const SensorDashboardTableInfo = props => {
  const params = useParams();
  const navigate = useNavigate();

  const onRowClick = useCallback(
    sensor => {
      const route = getSensorDetailsRoute(params, sensor);
      navigate(route);
    },
    [params, navigate]
  );

  return <Table {...props} columns={columns} onRowClick={onRowClick} />;
};

SensorDashboardTableInfo.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default SensorDashboardTableInfo;
