import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFetch } from 'hooks';
import { Loader, Segment } from 'semantic-ui-react';
import { fetchPatient } from 'api/icomp-patients';
import ErrorMessage from 'components/error-message';
import Fragment from './fragment';

const ICompPatientPlots = () => {
  const { hospitalId, departmentId, projectId, patientId } = useParams();

  const {
    fetching,
    data: patientDetails,
    error
  } = useFetch({
    source: JSON.stringify({ hospitalId, departmentId, projectId, patientId }),
    isJsonSource: true,
    apiFetch: fetchPatient
  });

  if (fetching) {
    return (
      <Segment padded style={{ textAlign: 'center' }}>
        <Loader active inline />
      </Segment>
    );
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return <Fragment patientDetails={patientDetails} />;
};

ICompPatientPlots.propTypes = {
  match: PropTypes.object.isRequired
};

export default ICompPatientPlots;
