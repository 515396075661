import React, { useState } from 'react';
import { useTranslate } from 'intl';
import { Button, Popup } from 'semantic-ui-react';
import { commandExportQueue } from 'api/export';
import { useExportController } from '../../context';

const RefreshFile = ({ data }) => {
  const t = useTranslate('export-controller');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { updateExportEntry } = useExportController();

  const onRefresh = () => {
    setIsRefreshing(true);
    commandExportQueue({ resource_uuid: data.uuid, command: 'restart' })
      .then(() => {
        updateExportEntry(data);
        setIsRefreshing(false);
      })
      .catch(() => {
        setIsRefreshing(false);
      });
  };

  if (
    data.status === 'export_status/failed' ||
    data.status === 'export_status/cancelled'
  ) {
    return (
      <Popup
        trigger={
          <Button
            icon="refresh"
            style={{ margin: 0, width: 38, height: 38 }}
            loading={isRefreshing}
            onClick={onRefresh}
          />
        }
        content={t('refresh-file/tooltip')}
      />
    );
  }

  // Only the files with the status export_status/failed and export_status/cancelled have the refresh button on the Files modal.

  return (
    <div style={{ visibility: 'hidden' }}>
      <Button icon="refresh" style={{ margin: 0, width: 38, height: 38 }} />
    </div>
  );
};

export default RefreshFile;
