import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { ButtonCancel, ButtonGroup, ButtonOk } from 'fragments/icomp-modal';
import { Button } from 'semantic-ui-react';
import Box from 'components/box';
import Table from 'components/table';
import SetDepartmentModal from '../set-department';
import { getColumns } from './configs';

const ICompAdminUserOverviewTable = ({ fetching, error, sensors }) => {
  const t = useTranslate('icomp-admin-sensor');

  const [editMode, onToggleEditMode] = useToggle(false);
  const [openPatientEditModal, onToggleOpenPatientEditModal] = useToggle(false);

  const [selectedSensors, setSelectedSensors] = useState([]);

  const onToggleSelect = user => {
    const nextValue = selectedSensors.find(u => u.id === user.id)
      ? selectedSensors.filter(u => u.id !== user.id)
      : [].concat(...selectedSensors, user);
    setSelectedSensors(nextValue);
  };

  const onEditCompleted = () => window.location.reload();

  return (
    <div>
      <Table
        fetching={fetching}
        data={sensors}
        error={error}
        columns={getColumns({ editMode, selectedSensors, onToggleSelect })}
      />
      <Box mt="2rem" align="right">
        {editMode ? (
          <ButtonGroup>
            <ButtonCancel
              onClick={() => {
                onToggleEditMode();
                setSelectedSensors([]);
              }}
            />
            <ButtonOk
              disabled={!selectedSensors.length}
              onClick={onToggleOpenPatientEditModal}
            >
              {t('common:edit')}
            </ButtonOk>
          </ButtonGroup>
        ) : (
          <Button
            icon="setting"
            content={t('edit-mode')}
            color="blue"
            floated="right"
            onClick={onToggleEditMode}
          />
        )}
      </Box>
      <SetDepartmentModal
        open={openPatientEditModal}
        onClose={onToggleOpenPatientEditModal}
        sensors={selectedSensors}
        onCompleted={onEditCompleted}
      />
    </div>
  );
};

ICompAdminUserOverviewTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  sensors: PropTypes.array
};

export default ICompAdminUserOverviewTable;
