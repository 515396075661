import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown, Grid, Header, Input } from 'semantic-ui-react';

const options = [
  'sensor_status/all',
  'sensor_status/running-ok',
  'sensor_status/expired',
  'sensor_status/deep-sleep',
  'sensor_status/waking-up',
  'sensor_status/putting-to-sleep'
];

const SimpleSensorDashboardHeader = ({
  search,
  filter,
  onSearch,
  onFilter
}) => {
  const t = useTranslate('simple-sensor');

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={6}>
          <Header as="h2">{t('header')}</Header>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Input
            value={search}
            onChange={onSearch}
            placeholder={t('common:search-by-name')}
            style={{ verticalAlign: 'top', marginRight: '1rem' }}
          />
          <Dropdown
            selection
            value={filter}
            onChange={onFilter}
            options={options.map(item => ({
              text: t(item),
              value: item
            }))}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SimpleSensorDashboardHeader.propTypes = {
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired
};

export default SimpleSensorDashboardHeader;
