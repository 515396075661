import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchSensor } from 'api/sensors';
import { getSensorParams } from 'utils/vendor';
import Fragment from './fragment';

const SensorDetails = () => {
  const params = useParams();

  const { fetching, data, error } = useFetch({
    source: JSON.stringify({
      ...getSensorParams(params),
      sensorId: params.sensorId
    }),
    isJsonSource: true,
    apiFetch: fetchSensor,
    refresh: 120000
  });

  return <Fragment fetching={fetching} data={data} error={error} />;
};

export default SensorDetails;
