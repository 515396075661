import { axiosClient } from 'api/axios-client';
import { nMonitorPatient } from './normalize';
import type { MonitorPatientRaw } from './types';

export const fetchMonitorPatients = async (mToken: string) => {
  const params = { m_token: mToken };
  const {
    project_name,
    device_id,
    patients
  }: {
    project_name: string;
    device_id: string;
    patients: MonitorPatientRaw[];
  } = await axiosClient
    .get('/api/1.0/monitor/refresh', { params })
    .then(response => response.data.value);

  return {
    projectName: project_name,
    deviceId: device_id,
    lastUpdate: new Date().toISOString(),
    patients: patients ? patients.map(nMonitorPatient) : null
  };
};
