import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button } from 'semantic-ui-react';
import { fetchNotificationLogs } from 'api/notifications';
import NotificationLogs from './notification-logs';
import SendNotificationForm from './send-notification-form';
import styles from './styles.module.css';

const SubjectSendNotifModal = ({ data: subject, onClose }) => {
  const t = useTranslate('nfa-subjects');
  const params = useParams();
  const { data: logs = [], onUpdate } = useFetch({
    apiFetch: fetchNotificationLogs,
    source: JSON.stringify({ ...params, subjectId: subject.id }),
    isJsonSource: true
  });

  return (
    <Modal open size="large">
      <Modal.Content className={styles.main}>
        <button className={styles.close} onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </button>

        <h3 className={styles.title}>{t('reminder-manager')}</h3>
        <p>{subject.phoneNumber}</p>

        <SendNotificationForm
          subject={subject}
          onSuccess={newLog => {
            const newLogs = [newLog, ...logs];
            onUpdate(newLogs);
          }}
        />

        {logs.length > 0 && <NotificationLogs logs={logs} />}

        <div style={{ marginTop: '3rem', textAlign: 'right' }}>
          <Button basic color="blue" onClick={onClose}>
            {t('common:close')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SubjectSendNotifModal;
