import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchOrganization } from 'api/organizations';
import Fragment from './fragment';

const ProjectDashboard = () => {
  const params = useParams();

  const { fetching, data, error } = useFetch({
    source: params.organizationId,
    apiFetch: fetchOrganization
  });

  const organizationName = fetching ? '' : data.name;
  const projects = fetching ? [] : data.projects;

  return (
    <Fragment
      fetching={fetching}
      error={error}
      organizationName={organizationName}
      data={projects}
    />
  );
};

export default ProjectDashboard;
