import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { searchPatients } from 'api/icomp-patients';
import Fragment from './fragment';

const regex = /^(\d{0,6})$|(^\d{6}-\d{0,4}$)/m;

class ICompPatientDashboardSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      // ---
      searching: false,
      error: null,
      data: null
    };
    this.cancel = null;
    this.setCancel = c => {
      this.cancel = c;
    };
    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  componentWillUnmount() {
    if (this.cancel) {
      this.cancel();
    }
  }

  onChange(event, { value }) {
    if (!regex.test(value)) {
      return;
    }

    if (this.cancel) {
      this.cancel();
    }

    const input =
      value.length === 6 && this.state.input.indexOf('-') === -1
        ? `${value}-`
        : value;

    this.setState({
      input,
      error: null,
      data: null
    });

    this.props.setSearchMode(false);
  }

  onSearch(event) {
    event.preventDefault();

    if (this.cancel) {
      this.cancel();
    }

    this.setState({ searching: true, error: null, data: null });

    searchPatients(
      {
        params: this.props.params,
        searchText: this.state.input
      },
      this.setCancel
    )
      .then(response => {
        this.props.setSearchMode(true);
        this.setState({ searching: false, data: response });
      })
      .catch(error => {
        this.setState({ searching: false, error });
      });
  }

  onClear() {
    this.props.setSearchMode(false);
    this.setState({
      input: '',
      // ---
      searching: false,
      error: null,
      data: null
    });
  }

  render() {
    return (
      <Fragment
        {...this.state}
        onChange={this.onChange}
        onSearch={this.onSearch}
        onAdd={this.props.onAdd}
        onClear={this.onClear}
      />
    );
  }
}

ICompPatientDashboardSearchContainer.propTypes = {
  onAdd: PropTypes.func.isRequired,
  // ---
  setSearchMode: PropTypes.func.isRequired
};

export default ICompPatientDashboardSearchContainer;
