import React, { useCallback, useState } from 'react';
import { useLongPress } from 'hooks';
import { useFormatDate, useTranslate } from 'intl';
import { Header } from 'semantic-ui-react';
import styles from './styles.module.css';

function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
}

const MonitorHeader = ({ projectName, deviceId, lastUpdate }) => {
  const t = useTranslate('monitor');
  const formatDate = useFormatDate();
  const [fullscreen, setFullscreen] = useState(undefined);
  const onEnterFullScreen = useCallback(() => {
    toggleFullScreen();
    setFullscreen(true);
  }, []);
  const onLeaveFullScreen = useCallback(() => {
    toggleFullScreen();
    setFullscreen(false);
  }, []);

  const { eventHandlers: enterFullsceenEventHandlers } = useLongPress({
    onCompleted: onEnterFullScreen,
    timeout: 1000
  });
  const { eventHandlers: leaveFullsceenEventHandlers } = useLongPress({
    onCompleted: onLeaveFullScreen,
    timeout: 3000
  });
  const fullscreenEventHandlers = fullscreen
    ? leaveFullsceenEventHandlers
    : enterFullsceenEventHandlers;

  return (
    <div className={styles.main}>
      <Header className={styles.header}>{projectName}</Header>

      <Header className={styles.header}>
        <h4>
          <span className={styles.button} {...fullscreenEventHandlers}>
            {t('device-id')}: {deviceId}
          </span>
          <Header.Subheader>
            <div className={styles.right}>
              {formatDate(lastUpdate, 'dd-mm-yyyy hh:mm')}
            </div>
          </Header.Subheader>
        </h4>
      </Header>
    </div>
  );
};

export default MonitorHeader;
