import { fetchAlgorithms } from 'api/algorithms';
import type { RouteParams } from 'app/types';
import type { SensorPlotStream, SensorPlotStreamDerived } from '../types';

export const fetchStreamOptions = async ({
  organizationId,
  projectId
}: Pick<RouteParams, 'organizationId' | 'projectId'>) => {
  const algorithms = await fetchAlgorithms({ organizationId, projectId });
  const derivedOptions: SensorPlotStreamDerived[] = algorithms.map(
    item => `derived:${item.name}` as SensorPlotStreamDerived
  );
  const rawOptions: SensorPlotStream[] = [
    'raw:acc/3ax/4g',
    'raw:temp/acc/scalar',
    'raw:volt/system/mv',
    'raw:cap/stretch/scalar',
    'derived:chunk/freq1'
  ];
  const streamOptions: SensorPlotStream[] = [...derivedOptions, ...rawOptions];
  return streamOptions;
};
