import { axiosClient } from 'api/axios-client';
import { nReport } from './normalize';
import type { GatewayReportRaw } from './types';

export const fetchReports = async ({
  gatewayId,
  view
}: {
  gatewayId: string;
  view: string;
}) => {
  const params = {
    gateway_id: gatewayId,
    scan_report_hours: view
  };

  const { reports }: { reports: GatewayReportRaw[] } = await axiosClient
    .get('/gwapi/1.0/adminx/gateway/scanreports', { params })
    .then(response => response.data.value);

  return reports.map((item, index) => ({
    ...nReport(item),
    idx: index + 1
  }));
};
