import React from 'react';
import { useToggle } from 'hooks';
import { useFormatDate, useTranslate } from 'intl';
import EditTreatmentModal from 'fragments/icomp-patient/edit-treatment-modal';
import { Button, Icon, Modal } from 'semantic-ui-react';
import styles from './styles.module.css';

export const getTrackIdValue = data => data.track.startTime;
export const TrackId = ({ data }) => {
  const formatDate = useFormatDate();
  return <span>{formatDate(data.track.startTime)}</span>;
};

export const getTreatmentIdValue = data => data.startTime;
export const TreatmentId = ({ data }) => {
  return <span>{`#${data.index + 1}`}</span>;
};

export const getTreatmentStartTimeValue = data => data.startTime;
export const TreatmentStartTime = ({ data }) => {
  const formatDate = useFormatDate();
  return <span>{formatDate(data.startTime, 'dd-mm-yyyy hh:mm')}</span>;
};

export const getTreatmentEndTimeValue = data => data.endTime;
export const TreatmentEndTime = ({ data }) => {
  const formatDate = useFormatDate();
  return <span>{formatDate(data.endTime, 'dd-mm-yyyy hh:mm')}</span>;
};

export const getSensorValue = data => data.sensor.name;
export const Sensor = ({ data }) => {
  return <span>{getSensorValue(data)}</span>;
};

export const getBandageValue = data => data?.meta?.bandage;
export const Bandage = ({ data }) => {
  return <span>{getBandageValue(data)}</span>;
};

export const getDiagnoseValue = data => data?.meta?.diagnose;
export const Diagnose = ({ data }) => {
  return <span>{getDiagnoseValue(data)}</span>;
};

export const getCircumsphereValue = data => {
  const circumsphere = data?.meta?.circumsphere;
  return circumsphere ? `${circumsphere} cm` : '';
};
export const Circumsphere = ({ data }) => {
  return <span>{getCircumsphereValue(data)}</span>;
};

export const getSideValue = data => data?.meta?.side;
export const Side = ({
  data: {
    meta: { side }
  }
}) => {
  const t = useTranslate('icomp-patient');
  return <span>{side ? t(`side-${side}`) : ''}</span>;
};

export const Note = ({
  data: {
    meta: { note }
  }
}) => {
  return <div className={styles.note}>{note}</div>;
};

export const Edit = ({ patient, data }) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <Button icon onClick={toggleOpen}>
        <Icon name="edit outline" />
      </Button>

      {open && (
        <Modal open size="small">
          <div style={{ padding: '2em 0' }}>
            <EditTreatmentModal
              patient={patient}
              treatment={data}
              onSubmit={() => window.location.reload()}
              onCancel={toggleOpen}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
