// @ts-nocheck
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler
} from 'axios';
import Cookies from 'js-cookie';

const client: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'Auth-Token': Cookies.get('Auth-Token')
  },
  timeout: 30000,
  validateStatus: status => {
    return status === 200 || status === 406;
  }
});

// Middle ware to transform response
client.interceptors.response.use(
  response => {
    const statusCode = response?.data?.status_code;
    switch (statusCode) {
      case 0:
      case undefined:
        return response;
      case 10:
        return Promise.reject({
          key: 'GENERATING_RESOURCE',
          message: 'Generating Resource',
          statusCode
        });
      default:
        return Promise.reject({
          key: 'REQUEST_FAILURE',
          message: response?.data?.status_msg,
          statusCode
        });
    }
  },
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject({
        type: 'intl',
        key: 'REQUEST_CANCELLED',
        message: 'request-cancelled'
      });
    }
    if (error?.response?.data?.status) {
      return Promise.reject({
        statusCode: error?.response?.data?.status,
        message: JSON.stringify(error?.response?.data?.message)
      });
    }
    return Promise.reject({
      type: 'intl',
      key: 'NETWORK_ERROR',
      message: 'network-error'
    });
  }
);

let CACHE_STORE: {
  [key: string]: Promise<AxiosResponse<any, any>>;
} = {};

export interface GetOptions extends AxiosRequestConfig {
  cache?: boolean;
}

interface AxiosClient extends AxiosInstance {
  get: (url: string, options?: GetOptions) => Promise<AxiosResponse<any, any>>;
  getUri: (options: AxiosRequestConfig) => string;
  clearCache: () => void;
}
export const axiosClient: AxiosClient = {
  ...client,
  get: (url: string, options: GetOptions = {}) => {
    const { cache, ...axiosOptions } = options;

    const key = client.getUri({ url, ...axiosOptions });

    if (cache && CACHE_STORE[key] !== undefined) {
      return CACHE_STORE[key];
    }

    const promise = client.get(url, axiosOptions);
    if (cache) {
      CACHE_STORE[key] = promise;
    }
    return promise;
  },
  getUri: ({ url, ...otherConfigs }: AxiosRequestConfig) => {
    return client.getUri({
      url: `${process.env.REACT_APP_API_HOST}${url}`,
      ...otherConfigs
    });
  },
  clearCache: () => {
    CACHE_STORE = {};
  }
};

/* Set Cancel Request */

export type CancelExecutor = (cancel: Canceler) => void;
