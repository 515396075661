import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import Menu from 'fragments/menu';
import { Container } from 'semantic-ui-react';
import styles from '../styles.module.scss';
import Breadcrumb from './breadcrumb';

const ICompAdminLayout = ({ children }) => (
  <div>
    <Menu homeUrl={routes.iCompDepartments()} />
    <Container className={styles.container}>
      <Breadcrumb />
      <div className={styles.body}>{children}</div>
    </Container>
  </div>
);

ICompAdminLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default ICompAdminLayout;
