import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const NearbySensor = ({ data }) => {
  const t = useTranslate('sens-admin-gateway');

  const { nearby } = data;
  if (nearby.length === 1) {
    return <span>{nearby[0].name}</span>;
  } else if (nearby.length > 1) {
    return (
      <span>{`${nearby[0].name} + ${nearby.length - 1} ${t('more')}`}</span>
    );
  } else {
    return <span>{t('none')}</span>;
  }
};

NearbySensor.propTypes = {
  data: PropTypes.shape({
    nearby: PropTypes.array.isRequired
  }).isRequired
};

export default NearbySensor;
