import { axiosClient } from 'api/axios-client';
import type { AppVersion, AppVersionResponse } from './types';

let promise: Promise<AppVersionResponse>;

export const fetchAppVersion = async (): Promise<AppVersion> => {
  if (!promise) {
    promise = axiosClient
      .get('/api/1.0/version_info')
      .then(response => response.data.value);
  }
  const response = await promise;
  const appVersion = response.version_info[0]!;
  return appVersion;
};
