import type { ScopeRaw } from 'api/scopes';
import type { ICompUser, ICompUserDepartment, ICompUserRaw } from './types';

const nICompUserDepartment = (d: ScopeRaw): ICompUserDepartment => ({
  id: d.id,
  name: d.name,
  longName: d.long_name || '',
  roles: d.roles,
  scopeClass: d.scope_class,
  raw: d
});

export const nICompUser = (
  user: ICompUserRaw,
  scopes: ScopeRaw[]
): ICompUser => ({
  id: user.user_id,
  email: user.email,
  firstName: user.first_name,
  lastName: user.last_name,
  initials: '',
  departmentsAndRoles: user.roles.map(role => {
    const { scope_id, roles } = role;
    const department = scopes.find(s => s.id === scope_id);
    return {
      department: department ? nICompUserDepartment(department) : undefined,
      roles: roles,
      raw: role
    };
  }),
  raw: user
});
