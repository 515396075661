import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEventHandler, useFetch } from 'hooks';
import { fetchReports } from 'api/admin/reports';
import Fragment from './fragment';

const ReportDashboard = () => {
  const params = useParams();
  const [view, setView] = useEventHandler('3');

  const { fetching, data, error } = useFetch({
    source: JSON.stringify({
      gatewayId: params.gatewayId,
      view
    }),
    isJsonSource: true,
    apiFetch: fetchReports
  });

  return (
    <Fragment
      view={view}
      setView={setView}
      fetching={fetching}
      data={data}
      error={error}
    />
  );
};

ReportDashboard.propTypes = {
  match: PropTypes.object.isRequired
};

export default ReportDashboard;
