import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

const regex = /^(\d{0,6})$|(^\d{6}-\d{0,4}$)/m;

class CprInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevValue: props.value
    };
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props) {
    return {
      prevValue: props.value
    };
  }

  onChange(event, { value }) {
    if (!regex.test(value)) {
      return;
    }

    if (value.length === 6 && this.state.prevValue.indexOf('-') === -1) {
      this.props.onChange(event, { value: `${value}-` });
    } else {
      this.props.onChange(event, { value });
    }
  }

  render() {
    return (
      <Form.Input
        {...this.props}
        pattern="^\d{6}-\d{4}$"
        placeholder="041155-1234"
        title="CPR format: 041155-1234"
        onChange={this.onChange}
      />
    );
  }
}

export default CprInput;
