import React from 'react';
import Header from './header';
import Table from './table';

const BLE = () => {
  return (
    <div>
      <Header />
      <Table />
    </div>
  );
};

export default BLE;
