import React, { useState } from 'react';
import AccelerometerData from './accelerometer-setup';
import ActivityData from './activity-setup';
import DataFile from './data-file';
import ExportType from './export-type';

const ExportSettings = ({ selectedData }) => {
  const [settings, setSettings] = useState('export-type');

  switch (settings) {
    case 'export-type':
      return (
        <ExportType
          selectedData={selectedData}
          onSetupDataFile={() => setSettings('data-file')}
        />
      );
    case 'data-file':
      return (
        <DataFile
          onSetupActivity={() => setSettings('activity-data')}
          onSetupAccelerometer={() => setSettings('accelerometer-data')}
          onBack={() => setSettings('export-type')}
        />
      );
    case 'activity-data':
      return (
        <ActivityData
          selectedData={selectedData}
          onBack={() => setSettings('data-file')}
        />
      );
    case 'accelerometer-data':
      return (
        <AccelerometerData
          selectedData={selectedData}
          onBack={() => setSettings('data-file')}
        />
      );

    default:
      return null;
  }
};

export default ExportSettings;
