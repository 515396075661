import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Plot = ({ data }) => {
  const total = data.reduce((prev, curr) => prev + curr.v, 0);
  const hours = Math.floor(total / 3600);
  const minutes = Math.floor(total / 60);

  return (
    <div className={styles.main}>
      <div style={{ fontSize: 'smaller' }}>{`${hours}h ${minutes}min`}</div>
      <div className={styles.plot}>
        {data.map((item, index) => (
          <span
            key={index}
            className={styles.item}
            style={{ height: `${(item.v * 100) / 60}%` }}
          />
        ))}
      </div>
    </div>
  );
};

Plot.propTypes = {
  data: PropTypes.array.isRequired
};

export default Plot;
