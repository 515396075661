const getYDomain = data => {
  const values = data.map(item => item.y).filter(item => item !== null);

  let min = Math.min(...values);
  let max = Math.max(...values);

  if (min === Infinity || max === Infinity) {
    return [0, 50];
  }

  min = Math.floor((min - 5) / 5) * 5;
  max = Math.ceil((max + 5) / 5) * 5;

  const diff = max - min;
  if (diff < 30) {
    return [min, min + 30];
  }

  return [min, max];
};

export default { getYDomain };
