import React, { useRef } from 'react';
import { useTranslate } from 'intl';
import { Form, Modal } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import SubjectForm from '../subject-form';
import { useEditSubject } from './use-edit-subject';

const SubjectEditModal = ({ subject, onClose, onSuccess }) => {
  const t = useTranslate('nfa-subjects');
  const countRef = useRef(0); // Tricky to reset phone input
  const { form, onChanges, validations, onSubmit, submitting, error } =
    useEditSubject({
      subject,
      onSuccess
    });

  return (
    <Modal open size="small">
      <Modal.Header>{t('edit-subject')}</Modal.Header>
      <Modal.Content>
        <Form key={countRef.current} error={!!error} onSubmit={onSubmit}>
          <SubjectForm
            mode="edit"
            form={form}
            onChanges={onChanges}
            validations={validations}
          />

          {error && <ErrorMessage error={error} />}

          <Form.Group style={{ marginTop: '3rem' }}>
            <Form.Button
              type="button"
              disabled={submitting}
              onClick={onClose}
              style={{ margin: 0 }}
            >
              {t('common:cancel')}
            </Form.Button>
            <Form.Button
              type="submit"
              color="blue"
              loading={submitting}
              disabled={submitting}
              style={{ margin: 0 }}
            >
              {t('confirm-changes')}
            </Form.Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default SubjectEditModal;
