import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { isDateChange } from 'utils/date';
import type { ISpinePatientForm, ISpinePatientMeasurement } from './types';

export const editPatient = async ({
  params,
  patient,
  form
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  patient: ISpinePatientMeasurement;
  form: ISpinePatientForm;
}) => {
  const requests = [];

  if (
    isDateChange(form.monitoringStartTime, patient.monitoring.startTime) ||
    isDateChange(form.monitoringEndTime, patient.monitoring.endTime)
  ) {
    const payload = {
      org_id: params.organizationId,
      project_id: params.projectId,
      measurement_id: patient.id,
      // ---
      start_time: form.monitoringStartTime,
      end_time: form.monitoringEndTime
    };
    requests.push(axiosClient.post('/api/1.0/measurements/edit', payload));
  }

  if (
    form.patient !== patient.patient.name ||
    form.phone !== patient.phone ||
    isDateChange(form.feedbackStartTime, patient.feedback.startTime) ||
    isDateChange(form.feedbackEndTime, patient.feedback.endTime)
  ) {
    const payload = {
      org_id: params.organizationId,
      project_id: params.projectId,
      patient_id: patient.patient.id,
      // ---
      first_name: form.patient,
      ...(form.phone ? { phone_number: form.phone } : null),
      meta: {
        feedback_start_time: form.feedbackStartTime,
        feedback_end_time: form.feedbackEndTime
      }
    };
    requests.push(axiosClient.post('/api/1.0/patients/edit', payload));
  }

  return Promise.all(requests).then(() => {
    const response: ISpinePatientMeasurement = { ...patient };

    response.patient.name = form.patient;
    response.phone = form.phone;
    response.monitoring = {
      startTime: form.monitoringStartTime,
      endTime: form.monitoringEndTime
    };
    response.feedback = {
      startTime: form.feedbackStartTime,
      endTime: form.feedbackEndTime
    };

    return response;
  });
};
