import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { fetchPlotDay, fetchPlotHour } from 'api/simple-sensors';
import DateSelect from 'components/plot/date-select';
import Fetcher from 'components/plot/fetcher';
import HourSelect from 'components/plot/hour-select';
import Layout from 'components/plot/layout';
import StreamSelect from 'components/plot/stream-select';
import ExportDay from './export-day';

const SimpleSensorPlotsFragment = ({
  defaultDate,
  minDate,
  maxDate,
  streamOptions,
  dayStatistics
}) => {
  const [date, setDate] = useState(defaultDate);
  const [prevDefaultDate, setPrevDefaultDate] = useState(null);
  const [streamPlotDay, setStreamPlotDay] = useState(streamOptions[0]);
  const [streamPlotHour, setStreamPlotHour] = useState(streamPlotDay);
  const [displayPlotHour, setDisplayPlotHour] = useState(false);

  if (defaultDate !== prevDefaultDate) {
    setDate(defaultDate);
    setDisplayPlotHour(false);
    setPrevDefaultDate(defaultDate);
  }

  const onChangeDatePlotDay = useCallback(value => {
    setDate(value);
    setDisplayPlotHour(false);
  }, []);

  const onChangeStreamPlotDay = useCallback(value => {
    setStreamPlotDay(value);
    setStreamPlotHour(value);
    setDisplayPlotHour(false);
  }, []);

  const onSelectTickPlotDay = useCallback(date => {
    setDate(date);
    setDisplayPlotHour(true);
  }, []);

  return (
    <Segment>
      <Layout
        type="day"
        timeSelect={
          <DateSelect
            value={date}
            onChange={onChangeDatePlotDay}
            minDate={minDate}
            maxDate={maxDate}
            dayStatistics={dayStatistics}
          />
        }
        exportButton={<ExportDay date={date} stream={streamPlotDay} />}
        streamSelect={
          streamOptions.length > 1 && (
            <StreamSelect
              value={streamPlotDay}
              onChange={onChangeStreamPlotDay}
              options={streamOptions}
            />
          )
        }
      >
        <Fetcher
          type="day"
          apiFetch={fetchPlotDay}
          date={date}
          stream={streamPlotDay}
          onSelectTick={onSelectTickPlotDay}
        />
      </Layout>

      {displayPlotHour && (
        <Layout
          type="hour"
          timeSelect={<HourSelect value={date} onChange={setDate} />}
          streamSelect={
            streamOptions.length > 1 && (
              <StreamSelect
                value={streamPlotHour}
                onChange={setStreamPlotHour}
                options={streamOptions}
              />
            )
          }
        >
          <Fetcher
            type="hour"
            apiFetch={fetchPlotHour}
            date={date}
            stream={streamPlotHour}
          />
        </Layout>
      )}
    </Segment>
  );
};

SimpleSensorPlotsFragment.propTypes = {
  defaultDate: PropTypes.string,
  // ---
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  // ---
  streamOptions: PropTypes.array.isRequired,
  dayStatistics: PropTypes.object.isRequired
};

export default SimpleSensorPlotsFragment;
