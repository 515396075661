import React from 'react';
import { useFetch, useSearch } from 'hooks';
import { fetchOrganizations } from 'api/organizations';
import Header from './header';
import Table from './table';
import useRedirect from './use-redirect';
import utils from './utils';

const OrganizationDashboard = () => {
  const {
    fetching,
    data = [],
    error
  } = useFetch({
    apiFetch: fetchOrganizations
  });

  const [search, onSearch] = useSearch({ uppercase: true });

  useRedirect(data);

  return (
    <React.Fragment>
      <Header search={search} onSearch={onSearch} />
      <Table
        fetching={fetching}
        data={utils.filterData({ data, search })}
        error={error}
      />
    </React.Fragment>
  );
};

export default OrganizationDashboard;
