import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';

function withStreamOptions(WrappedComponent, apiFetch) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithStreamOptions = props => {
    const { organizationId, projectId } = useParams();
    const {
      fetching,
      data: streamOptions = [],
      error
    } = useFetch({
      apiFetch,
      source: JSON.stringify({ organizationId, projectId }),
      isJsonSource: true
    });

    if (fetching) {
      return <LoadingSection />;
    }

    if (error) {
      return <ErrorMessage error={error} />;
    }

    return <WrappedComponent {...props} streamOptions={streamOptions} />;
  };

  ComponentWithStreamOptions.displayName = `withStreamOptions(${displayName})`;

  return ComponentWithStreamOptions;
}

export default withStreamOptions;
