/* eslint-disable dot-notation */
import { createSearchRegex } from 'utils/search';
import { createSorter } from 'utils/sort';

const getCreateDateOrder = data => data.idx;

const getSensorNameOrder = data => data.sensor.name;

const getPatientOrder = data => data.patient.name;

const getStartTimeOrder = data => data.startTime;

const getEndTimeOrder = data => data.endTime;

const statusOrders = ['status/discharged', 'status/active'];
const getStatusOrder = data => statusOrders.indexOf(data.patient.status) + 1;

const orders = {
  'create-date': getCreateDateOrder,
  'sensor-name': getSensorNameOrder,
  patient: getPatientOrder,
  'start-time': getStartTimeOrder,
  'end-time': getEndTimeOrder,
  status: getStatusOrder
};

const sorters = {
  'create-date': createSorter(orders['create-date']),
  'sensor-name': createSorter(orders['sensor-name']),
  patient: createSorter(orders['patient']),
  'start-time': createSorter(orders['start-time']),
  'end-time': createSorter(orders['end-time']),
  status: createSorter(orders['status'])
};

const statusFilters = {
  discharged: 'status/discharged',
  active: 'status/active'
};

export const filterData = ({
  data,
  sortAccessor,
  sortDirection,
  searchQuery,
  filterStatus
}) => {
  let result = data.slice();

  if (searchQuery) {
    const searchRegex = createSearchRegex(searchQuery);
    result = result.filter(item => item.patient.name.match(searchRegex));
  }

  if (sortAccessor) {
    const sorter = sorters[sortAccessor];
    let result1 = result.filter(item => orders[sortAccessor](item));
    const result2 = result.filter(item => !orders[sortAccessor](item));
    result1 = result1.sort(sorter);
    if (sortDirection === 'desc') {
      result1.reverse();
    }
    result = [...result1, ...result2];
  }

  if (filterStatus.length) {
    const status = filterStatus.reduce(
      (prev, curr) => prev.concat(statusFilters[curr]),
      []
    );
    result = result.filter(item => status.includes(item.patient.status));
  }

  return result;
};

export const isDisabledExport = () => false;
