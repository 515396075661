import { axiosClient } from 'api/axios-client';
import type { SensorRaw } from 'api/sensors';
import type { RouteParams } from 'app/types';
import { nNfaSubject } from './normalize';
import type { NFASubjectRaw } from './types';

export const fetchSubjects = async ({
  organizationId,
  projectId
}: Pick<RouteParams, 'organizationId' | 'projectId'>) => {
  const params = {
    org_id: organizationId,
    project_id: projectId
  };
  const {
    patients,
    sensors
  }: { patients: NFASubjectRaw[]; sensors: SensorRaw[] } = await axiosClient
    .get('/api/1.0/patients/extended', { params })
    .then(response => {
      const raw = response?.data?.value;
      const patients = raw[0]?.patients || [];
      const sensors = raw[0]?.sensors || [];
      return { patients, sensors };
    });

  return patients.map((patient, index) => ({
    ...nNfaSubject(patient, sensors),
    idx: index
  }));
};
