import React from 'react';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import Field from '../../field';
import Modal from './modal';

const EditPatient = ({ patientDetails }) => {
  const t = useTranslate('icomp-patient');
  const [isOpen, onToggleIsOpen] = useToggle(false);
  const onCompleted = () => window.location.reload();

  return (
    <React.Fragment>
      <Field label="">
        <Button
          primary
          data-cy="patient-details__edit-patient-button"
          onClick={onToggleIsOpen}
        >
          {t('common:edit')}
        </Button>
      </Field>
      <Modal
        isOpen={isOpen}
        onClose={onToggleIsOpen}
        patient={patientDetails.patient}
        onCompleted={onCompleted}
      />
    </React.Fragment>
  );
};

export default EditPatient;
