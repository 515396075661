import { fetchHospitals } from './fetch-hospitals';

export const fetchHospital = async ({
  hospitalId,
  withProject = false
}: {
  hospitalId: string;
  withProject?: boolean;
}) => {
  return fetchHospitals({ withProject }).then(hospitals =>
    hospitals.find(h => h.id === hospitalId)
  );
};
