import type { GatewayReport, GatewayReportRaw } from './types';

export const nReport = (report: GatewayReportRaw): GatewayReport => ({
  id: report.id,
  time: report.time,
  trigger: report.trigger,
  sensors: report.devices,
  status: report.state.split(',').join(', '),
  raw: report
});
