import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { StatusDropdown } from 'fragments/sensor';
import { Input } from 'semantic-ui-react';
import Box from 'components/box';

const Filter = ({ search, onSearch, filter, onFilter }) => {
  const t = useTranslate('icomp-admin-sensor');

  return (
    <div>
      <Input
        value={search}
        onChange={onSearch}
        placeholder={t('common:search-by-name')}
        style={{ verticalAlign: 'top' }}
      />

      <Box as="span" ml="1rem">
        <StatusDropdown value={filter} onChange={onFilter} />
      </Box>
    </div>
  );
};

Filter.propTypes = {
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired
};

export default Filter;
