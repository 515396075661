import React from 'react';
import Body from 'containers/admin/nearby-sensor/dashboard';
import Layout from 'layouts/sens-admin';

const PageAdminNearbySensors = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageAdminNearbySensors;
