import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Tab } from 'semantic-ui-react';
// import Table from '../table';
import Control from '../control';
import InfoBasic from '../info-basic';
import InfoDetails from '../info-details';
import Plots from '../plots';

const SensorDetailsBodyFragment = ({
  info,
  // sessions,
  dayStatistics,
  date,
  minDate,
  maxDate,
  onUpdateInfo
  // onUpdateDate
}) => {
  const t = useTranslate('sensor');

  const panes = [
    {
      menuItem: t('details'),
      render: () => (
        <Tab.Pane>
          <InfoDetails data={info} />
        </Tab.Pane>
      )
    },
    // Disabled MOKJ
    // {
    //   menuItem: t('details'),
    //   render: () => (
    //     <Tab.Pane>
    //       <Table sessions={sessions} onSelectDate={onUpdateDate} />
    //     </Tab.Pane>
    //   )
    // },
    ...(info.expired
      ? []
      : [
          {
            menuItem: t('control'),
            render: () => (
              <Tab.Pane>
                <Control data={info} onUpdate={onUpdateInfo} />
              </Tab.Pane>
            )
          }
        ])
  ];

  return (
    <div>
      <InfoBasic data={info} />
      <Tab panes={panes} />
      <Plots
        defaultDate={date}
        minDate={minDate}
        maxDate={maxDate}
        dayStatistics={dayStatistics}
      />
    </div>
  );
};

SensorDetailsBodyFragment.propTypes = {
  info: PropTypes.object.isRequired,
  sessions: PropTypes.array.isRequired,
  dayStatistics: PropTypes.object.isRequired,
  // ---
  date: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  // ---
  onUpdateInfo: PropTypes.func.isRequired,
  onUpdateDate: PropTypes.func.isRequired
};

export default SensorDetailsBodyFragment;
