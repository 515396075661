import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nICompPatient } from './normalize';
import type { ICompPatientForm, ICompPatientRaw } from './types';

export const addPatient = async ({
  form,
  params
}: {
  form: ICompPatientForm;
  params: Pick<RouteParams, 'departmentId' | 'projectId'>;
}) => {
  if (!form.sensor) {
    return Promise.reject({
      type: 'intl',
      message: 'sensor-is-required'
    });
  }
  if (!form.bandage) {
    return Promise.reject({
      type: 'intl',
      message: 'bandage-is-required'
    });
  }
  if (!form.diagnose) {
    return Promise.reject({
      type: 'intl',
      message: 'diagnose-is-required'
    });
  }

  const payload = {
    org_id: params.departmentId,
    project_id: params.projectId,
    first_name: form.firstName,
    last_name: form.lastName,
    dk_cpr: form.cpr.split('-').join(''),
    ...(form.phone ? { phone_number: form.phone } : null),
    side: form.side,
    sensor_id: form.sensor,
    start_time: form.date,
    type: form.bandage,
    diagnose: form.diagnose,
    circumsphere: Number(form.circumsphere),
    note: form.note
  };

  const { patient }: { patient: ICompPatientRaw } = await axiosClient
    .post('/api/1.0/icompression/patients/create', payload)
    .then(response => response.data.value);

  return nICompPatient(patient);
};
