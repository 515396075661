import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  name: <Translate ns="icomp-admin-department" i18nKey="name" />,
  description: <Translate ns="icomp-admin-department" i18nKey="description" />,
  numOfSensors: (
    <Translate ns="icomp-admin-department" i18nKey="num-of-sensors" />
  ),
  numOfUsers: <Translate ns="icomp-admin-department" i18nKey="num-of-users" />
};
