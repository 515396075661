import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Container, Icon, Message } from 'semantic-ui-react';
import styles from './styles.module.css';

const VersionMessage = ({ position }) => {
  const t = useTranslate('version-message');

  if (position === 'menu') {
    return (
      <Message className={styles.yellow}>
        <Container className={styles.message}>
          <Icon name="info circle" size="large" />
          <p>
            {t('development-version')}
            {` `}
            {t('released-version')}
            {` `}
            <a
              href="https://app.sens.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://app.sens.dk
            </a>
          </p>
        </Container>
      </Message>
    );
  }

  return (
    <Message color="yellow" className={styles.message2}>
      <Icon name="info circle" size="large" />
      <div>
        {t('development-version')}
        <br />
        {t('released-version')}
        {` `}
        <a href="https://app.sens.dk" target="_blank" rel="noopener noreferrer">
          https://app.sens.dk
        </a>
      </div>
    </Message>
  );
};

VersionMessage.propTypes = {
  position: PropTypes.oneOf(['menu', 'login']).isRequired
};

export default VersionMessage;
