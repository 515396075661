import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { ICompPatient, ICompTreatment } from '../patients';
import type { TreatmentEditForm } from './types';

export const editTreatment = async ({
  params,
  patient,
  treatment,
  form
}: {
  params: Pick<RouteParams, 'departmentId' | 'projectId'>;
  patient: ICompPatient;
  treatment: ICompTreatment;
  form: TreatmentEditForm;
}) => {
  if (!form.sensor) {
    return Promise.reject({
      type: 'intl',
      message: 'sensor-is-required'
    });
  }
  if (!form.bandage) {
    return Promise.reject({
      type: 'intl',
      message: 'bandage-is-required'
    });
  }
  if (!form.diagnose) {
    return Promise.reject({
      type: 'intl',
      message: 'diagnose-is-required'
    });
  }
  if (!form.side) {
    return Promise.reject({
      type: 'intl',
      message: 'side-is-required'
    });
  }

  let payload: any = {
    org_id: params.departmentId,
    project_id: params.projectId,
    patient_id: patient.id,
    measurement_id: treatment.id
  };

  if (form.startTime !== treatment.startTime) {
    payload = {
      ...payload,
      start_time: form.startTime
    };
  }
  if (form.endTime !== treatment.endTime) {
    payload = {
      ...payload,
      end_time: form.endTime
    };
  }
  if (form.sensor && form.sensor !== treatment?.sensor?.id) {
    payload = {
      ...payload,
      sensor_id: form.sensor
    };
  }
  if (form.side !== treatment?.meta?.side) {
    payload = {
      ...payload,
      side: form.side
    };
  }
  if (form.bandage !== treatment?.meta?.bandage) {
    payload = {
      ...payload,
      type: form.bandage
    };
  }
  if (form.diagnose !== treatment?.meta?.diagnose) {
    payload = {
      ...payload,
      diagnose: form.diagnose
    };
  }
  if (form.circumsphere !== treatment?.meta?.circumsphere) {
    payload = {
      ...payload,
      circumsphere: Number(form.circumsphere)
    };
  }
  if (form.note !== treatment?.meta?.note) {
    payload = {
      ...payload,
      note: form.note
    };
  }

  return axiosClient.post('/api/1.0/icompression/measurement/edit', payload);
};
