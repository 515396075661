/* eslint-disable */
import moment from 'moment';

const TS = {
  HOUR: 60 * 60 * 1000,
  MINUTE: 60 * 1000,
  SECOND: 1000
};

const getXDomain = (date, zoomId) => {
  const timestamp = moment(date).valueOf();

  // domain in 1 hour
  const xdomain = [timestamp, timestamp + TS.HOUR];

  if (zoomId === undefined) {
    return xdomain;
  }

  // domain in 1 minute
  return [
    xdomain[0] + zoomId * TS.MINUTE,
    xdomain[0] + (1 + zoomId) * TS.MINUTE
  ];
};

const getXTicks = (domain, zoomed) => {
  const ticks = [];
  const x = domain.x[0];
  if (zoomed) {
    // ticks every 5 seconds
    for (let i = 0; i <= 12; i++) {
      ticks.push(x + i * 5 * TS.SECOND);
    }
  } else {
    // ticks every 5 minutes
    for (let i = 0; i <= 12; i++) {
      ticks.push(x + i * 5 * TS.MINUTE);
    }
  }
  return ticks;
};

const getYTicks = (domain, step) => {
  const ticks = [];
  for (let i = domain.y[0]; i <= domain.y[1]; i += step) {
    ticks.push(i);
  }
  return ticks;
};

export default {
  getXDomain,
  getXTicks,
  getYTicks
};
