import React from 'react';
import { Control, Headers, LastOnline, Name, Status } from '../elements';

export const getColumns = props => [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status,
    sortable: true
  },
  {
    name: 'lastOnline',
    header: Headers.lastOnline,
    cell: LastOnline
  },
  {
    name: 'control',
    header: Headers.control,
    cell: ({ data }) => <Control data={data} {...props} />,
    align: 'center'
  }
];
