import { useState } from 'react';
import moment from 'moment';

/*
  Default `StartTime` is current selected date time.
  Default `EndTime` is current selected date time +1h.
  Max interval is 30 hours
*/

const useDatesCSV = date => {
  const [startDate, setStartDate] = useState(() => moment(date).format());

  const [endDate, setEndDate] = useState(() =>
    moment(date).add(1, 'hours').format()
  );

  const onChangeStartDate = (event, { value }) => {
    const startDateObj = moment(value);
    const endDateObj = moment(endDate);
    setStartDate(startDateObj.format());
    const diff = endDateObj.diff(startDateObj, 'hours');
    if (diff > 30 || diff < 1) {
      setEndDate(moment(startDateObj).add(30, 'hours').format());
    }
  };

  const onChangeEndDate = (event, { value }) => {
    const startDateObj = moment(startDate);
    const endDateObj = moment(value);
    const diff = endDateObj.diff(startDateObj, 'hours');
    if (diff > 30 || diff < 1) {
      setStartDate(moment(endDateObj).add(-30, 'hours').format());
    }
    setEndDate(endDateObj.format());
  };

  return {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate
  };
};

export default useDatesCSV;
