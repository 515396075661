import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Filter from './filter';
import Header from './header';
import Search from './search';
import Table from './table';
import useFilter from './use-filter';
import utils from './utils';

const ICompPatientDashboardFragment = ({ fetching, error, data, onAdd }) => {
  const [filter, onFilter] = useFilter();
  const filtered = utils.filterData({ data, filter });

  const [searchMode, setSearchMode] = useState(false);

  return (
    <div>
      <Header />
      <Search onAdd={onAdd} setSearchMode={setSearchMode} />
      {!searchMode && (
        <React.Fragment>
          <Filter filter={filter} onFilter={onFilter} />
          <Table fetching={fetching} error={error} data={filtered} />
        </React.Fragment>
      )}
    </div>
  );
};

ICompPatientDashboardFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array.isRequired,
  // ---
  onAdd: PropTypes.func.isRequired
};

export default ICompPatientDashboardFragment;
