import React from 'react';
import PropTypes from 'prop-types';
import Plot from '../plot';

const Today = ({ data }) => {
  return <Plot data={data.today} />;
};

Today.propTypes = {
  data: PropTypes.object.isRequired
};

export default Today;
