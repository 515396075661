import { axiosClient } from 'api/axios-client';

let CACHED_DOWNLOAD_TOKEN: string | null = null;

export const fetchDownloadToken = async () => {
  if (CACHED_DOWNLOAD_TOKEN) {
    return CACHED_DOWNLOAD_TOKEN;
  }

  const { download_token: downloadToken }: { download_token: string } =
    await axiosClient
      .get('/api/1.0/auth/get_download_token')
      .then(response => response.data.value);

  CACHED_DOWNLOAD_TOKEN = downloadToken;

  return downloadToken;
};
