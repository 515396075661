import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import { useBreadCrumbs } from './use-breadcrumb';

const ICompAdminBreadcrumb = () => {
  const breadcrumbs = useBreadCrumbs();

  return (
    <Breadcrumb>
      {breadcrumbs.map((c, i) => (
        <React.Fragment key={i}>
          {i === breadcrumbs.length - 1 ? (
            <Breadcrumb.Section active>{c.label}</Breadcrumb.Section>
          ) : (
            <Breadcrumb.Section link as={Link} to={c.link}>
              {c.label}
            </Breadcrumb.Section>
          )}
          {i !== breadcrumbs.length - 1 && <Breadcrumb.Divider />}
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export default ICompAdminBreadcrumb;
