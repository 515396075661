import React from 'react';
import { useTranslate } from 'intl';
import { faUser, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BATTERY_COLORS, BATTERY_ICONS, SENSOR_COLORS } from 'consts/sensor';
import { Battery, SensorDisplay, SensorSelect, Status } from 'fragments/sensor';
import { Form, Icon, Label } from 'semantic-ui-react';
import styles from './styles.module.scss';

const PatientSensorSelect = ({
  label,
  placeholder,
  value,
  onChange,
  error
}) => {
  const t = useTranslate('patient');
  return (
    <Form.Group widths={2}>
      <Form.Field
        label={label}
        control={SensorSelect}
        showExpiredSensors
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        customContent={sensor => (
          <div className={styles.option}>
            <div className={styles.left}>
              <Label
                circular
                empty
                color={SENSOR_COLORS[sensor.status]}
                className={styles.status}
              />
              <span className={styles.name}>{sensor.name}</span>
            </div>

            {sensor.battery?.status && (
              <div className={styles.right}>
                <div className={styles.attachedPatient}>
                  {sensor.attachedPatients.length ? (
                    <FontAwesomeIcon
                      className={styles.inUse}
                      icon={faUserCheck}
                    />
                  ) : (
                    <FontAwesomeIcon className={styles.free} icon={faUser} />
                  )}
                </div>
                <Icon
                  className={styles.battery}
                  name={BATTERY_ICONS[sensor.battery.status]}
                  style={{ color: BATTERY_COLORS[sensor.battery.status] }}
                />
              </div>
            )}
          </div>
        )}
        required
        error={error}
      />

      <Form.Field>
        <label>&nbsp;</label>
        <SensorDisplay
          sensorId={value}
          render={sensor =>
            sensor && (
              <div className={styles.selected}>
                <div className={styles.status}>
                  <Status data={sensor} />
                </div>
                <div className={styles.attachedPatient}>
                  {sensor.attachedPatients.length ? (
                    <>
                      <FontAwesomeIcon
                        className={styles.inUse}
                        icon={faUserCheck}
                      />
                      <span className={styles.text}>
                        {t('form.sensor-in-use')}
                      </span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon className={styles.free} icon={faUser} />
                      <span className={styles.text}>
                        {t('form.sensor-free-to-use')}
                      </span>
                    </>
                  )}
                </div>
                <div className={styles.battery}>
                  <Battery data={sensor} />
                </div>
              </div>
            )
          }
        />
      </Form.Field>
    </Form.Group>
  );
};

export default PatientSensorSelect;
