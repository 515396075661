import { useTranslation } from 'react-i18next';

export const useTranslate = namespaces => {
  /* eslint-disable-next-line no-nested-ternary */
  const merged = !namespaces
    ? ['common']
    : typeof namespaces === 'string'
    ? [namespaces, 'common']
    : [...namespaces, 'common'];

  const cleaned = [...new Set(merged)];

  const { t } = useTranslation(cleaned);

  return (translationKey, interpolation) => {
    return t(translationKey, translationKey, interpolation);
  };
};
