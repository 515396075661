import { useState } from 'react';
import moment from 'moment';

/*
  Default `EndTime` is current selected day.
  Default `StartTime` is 7 days before `EndTime`.
  Only select full days.
  Allow max 14 days.
*/

const useDatesPDF = date => {
  const [startDate, setStartDate] = useState(() =>
    moment(date).startOf('day').add(-7, 'days').format()
  );

  const [endDate, setEndDate] = useState(() =>
    moment(date).startOf('day').format()
  );

  const onChangeStartDate = (event, { value }) => {
    const startDateObj = moment(value).startOf('day');
    const endDateObj = moment(endDate).startOf('day');
    const diff = endDateObj.diff(startDateObj, 'days');
    setStartDate(startDateObj.format());
    if (diff > 14 || diff < 1) {
      setEndDate(moment(startDateObj).add(14, 'days').format());
    }
  };

  const onChangeEndDate = (event, { value }) => {
    const startDateObj = moment(startDate).startOf('day');
    const endDateObj = moment(value).startOf('day');
    const diff = endDateObj.diff(startDateObj, 'days');
    if (diff > 14 || diff < 1) {
      setStartDate(moment(endDateObj).add(-14, 'days').format());
    }
    setEndDate(endDateObj.format());
  };

  return {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate
  };
};

export default useDatesPDF;
