import React from 'react';
import {
  // Bandage,
  // Diagnose,
  Circumsphere,
  Edit,
  Headers,
  Note,
  Sensor,
  Side,
  TrackId,
  TreatmentEndTime,
  TreatmentId,
  TreatmentStartTime,
  sorters
} from './elements';

export const columns = [
  {
    name: 'trackId',
    header: Headers.trackId,
    cell: TrackId,
    sortable: true,
    sorter: sorters.trackId
  },
  {
    name: 'treatmentId',
    header: Headers.treatmentId,
    cell: TreatmentId,
    sortable: true,
    sorter: sorters.treatmentId
  },
  {
    name: 'treatmentStartTime',
    header: Headers.treatmentStartTime,
    cell: TreatmentStartTime,
    sortable: true,
    sorter: sorters.treatmentStartTime
  },
  {
    name: 'treatmentEndTime',
    header: Headers.treatmentEndTime,
    cell: TreatmentEndTime,
    sortable: true,
    sorter: sorters.treatmentEndTime
  },
  {
    name: 'sensor',
    header: Headers.sensor,
    cell: Sensor,
    sortable: true,
    sorter: sorters.sensor
  },
  // {
  //   name: 'bandage',
  //   header: Headers.bandage,
  //   cell: Bandage,
  //   sortable: true,
  //   sorter: sorters.bandage
  // },
  // {
  //   name: 'diagnose',
  //   header: Headers.diagnose,
  //   cell: Diagnose,
  //   sortable: true,
  //   sorter: sorters.diagnose
  // },
  {
    name: 'circumsphere',
    header: Headers.circumsphere,
    cell: Circumsphere,
    sortable: true,
    sorter: sorters.circumsphere
  },
  {
    name: 'side',
    header: Headers.side,
    cell: Side,
    sortable: true,
    sorter: sorters.side
  },
  {
    name: 'note',
    header: Headers.note,
    cell: Note,
    sortable: false
  }
];

export const getColumns = ({ editMode, patient }) => {
  if (!editMode) return columns;
  return [
    ...columns,
    {
      name: 'edit',
      header: Headers.edit,
      cell: ({ data }) => <Edit patient={patient} data={data} />
    }
  ];
};
