import { useState } from 'react';
import moment from 'moment';

/*
  Default `EndTime` is current selected day.
  Default `StartTime` is 7 days before `EndTime`.
  Limit 14 days export.
*/

const LIMIT = 14;

const useDatesPDF = date => {
  const [startDate, setStartDate] = useState(() =>
    moment(date).startOf('day').add(-7, 'days').format()
  );

  const [endDate, setEndDate] = useState(() =>
    moment(date).startOf('day').format()
  );

  const onChangeStartDate = (event, { value }) => {
    const startDateObj = moment(value).startOf('day');
    const endDateObj = moment(endDate).startOf('day');
    const diff = endDateObj.diff(startDateObj, 'days');
    setStartDate(startDateObj.format());
    if (diff > LIMIT || diff < 1) {
      setEndDate(moment(startDateObj).add(LIMIT, 'days').format());
    }
  };

  const onChangeEndDate = (event, { value }) => {
    const startDateObj = moment(startDate).startOf('day');
    const endDateObj = moment(value).startOf('day');
    const diff = endDateObj.diff(startDateObj, 'days');
    if (diff > LIMIT || diff < 1) {
      setStartDate(moment(endDateObj).add(-LIMIT, 'days').format());
    }
    setEndDate(endDateObj.format());
  };

  const minDate = moment(startDate).add(1, 'days').format();
  const maxDate = moment(startDate).add(LIMIT, 'days').format();

  return {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    limit: LIMIT,
    minDate,
    maxDate
  };
};

export default useDatesPDF;
