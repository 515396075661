import type { MonitorPatient, MonitorPatientRaw } from './types';

export const nMonitorPatient = (
  patient: MonitorPatientRaw
): MonitorPatient => ({
  id: patient.id,
  firstName: patient.first_name,
  lastName: patient.last_name,
  measurementStatus: patient.measurement_status,
  patientType: patient.patient_type,
  startTime: patient.start_time,
  endTime: patient.end_time,
  days: patient.days,
  sensorName: patient.sensor_name,
  sensorTimestamp: patient.sensor_timestamp,
  todayActive: patient.today_active,
  yesterdayActive: patient.yesterday_active,
  todaySteps: patient.today_steps,
  yesterdaySteps: patient.yesterday_steps,
  lastMobilizedTime: patient.last_mobilized_time,
  obs: patient.obs,
  raw: patient
});
