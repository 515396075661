import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Header } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';
import Body from './body';

const SensorDetailsFragment = ({ fetching, data, error }) => {
  const t = useTranslate('sensor');

  if (fetching) {
    return <LoadingSection />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div>
      <Header as="h2">{t('sensor-details')}</Header>
      <Body data={data} />
    </div>
  );
};

SensorDetailsFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.object
};

export default SensorDetailsFragment;
