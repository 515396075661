import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { Icon, Menu } from 'semantic-ui-react';
import { fetchDepartment } from 'api/icomp-departments';

const ICompNavbar = () => {
  const t = useTranslate('menu');

  const { hospitalId, departmentId, projectId } = useParams();

  const { fetching, data: department } = useFetch({
    source: JSON.stringify({
      hospitalId,
      departmentId,
      withProject: true
    }),
    isJsonSource: true,
    apiFetch: fetchDepartment
  });

  if (!departmentId) return null;

  const views = fetching ? [] : department.project.views;

  return (
    <React.Fragment>
      {views.map(item => {
        return (
          <Menu.Item
            key={item.name}
            as={NavLink}
            to={item.route(hospitalId, departmentId, projectId)}
            disabled={!projectId}
            data-cy={`menu-item__${item.name}`}
          >
            <Icon name={item.icon} />
            {t(item.name)}
          </Menu.Item>
        );
      })}
    </React.Fragment>
  );
};

export default ICompNavbar;
