import React from 'react';
import PropTypes from 'prop-types';
import { Header, Modal } from 'semantic-ui-react';
import Box from 'components/box';
import ErrorMessage from 'components/error-message';

const ICompModal = ({
  open,
  title,
  description,
  // ---
  body,
  footer,
  // ---
  error,
  ...otherProps
}) => {
  return (
    <Modal open={open} size="small" {...otherProps}>
      <div style={{ padding: '3rem 3rem 2rem 3rem' }}>
        <Header as="h3">{title}</Header>
        <div>
          <div>{description}</div>

          <Box mt="2rem">{body}</Box>

          {error && (
            <Box mt="2rem">
              <ErrorMessage error={error} />
            </Box>
          )}

          {footer && <Box mt="2.5rem">{footer}</Box>}
        </div>
      </div>
    </Modal>
  );
};

ICompModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.any,
  description: PropTypes.any,
  body: PropTypes.node,
  footer: PropTypes.node,
  error: PropTypes.object
};

export default ICompModal;
