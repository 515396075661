import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Icon, Menu } from 'semantic-ui-react';

const views = [
  { name: 'nfa-subjects', icon: 'address card', route: routes.nfaSubjects },
  { name: 'nfa-sensors', icon: 'ticket', route: routes.nfaSensors }
];

const NFANavbar = () => {
  const t = useTranslate('menu');
  const { organizationId, projectId } = useParams();

  return (
    <React.Fragment>
      {views.map(item => {
        return (
          <Menu.Item
            key={item.name}
            as={NavLink}
            to={item.route(organizationId, projectId)}
          >
            <Icon name={item.icon} />
            {t(item.name)}
          </Menu.Item>
        );
      })}
    </React.Fragment>
  );
};

export default NFANavbar;
