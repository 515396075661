import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import routes from 'routes';
import Table from 'components/table';
import { isDisabledExport } from '../utils';
import TableBody from './body';
import { getColumns } from './configs';

const PatientsTable = ({
  fetching,
  error,
  data,
  // ---
  isOpenBulkExportMode,
  bulkExportData: selectedData,
  setBulkExportData: onSelect,
  setExportData,
  setEditData,
  setDeleteData
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const columns = getColumns({
    isOpenBulkExportMode,
    setExportData,
    setEditData,
    setDeleteData
  });

  const onRowClick = patient => {
    const { organizationId, projectId } = params;
    const path = routes.patientDetails(organizationId, projectId, patient.id);
    navigate(path);
  };

  return (
    <Table
      fetching={fetching}
      error={error}
      data={data}
      selectableData={data.filter(item => !isDisabledExport(item))}
      columns={columns}
      CustomBody={TableBody}
      onRowClick={isOpenBulkExportMode ? undefined : onRowClick}
      selectable={isOpenBulkExportMode}
      selectedData={selectedData}
      onSelect={onSelect}
    />
  );
};

export default PatientsTable;
