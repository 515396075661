import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Icon, Popup } from 'semantic-ui-react';

const mapColors = {
  ok: 'green',
  offline: 'grey'
};

const Stats = ({ data }) => (
  <div>
    {Object.keys(data).map(key => (
      <div key={key}>
        <span style={{ fontWeight: 600 }}>
          {key}
          {': '}
        </span>
        <span>{data[key]}</span>
      </div>
    ))}
  </div>
);

const Status = ({ data }) => {
  const t = useTranslate('sens-admin-gateway');
  const { status, stats } = data;
  const color = mapColors[status];

  return (
    <span>
      <Popup
        trigger={<Icon name="circle" color={color} />}
        content={<Stats data={stats} />}
      />
      <span>{t(`gateway.status-${status}`)}</span>
    </span>
  );
};

Status.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string,
    stats: PropTypes.object
  }).isRequired
};

export default Status;
