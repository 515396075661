import React from 'react';
import {
  BackgroundState,
  Headers,
  LastOnline,
  LastReport,
  Name,
  NearbySensor,
  Platform,
  PowerState,
  Status
} from '../../elements';

const NameAndPlatform = ({ data }) => (
  <div>
    <div>
      <Name data={data} />
    </div>
    <div style={{ fontSize: 'smaller' }}>
      <Platform data={data} />
    </div>
  </div>
);

const NearbySensorAndLastReport = ({ data }) => (
  <div>
    <div>
      <NearbySensor data={data} />
    </div>
    <div style={{ fontSize: 'smaller' }}>
      <LastReport data={data} title />
    </div>
  </div>
);

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: NameAndPlatform,
    sortable: true
  },
  {
    name: 'last-online',
    header: Headers.lastOnline,
    cell: LastOnline
  },
  {
    name: 'nearby-sensor',
    header: Headers.nearbySensor,
    cell: NearbySensorAndLastReport
  },
  {
    name: 'power-state',
    header: Headers.powerState,
    cell: PowerState
  },
  {
    name: 'background-state',
    header: Headers.backgroundState,
    cell: BackgroundState
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status
  }
];
