import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button, Segment } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import Table from 'components/table';
import { getColumns } from './configs';

const SensorDashboardTableControlFragment = ({
  fetching,
  parentError,
  submitting,
  error,
  data,
  onExit,
  onToggle,
  onSubmit
}) => {
  const t = useTranslate('sensor');
  const columns = getColumns({ submitting, onToggle });

  return (
    <React.Fragment>
      <Table
        fetching={fetching}
        error={parentError}
        data={data}
        columns={columns}
      />
      <ErrorMessage error={error} />
      <Segment basic clearing style={{ padding: '0' }}>
        {data.find(item => item.changed) && (
          <Button
            content={t('common:submit')}
            color="blue"
            icon="checkmark"
            floated="right"
            loading={submitting}
            onClick={onSubmit}
          />
        )}
        <Button
          content={t('exit-control-mode')}
          icon="cancel"
          floated="right"
          onClick={onExit}
        />
      </Segment>
    </React.Fragment>
  );
};

SensorDashboardTableControlFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  parentError: PropTypes.node,
  // ---
  submitting: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onExit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // ---
  error: PropTypes.object
};

export default SensorDashboardTableControlFragment;
