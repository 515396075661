import { axiosClient } from 'api/axios-client';
import type { ProjectsResponse } from 'api/projects';
import { filterProjects } from './utils';

let promise: Promise<ProjectsResponse>;

export const fetchScopeProjects = async (scope_id: string) => {
  if (!promise) {
    promise = axiosClient
      .post('/api/1.0/projects2', {
        scope_id: scope_id
      })
      .then(response => response.data.value[0]);
  }

  const { projects } = await promise;
  return filterProjects(projects);
};
