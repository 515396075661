import { nProject } from 'api/projects';
import type { Collection } from 'app/types';
import type { Organization, OrganizationRaw } from './types';

export const nOrganization = (raw: OrganizationRaw) => ({
  id: raw.id,
  name: raw.name,
  projects: raw.projects.map((item, index) => ({
    ...nProject(item),
    idx: index + 1
  })),
  raw
});

export const nOrganizations = (
  raw: OrganizationRaw[]
): Collection<Organization> => {
  return raw.map((item, index) => ({
    ...nOrganization(item),
    idx: index + 1
  }));
};
