import React from 'react';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { SensorSelect } from 'fragments/sensor';
import { Form, Input } from 'semantic-ui-react';
import DatePicker from 'components/date-picker';
import ErrorMessage from 'components/error-message';
import PhoneInput from 'components/phone-input';
import { flatten } from './utils';

const ISpinePatientForm = ({ data, onSubmit: handleSubmit, onCancel }) => {
  const t = useTranslate('ispine-patient');

  const editMode = !!data;

  const { form, onChanges, onSubmit, submitting, error } = useForm({
    initialForm: flatten(data),
    apiSubmit: payload =>
      handleSubmit(payload, data).then(() => {
        if (!editMode) onCancel();
      })
  });

  return (
    <Form onSubmit={onSubmit} error={error}>
      <Form.Field required>
        <label>{t('patient')}</label>
        <Input
          value={form.patient}
          onChange={(event, { value }) => onChanges.patient(value)}
          placeholder={t('patient')}
          required
        />
      </Form.Field>
      <Form.Group widths="equal">
        <Form.Field required>
          <label>{t('sensor')}</label>
          <SensorSelect
            value={form.sensor}
            onChange={(event, { value }) => onChanges.sensor(value)}
            placeholder={t('sensor')}
            disabled={editMode}
            renderStatus
            required
          />
        </Form.Field>
        <Form.Field>
          <label>{t('phone')}</label>
          <PhoneInput
            value={form.phone}
            onChange={(event, { value }) => onChanges.phone(value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field required>
        <label>{t('monitoring')}</label>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('start-time')}</label>
            <DatePicker
              value={form.monitoringStartTime}
              onChange={(event, { value }) =>
                onChanges.monitoringStartTime(value)
              }
            />
          </Form.Field>
          <Form.Field>
            <label>{t('end-time')}</label>
            <DatePicker
              value={form.monitoringEndTime}
              onChange={(event, { value }) =>
                onChanges.monitoringEndTime(value)
              }
            />
          </Form.Field>
        </Form.Group>
      </Form.Field>
      <Form.Field required>
        <label>{t('feedback')}</label>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('start-time')}</label>
            <DatePicker
              value={form.feedbackStartTime}
              onChange={(event, { value }) =>
                onChanges.feedbackStartTime(value)
              }
            />
          </Form.Field>
          <Form.Field>
            <label>{t('end-time')}</label>
            <DatePicker
              value={form.feedbackEndTime}
              onChange={(event, { value }) => onChanges.feedbackEndTime(value)}
            />
          </Form.Field>
        </Form.Group>
      </Form.Field>
      <Form.Group style={{ marginTop: '2rem' }}>
        <Form.Button
          type="button"
          onClick={onCancel}
          content={t('common:cancel')}
          style={{ margin: 0 }}
        />
        <Form.Button
          type="submit"
          color="blue"
          loading={submitting}
          content={t('common:submit')}
        />
      </Form.Group>
      <ErrorMessage error={error} />
    </Form>
  );
};

export default ISpinePatientForm;
