import { nSensor } from 'api/sensors';
import type { Sensor, SensorRaw } from 'api/sensors';
import type { NFASubject, NFASubjectRaw } from './types';

export const nNfaSubject = (
  subject: NFASubjectRaw,
  sensors: SensorRaw[]
): NFASubject => {
  return {
    id: subject.id,
    name: subject.first_name,
    phoneNumber: subject.phone_number.endsWith('@')
      ? subject.phone_number.slice(0, -1)
      : subject.phone_number,
    phoneLocked: subject.phone_number.endsWith('@'),
    timezone: subject.timezone,
    sensors: subject.attached_sensors
      ? subject.attached_sensors.map((sensorId, index) => {
          const sensor = sensors.find(s => s.id === sensorId);
          if (sensor) {
            return nSensor({
              ...sensor,
              place: subject.attached_places && subject.attached_places[index]
            });
          } else
            return {
              id: sensorId
            } as Sensor;
        })
      : [],
    startTime: subject.start_time || undefined,
    endTime: subject.end_time || undefined,
    expectedStartTime: subject.meta?.expected_start_time || undefined,
    status: subject.status,
    diaryUpdated: subject.extras?.diary_updated,
    note: subject.meta?.note,
    raw: subject
  };
};
