import type { ProjectRaw } from 'api/projects';
import { getVendor } from 'utils/vendor';
import type { ScopeRaw } from './types';

const vendor = getVendor();

export const getAllowedScopes = () => {
  switch (vendor) {
    case 'demos':
      return ['scope/demos_org'];
    case 'icomp':
      return ['scope/icomp_org', 'scope/icomp_dept'];
    default:
      return ['scope/org'];
  }
};

export const filterScopes = (scopes: ScopeRaw[]) => {
  const allowedScopes = getAllowedScopes();
  return scopes.filter(s => allowedScopes.includes(s.scope_class));
};

export const filterProjects = (projects: ProjectRaw[]) => {
  return vendor === 'nfa'
    ? projects.filter(item => item.project_class === 'project/nfa')
    : projects;
};
