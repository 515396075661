import React from 'react';
import PropTypes from 'prop-types';
import { VictoryArea } from 'victory';

const Area = ({ data, fill }) => (
  <VictoryArea data={data} style={{ data: { fill } }} />
);

Area.propTypes = {
  data: PropTypes.array.isRequired,
  fill: PropTypes.string.isRequired
};

export default Area;
