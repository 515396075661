import { createSorter } from 'utils/sort';
import {
  getBandageValue,
  getCircumsphereValue,
  getDiagnoseValue,
  getSensorValue,
  getSideValue,
  getTrackIdValue,
  getTreatmentEndTimeValue,
  getTreatmentIdValue,
  getTreatmentStartTimeValue
} from './cells';

export const sorters = {
  trackId: createSorter(getTrackIdValue),
  treatmentId: createSorter(getTreatmentIdValue),
  treatmentStartTime: createSorter(getTreatmentStartTimeValue),
  treatmentEndTime: createSorter(getTreatmentEndTimeValue),
  sensor: createSorter(getSensorValue),
  bandage: createSorter(getBandageValue),
  diagnose: createSorter(getDiagnoseValue),
  circumsphere: createSorter(getCircumsphereValue),
  side: createSorter(getSideValue),
  note: createSorter('note')
};
