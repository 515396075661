import React from 'react';
import { useTranslate } from 'intl';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useExportController } from '../../context';

const DownloadFile = ({ data }) => {
  const t = useTranslate('export-controller');
  const { setDownloadedUuids } = useExportController();

  const onDownload = entry => {
    window.open(
      entry.url.startsWith('http')
        ? `${entry.url}`
        : `${process.env.REACT_APP_API_HOST}${entry.url}`
    );
    setDownloadedUuids(uuids => [...uuids, entry.uuid]);
  };

  return (
    <Popup
      trigger={
        <Button
          primary
          icon
          fluid
          style={{ margin: 0 }}
          onClick={() => onDownload(data)}
        >
          <Icon name="cloud download" />
          &nbsp;&nbsp;&nbsp;
          {t('download-file/trigger')}
        </Button>
      }
      content={t('download-file/tooltip')}
    />
  );
};

export default DownloadFile;
