import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'intl';
import { useExportController } from 'fragments/export-controller';

async function runSequentialTasks(tasks) {
  const results = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const task of tasks) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await task();
      results.push(result);
    } catch (error) {
      results.push(null);
    }
  }

  return results;
}

export const useBulkExport = ({
  exportAction, // 'modal' | 'url'
  apiExport,
  patients,
  onCompleted
}) => {
  const { language } = useIntl();
  const { organizationId, projectId } = useParams();
  const [exporting, setExporting] = useState(false);
  const [error, setError] = useState(null);
  const { addExportEntry, onOpenExportModal } = useExportController();

  const onExport = event => {
    event.stopPropagation();
    setExporting(true);

    // exportAction === 'modal'
    if (exportAction === 'modal') {
      const arrayRequests = patients.map(patient => {
        const params = {
          lang: language,
          organizationId,
          projectId,
          patientId: patient.id
        };
        return () => apiExport(params, exportAction);
      });

      runSequentialTasks(arrayRequests)
        .then(results => {
          results.forEach(entry => {
            addExportEntry(entry);
          });
          setExporting(false);
          onCompleted();
          onOpenExportModal();
        })
        .catch(error => {
          setError(error);
          setExporting(false);
          return Promise.reject(error);
        });
    }
    // exportAction === 'url'
    else {
      const arrayRequests = patients.map(patient => {
        const params = {
          lang: language,
          organizationId,
          projectId,
          patientId: patient.id
        };
        return () => apiExport(params, exportAction);
      });

      runSequentialTasks(arrayRequests)
        .then(results => {
          results.forEach(url => {
            window.open(url);
          });
          setExporting(false);
          onCompleted();
        })
        .catch(error => {
          setError(error);
          setExporting(false);
          return Promise.reject(error);
        });
    }
  };

  return [exporting, onExport, error];
};
