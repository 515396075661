import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const Background = ({ data }) => {
  const t = useTranslate('sens-admin-gateway');

  return (
    <span>
      {data.background
        ? t('background-background')
        : t('background-foreground')}
    </span>
  );
};

Background.propTypes = {
  data: PropTypes.shape({
    background: PropTypes.string
  }).isRequired
};

export default Background;
