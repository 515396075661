import { useCallback, useState } from 'react';

const regex = /^(\d{0,6})$|(^\d{6}-\d{0,4}$)/m;

function useFilter() {
  const [cpr, setCpr] = useState('');
  const [name, setName] = useState('');

  const onChangeCpr = useCallback((event, { value }) => {
    if (!regex.test(value)) {
      return;
    }

    setCpr(cpr => {
      return value.length === 6 && cpr.indexOf('-') === -1
        ? `${value}-`
        : value;
    });
  }, []);

  const onChangeName = useCallback((event, { value }) => {
    setName(value);
  }, []);

  const filter = {
    cpr,
    name
  };
  const onFilter = {
    cpr: onChangeCpr,
    name: onChangeName
  };

  return [filter, onFilter];
}

export default useFilter;
