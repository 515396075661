import moment from 'moment';
import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { SensorExportLang, SensorPlotStream } from 'api/sensors';
import type { RouteParams } from 'app/types';

export const exportPdfPlot = async ({
  organizationId,
  projectId,
  measurementId,
  lang,
  startDate,
  endDate,
  stream
}: Pick<RouteParams, 'organizationId' | 'projectId' | 'measurementId'> & {
  lang: SensorExportLang;
  startDate: string;
  endDate: string;
  stream: SensorPlotStream;
}) => {
  const downloadToken = await fetchDownloadToken();
  /* eslint-disable-next-line unused-imports/no-unused-vars */
  const [streamType, streamValue] = stream.split(':');
  const params = {
    scope_id: organizationId,
    project_id: projectId,
    measurement_id: measurementId,
    lang,
    start_date: moment(startDate).format('YYYY-MM-DD'),
    end_date: moment(endDate).format('YYYY-MM-DD'),
    alg_profile: streamValue,
    tz: 'Europe/Copenhagen',
    dt: downloadToken
  };

  const exportUrl = axiosClient.getUri({
    url: '/export/measurement/report',
    params
  });

  return exportUrl;
};
