import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import options from './options';

const TimezoneSelect = ({ value, onChange }) => (
  <Dropdown
    fluid
    search
    selection
    options={options}
    value={value}
    onChange={onChange}
  />
);

TimezoneSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TimezoneSelect;
