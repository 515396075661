import moment from 'moment';
import groupBy from 'lodash.groupby';

const DATE_FORMAT = 'YYYY-MM-DD';

export const normalize = (values, unit) => {
  const { streams, flags } = values;
  const { ts, v } = streams;

  const dataDay = [];
  const dataNight = [];
  const dataAll = [];

  flags.forEach((isDay, i) => {
    const element = {
      x: ts[i],
      y: unit === 'pF' ? v[i] : v[i] * 0.8,
      f: moment.unix(ts[i] / 1000).format('YYYY-MM-DD HH:mm')
    };

    if (isDay) {
      dataDay.push(element);
    } else {
      dataNight.push(element);
    }

    dataAll.push(element);
  });

  return {
    dataDay,
    dataNight,
    dataAll
  };
};

const extract = collection => {
  const solid = [];
  const dashed = [];

  collection.forEach((values, index) => {
    const startValue = values[0];
    const endValue = values[values.length - 1];

    solid.push({
      x: startValue.x,
      y: startValue.y
    });
    solid.push({
      x: endValue.x,
      y: endValue.y
    });
    solid.push({
      x: endValue.x,
      y: null
    });

    const next = collection[index + 1];
    if (next) {
      dashed.push({
        x: endValue.x,
        y: endValue.y
      });
      dashed.push({
        x: next[0].x,
        y: next[0].y
      });
      dashed.push({
        x: next[0].x,
        y: null
      });
    }
  });

  return { solid, dashed };
};

export const transform = ({ dataDay, dataNight }) => {
  const groupDay = groupBy(dataDay, item =>
    moment.unix(item.x / 1000).format(DATE_FORMAT)
  );

  const groupNight = groupBy(dataNight, item =>
    moment.unix(item.x / 1000).format(DATE_FORMAT)
  );

  const groupDayValues = [];
  Object.entries(groupDay).forEach(([key, values]) => {
    const dayValues = values.slice();
    const nextNight = moment(key).add(1, 'day').format(DATE_FORMAT);
    const nextNightValue = groupNight[nextNight] && groupNight[nextNight][0];
    if (nextNightValue) {
      dayValues.push({
        x: nextNightValue.x,
        y: dayValues[0].y,
        f: nextNightValue.f
      });
    }
    groupDayValues.push(dayValues);
  });

  const groupNightValues = [];
  Object.entries(groupNight).forEach(([key, values]) => {
    const nightValues = values.slice();
    const sameDay = key;
    const sameDayValue = groupDay[sameDay] && groupDay[sameDay][0];
    if (sameDayValue) {
      nightValues.push({
        x: sameDayValue.x,
        y: nightValues[0].y,
        f: sameDayValue.f
      });
    }
    groupNightValues.push(nightValues);
  });

  const { solid: daySolid, dashed: dayDashed } = extract(groupDayValues);
  const { solid: nightSolid, dashed: nightDashed } = extract(groupNightValues);

  return { daySolid, dayDashed, nightSolid, nightDashed };
};
