import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { RouteParams } from 'app/types';
import type {
  MeasurementExportLang,
  MeasurementExportSummarize,
  MeasurementPlotStream
} from '../types';

const createExportCsvPlot =
  (dataFormat: MeasurementExportSummarize) =>
  async ({
    organizationId,
    projectId,
    measurementId,
    lang,
    startDate,
    endDate,
    stream
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'measurementId'> & {
    lang: MeasurementExportLang;
    startDate: string;
    endDate: string;
    stream: MeasurementPlotStream;
  }) => {
    const downloadToken = await fetchDownloadToken();

    const [streamType, streamValue] = stream.split(':');

    const params = {
      scope_id: organizationId,
      project_id: projectId,
      measurement_id: measurementId,
      lang,
      start_time: startDate,
      end_time: endDate,
      ...(streamType === 'derived' ? { alg_profile: streamValue } : {}),
      ...(streamType === 'raw' ? { stream_name: streamValue } : {}),
      data_format: dataFormat,
      dt: downloadToken,
      show: 0
    };

    const exportUrl = axiosClient.getUri({
      url: `/export/measurement`,
      params
    });

    return exportUrl;
  };

export const exportCsvPlotDay = createExportCsvPlot('summary1h');

export const exportCsvPlotHour = createExportCsvPlot('detailed');
