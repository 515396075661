import React from 'react';
import Body from 'containers/admin/gateway/dashboard';
import Layout from 'layouts/sens-admin';

const PageAdminGateways = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageAdminGateways;
