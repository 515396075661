import React from 'react';
import PropTypes from 'prop-types';
import { Header, Radio } from 'semantic-ui-react';
import styles from './styles.module.css';

const ICompRadioSelect = ({ label, name, value, onChange, options }) => {
  return (
    <div className={styles.main}>
      <div className={styles.label}>
        <Header as="h4">{label}: </Header>
      </div>
      <div className={styles.control}>
        {options.map(o => (
          <div key={o.value} className={styles.radio}>
            <Radio
              name={name}
              label={o.label}
              value={o.value}
              checked={value === o.value}
              onChange={(event, { value: v }) => onChange(v)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ICompRadioSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default ICompRadioSelect;
