import { useCallback, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

const useToggleOpen = (
  initialState: boolean | (() => boolean)
): [
  boolean,
  () => void,
  () => void,
  () => void,
  Dispatch<SetStateAction<boolean>>
] => {
  const [open, onOpenChange] = useState(initialState);
  const onOpen = useCallback(() => onOpenChange(true), []);
  const onClose = useCallback(() => onOpenChange(false), []);
  const onToggle = useCallback(() => onOpenChange(v => !v), []);
  return [open, onOpen, onClose, onToggle, onOpenChange];
};

export default useToggleOpen;
