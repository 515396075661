import React from 'react';
import { useTranslate } from 'intl';
import { Loader, Table } from 'semantic-ui-react';
import { RawErrorMessage } from 'components/error-message';

const PreBody = ({ fetching, error, empty, colSpan, render }) => {
  const t = useTranslate('common');

  if (fetching) {
    return (
      <Table.Row>
        <Table.Cell
          colSpan={colSpan}
          textAlign="center"
          style={{ padding: '1rem' }}
        >
          <Loader active inline size="small" />
        </Table.Cell>
      </Table.Row>
    );
  }
  if (error) {
    return (
      <Table.Row error>
        <Table.Cell
          colSpan={colSpan}
          textAlign="center"
          style={{ padding: '1rem' }}
        >
          <RawErrorMessage error={error} />
        </Table.Cell>
      </Table.Row>
    );
  }
  if (empty) {
    return (
      <Table.Row>
        <Table.Cell
          colSpan={colSpan}
          disabled
          textAlign="center"
          style={{ padding: '1rem' }}
        >
          {t('common:no-entries')}
        </Table.Cell>
      </Table.Row>
    );
  }

  return render();
};

export default PreBody;
