import React from 'react';
import Body from 'fragments/icomp-patient/new-patient-modal';
import Layout from 'layouts/icomp';

const PageICompPatientAdd = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageICompPatientAdd;
