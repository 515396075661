import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Form, Header, Input, Segment } from 'semantic-ui-react';
import { changeUserName } from 'api/user';
import ErrorMessage from 'components/error-message';

const ChangeName = ({
  defaultFirstName,
  defaultLastName,
  onSuccess,
  onCancel
}) => {
  const t = useTranslate('settings');

  const { form, onChanges, onSubmit, submitting, error, onClearError } =
    useForm({
      initialForm: {
        firstName: defaultFirstName,
        lastName: defaultLastName
      },
      apiSubmit: form => changeUserName(form).then(() => onSuccess(form))
    });

  return (
    <Segment>
      <Header>{t('change-name')}</Header>
      <Form onSubmit={onSubmit} error={!!error}>
        <Form.Field>
          <label>{t('first-name')}</label>
          <Input
            value={form.firstName}
            onChange={(event, { value }) => onChanges.firstName(value)}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('last-name')}</label>
          <Input
            value={form.lastName}
            onChange={(event, { value }) => onChanges.lastName(value)}
          />
        </Form.Field>
        <ErrorMessage error={error} onDismiss={onClearError} />
        <div>
          <Button type="button" disabled={submitting} onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" color="blue" loading={submitting}>
            {t('change')}
          </Button>
        </div>
      </Form>
    </Segment>
  );
};

ChangeName.propTypes = {
  defaultFirstName: PropTypes.string.isRequired,
  defaultLastName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ChangeName;
