import React from 'react';
import { useFormatDate, useTranslate } from 'intl';
import { Icon, Popup, Loader, Table } from 'semantic-ui-react';
import Dash from 'components/dash';
import TimeAgo from 'components/time-ago';
import { useExportController } from '../../context';
import Actions from './actions';
import styles from './styles.module.scss';

/* eslint-disable no-nested-ternary */
const ExportControllerTable = ({ fetching, error, data }) => {
  const t = useTranslate('export-controller');
  const formatDate = useFormatDate();
  const { downloadedUuids } = useExportController();

  return (
    <Table basic="very" padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('file-name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('generation-date')}</Table.HeaderCell>
          <Table.HeaderCell>{t('due-date')}</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            {t('progress-actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {fetching ? (
          <Table.Row>
            <Table.Cell
              colSpan={4}
              textAlign="center"
              style={{ padding: '1rem' }}
            >
              <Loader active inline size="small" />
            </Table.Cell>
          </Table.Row>
        ) : error ? (
          <Table.Row>
            <Table.Cell
              colSpan={4}
              textAlign="center"
              style={{ padding: '3rem' }}
            >
              <p className={styles['error-message']}>{t('error-message')}</p>
              <p className={styles['error-description']}>
                {t('error-description')}
              </p>
              <img
                className={styles['error-image']}
                src="/assets/error-image.svg"
              />
            </Table.Cell>
          </Table.Row>
        ) : (
          data.map(entry => (
            <Table.Row key={entry.uuid}>
              <Table.Cell width={5}>
                {downloadedUuids.includes(entry.uuid) ? (
                  <div className={styles['file-name']}>
                    <Popup
                      trigger={
                        <div className={styles.flag}>
                          <Icon name="check circle" />
                        </div>
                      }
                      content={t('downloaded-file/downloaded')}
                    />

                    {entry.filename}
                  </div>
                ) : (
                  entry.filename
                )}
              </Table.Cell>
              <Table.Cell width={3}>
                {entry.queue_time ? (
                  <TimeAgo value={entry.queue_time} ago />
                ) : (
                  <Dash />
                )}
              </Table.Cell>
              <Table.Cell width={3}>{formatDate(entry.expire_time)}</Table.Cell>
              <Table.Cell width={5}>
                <Actions data={entry} />
              </Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default ExportControllerTable;
