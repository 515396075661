import React from 'react';
import moment from 'moment';
import { FormatDate } from 'intl';
import Dash from 'components/dash';

export const StartTime = ({ data: subject }) => {
  switch (subject.status) {
    case 'status/started': {
      if (!subject.startTime) return <Dash />;
      return (
        <span className="color-green">
          <FormatDate value={subject.startTime} format="dd-mm-yyyy" />
          <br />
          <small>
            <FormatDate value={subject.startTime} format="hh:mm" />
          </small>
          {/* TODO: What is ACTIVATED SENSOR start time stamp: (dd/mm/yearhour:min:sec(24h)) */}
        </span>
      );
    }

    case 'status/not_started': {
      if (!subject.expectedStartTime) return <Dash />;
      const diffDays = moment().diff(subject.expectedStartTime, 'days');
      let color;
      if (diffDays < 2) {
        color = 'black';
      } else if (diffDays < 5) {
        color = 'yellow';
      } else {
        color = 'red';
      }
      return (
        <span className={`color-${color}`}>
          <FormatDate value={subject.expectedStartTime} format="dd-mm-yyyy" />
          <br />
          <small>
            <FormatDate value={subject.startTime} format="hh:mm" />
          </small>
        </span>
      );
    }

    case 'status/completed':
    default:
      return <Dash />;
  }
};
