import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, useToggle } from 'hooks';
import { fetchPatients } from 'api/patients';
import ExportSettings from './export-settings';
import Header from './header';
import Table from './table';
import utils from './utils';

const BrowseData = () => {
  const params = useParams();

  const { organizationId, projectId } = params;
  const {
    fetching,
    data: activePatients = [],
    error
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId }),
    isJsonSource: true,
    apiFetch: fetchPatients,
    refresh: 20000,
    silentRefresh: true
  });

  const [sort, onChangeSort] = useState('create-date');
  const [descOrder, toggleDescOrder] = useToggle(false);
  const [search, onChangeSearch] = useState('');
  const [filter, onChangeFilter] = useState([]);

  const [openDataExport, setOpenDataExport] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  return (
    <>
      <Header
        sort={sort}
        onChangeSort={onChangeSort}
        descOrder={descOrder}
        toggleDescOrder={toggleDescOrder}
        search={search}
        onChangeSearch={onChangeSearch}
        filter={filter}
        onChangeFilter={onChangeFilter}
        openDataExport={openDataExport}
        setOpenDataExport={setOpenDataExport}
      />

      {openDataExport && <ExportSettings selectedData={selectedData} />}

      <Table
        fetching={fetching}
        error={error}
        data={utils.filterData({
          data: activePatients,
          sort,
          descOrder,
          search,
          filter
        })}
        selectable={openDataExport}
        selectedData={selectedData}
        onSelect={setSelectedData}
      />
    </>
  );
};

export default BrowseData;
