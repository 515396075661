import { fetchAlgorithms } from 'api/algorithms';
import type { RouteParams } from 'app/types';
import type { SimpleSensorPlotStream } from '../types';

export const fetchStreamOptions = async ({
  organizationId,
  projectId
}: Pick<RouteParams, 'organizationId' | 'projectId'>) => {
  const algorithms = await fetchAlgorithms({ organizationId, projectId });
  const derivedOptions = algorithms.map(
    item => `derived:${item.name}` as SimpleSensorPlotStream
  );
  return derivedOptions;
};
