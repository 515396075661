import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Button, Dropdown, Grid, Header, Input } from 'semantic-ui-react';

const ICompDepartmentDashboard = ({
  hospitals,
  hospital,
  setHospital,
  search,
  onSearch
}) => {
  const t = useTranslate('icomp-department');

  const options = hospitals
    ? hospitals.map(h => ({ key: h.id, text: h.name, value: h.id }))
    : [];

  const onSelectHospital = useCallback(
    (event, { value }) => {
      setHospital(hospitals.find(h => h.id === value));
    },
    [hospitals, setHospital]
  );

  return (
    <div>
      <Header as="h2" textAlign="center" data-cy="departments__header">
        {t('select-department')}
      </Header>
      <Grid style={{ marginTop: '2rem' }}>
        <Grid.Row verticalAlign="bottom">
          <Grid.Column width={6}>
            <Header as="h4">{t('select-hospital')}</Header>
            <div>
              <Dropdown
                data-cy="departments__hospital-dropdown"
                placeholder={t('select-hospital')}
                selection
                options={options}
                value={hospital.id}
                onChange={onSelectHospital}
              />
              {hospital.roles && hospital.roles.includes('role/admin') && (
                <Button
                  primary
                  style={{ marginLeft: '1rem' }}
                  as={Link}
                  to={routes.iCompAdmin()}
                >
                  {t('admin')}
                </Button>
              )}
            </div>
          </Grid.Column>
          <Grid.Column width={10} textAlign="right">
            <Input
              value={search}
              onChange={onSearch}
              placeholder={t('common:search-by-name')}
              style={{ verticalAlign: 'top' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

ICompDepartmentDashboard.propTypes = {
  hospitals: PropTypes.array,
  hospital: PropTypes.object,
  setHospital: PropTypes.func,
  // ---
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default ICompDepartmentDashboard;
