import React from 'react';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import StaticBox from '../static-box';

const DataFile = ({ onSetupDataFile }) => {
  const t = useTranslate('browse-data');

  return (
    <StaticBox
      header={t('data-file.header')}
      listTitle={t('data-file.list-title')}
      listItems={[
        t('data-file.activity-data'),
        t('data-file.accelerometer-data')
      ]}
      button={
        <Button primary onClick={onSetupDataFile}>
          {t('data-file.setup-report')}
        </Button>
      }
    />
  );
};

export default DataFile;
