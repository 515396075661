import React from 'react';
import Actions from './actions';
import Export from './export';
import LatestEvent from './latest-event';
import Period from './period';
import Sensor from './sensor';

const Patient = ({ data }) => <span>{data.patient.name}</span>;
const Phone = ({ data }) => <span>{data.phone}</span>;
const Monitoring = ({ data }) => <Period data={data.monitoring} />;
const Feedback = ({ data }) => <Period data={data.feedback} />;

// ---

export {
  Patient,
  Sensor,
  Phone,
  Monitoring,
  Feedback,
  LatestEvent,
  Export,
  Actions
};
