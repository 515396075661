import { axiosClient } from 'api/axios-client';
import { nGateway } from './normalize';

export const fetchGateway = async (gatewayId: string) => {
  const params = { gateway_id: gatewayId };

  const { gateway } = await axiosClient
    .get('/gwapi/1.0/adminx/gateway/details', { params })
    .then(response => response.data.value);

  return {
    ...nGateway(gateway),
    localId: gateway.local_id,
    parameters: gateway.parameters
  };
};
