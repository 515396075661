import Cookies from 'js-cookie';
import { axiosClient } from 'api/axios-client';

export const setAuthToken = (authToken: string) => {
  Cookies.set('Auth-Token', authToken);
  (axiosClient.defaults.headers as any)['Auth-Token'] = authToken;
};

export const getAuthToken = () => {
  return Cookies.get('Auth-Token');
};

export const clearAuthToken = () => {
  Cookies.remove('Auth-Token');
  (axiosClient.defaults.headers as any)['Auth-Token'] = null;
};

export const setSessionKey = (email: string, sessionKey: string) => {
  window.localStorage.setItem(`SESSION_KEY_${email}`, sessionKey);
};

export const getSessionKey = (email: string) => {
  return window.localStorage.getItem(`SESSION_KEY_${email}`);
};
