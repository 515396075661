import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  name: <Translate ns="icomp-department" i18nKey="name" />,
  description: <Translate ns="icomp-department" i18nKey="description" />,
  roles: <Translate ns="icomp-department" i18nKey="roles" />,
  numActivePatients: (
    <Translate ns="icomp-department" i18nKey="num-of-active-patients" />
  )
};
