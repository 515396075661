import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Form, Header, Modal } from 'semantic-ui-react';
import DatePicker from 'components/date-picker';
import ErrorMessage from 'components/error-message';

const MeasurementPlotsExportModal = ({
  open,
  // ---
  exportType,
  inputType,
  // ---
  limit,
  // ---
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  // ---
  onExport,
  onCancel,
  exporting,
  error
}) => {
  const t = useTranslate('measurement');

  const limitValue = t(`limit-${limit.unit}`, { limit: limit.value });

  return (
    <Modal open={open} size="small">
      <Modal.Header>{t(`export-${exportType}`)}</Modal.Header>
      <Modal.Content>
        <Header as="h4" style={{ marginBottom: '0.3em' }}>
          {t('select-period')}
        </Header>
        <p>{t('limit', { limit: limitValue })}</p>

        <Form onSubmit={onExport} error={error}>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t('from')}</label>
              <DatePicker
                type={inputType}
                value={startDate}
                onChange={onChangeStartDate}
              />
            </Form.Field>
            <Form.Field required>
              <label>{t('to')}</label>
              <DatePicker
                type={inputType}
                value={endDate}
                onChange={onChangeEndDate}
              />
            </Form.Field>
          </Form.Group>

          {error && (
            <div style={{ marginTop: '2rem' }}>
              <ErrorMessage error={error} />
            </div>
          )}

          <Form.Group style={{ marginTop: '2rem' }}>
            <Form.Button
              type="button"
              onClick={onCancel}
              content={t('common:cancel')}
              style={{ margin: 0 }}
            />
            <Form.Button
              type="submit"
              color="blue"
              loading={exporting}
              content={t('export')}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

MeasurementPlotsExportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  // ---
  exportType: PropTypes.oneOf(['PDF', 'CSV', 'Raw']).isRequired,
  inputType: PropTypes.oneOf(['date', 'date-time']).isRequired,
  // ---
  limit: PropTypes.shape({
    value: PropTypes.number.isRequired,
    unit: PropTypes.oneOf(['day', 'hour']).isRequired
  }).isRequired,
  // ---
  startDate: PropTypes.string.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  // ---
  endDate: PropTypes.string.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  // ---
  onExport: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  exporting: PropTypes.bool,
  error: PropTypes.object
};

export default MeasurementPlotsExportModal;
