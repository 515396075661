import React, { Component } from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import { addPatient } from 'api/icomp-patients';
import Fragment from './fragment';

class ICompPatientDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
    this.onAdd = this.onAdd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }
  }

  onAdd(form) {
    const { params, navigate } = this.props;
    const { hospitalId, departmentId, projectId } = params;
    return addPatient({ params, form }).then(response => {
      // const data = this.state.data.concat(response);
      // this.setState({ data });
      const { id: patientId } = response;
      const patientRoute = routes.iCompPatientPlots(
        hospitalId,
        departmentId,
        projectId,
        patientId
      );
      navigate(patientRoute);
    });
  }

  render() {
    return <Fragment {...this.props} {...this.state} onAdd={this.onAdd} />;
  }
}

ICompPatientDashboardContainer.propTypes = {
  params: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default ICompPatientDashboardContainer;
