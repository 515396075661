import React from 'react';
import { useTranslate } from 'intl';
import { Icon } from 'semantic-ui-react';

const mapStatusColor = {
  'status/active': 'green',
  'status/discharged': 'grey'
};

export const PatientStatus = ({ data }) => {
  const t = useTranslate('hospital-patient');

  const { status } = data.patient;
  return (
    <span>
      <Icon name="circle" color={mapStatusColor[status]} />
      {` `}
      {t(status)}
    </span>
  );
};
