import React from 'react';

const Headers = {
  column1: 'Column 1',
  column2: 'Column 2',
  column3: 'Column 3'
};

const Column1 = ({ data }) => <span>{data.column1}</span>;
const Column2 = ({ data }) => <span>{data.column2}</span>;
const Column3 = ({ data }) => <span>{data.column3}</span>;

export const columns = [
  {
    name: 'column1',
    header: Headers.column1,
    cell: Column1,
    sortable: true
  },
  {
    name: 'column2',
    header: Headers.column2,
    cell: Column2,
    sortable: true
  },
  {
    name: 'column3',
    header: Headers.column3,
    cell: Column3,
    sortable: true
  }
];
