import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { SENSOR_COLORS } from 'consts/sensor';
import { Icon } from 'semantic-ui-react';

const Status = ({ data: { status } }) => {
  const t = useTranslate('sensor');

  if (!status) return <span>-</span>;
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <Icon name="circle" style={{ color: SENSOR_COLORS[status] }} />
      {t(status)}
    </span>
  );
};

Status.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string.isRequired
  }).isRequired
};

export default Status;
