import { nPatient } from 'api/patients';
import { nSensor } from 'api/sensors';
import type { Measurement, MeasurementRaw, MeasurementStatus2 } from './types';

export const nMeasurementState = (data: Pick<MeasurementRaw, 'state'>) => {
  if (data.state === 0) {
    return 'not-started';
  }
  if (data.state === 1 || data.state === 2) {
    return 'active';
  }
  // 3 or 4
  return 'completed';
};

const nMeasurementStatus = (
  data: Pick<MeasurementRaw, 'start_time' | 'end_time' | 'pending_data'>
): MeasurementStatus2 => {
  if (!data.start_time) {
    return 'configured';
  }
  if (!data.end_time) {
    return Number(data.pending_data) < 7 ? 'continous-short' : 'continous-long';
  }
  const endTime = new Date(data.end_time).getTime();
  const now = Date.now();

  if (endTime > now) {
    return Number(data.pending_data) < 7 ? 'running-short' : 'running-long';
  }
  return 'completed';
};

export const nMeasurement = (raw: MeasurementRaw): Measurement => ({
  id: raw.id,
  method: raw.profile,
  patient: nPatient(raw.patient),
  startTime: raw.start_time,
  endTime: raw.end_time,
  pendingData: Number(raw.pending_data),
  sensors: raw.places.map((item, index) => ({
    ...nSensor(item.sensor),
    place: item.place,
    idx: index + 1
  })),
  status: nMeasurementStatus(raw),
  raw
});
