import { useCallback, useReducer } from 'react';
import {
  getInitialDates,
  getInitialMeasurement,
  getInitialTrack
} from './utils';

function initPlotsState({ location, tracks }) {
  const track = getInitialTrack({
    location,
    tracks
  });
  const measurement = getInitialMeasurement({
    track,
    tracks
  });
  const { startDate, endDate } = getInitialDates({
    track,
    measurement,
    tracks
  });
  return {
    track,
    measurement,
    startDate,
    endDate,
    dateRange: {
      min: startDate,
      max: endDate
    },
    tracks
  };
}

function plotsReducer(state, action) {
  switch (action.type) {
    case 'change_track': {
      const track = action.payload;
      const { tracks } = state;
      const trackItem = tracks.find(item => item.id === track);
      const { measurements } = trackItem;
      const measurement = measurements.length
        ? measurements[measurements.length - 1].id
        : 'all';
      const { startDate, endDate } = getInitialDates({
        track,
        measurement,
        tracks
      });
      return {
        ...state,
        track,
        measurement,
        startDate,
        endDate,
        dateRange: {
          min: startDate,
          max: endDate
        }
      };
    }
    case 'change_measurement': {
      const measurement = action.payload;
      const { track, tracks } = state;
      const { startDate, endDate } = getInitialDates({
        track,
        measurement,
        tracks
      });
      return {
        ...state,
        track,
        measurement,
        startDate,
        endDate,
        dateRange: {
          min: startDate,
          max: endDate
        }
      };
    }
    case 'change_start_date':
      return { ...state, startDate: action.payload };
    case 'change_end_date':
      return { ...state, endDate: action.payload };
    default:
      throw new Error();
  }
}

const initialStreams = {
  capacitance: true,
  capacitance6h: false,
  capacitance6hadjust: false,
  // capacitance24h: false,
  // capacitanceDayNight: false,
  temperature: false
};

function streamsReducer(state, action) {
  switch (action.type) {
    case 'toggle': {
      const stream = action.payload;
      return {
        ...state,
        [stream]: !state[stream]
      };
    }
    default:
      throw new Error();
  }
}

function usePlotState({ location, tracks }) {
  const [
    { track, measurement, startDate, endDate, dateRange },
    dispatchPlotState
  ] = useReducer(plotsReducer, { location, tracks }, initPlotsState);
  const setTrack = useCallback(value => {
    dispatchPlotState({ type: 'change_track', payload: value });
  }, []);
  const setMeasurement = useCallback(value => {
    dispatchPlotState({ type: 'change_measurement', payload: value });
  }, []);
  const setStartDate = useCallback(value => {
    dispatchPlotState({ type: 'change_start_date', payload: value });
  }, []);
  const setEndDate = useCallback(value => {
    dispatchPlotState({ type: 'change_end_date', payload: value });
  }, []);

  const [streams, dispatchStreams] = useReducer(streamsReducer, initialStreams);
  const toggleStream = useCallback(stream => {
    dispatchStreams({ type: 'toggle', payload: stream });
  }, []);

  return {
    track,
    setTrack,
    // ---
    measurement,
    setMeasurement,
    // ---
    startDate,
    setStartDate,
    // ---
    endDate,
    setEndDate,
    // ---
    dateRange,
    // ---
    streams,
    toggleStream
  };
}

export default usePlotState;
