import React from 'react';
import { useTranslate } from 'intl';
import { Header, Modal, Button } from 'semantic-ui-react';
import { exportActivityData, exportDiary } from 'api/nfa-subjects';
import styles from './styles.module.scss';
import { useBulkExport } from './use-bulk-export';

const NFABulkExportModal = ({ subjects, open, onClose }) => {
  const t = useTranslate(['common', 'nfa-subjects', 'measurement-bulk-export']);

  const { exporting: exportingActivity, onExport: onExportActivity } =
    useBulkExport({
      exportAction: 'modal',
      apiExport: exportActivityData,
      subjects,
      onCompleted: onClose
    });

  const { exporting: exportingDiary, onExport: onExportDiary } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportDiary,
    subjects,
    onCompleted: onClose
  });

  return (
    <Modal open={open}>
      <div className={styles.header}>
        <Header className={styles.title}>
          {t('measurement-bulk-export:header')}
        </Header>
        <p className={styles.description}>
          {t('nfa-subjects:bulk-export.num-of-subjects', {
            count: subjects.length
          })}
        </p>
      </div>
      <Modal.Content className={styles.content}>
        <div className={styles.body}>
          <div className={styles.left}>
            <Header>{t('nfa-subjects:export-activity-data')}</Header>
            <Button
              primary
              size="huge"
              icon="download"
              disabled={subjects.length === 0}
              loading={exportingActivity}
              onClick={onExportActivity}
            />
          </div>
          <div className={styles.right}>
            <Header>{t('nfa-subjects:export-diary')}</Header>
            <Button
              primary
              size="huge"
              icon="calendar alternate outline"
              disabled={subjects.length === 0}
              loading={exportingDiary}
              onClick={onExportDiary}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          {t('common:close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NFABulkExportModal;
