import { axiosClient } from 'api/axios-client';

export const changeUserName = async (form: {
  firstName: string;
  lastName: string;
}) => {
  const data = {
    first_name: form.firstName,
    last_name: form.lastName
  };
  return axiosClient.post('/api/1.0/user/profile/edit', data);
};

export const changePhoneNumber = async (phone: string) => {
  const data = { phone_number: phone };
  return axiosClient.post('/api/1.0/user/profile/edit', data);
};

export const changeTimezone = async (timezone: string) => {
  const data = { timezone };
  return axiosClient.post('/api/1.0/user/profile/edit', data);
};
