/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { VictoryAxis, VictoryLabel } from 'victory';
import configs from './configs';

const styles = {
  axis: { stroke: configs.axisColor },
  axisLabel: { padding: 45 },
  grid: { stroke: configs.gridColor, strokeWidth: configs.strokeWidthGrid },
  ticks: { stroke: configs.gridColor, strokeWidth: 1, size: 10 }
};

const stylesZoomed = {
  axis: { stroke: configs.axisColor },
  axisLabel: { padding: 55 },
  grid: { stroke: configs.gridColor, strokeWidth: configs.strokeWidthGrid },
  ticks: { stroke: configs.gridColor, strokeWidth: 1, size: 10 }
};

const AxisX = ({ tickValues, label, zoomed, tickFormat }) => (
  <VictoryAxis
    label={label}
    style={zoomed ? stylesZoomed : styles}
    offsetY={70}
    tickValues={tickValues}
    tickFormat={
      tickFormat
        ? tickFormat
        : t =>
            zoomed ? moment(t).format('HH:mm:ss') : moment(t).format('HH:mm')
    }
    tickLabelComponent={
      <VictoryLabel
        angle={45}
        style={{ fontSize: '10px' }}
        textAnchor="start"
        verticalAnchor="middle"
      />
    }
  />
);

AxisX.propTypes = {
  tickValues: PropTypes.array,
  label: PropTypes.string,
  zoomed: PropTypes.bool
};

export default AxisX;
