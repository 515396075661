import React from 'react';
import PropTypes from 'prop-types';
import { Headers, Name, Status } from 'fragments/sensor';
import Box from 'components/box';

const SensorDetailsInfoBasic = ({ data }) => (
  <Box mb="2rem">
    <span style={{ fontWeight: 600 }}>
      {Headers.name}
      {`: `}
    </span>
    <Name data={data} />
    <span>{` - `}</span>
    <span style={{ fontWeight: 600 }}>
      {Headers.status}
      {`: `}
    </span>
    <Status data={data} />
  </Box>
);

SensorDetailsInfoBasic.propTypes = {
  data: PropTypes.object.isRequired
};

export default SensorDetailsInfoBasic;
