import React from 'react';
import { Department, Headers, Name, Select, Status } from '../elements';

const baseColumns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status,
    sortable: true
  },
  {
    name: 'departments',
    header: Headers.department,
    cell: Department,
    sortable: true
  }
];

export const getColumns = ({ editMode, selectedSensors, onToggleSelect }) => {
  if (!editMode) return baseColumns;

  return [
    ...baseColumns,
    {
      name: 'select',
      header: Headers.select,
      cell: ({ data }) => (
        <Select
          data={data}
          selected={!!selectedSensors.find(u => u.id === data.id)}
          onToggleSelect={onToggleSelect}
        />
      ),
      align: 'center'
    }
  ];
};
