import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Dropdown, Header, Icon } from 'semantic-ui-react';
import { fetchAppVersion } from 'api/app-version';

const UserMenu = () => {
  const t = useTranslate('menu');
  const { user } = useContext(UserContext);
  const { data: appVersion } = useFetch({ apiFetch: fetchAppVersion });
  return (
    <Dropdown
      item
      position="right"
      trigger={
        <Header as="h5" style={{ margin: 0 }}>
          <Icon name="user" />
          {user.email && (
            <Header.Content>
              {`${user.firstName} ${user.lastName}`}
              <Header.Subheader>{user.email}</Header.Subheader>
            </Header.Content>
          )}
        </Header>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={routes.settings()}>
          <Icon name="setting" />
          {t('settings')}
        </Dropdown.Item>
        {/* <Dropdown.Item as={Link} to={routes.ble()} hidden={true}>
          <Icon name="bluetooth" />
          {intl.formatMessage(translations.ble)}
        </Dropdown.Item> */}
        <Dropdown.Item as={Link} to={routes.logout()}>
          <Icon name="sign out" />
          {t('logout')}
        </Dropdown.Item>

        {appVersion && (
          <>
            <Dropdown.Divider />

            <p
              style={{
                color: 'rgba(0,0,0,.4)',
                padding: '0.2em 1.14285714em',
                marginBottom: '0.5em'
              }}
            >
              {t('common:web-app-version', {
                version: appVersion.version_short
              })}
            </p>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserMenu;
