import React from 'react';
import PropTypes from 'prop-types';
import { usePlotExport } from 'hooks';
import { exportCSVHour, exportRawHour } from 'api/sensors';
import Modal from '../../modal';
import useDates from './use-dates';
import useExportFormat from './use-export-format';

const apiExports = {
  CSV: exportCSVHour,
  Raw: exportRawHour
};

const SensorPlotsExportHourSelectPeriod = ({
  exportType,
  onResetExportType,
  // ---
  date,
  stream,
  sensorId, // to share with Measurement Raw Plot
  dayStatistics
}) => {
  const {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    limit, // { value, unit }
    minDate,
    maxDate
  } = useDates({
    exportType,
    date,
    stream
  });
  const { exportFormat, onChangeExportFormat } = useExportFormat();
  const { exporting, onExport, error } = usePlotExport({
    startDate,
    endDate,
    stream,
    exportFormat,
    apiExport: apiExports[exportType],
    ...(sensorId ? { params: { sensorId } } : {}), // to share with Measurement Raw Plot
    exportAction: 'modal'
  });
  const showExportFormat = stream.split(':')[0] === 'raw';
  const onSubmit = () => onExport().then(onResetExportType);

  return (
    <Modal
      open
      // ---
      exportType={exportType}
      inputType="date-time"
      // ---
      limit={limit}
      // ---
      startDate={startDate}
      onChangeStartDate={onChangeStartDate}
      endDate={endDate}
      onChangeEndDate={onChangeEndDate}
      minDate={minDate}
      maxDate={maxDate}
      dayStatistics={dayStatistics}
      // ---
      exportFormat={exportFormat}
      onChangeExportFormat={onChangeExportFormat}
      showExportFormat={showExportFormat}
      // ---
      onExport={onSubmit}
      onCancel={onResetExportType}
      exporting={exporting}
      error={error}
    />
  );
};

SensorPlotsExportHourSelectPeriod.propTypes = {
  exportType: PropTypes.oneOf(['CSV', 'Raw']).isRequired,
  onResetExportType: PropTypes.func.isRequired,
  // ---
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired,
  sensorId: PropTypes.string // to share with Measurement Raw Plot
};

export default SensorPlotsExportHourSelectPeriod;
