import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Button, Form, Input, Segment } from 'semantic-ui-react';
import { verifyConfirmationCode } from 'api/user';
import ErrorMessage from 'components/error-message';
import styles from './styles.module.scss';

const numberRegex = /^[0-9]+$/;

const ConfirmationCodeForm = () => {
  const t = useTranslate('verify-confirmation-code');

  const navigate = useNavigate();

  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();
  const input4Ref = useRef();
  const input5Ref = useRef();
  const input6Ref = useRef();

  const { form, onChanges, onSubmit, submitting, error, onClearError } =
    useForm({
      initialForm: {
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: ''
      },
      apiSubmit: verifyConfirmationCode,
      onSuccess: confirmationCode => {
        navigate(`${routes.resetPassword()}?token=${confirmationCode}`);
      }
    });

  useEffect(() => {
    const inputs = [
      input1Ref.current,
      input2Ref.current,
      input3Ref.current,
      input4Ref.current,
      input5Ref.current,
      input6Ref.current
    ];

    const handleChanges = [
      value => onChanges.code1(value),
      value => onChanges.code2(value),
      value => onChanges.code3(value),
      value => onChanges.code4(value),
      value => onChanges.code5(value),
      value => onChanges.code6(value)
    ];

    // Auto focus the first input
    inputs[0].focus();

    const pasteEvents = {};
    const keydownEvents = {};

    inputs.forEach((input, index) => {
      function handlePasteEvent(event) {
        const pasteData = (event.clipboardData || window.clipboardData)
          .getData('text')
          .trim();
        if (numberRegex.test(pasteData)) {
          event.preventDefault();
          const codes = pasteData.split('');
          for (let i = index; i < inputs.length; i += 1) {
            if (codes[i]) {
              handleChanges[i](codes[i - index]);
            }
          }
          let lastIndex = index + codes.length - 1;
          if (lastIndex > inputs.length) {
            lastIndex = inputs.length - 1;
          }
          inputs[lastIndex].focus();
        } else {
          event.preventDefault();
        }
      }

      function handleKeydownEvent(event) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
          event.preventDefault();
          const prevInput = inputs[index - 1];
          if (prevInput) {
            prevInput.focus();
          }
        } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
          event.preventDefault();
          const nextInput = inputs[index + 1];
          if (nextInput) {
            nextInput.focus();
          }
        } else if (event.key === 'Delete' || event.key === 'Backspace') {
          event.preventDefault();
          handleChanges[index]('');
          const prevInput = inputs[index - 1];
          if (prevInput) {
            prevInput.focus();
          }
        } else if (numberRegex.test(event.key)) {
          const inputValue = event.target.value;
          const nextInput = inputs[index + 1];
          if (inputValue.length) {
            event.preventDefault();
            if (nextInput) {
              nextInput.focus();
              handleChanges[index + 1](event.key);
            }
          } else {
            event.preventDefault();
            handleChanges[index](event.key);
            if (nextInput) {
              nextInput.focus();
            }
          }
        }
      }

      input.inputRef.current.addEventListener('paste', handlePasteEvent);
      input.inputRef.current.addEventListener('keydown', handleKeydownEvent);

      pasteEvents[index] = handlePasteEvent;
      keydownEvents[index] = handleKeydownEvent;
    });

    return () => {
      inputs.forEach((input, index) => {
        input.inputRef.current?.removeEventListener(
          'paste',
          pasteEvents[index]
        );
        input.inputRef.current?.removeEventListener(
          'keydown',
          keydownEvents[index]
        );
      });
    };
  }, []);

  return (
    <>
      <Segment stacked className={styles.segment}>
        <Form onSubmit={onSubmit}>
          <div className={styles.code}>
            <Input
              ref={input1Ref}
              className={styles.input}
              type="text"
              value={form.code1}
              onChange={(event, { value }) => onChanges.code1(value)}
              onKeyPress={e => {
                if (!numberRegex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />
            <Input
              ref={input2Ref}
              className={styles.input}
              type="text"
              value={form.code2}
              onChange={(event, { value }) => onChanges.code2(value)}
              onKeyPress={e => {
                if (!numberRegex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />
            <Input
              ref={input3Ref}
              className={styles.input}
              type="text"
              value={form.code3}
              onChange={(event, { value }) => onChanges.code3(value)}
              onKeyPress={e => {
                if (!numberRegex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />
            <Input
              ref={input4Ref}
              className={styles.input}
              type="text"
              value={form.code4}
              onChange={(event, { value }) => onChanges.code4(value)}
              onKeyPress={e => {
                if (!numberRegex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />
            <Input
              ref={input5Ref}
              className={styles.input}
              type="text"
              value={form.code5}
              onChange={(event, { value }) => onChanges.code5(value)}
              onKeyPress={e => {
                if (!numberRegex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />
            <Input
              ref={input6Ref}
              className={styles.input}
              type="text"
              value={form.code6}
              onChange={(event, { value }) => onChanges.code6(value)}
              onKeyPress={e => {
                if (!numberRegex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              required
            />
          </div>

          <div style={{ padding: '0 44px', marginTop: '32px' }}>
            <Button
              fluid
              type="submit"
              color="blue"
              size="large"
              loading={submitting}
              content={t('submit-code')}
            />
          </div>
        </Form>
      </Segment>

      <ErrorMessage error={error} onDismiss={onClearError} attached="bottom" />
    </>
  );
};

export default ConfirmationCodeForm;
