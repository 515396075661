import moment from 'moment-timezone';
import i18n from './i18n';
import timezones from './timezones';

const options = moment.tz
  .names()
  .filter(tz => {
    return timezones.includes(tz);
  })
  .map(tz => {
    const timezone = moment.tz(tz);
    const offset = timezone.utcOffset();
    const text = offset === 0 ? tz : `GMT${timezone.format('Z')}`;
    const label = i18n[tz] || tz;
    return {
      value: tz,
      text: `(${text}) ${label}`
    };
  });

export default options;
