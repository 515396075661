import routes from 'routes';
import type { ProjectRaw } from 'api/projects';
import type { ScopeRaw } from 'api/scopes';
import type {
  ICompDepartment,
  ICompDepartmentProject,
  ICompDepartmentProjectView,
  ICompDepartmentRaw
} from './types';

const viewConfigs = {
  iCompSensors: {
    name: 'icomp-sensors',
    icon: 'ticket',
    route: routes.iCompSensors
  } as ICompDepartmentProjectView,
  iCompPatients: {
    name: 'icomp-patients',
    icon: 'users',
    route: routes.iCompPatients
  } as ICompDepartmentProjectView
};

const nICompProjectStatus = (statusNum: number) => {
  switch (statusNum) {
    case 1:
      return 'open';
    case 2:
      return 'closed';
    case 3:
      return 'archived';
    case 4:
      return 'deleted';
    default:
      throw new Error('Invalid Status');
  }
};

const nICompProject = (raw: ProjectRaw): ICompDepartmentProject => ({
  id: raw.id,
  name: raw.name,
  created: raw.created,
  status: nICompProjectStatus(raw.status),
  class: raw.project_class,
  numActivePatients: raw.statistics.num_active_patients,
  views: [viewConfigs.iCompPatients, viewConfigs.iCompSensors],
  raw
});

export const nICompDepartment = (
  _raw: ICompDepartmentRaw | ScopeRaw
): ICompDepartment => {
  const raw = _raw as ICompDepartmentRaw;
  return {
    id: raw.id,
    name: raw.name,
    description: raw.long_name || '',
    roles: raw.roles,
    numSensors: raw.statistics.num_sensors,
    numUsers: raw.statistics.num_users,
    project: raw.project ? nICompProject(raw.project) : undefined,
    raw
  };
};
