import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Name } from 'fragments/sensor';

const MeasurmentDetailsPlotsSensor = ({ data }) => {
  const t = useTranslate('measurement');

  return (
    <span>
      {t('sensor')}: <Name data={data} />
    </span>
  );
};

MeasurmentDetailsPlotsSensor.propTypes = {
  data: PropTypes.object.isRequired
};

export default MeasurmentDetailsPlotsSensor;
