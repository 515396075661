import { fetchScopeProjects, fetchScopes } from 'api/scopes';
import type { Collection } from 'app/types';
import { nOrganizations } from './normalize';
import type { Organization, OrganizationRaw } from './types';

let CACHE: Collection<Organization>;

export const fetchOrganizations = async () => {
  if (CACHE) return CACHE;

  const scopes = await fetchScopes();
  const scope_id = scopes.map(o => o.id).join(',');
  const projects = await fetchScopeProjects(scope_id);

  const raw: OrganizationRaw[] = scopes.map(s => ({
    ...s,
    projects: projects.filter(p => p.parent_id === s.id)
  }));

  const organizations = nOrganizations(raw);
  CACHE = organizations;

  return organizations;
};
