import { axiosClient } from 'api/axios-client';

export const registerMonitorToken = async ({
  deviceKey,
  deviceName,
  deviceInfo,
  overrideToday
}: {
  deviceKey: string;
  deviceName: string;
  deviceInfo: string;
  overrideToday: string;
}) => {
  const payload = {
    device_key: deviceKey,
    device_name: deviceName,
    device_info: deviceInfo,
    override_today: overrideToday
  };
  const {
    device_id,
    m_token
  }: {
    device_id: string;
    m_token: string;
  } = await axiosClient
    .post('/api/1.0/monitor/register', payload)
    .then(response => response.data.value);

  return {
    deviceId: device_id,
    mToken: m_token
  };
};
