import { fetchOrganization } from 'api/organizations';

export const fetchProjects = async ({
  organizationId
}: {
  organizationId: string;
}) => {
  const projects = await fetchOrganization(organizationId).then(
    response => response?.projects || []
  );
  return projects;
};
