import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  subjectId: <Translate ns="nfa-subjects" i18nKey="id" />,
  phoneNumber: <Translate ns="nfa-subjects" i18nKey="phone-number" />,
  sensorName: <Translate ns="nfa-subjects" i18nKey="sensor-name" />,
  sensorData: <Translate ns="nfa-subjects" i18nKey="sensor-data" />,
  startTime: <Translate ns="nfa-subjects" i18nKey="start-time" />,
  status: <Translate ns="nfa-subjects" i18nKey="status" />,
  diaryUpdated: <Translate ns="nfa-subjects" i18nKey="diary-updated" />,
  actions: <Translate ns="nfa-subjects" i18nKey="actions" />
};
