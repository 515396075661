import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nActivePatient } from './normalize';
import type { ActivePatient, ActivePatientForm } from './types';
import { getEndTime, getOverrideTime, getStartTime } from './utils';

export const editPatient = async ({
  form,
  params,
  patient: _patient
}: {
  form: ActivePatientForm;
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  patient: ActivePatient;
}) => {
  if (!form.measurementMethod) {
    return Promise.reject({
      type: 'intl',
      message: 'measurement-method-is-required'
    });
  }

  if (!form.sensorThigh && !form.sensorChest) {
    return Promise.reject({
      type: 'intl',
      message: 'sensor-is-required'
    });
  }

  if (form.startTimeType === 'specific-time' && !form.startTimeValue) {
    return Promise.reject({
      type: 'intl',
      message: 'start-time-is-required'
    });
  }

  const startTime = getStartTime(form);
  const endTime = getEndTime(form);
  const payload = {
    org_id: params.organizationId,
    project_id: params.projectId,
    patient_id: _patient.id,
    first_name: form.firstName,
    last_name: form.lastName,
    timezone: form.timezone,
    phone_number: form.phoneNumber || null,
    alg_profile: form.measurementMethod,
    sensor_id: form.sensorThigh,
    sensor2_id: form.sensorChest || null,
    meta: {
      start_time_type: form.startTimeType,
      end_time_type: form.endTimeType,
      end_time_duration: form.endTimeDuration
    },
    start_time: getOverrideTime(startTime, _patient.startTime),
    end_time: getOverrideTime(endTime, _patient.endTime)
  };

  const { patient, sensors } = await axiosClient
    .post('/api/1.0/patients/edit2', payload)
    .then(response => response.data.value);

  return nActivePatient(patient, sensors);
};
