export const BATTERY_COLORS = {
  'battery_status/unknown': 'silver',
  'battery_status/ok': 'green',
  'battery_status/medium': 'olive',
  'battery_status/low': 'orange',
  'battery_status/fatal': 'red'
};

export const BATTERY_ICONS = {
  'battery_status/unknown': 'question circle',
  'battery_status/ok': 'battery full',
  'battery_status/medium': 'battery three quarters',
  'battery_status/low': 'battery quarter',
  'battery_status/fatal': 'battery empty'
};

export const SENSOR_COLORS = {
  'sensor_status/expired': 'silver',
  'sensor_status/removed': 'silver',
  'sensor_status/deep-sleep': 'black',
  'sensor_status/waking-up': 'orange',
  'sensor_status/putting-to-sleep': 'olive',
  'sensor_status/running-ok': 'green',
  'sensor_status/running-sync-warning': 'yellow',
  'sensor_status/running-sync-alert': 'red'
};
