import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';

const Control = ({
  data,
  submitting,
  target,
  onActivate,
  onDeactivate,
  onTerminate
}) => {
  const t = useTranslate('sens-admin-nearby-sensor');

  return (
    <div>
      <Button
        basic
        compact
        color="green"
        content={t('activate')}
        loading={submitting === 'activate' && target === data.id}
        disabled={
          !!submitting || data.status === 'expired' || data.status === 'ok'
        }
        onClick={onActivate.bind(null, data)}
      />
      <Button
        basic
        compact
        color="orange"
        content={t('deactivate')}
        loading={submitting === 'deactivate' && target === data.id}
        disabled={
          !!submitting ||
          data.status === 'expired' ||
          data.status === 'putting-to-sleep'
        }
        onClick={onDeactivate.bind(null, data)}
      />
      <Button
        basic
        compact
        color="red"
        content={t('terminate')}
        loading={submitting === 'terminate' && target === data.id}
        disabled={!!submitting || data.status === 'expired'}
        onClick={onTerminate.bind(null, data)}
      />
    </div>
  );
};

Control.propTypes = {
  data: PropTypes.object.isRequired,
  submitting: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onTerminate: PropTypes.func.isRequired
};

export default Control;
