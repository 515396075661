import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import { fetchPlot } from 'api/icomp-patients';
import Box from 'components/box';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';
import styles from './styles.module.css';
import PlotVictory from './victory';

const PPlot = ({
  track,
  measurement,
  stream,
  startDate,
  endDate,
  measurements
}) => {
  const t = useTranslate('icomp-patient');

  const { hospitalId, departmentId, projectId } = useParams();

  const source = JSON.stringify({
    hospitalId,
    departmentId,
    projectId,
    track,
    measurement,
    stream,
    startDate: startDate && startDate.toString(),
    endDate: endDate && endDate.toString()
  });

  const { fetching, data, error, refetch } = useFetch({
    source,
    isJsonSource: true,
    apiFetch: fetchPlot
  });

  // Show fetching indicator
  if (fetching) {
    return (
      <LoadingSection height="20rem">{t('common:fetching')}</LoadingSection>
    );
  }

  // Handle `Generating Resource` response
  if (error?.statusCode === 10) {
    return (
      <div className={styles.retry}>
        <p>{t('common:retry-message')}</p>
        <div>
          <Button primary onClick={refetch}>
            {t('common:retry-btn')}
          </Button>
        </div>
      </div>
    );
  }

  // Handle other errors
  if (error) {
    return (
      <Box mt="2rem">
        <ErrorMessage error={error} />
      </Box>
    );
  }

  // Fetch data success -> render content
  return (
    <Box mt="4rem">
      <PlotVictory
        stream={data.stream}
        startDate={data.startDate}
        endDate={data.endDate}
        values={data.values}
        measurements={measurements}
      />
    </Box>
  );
};

PPlot.propTypes = {
  track: PropTypes.string.isRequired,
  measurement: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  // ---
  measurements: PropTypes.array.isRequired
};

export default PPlot;
