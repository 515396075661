import { axiosClient } from 'api/axios-client';
import type { ScopeRaw } from 'api/scopes';
import { nICompUser } from './normalize';
import type { ICompUserRaw } from './types';

export const fetchUsers = async ({ scopeId }: { scopeId: string }) => {
  const { scopes, members }: { scopes: ScopeRaw[]; members: ICompUserRaw[] } =
    await Promise.all([
      axiosClient.get('/api/1.0/access_scopes/list'),
      axiosClient.get('/api/1.0/access_scopes/members', {
        params: { parent_scope_id: scopeId }
      })
    ]).then(([scopesResponse, membersResponse]) => {
      return {
        scopes: scopesResponse.data.value,
        members: membersResponse.data.value
      };
    });
  const users = members.map(member => nICompUser(member, scopes));
  return users;
};
