import { Headers, Name, Projects } from '../elements';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'projects',
    header: Headers.projects,
    cell: Projects,
    sortable: true
  }
];
