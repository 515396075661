import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { StatusDropdown } from 'fragments/sensor';
import { Grid, Header, Input } from 'semantic-ui-react';
import { getVendor } from 'utils/vendor';

const SensorDashboardHeader = ({
  search,
  filter,
  onSearch,
  onFilter,
  disabled
}) => {
  const t = useTranslate('sensor');
  const vendor = getVendor();

  if (vendor === 'nfa') {
    return (
      <div>
        <Header as="h2">{t('header')}</Header>
        <div style={{ marginTop: '1rem' }}>
          <Input
            value={search}
            onChange={onSearch}
            placeholder={t('common:search-by-name')}
            style={{ verticalAlign: 'top', marginRight: '1rem' }}
            disabled={disabled}
          />
          <StatusDropdown
            value={filter}
            onChange={onFilter}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={6}>
          <Header as="h2">{t('header')}</Header>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Input
            value={search}
            onChange={onSearch}
            placeholder={t('common:search-by-name')}
            style={{ verticalAlign: 'top', marginRight: '1rem' }}
            disabled={disabled}
          />
          <StatusDropdown
            value={filter}
            onChange={onFilter}
            disabled={disabled}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SensorDashboardHeader.propTypes = {
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default SensorDashboardHeader;
