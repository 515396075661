import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import timezones from 'components/timezone-select/options';

class Timezone extends PureComponent {
  render() {
    const timezone = timezones.find(item => item.value === this.props.value);
    return <span>{timezone.text}</span>;
  }
}

Timezone.propTypes = {
  value: PropTypes.string.isRequired
};

export default Timezone;
