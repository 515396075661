import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  name: <Translate ns="sensor" i18nKey="name" />,
  status: <Translate ns="sensor" i18nKey="status" />,
  patientAttached: <Translate ns="sensor" i18nKey="patient-attached" />,
  lastSeen: <Translate ns="sensor" i18nKey="last-seen" />,
  lastSynced: <Translate ns="sensor" i18nKey="last-synced" />,
  lastOnline: <Translate ns="sensor" i18nKey="last-online" />,
  runtime: <Translate ns="sensor" i18nKey="runtime" />,
  control: <Translate ns="sensor" i18nKey="control" />
};
