/* eslint-disable dot-notation */
import { createSearchRegex } from 'utils/search';
import { createSorter } from 'utils/sort';

const getNameOrder = data => data.name;

const getStartTimeOrder = data => {
  switch (data.status) {
    case 'status/not_started':
      return data.expectedStartTime;
    default:
      return data.startTime;
  }
};

const statusOrders = [
  'status/not_started',
  'status/started',
  'status/completed'
];
const getStatusOrder = data => statusOrders.indexOf(data.status) + 1;

const orders = {
  name: getNameOrder,
  'start-time': getStartTimeOrder,
  status: getStatusOrder
};

const sorters = {
  name: createSorter(orders['name']),
  'start-time': createSorter(orders['start-time']),
  status: createSorter(orders['status'])
};

export function filterData({
  data,
  sortAccessor,
  sortDirection,
  searchQuery,
  filterStatus
}) {
  let result = data.slice();

  if (searchQuery) {
    const searchRegex = createSearchRegex(searchQuery);
    result = result.filter(
      item =>
        item.name.match(searchRegex) || item.phoneNumber.match(searchRegex)
    );
  }

  if (sortAccessor) {
    const sorter = sorters[sortAccessor];
    let result1 = result.filter(item => orders[sortAccessor](item));
    const result2 = result.filter(item => !orders[sortAccessor](item));
    result1 = result1.sort(sorter);
    if (sortDirection === 'desc') {
      result1.reverse();
    }
    result = [...result1, ...result2];
  }

  if (filterStatus.length) {
    result = result.filter(item => filterStatus.includes(item.status));
  }

  return result;
}

export const isDisabledExport = subject =>
  subject.status === 'status/not_started';
