import React from 'react';
import Body from 'containers/patient/details';
import Layout from 'layouts/sens';

const PagePatientDetails = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PagePatientDetails;
