import React from 'react';
import Body from 'containers/admin/gateway/details';
import Layout from 'layouts/sens-admin';

const PageAdminGatewayDetails = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageAdminGatewayDetails;
