import React from 'react';
import { useTranslate } from 'intl';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';
import Table from 'components/table';
import { getColumns } from './configs';

const ICompPatientDashboardTableFragment = ({
  patient,
  open,
  onClose,
  onLogAccess,
  // ---
  fetching,
  error,
  data,
  onRowClick,
  searchMode
}) => {
  const t = useTranslate('icomp-patient');

  return (
    <React.Fragment>
      <Table
        data-cy="patients__list"
        fetching={fetching}
        error={error}
        data={data}
        onRowClick={onRowClick}
        columns={getColumns(searchMode)}
      />
      <Modal
        size="tiny"
        open={open}
        title={t('access-confirmation-title')}
        description={
          <div style={{ marginTop: '-0.5em' }}>
            <div
              style={{
                fontSize: 'large',
                margin: 0
              }}
            >
              {patient.name}
            </div>
            <div
              style={{
                fontSize: 'medium',
                marginTop: '0.5em'
              }}
            >
              {t('access-confirmation-description')}
            </div>
          </div>
        }
        footer={
          <ButtonGroup>
            <ButtonCancel onClick={onClose} />
            <ButtonOk
              data-cy="patients__confirm-access-button"
              onClick={onLogAccess}
            />
          </ButtonGroup>
        }
      />
    </React.Fragment>
  );
};

export default ICompPatientDashboardTableFragment;
