import {
  Description,
  Headers,
  Name,
  NumActivePatients,
  Roles,
  sorters
} from '../elements';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true,
    sorter: sorters.name
  },
  {
    name: 'description',
    header: Headers.description,
    cell: Description,
    sortable: true
  },
  {
    name: 'roles',
    header: Headers.roles,
    cell: Roles,
    sortable: true,
    sorter: sorters.roles
  },
  {
    name: 'numActivePatients',
    header: Headers.numActivePatients,
    cell: NumActivePatients,
    sortable: true,
    sorter: sorters.numActivePatients
  }
];
