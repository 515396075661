import React from 'react';
import { useTranslate } from 'intl';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Header, Input, Radio } from 'semantic-ui-react';
import styles from './styles.module.scss';

const SORT_VALUES = [
  'create-date',
  'sensor-name',
  'patient',
  'start-time',
  'end-time',
  'status'
];
const FILTER_VALUES = ['discharged', 'active'];

const HostpialPatientsHeader = ({
  searchQuery,
  setSearchQuery,
  filterStatus,
  setFilterStatus,
  sortAccessor,
  setSortAccessor,
  sortDirection,
  setSortDirection,
  onToggleBulkExportMode
}) => {
  const t = useTranslate('hospital-patient');

  return (
    <>
      <Header as="h2">{t('header')}</Header>

      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.dropdown}>
            <label>{`${t('common:sort-by')}: `}</label>
            <Dropdown
              selection
              options={SORT_VALUES.map(item => ({
                key: item,
                value: item,
                text: t(`sort-by/${item}`)
              }))}
              value={sortAccessor}
              onChange={(e, { value }) => setSortAccessor(value)}
            />
          </div>
          <div className={styles.toggle}>
            <Radio
              toggle
              checked={sortDirection === 'desc'}
              onChange={(event, { checked }) =>
                setSortDirection(checked ? 'desc' : 'asc')
              }
            />
            <label>
              {sortDirection === 'desc'
                ? t('common:order/desc')
                : t('common:order/asc')}
            </label>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.dropdown}>
            <Dropdown
              placeholder={t('filter-status')}
              selection
              multiple
              options={FILTER_VALUES.map(item => ({
                key: item,
                value: item,
                text: t(`status/${item}`)
              }))}
              value={filterStatus}
              onChange={(e, { value }) => setFilterStatus(value)}
            />
          </div>
          <div className={styles.input}>
            <Input
              value={searchQuery}
              onChange={(e, { value }) => setSearchQuery(value)}
              placeholder={t('common:search-by-name')}
            />
          </div>
          <div className={styles.button}>
            <Button basic icon color="blue" onClick={onToggleBulkExportMode}>
              <FontAwesomeIcon icon={faFileExport} />
              <span className={styles['button-text']}>
                {t('measurement-bulk-export:trigger')}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostpialPatientsHeader;
