import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'components/time-ago';

const LatestEvent = ({ data }) => <TimeAgo value={data.latestEvent} ago />;

LatestEvent.propTypes = {
  data: PropTypes.object.isRequired
};

export default LatestEvent;
