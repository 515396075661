import React from 'react';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { fetchAppVersion } from 'api/app-version';

const WebAppVersion = () => {
  const t = useTranslate('common');
  const { data: appVersion } = useFetch({ apiFetch: fetchAppVersion });

  return (
    appVersion && (
      <p style={{ margin: '2rem 0', textAlign: 'center' }}>
        {t('web-app-version', {
          version: appVersion.version_short
        })}
      </p>
    )
  );
};

export default WebAppVersion;
