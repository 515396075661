import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';
import Table from 'components/table';
import { columns } from './configs';

const ICompAdminUserOverviewTable = ({ fetching, error, users }) => {
  const navigate = useNavigate();
  const { hospitalId, departmentId } = useParams();

  const onRowClick = user => {
    const path = departmentId
      ? routes.iCompAdminDepartmentUserProfile(
          hospitalId,
          departmentId,
          user.id
        )
      : routes.iCompAdminUserProfile(hospitalId, user.id);
    navigate(path);
  };

  return (
    <Table
      fetching={fetching}
      data={users}
      error={error}
      hospitalId={hospitalId}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

ICompAdminUserOverviewTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  users: PropTypes.array,
  hospitalId: PropTypes.string
};

export default ICompAdminUserOverviewTable;
