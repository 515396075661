import React from 'react';
import clsx from 'clsx';
import { useTranslate } from 'intl';
import { Icon, Menu } from 'semantic-ui-react';
import { useExportController } from '../context';
import styles from './styles.module.css';

const getIndicatorColor = exportEntries => {
  if (exportEntries.find(item => item.status === 'export_status/failed')) {
    return 'red';
  }

  if (
    exportEntries.find(
      item =>
        item.status === 'export_status/queued' ||
        item.status === 'export_status/running'
    )
  ) {
    return 'yellow';
  }

  if (exportEntries.find(item => item.status === 'export_status/completed')) {
    return 'blue';
  }

  return null;
};

export const ExportControllerTrigger = () => {
  const t = useTranslate('export-controller');
  const { exportEntries, refetchExportEntries, onOpenExportModal } =
    useExportController();

  const indicatorColor = getIndicatorColor(exportEntries);

  return (
    <Menu.Item
      className={
        indicatorColor &&
        clsx(styles.indicator, styles[`indicator-${indicatorColor}`])
      }
      onClick={() => {
        refetchExportEntries();
        onOpenExportModal();
      }}
    >
      <Icon name="download" />
      {t('trigger')}
    </Menu.Item>
  );
};
