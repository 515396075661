import { axiosClient } from 'api/axios-client';
import { nEvent } from './normalize';
import type { NearbySensorEventRaw } from './types';

export const fetchNearbySensor = async ({
  gatewayId,
  sensorId
}: {
  gatewayId: string;
  sensorId: string;
}) => {
  const params = {
    gateway_id: gatewayId,
    sensor_id: sensorId,
    seen_hours: '12'
  };

  const { seen_events: events }: { seen_events: NearbySensorEventRaw[] } =
    await axiosClient
      .get('/gwapi/1.0/adminx/gateway/seen', { params })
      .then(response => response.data.value);

  return events.map((item, index) => ({
    ...nEvent(item),
    idx: index + 1
  }));
};
