import React from 'react';
import { useTranslate } from 'intl';
import { Button, Icon, Modal } from 'semantic-ui-react';
import styles from './styles.module.scss';

const ConfirmationModal = ({ open, onConfirm, onCancel }) => {
  const t = useTranslate('patient');

  return (
    <Modal className={styles.modal} size="tiny" open={open} onClose={onCancel}>
      <div className={styles.header}>
        <Icon name="trash alternate outline" />
        {t('confirmation.header')}
      </div>
      <Modal.Content>
        <p>{t('confirmation.content')} </p>
      </Modal.Content>
      <Modal.Actions>
        <Button content={t('common:cancel')} color="grey" onClick={onCancel} />
        <Button
          icon="check"
          labelPosition="right"
          content={t('confirmation.confirm')}
          color="red"
          onClick={() => {
            onConfirm();
            onCancel();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
