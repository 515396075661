import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import type { ICompDepartmentRaw } from 'api/icomp-departments';
import { nICompPatient } from './normalize';
import type { ICompPatientRaw } from './types';

export const fetchPatients = async (
  {
    departmentId,
    projectId
  }: {
    departmentId: string;
    projectId: string;
  },
  setCancel: CancelExecutor
) => {
  const params = {
    org_id: departmentId,
    project_id: projectId
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };
  const {
    patients,
    departments
  }: {
    patients: ICompPatientRaw[];
    departments: ICompDepartmentRaw[];
  } = await axiosClient
    .get('/api/1.0/icompression/patients', options)
    .then(response => response.data.value[0]);

  return patients.map((item, index) => ({
    ...nICompPatient(item, departments),
    idx: index + 1
  }));
};
