import moment from 'moment';

const today = moment().format();

export const flatten = initialForm => ({
  patient: initialForm?.patient?.name || '',
  sensor: initialForm?.sensor?.id || '',
  phone: initialForm?.phone || '',
  monitoringStartTime: initialForm?.monitoring?.startTime || today,
  monitoringEndTime: initialForm?.monitoring?.endTime || today,
  feedbackStartTime: initialForm?.feedback?.startTime || today,
  feedbackEndTime: initialForm?.feedback?.endTime || today
});
