import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { ICompActiveMeasurement, ICompPatient } from '../patients';
import type { TreatmentCreateForm } from './types';

export const addTreatment = async ({
  params,
  patient,
  treatment,
  form
}: {
  params: Pick<RouteParams, 'departmentId' | 'projectId'>;
  patient: ICompPatient;
  treatment: ICompActiveMeasurement;
  form: TreatmentCreateForm;
}) => {
  if (!form.sensor) {
    return Promise.reject({
      type: 'intl',
      message: 'sensor-is-required'
    });
  }
  if (!form.bandage) {
    return Promise.reject({
      type: 'intl',
      message: 'bandage-is-required'
    });
  }
  if (!form.diagnose) {
    return Promise.reject({
      type: 'intl',
      message: 'diagnose-is-required'
    });
  }
  if (!form.side) {
    return Promise.reject({
      type: 'intl',
      message: 'side-is-required'
    });
  }

  const payload = {
    org_id: params.departmentId,
    project_id: params.projectId,
    patient_id: patient.id,
    measurement_track_id: treatment?.track?.id,
    // ---
    side: form.side,
    sensor_id: form.sensor,
    start_time: form.date,
    type: form.bandage,
    diagnose: form.diagnose,
    circumsphere: Number(form.circumsphere),
    note: form.note
  };

  return axiosClient.post('/api/1.0/icompression/measurement/create', payload);
};
