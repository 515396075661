import { Departments, Email, Headers, Initals, Name, Roles } from '../elements';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'email',
    header: Headers.email,
    cell: Email,
    sortable: true
  },
  {
    name: 'initials',
    header: Headers.initials,
    cell: Initals,
    sortable: true
  },
  {
    name: 'departments',
    header: Headers.departments,
    cell: Departments,
    sortable: true
  },
  {
    name: 'roles',
    header: Headers.roles,
    cell: Roles,
    sortable: true
  }
];
