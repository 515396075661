import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type {
  SimpleSensorPlotStreamType,
  SimpleSensorPlotType
} from '../../types';

const fetchRaw = async ({
  organizationId,
  projectId,
  sensorId,
  type,
  date,
  stream,
  setCancel
}: Pick<RouteParams, 'organizationId' | 'projectId' | 'sensorId'> & {
  type: SimpleSensorPlotType;
  date: string;
  stream: string;
  setCancel: CancelExecutor;
}) => {
  const params = {
    org_id: organizationId,
    project_id: projectId,
    sensor_id: sensorId,
    window_type: type,
    streams: stream,
    start_time: date
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const { values: streams } = await axiosClient
    .get('/api/1.0/sensor/data/raw', options)
    .then(response => response.data.value.data.streams[0]);

  return { streams };
};

const fetchDerived = async ({
  organizationId,
  projectId,
  sensorId,
  type,
  date,
  stream,
  setCancel
}: Pick<RouteParams, 'organizationId' | 'projectId' | 'sensorId'> & {
  type: SimpleSensorPlotType;
  date: string;
  stream: string;
  setCancel: CancelExecutor;
}) => {
  const params = {
    org_id: organizationId,
    project_id: projectId,
    sensor_id: sensorId,
    window_type: type,
    patient_profile: 'person/default',
    alg_profile: stream,
    start_time: date
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const { values: streams, categories } = await axiosClient
    .get('/api/1.0/sensor/data/derived', options)
    .then(response => response.data.value.data.streams[0]);

  return { streams, categories };
};

const mapFetch = {
  raw: fetchRaw,
  derived: fetchDerived
};

const createFetchPlot =
  (type: SimpleSensorPlotType) =>
  async (
    source: Pick<RouteParams, 'organizationId' | 'projectId' | 'sensorId'> & {
      type: SimpleSensorPlotType;
      date: string;
      stream: string;
    },
    setCancel: CancelExecutor
  ) => {
    const { date, stream, ...routeParams } = source;
    const [streamType, streamValue] = stream.split(':') as [
      SimpleSensorPlotStreamType,
      string
    ];
    const fetchStream = mapFetch[streamType];
    return fetchStream({
      ...routeParams,
      type: type,
      date: date,
      stream: streamValue,
      setCancel: setCancel
    }).then(values => ({ type, date, stream, values }));
  };

export default createFetchPlot;
