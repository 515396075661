import React from 'react';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Checkbox, List, Radio, Segment } from 'semantic-ui-react';
import { exportData } from 'api/browse-data';
import BackButton from '../back-button';
import SetupBox from '../setup-box';

const ActivityData = ({ selectedData, onBack }) => {
  const t = useTranslate('browse-data');

  const { form, onChanges, onSubmit } = useForm({
    initialForm: {
      summarize: 'all',
      summarizeValue: '1h',
      fileType: 'csv-en',
      timestamps: ['iso-local']
    },
    apiSubmit: form => {
      return exportData({ settings: form, patients: selectedData });
    }
  });

  return (
    <Segment>
      <BackButton onClick={onBack} />

      <SetupBox
        header={t('export-settings.export-activity-data')}
        fields={[
          {
            key: 'summarize',
            label: t('export-settings.summarize'),
            tooltip: t('export-settings.summarize-tooltip'),
            element: (
              <List>
                {['all', 'summarize'].map(option => (
                  <List.Item key={option}>
                    <Radio
                      key={option}
                      label={t(`export-settings.summarize/${option}`)}
                      name="summarize"
                      value={option}
                      checked={form.summarize === option}
                      onChange={(event, { value }) =>
                        onChanges.summarize(value)
                      }
                    />

                    {option === 'summarize' && form.summarize === 'summarize' && (
                      <List.Item>
                        <List.List>
                          {['1h', '15m'].map(option => (
                            <List.Item key={option}>
                              <Radio
                                label={t(
                                  `export-settings.summarize-value/${option}`
                                )}
                                name="summarize-value"
                                value={option}
                                checked={form.summarizeValue === option}
                                onChange={(event, { value }) =>
                                  onChanges.summarizeValue(value)
                                }
                              />
                            </List.Item>
                          ))}
                        </List.List>
                      </List.Item>
                    )}
                  </List.Item>
                ))}
              </List>
            )
          },
          {
            key: 'file-type',
            label: t('export-settings.file-type'),
            tooltip: t('export-settings.file-type-tooltip'),
            element: (
              <List>
                {['csv-en', 'csv-da', 'excel'].map(option => (
                  <List.Item key={option}>
                    <Radio
                      label={t(`export-settings.file-type/${option}`)}
                      name="file-type"
                      value={option}
                      checked={form.fileType === option}
                      onChange={(event, { value }) => onChanges.fileType(value)}
                    />
                  </List.Item>
                ))}
              </List>
            )
          },
          {
            key: 'timestamp',
            label: t('export-settings.timestamp'),
            tooltip: t('export-settings.timestamp-tooltip'),
            element: (
              <List>
                {['unix', 'iso-utc', 'iso-local'].map(option => (
                  <List.Item key={option}>
                    <Checkbox
                      label={t(`export-settings.timestamp/${option}`)}
                      name="file-type"
                      value={option}
                      checked={form.timestamps.includes(option)}
                      onChange={(event, { checked, value }) => {
                        onChanges.timestamps(
                          checked
                            ? form.timestamps.concat(value)
                            : form.timestamps.filter(option => option !== value)
                        );
                      }}
                    />
                  </List.Item>
                ))}
              </List>
            )
          }
        ]}
        selectedData={selectedData}
        onSubmit={onSubmit}
      />
    </Segment>
  );
};

export default ActivityData;
