import moment from 'moment';

export const migrateForm = ({ patient, treatment }) => {
  return {
    cpr: patient.cpr || '',
    phone: patient.phone || '',
    firstName: patient.firstName || '',
    lastName: patient.lastName || '',
    // ---
    session: patient.nextTrackCnt,
    treatment: patient.nextMeasCnt,
    therapist: '',
    // ---
    date: moment().format(),
    // ---
    sensor: treatment?.sensor?.id || '',
    // ---
    bandage: '',
    side: '',
    // ---
    diagnose: treatment?.meta?.diagnose || '',
    circumsphere: '',
    note: ''
  };
};
