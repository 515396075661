import React from 'react';
import moment from 'moment';
import { useTranslate } from 'intl';
import { Label } from 'semantic-ui-react';
import Dash from 'components/dash';
import styles from './styles.module.css';

export const Name = ({ data }) => {
  const name = `${data.firstName} ${data.lastName}`;
  return (
    <div className={styles.name} title={name}>
      {name}
    </div>
  );
};

const getOBSColor = obs => {
  switch (obs) {
    case 'obs/offline':
      return 'grey';
    case 'obs/mobilize':
      return 'yellow';
    case 'obs/mobilize_severe':
      return 'orange';
    case 'obs/new':
      return 'blue';
    case 'obs/none':
      return 'grey';
    case 'obs/analyzing':
      return 'grey';
    default:
      return 'green';
  }
};

const actionOrders = [
  'obs/none',
  'obs/mobilize',
  'obs/mobilize_severe',
  'obs/new',
  'obs/analyzing',
  'obs/offline'
];
export const getActionOrder = data => actionOrders.indexOf(data.obs) + 1;

export const Action = ({ data }) => {
  const t = useTranslate('monitor');

  if (!data.obs || data.obs === 'obs/none') {
    return <Label color="green">{t('obs/ok')}</Label>;
  }

  return <Label color={getOBSColor(data.obs)}>{t(data.obs)}</Label>;
};

export const LastMobilised = ({ data }) => {
  if (!data.lastMobilizedTime) return <Dash />;

  const diffDays = moment()
    .hours(23)
    .minutes(23)
    .second(59)
    .diff(moment(data.lastMobilizedTime), 'day');
  const formattedTime = moment(data.lastMobilizedTime).format('HH:mm');
  return (
    <span>
      {diffDays > 0 ? `${formattedTime} (+${diffDays})` : formattedTime}
    </span>
  );
};

export const Days = ({ data }) => <span>{data.days}</span>;

const getTime = value => {
  let minutes = value / 60;
  if (minutes > 60) {
    const hours = Math.floor(minutes / 60);
    minutes = Math.round(minutes - hours * 60);
    return { hours, minutes };
  }
  return { minutes: Math.round(minutes) };
};
const TotalActivity = ({ value }) => {
  const t = useTranslate('time');
  const { hours, minutes } = getTime(value);
  if (!hours && !minutes) return t('m', { count: 0 });
  const minsTex = t('m', { count: minutes });
  if (!hours) return minsTex;
  const hrsText = t('h', { count: hours });
  return `${hrsText} ${minsTex}`;
};

export const TodayActivity = ({ data }) => (
  <TotalActivity value={data.todayActive} />
);

export const YesterdayActivity = ({ data }) => (
  <TotalActivity value={data.yesterdayActive} />
);

export const TodaySteps = ({ data }) => <span>{data.todaySteps}</span>;

export const YesterdaySteps = ({ data }) => <span>{data.yesterdaySteps}</span>;

export const SensorName = ({ data }) => <span>{data.sensorName}</span>;

export const SensorLastUpdate = ({ data }) => {
  if (!data.sensorTimestamp) return <Dash />;
  const lastUpdate = moment(data.sensorTimestamp);
  const diff = moment().diff(lastUpdate, 'day');
  return (
    <span>
      {lastUpdate.format('HH:mm')}
      {diff > 0 ? ` (+${diff})` : ''}
    </span>
  );
};
