import { axiosClient } from 'api/axios-client';
import type { NetworkError } from 'app/types';
import type {
  LoginForm,
  LoginPayload,
  LoginResponse,
  LoginResponseRaw
} from './types';

export const login = async ({
  email,
  password,
  sessionKey
}: LoginForm): Promise<LoginResponse> => {
  const payload: LoginPayload = {
    user_email: email,
    password: password,
    session_key: sessionKey
  };

  try {
    const response = await axiosClient
      .post<{ value: LoginResponseRaw }>('/api/1.0/auth/login', payload)
      .then(raw => {
        axiosClient.clearCache();
        const { auth_token, session_key } = raw.data.value;
        const response: LoginResponse = {
          authToken: auth_token,
          sessionKey: session_key
        };
        return response;
      });
    return response;
  } catch (_e) {
    const error = _e as NetworkError;
    return Promise.reject({
      type: 'intl',
      key: error.key,
      message: error.key === 'REQUEST_FAILURE' ? 'login-error' : 'server-error'
    });
  }
};
