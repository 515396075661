import React from 'react';
import { BATTERY_COLORS, BATTERY_ICONS } from 'consts/sensor';
import { Icon } from 'semantic-ui-react';

const Battery = ({ data }) => {
  if (!data.battery?.status) return <span>-</span>;
  return (
    <Icon
      name={BATTERY_ICONS[data.battery.status]}
      style={{ color: BATTERY_COLORS[data.battery.status] }}
    />
  );
};

export default Battery;
