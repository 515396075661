import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import NewPatientModal from 'fragments/icomp-patient/new-patient-modal';
import { Button, Header, Icon, Input, Message } from 'semantic-ui-react';
import Box from 'components/box';
import ErrorMessage from 'components/error-message';
import Table from '../table';
import styles from './styles.module.css';

const ICompPatientDashboardSearchFragment = ({
  input,
  onChange,
  // ---
  searching,
  error,
  data,
  // ---
  onSearch,
  onClear,
  // ---
  onAdd
}) => {
  const t = useTranslate('icomp-patient');

  return (
    <Box my="3rem">
      <Header as="h3">{t('find-patient')}</Header>
      <div className={styles.row}>
        <div className={styles.left}>
          <form onSubmit={onSearch}>
            <Input
              action
              placeholder={t('input-cpr')}
              pattern="^\d{6}-\d{4}$"
              title={t('input-cpr-format')}
              data-cy="find-patient__cpr-input"
              value={input}
              onChange={onChange}
              required
            >
              <input />
              <Button
                icon="search"
                color="blue"
                loading={searching}
                type="submit"
                data-cy="find-patient__submit-button"
              />
            </Input>
          </form>

          {data && (
            <Button
              icon
              labelPosition="right"
              data-cy="find-patient__clear-button"
              onClick={onClear}
            >
              {t('common:clear')}
              <Icon name="remove" />
            </Button>
          )}
        </div>

        {(!data || data.length === 0) && (
          <NewPatientModal defaultCpr={data ? input : ''} onSubmit={onAdd} />
        )}
      </div>

      <ErrorMessage error={error} />

      {data && data.length > 0 && (
        <Table fetching={false} error={null} data={data} searchMode />
      )}

      {data && data.length === 0 && (
        <Message data-cy="find-patient__patient-not-found-message">
          <p>{t('patient-not-found')}</p>
        </Message>
      )}
    </Box>
  );
};

ICompPatientDashboardSearchFragment.propTypes = {
  input: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // ---
  searching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array,
  // ---
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  // ---
  onAdd: PropTypes.func.isRequired
};

export default ICompPatientDashboardSearchFragment;
