import { Headers, Sensors, Status, Time, Trigger } from '../elements';

export const columns = [
  {
    name: 'time',
    header: Headers.time,
    cell: Time
  },
  {
    name: 'trigger',
    header: Headers.trigger,
    cell: Trigger
  },
  {
    name: 'sensors',
    header: Headers.sensors,
    cell: Sensors
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status
  }
];
