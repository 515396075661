import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';
import { Button } from 'semantic-ui-react';
import { deleteUser } from 'api/icomp-users';

const ICompAdminUserProfileDelete = ({ user }) => {
  const t = useTranslate('icomp-admin-user');

  const navigate = useNavigate();

  const { hospitalId, departmentId, userId } = useParams();
  const scopeId = departmentId || hospitalId;

  const [deleting, setDeleting] = useState(false);
  const [open, toggleOpen] = useToggle(false);

  const onDelete = () => {
    setDeleting(true);
    return deleteUser({ scopeId, userId })
      .catch(() => {})
      .then(() => {
        navigate(routes.iCompAdminUserOverview(hospitalId));
      });
  };

  return (
    <div>
      <Button
        primary
        content={t('common:delete')}
        icon="trash"
        labelPosition="left"
        floated="right"
        onClick={toggleOpen}
      />

      <Modal
        size="tiny"
        open={open}
        onClose={toggleOpen}
        title={
          <div style={{ textAlign: 'center' }}>
            <div>{t('delete-confirmation')}</div>
            <div>{user.name}</div>
          </div>
        }
        footer={
          <ButtonGroup>
            <ButtonCancel onClick={toggleOpen} />
            <ButtonOk loading={deleting} onClick={onDelete}>
              {t('common:delete')}
            </ButtonOk>
          </ButtonGroup>
        }
      />
    </div>
  );
};

export default ICompAdminUserProfileDelete;
