import React, { useRef } from 'react';
import { useTranslate } from 'intl';
import { Form, Modal } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import SubjectForm from '../subject-form';
import { useAddSubject } from './use-add-subject';

const SubjectAddModal = ({ onClose, onSuccess }) => {
  const t = useTranslate('nfa-subjects');
  const countRef = useRef(0); // Tricky to reset phone input
  const submitTypeRef = useRef(null);
  const { form, onChanges, validations, onSubmit, submitting, error } =
    useAddSubject({
      countRef,
      submitTypeRef,
      onClose,
      onSuccess
    });

  return (
    <Modal open size="tiny">
      <Modal.Header>{t('add-new-subject')}</Modal.Header>
      <Modal.Content>
        <Form key={countRef.current} error={!!error} onSubmit={onSubmit}>
          <SubjectForm
            mode="add"
            form={form}
            onChanges={onChanges}
            validations={validations}
          />

          {error && <ErrorMessage error={error} />}

          <Form.Group style={{ marginTop: '3rem' }}>
            <Form.Button
              type="button"
              disabled={submitting}
              onClick={onClose}
              style={{ margin: 0 }}
            >
              {t('common:cancel')}
            </Form.Button>
            <Form.Button
              type="submit"
              color="blue"
              loading={submitTypeRef.current === 1 && submitting}
              disabled={submitTypeRef.current !== 1 && submitting}
              style={{ margin: 0 }}
              onClick={() => {
                submitTypeRef.current = 1;
              }}
            >
              {t('common:submit')}
            </Form.Button>
            <Form.Button
              type="submit"
              color="blue"
              loading={submitTypeRef.current === 2 && submitting}
              disabled={submitTypeRef.current !== 2 && submitting}
              style={{ margin: 0 }}
              onClick={() => {
                submitTypeRef.current = 2;
              }}
            >
              {t('submit-and-add-new')}
            </Form.Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default SubjectAddModal;
