import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslate } from 'intl';
import { Checkbox, Dropdown, List } from 'semantic-ui-react';
import DatePicker from 'components/date-picker';
import TextField from 'components/text-field';
import Field from '../field';
import styles from './styles.module.css';

const PSelect = ({
  track,
  setTrack,
  trackOptions,
  measurement,
  measurementData,
  setMeasurement,
  measurementOptions,
  streams,
  toggleStream,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateRange
}) => {
  const t = useTranslate('icomp-patient');

  const startDateMin = dateRange.min;
  const startDateMax = endDate || dateRange.max;

  const endDateMin = startDate ? moment(startDate).format() : dateRange.min;
  const endDateMax = dateRange.max;

  const { sensor = {}, meta = {} } = measurementData;

  return (
    <div>
      <div className={styles.group}>
        <Field label={t('select-track')}>
          <Dropdown
            data-cy="patient-details__track-dropdown"
            selection
            fluid
            value={track}
            options={trackOptions}
            onChange={(event, { value }) => {
              setTrack(value);
            }}
          />
        </Field>
        <Field label={t('select-treatment')}>
          <Dropdown
            data-cy="patient-details__treatment-dropdown"
            selection
            fluid
            value={measurement}
            options={measurementOptions}
            onChange={(event, { value }) => {
              setMeasurement(value);
            }}
          />
        </Field>
        <Field label={t('bandage')}>
          <TextField data-cy="patient-details__bandage-data">
            {meta.bandage ? t(meta.bandage) : ''}
          </TextField>
        </Field>
        <Field label={t('diagnose')}>
          <TextField data-cy="patient-details__diagnose-data">
            {meta.diagnose ? t(meta.diagnose) : meta.diagnose}
          </TextField>
        </Field>
        <Field label={t('circumsphere')}>
          <TextField data-cy="patient-details__circumsphere-data">
            {meta.circumsphere ? `${meta.circumsphere} cm` : ''}
          </TextField>
        </Field>
        <Field label={t('side')}>
          <TextField data-cy="patient-details__side-data">
            {meta.side ? t(`side-${meta.side}`) : ''}
          </TextField>
        </Field>
      </div>

      <div className={styles.group}>
        <div className={styles.two}>
          <Field inline label={t('display-plots')}>
            <List>
              {Object.keys(streams).map(s => (
                <List.Item key={s}>
                  <Checkbox
                    data-cy={`patients-details__plot-checkbox-${s}`}
                    label={t(`plot-${s}`)}
                    checked={streams[s]}
                    onChange={toggleStream.bind(null, s)}
                  />
                </List.Item>
              ))}
            </List>
          </Field>
        </div>

        <div className={styles.two}>
          <Field label={t('start-date')}>
            <DatePicker
              name="Start Date"
              data-cy="patient-details__start-date-picker"
              minDate={startDateMin}
              maxDate={startDateMax}
              value={startDate}
              onChange={(event, { value }) => {
                setStartDate(value);
              }}
            />
          </Field>

          <Field label={t('end-date')}>
            <DatePicker
              name="End Date"
              data-cy="patient-details__end-date-picker"
              minDate={endDateMin}
              maxDate={endDateMax}
              value={endDate}
              onChange={(event, { value }) => {
                setEndDate(value);
              }}
            />
          </Field>
        </div>

        <div className={`${styles.two} ${styles.overflowHidden}`}>
          <Field label={t('active-sensor')}>
            <div className={styles['active-sensor']}>
              <TextField>{sensor.name ? sensor.name : 'N/A'}</TextField>
            </div>
          </Field>
          <Field label={t('note')}>
            <TextField>
              <span className={styles.pre} data-cy="patient-details__note-data">
                {meta.note}
              </span>
            </TextField>
          </Field>
        </div>
      </div>
    </div>
  );
};

PSelect.propTypes = {
  track: PropTypes.string.isRequired,
  setTrack: PropTypes.func.isRequired,
  trackOptions: PropTypes.array.isRequired,
  // ---
  measurement: PropTypes.string.isRequired,
  measurementData: PropTypes.object.isRequired,
  setMeasurement: PropTypes.func.isRequired,
  measurementOptions: PropTypes.array.isRequired,
  // ---
  streams: PropTypes.object.isRequired,
  toggleStream: PropTypes.func.isRequired,
  // ---
  startDate: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  // ---
  endDate: PropTypes.string,
  setEndDate: PropTypes.func.isRequired,
  // ---
  dateRange: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string
  }).isRequired
};

export default PSelect;
