import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import { normalizeExportQueueEntry, type ExportQueueEntry } from 'api/export';
import type { RouteParams } from 'app/types';

type Params = Pick<
  RouteParams,
  'organizationId' | 'projectId' | 'patientId'
> & {
  interval: '1h' | '15m';
};

type ExportAction = 'modal' | 'url';

const exportIntervals = {
  '1h': 'summary1h',
  '15m': 'summary15m',
  '5s': 'detailed'
};
export const exportMeasurement = async (
  { organizationId, projectId, patientId, interval }: Params,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: exportIntervals[interval],
      file_format: 'csv'
    };
    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .get(`/api/1.0/export/measurement`, { params })
        .then(response => response.data);
    return normalizeExportQueueEntry(response.value.queue_entry);
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: exportIntervals[interval],
      dt: downloadToken
    };

    const exportUrl = axiosClient.getUri({
      url: '/export/measurement',
      params
    });
    return exportUrl;
  }
};
