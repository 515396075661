import React from 'react';
import { useTranslate } from 'intl';
import styles from './styles.module.scss';

export const PatientName = ({ data }) => {
  const t = useTranslate('patient');
  return (
    <div className={styles.patient}>
      <p className={styles.first}>{data.firstName}</p>
      <p className={styles.last}>{data.lastName}</p>
      {data.phoneNumber && (
        <p className={styles.phone}>
          {t('phone-number')}
          {`: `}
          {data.phoneNumber}
        </p>
      )}
    </div>
  );
};
