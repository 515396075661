import { fetchUsers } from './fetch-users';

export const fetchUser = async ({
  scopeId,
  userId
}: {
  scopeId: string;
  userId: string;
}) => {
  return fetchUsers({ scopeId }).then(users =>
    users.find(u => u.id === userId)
  );
};
