import { useState } from 'react';
import moment from 'moment';

/*
  If Activity data
    Default `EndTime` is current selected day.
    Default `StartTime` is 7 days before `EndTime`.
    Limit 31 days export.
  Other
    Default `StartTime` is current selected date time.
    Default `EndTime` is current selected date time +1h.
    Max interval is 30 hours
*/

const useDatesRaw = (date, stream) => {
  const [streamType] = stream.split(':');
  const isActivityData = streamType === 'derived';
  const limit = isActivityData
    ? { value: 31, unit: 'day' }
    : { value: 30, unit: 'hour' };

  const [startDate, setStartDate] = useState(() => {
    if (isActivityData) {
      return moment(date).startOf('day').add(-7, 'days').format();
    } else {
      return moment(date).format();
    }
  });
  const [endDate, setEndDate] = useState(() => {
    if (isActivityData) {
      return moment(date).startOf('day').format();
    } else {
      return moment(date).add(1, 'hours').format();
    }
  });

  const onChangeStartDate = (event, { value }) => {
    const startDateObj = moment(value);
    const endDateObj = moment(endDate);
    setStartDate(startDateObj.format());
    const diff = endDateObj.diff(startDateObj, limit.unit);
    if (diff > limit.value || diff < 1) {
      setEndDate(moment(startDateObj).add(limit.value, limit.unit).format());
    }
  };

  const onChangeEndDate = (event, { value }) => {
    const startDateObj = moment(startDate);
    const endDateObj = moment(value);
    const diff = endDateObj.diff(startDateObj, limit.unit);
    if (diff > limit || diff < 1) {
      setStartDate(moment(endDateObj).add(-limit.value, limit.unit).format());
    }
    setEndDate(endDateObj.format());
  };

  const minDate = moment(startDate).add(1, limit.unit);
  const maxDate = moment(startDate).add(limit.value, limit.unit);

  return {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    limit,
    minDate,
    maxDate
  };
};

export default useDatesRaw;
