import React from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Form, TextArea, Dropdown, Button } from 'semantic-ui-react';
import { sendNotification } from 'api/notifications';
import ErrorMessage from 'components/error-message';
import styles from './styles.module.css';

const LIMIT = 256;

const SendNotificationForm = ({ subject, onSuccess }) => {
  const t = useTranslate('nfa-subjects');
  const params = useParams();

  const { form, onChanges, onSubmit, submitting, error } = useForm(
    {
      initialForm: { title: '', message: '' },
      apiSubmit: form =>
        sendNotification({
          ...params,
          subjectId: subject.id,
          content: form.message
        }),
      onSuccess: newLog => {
        onSuccess(newLog);
        onChanges.title('');
        onChanges.message('');
      }
    },
    [onSuccess]
  );

  const options = [
    {
      value: 'option-1',
      title: t('reminder-1-title'),
      message: t('reminder-1-message')
    },
    {
      value: 'option-2',
      title: t('reminder-2-title'),
      message: t('reminder-2-message')
    },
    {
      value: 'option-3',
      title: t('reminder-3-title'),
      message: t('reminder-3-message')
    },
    {
      value: 'option-4',
      title: t('reminder-4-title'),
      message: t('reminder-4-message')
    },
    {
      value: 'option-5',
      title: t('reminder-5-title'),
      message: t('reminder-5-message')
    },
    {
      value: 'option-6',
      title: t('reminder-6-title'),
      message: t('reminder-6-message')
    },
    {
      value: 'option-7',
      title: t('reminder-7-title'),
      message: t('reminder-7-message')
    },
    {
      value: 'option-8',
      title: t('reminder-8-title'),
      message: t('reminder-8-message')
    },
    {
      value: 'option-9',
      title: t('reminder-9-title'),
      message: t('reminder-9-message')
    },
    {
      value: 'option-10',
      title: t('reminder-10-title'),
      message: t('reminder-10-message')
    },
    {
      value: 'option-11',
      title: t('reminder-11-title'),
      message: t('reminder-11-message')
    }
  ];

  return (
    <Form style={{ marginTop: '2rem' }} error={!!error} onSubmit={onSubmit}>
      <Form.Field>
        <label>{t('new-reminder')}</label>
        <Dropdown
          selection
          fluid
          placeholder={t('select-subject')}
          value={form.title}
          onChange={(event, { value }) => {
            onChanges.title(value);
            onChanges.message(
              options.find(item => item.value === value)?.message
            );
          }}
          options={options.map(item => ({
            value: item.value,
            text: item.title
          }))}
        />
      </Form.Field>

      <TextArea
        rows={5}
        style={{ maxHeight: '20rem' }}
        value={form.message}
        onChange={(event, { value }) => {
          const message = value.length <= LIMIT ? value : value.slice(0, LIMIT);
          onChanges.message(message);
        }}
      />

      <p
        className={clsx(
          styles.counter,
          form.message.length >= LIMIT && styles.exceed
        )}
      >
        <span>{t('max-length-message')}</span>
        <span>
          {form.message.length}/{LIMIT}
        </span>
      </p>

      {error && (
        <div style={{ marginTop: '1rem' }}>
          <ErrorMessage error={error} />
        </div>
      )}

      <div style={{ marginTop: '2rem', textAlign: 'right' }}>
        <Button
          primary
          type="submit"
          loading={submitting}
          disabled={!form.message}
        >
          {t('send-sms')}
        </Button>
      </div>
    </Form>
  );
};

export default SendNotificationForm;
