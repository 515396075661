import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchSensors } from 'api/simple-sensors';
import Fragment from './fragment';

const SimpleSensorDashboard = () => {
  const { organizationId, projectId } = useParams();

  const {
    fetching,
    data = [],
    error
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId }),
    isJsonSource: true,
    apiFetch: fetchSensors,
    refresh: 20000
  });

  return <Fragment fetching={fetching} data={data} error={error} />;
};

export default SimpleSensorDashboard;
