import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';

const ICompButtonCancel = ({ 'data-cy': dataCy, children, onClick }) => {
  const t = useTranslate('common');

  return (
    <Button
      type="button"
      style={{ width: '7em' }}
      data-cy={dataCy}
      onClick={onClick}
    >
      {children || t('cancel')}
    </Button>
  );
};

ICompButtonCancel.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default ICompButtonCancel;
