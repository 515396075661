import { fetchScopeProjects, fetchScopes } from 'api/scopes';
import type { ScopeRaw } from 'api/scopes';
import { nOrganization } from './normalize';
import type { Organization, OrganizationRaw } from './types';

const CACHE: Record<string, Organization> = {};

export const fetchOrganization = async (organizationId: string) => {
  if (CACHE[organizationId]) return CACHE[organizationId]!;

  const scopes = await fetchScopes();
  const scope = scopes.find(s => s.id === organizationId) as ScopeRaw;
  const scope_id = scopes.map(o => o.id).join(',');
  const projects = await fetchScopeProjects(scope_id);

  const raw: OrganizationRaw = {
    ...scope,
    projects: projects.filter(p => p.parent_id === organizationId)
  };

  const organization = nOrganization(raw);
  CACHE[organizationId] = organization;

  return organization;
};
