import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  name: <Translate ns="admin" i18nKey="gateway.name" />,
  lastOnline: <Translate ns="admin" i18nKey="gateway.last-online" />,
  nearbySensor: <Translate ns="admin" i18nKey="gateway.nearby-sensor" />,
  powerState: <Translate ns="admin" i18nKey="gateway.power" />,
  backgroundState: <Translate ns="admin" i18nKey="gateway.background" />,
  status: <Translate ns="admin" i18nKey="gateway.status" />
};
