import React from 'react';
import {
  Headers,
  LastOnline,
  LastSynced,
  Name,
  PatientAttached,
  Runtime,
  Status,
  sorters
} from 'fragments/sensor';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: ({ data }) => <Name fluid data={data} />,
    sortable: true,
    sorter: sorters.name
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status,
    sortable: true,
    sorter: sorters.status
  },
  {
    name: 'patientAttached',
    header: Headers.patientAttached,
    cell: PatientAttached,
    sortable: true,
    sorter: sorters.patientAttached
  },
  {
    name: 'lastSynced',
    header: Headers.lastSynced,
    cell: LastSynced,
    sortable: true,
    sorter: sorters.lastSynced
  },
  {
    name: 'lastOnline',
    header: Headers.lastOnline,
    cell: LastOnline,
    sortable: true,
    sorter: sorters.lastOnline
  },
  {
    name: 'runtime',
    header: Headers.runtime,
    cell: Runtime,
    align: 'right',
    sortable: true,
    sorter: sorters.runtime
  }
];
