import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import Menu from 'fragments/menu';
import { Container } from 'semantic-ui-react';
import styles from '../styles.module.scss';
import DepartmentSelect from './department-select';
import Navbar from './navbar';

const ICompLayout = ({ children }) => (
  <div>
    <Menu
      homeUrl={routes.iCompDepartments()}
      appSelect={<DepartmentSelect />}
      navbar={<Navbar />}
    />
    <Container className={styles.container}>{children}</Container>
  </div>
);

ICompLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default ICompLayout;
