import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { clearCacheSensors, fetchSensors } from './fetch-sensors';
import { nSensor } from './normalize';
import type { Sensor } from './types';

export const controlSensor = async ({
  params: { organizationId, projectId },
  sensor: _sensor
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  sensor: Sensor;
}) => {
  const payload = {
    org_id: organizationId,
    project_id: projectId,
    sensor_id: _sensor.id,
    command: _sensor.runStateRequested === 0 ? 'activate' : 'deactivate'
  };
  const { sensor } = await axiosClient
    .post('/api/1.0/sensor/control', payload)
    .then(response => response.data.value);

  return nSensor(sensor);
};

export const controlSensors = async ({
  params: { organizationId, projectId },
  sensors
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  sensors: Sensor[];
}) => {
  return Promise.all(
    sensors.map(sensor => {
      const payload = {
        org_id: organizationId,
        project_id: projectId,
        sensor_id: sensor.id,
        command: sensor.runStateRequested === 0 ? 'activate' : 'deactivate'
      };
      return axiosClient.post('/api/1.0/sensor/control', payload);
    })
  ).then(() => {
    clearCacheSensors({ organizationId, projectId });
    return fetchSensors({ organizationId, projectId });
  });
};
