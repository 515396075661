import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'components/time-ago';

const LastOnline = ({ data }) => <TimeAgo value={data.lastOnline} ago />;

LastOnline.propTypes = {
  data: PropTypes.shape({
    lastOnline: PropTypes.string.isRequired
  }).isRequired
};

export default LastOnline;
