import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Modal } from 'semantic-ui-react';
import PatientForm from '../patient-form';

const PatientEditModal = ({ data, onSubmit, onCancel }) => {
  const t = useTranslate('hospital-patient');

  return (
    <Modal open size="small">
      <Modal.Header>{t('edit-patient')}</Modal.Header>
      <Modal.Content>
        <PatientForm data={data} onSubmit={onSubmit} onCancel={onCancel} />
      </Modal.Content>
    </Modal>
  );
};

PatientEditModal.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default PatientEditModal;
