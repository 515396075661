import { axiosClient } from 'api/axios-client';
import type { ICompDepartment } from 'api/icomp-departments';
import type { ProjectRole } from 'api/projects';
import type { ICompUser } from './types';

export const setDepartmentAndRoles = async ({
  user,
  department,
  roles
}: {
  user: ICompUser;
  department: ICompDepartment;
  roles: ProjectRole[];
}) => {
  const payload = {
    scope_id: department,
    user_id: user.id,
    roles: roles
  };
  return axiosClient.post('/api/1.0/access_scopes/members/edit_roles', payload);
};
