import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'components/time-ago';

const LastSeen = ({ data }) => {
  return <TimeAgo value={data.lastSeen} ago />;
};

LastSeen.propTypes = {
  data: PropTypes.shape({
    lastSeen: PropTypes.string
  }).isRequired
};

export default LastSeen;
