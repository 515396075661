import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Loader } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import PlotVictory, { Chart as EmptyChart } from 'components/plot/victory';
import { getSensorParams } from 'utils/vendor';
import styles from './styles.module.css';

const PlotFetcher = ({ type, apiFetch, date, stream, onSelectTick }) => {
  const t = useTranslate('common');
  const params = useParams();

  const formattedDate =
    type === 'day' ? moment(date).startOf('day').format() : date;

  const source = JSON.stringify({
    ...params,
    ...getSensorParams(params),
    stream: stream,
    date: formattedDate
  });

  const { fetching, data, error, refetch } = useFetch({
    source,
    isJsonSource: true,
    apiFetch: apiFetch
  });

  // Show fetching indicator
  if (fetching) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.hidden}>
          <EmptyChart />
        </div>
        <div className={styles.content}>
          <Loader active>{t('fetching')}</Loader>
        </div>
      </div>
    );
  }

  if (error?.statusCode === 10) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.hidden}>
          <EmptyChart />
        </div>
        <div className={`${styles.content} ${styles.center}`}>
          <p>{t('retry-message')}</p>
          <div>
            <Button primary onClick={refetch}>
              {t('retry-btn')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  // Handle other errors
  if (error) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.hidden}>
          <EmptyChart />
        </div>
        <div className={styles.content}>
          <ErrorMessage error={error} />
        </div>
      </div>
    );
  }

  // Fetch data success -> render content
  return <PlotVictory {...data} onSelectTick={onSelectTick} />;
};

PlotFetcher.propTypes = {
  type: PropTypes.oneOf(['day', 'hour']).isRequired,
  apiFetch: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired,
  // ---
  onSelectTick: PropTypes.func
};

export default PlotFetcher;
