import React, { useState } from 'react';
import moment from 'moment';
import { useForm, usePrevious } from 'hooks';
import { useTranslate } from 'intl';
import { Form } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import { useAlgorithms } from '../algorithms-context';
import ConfirmationModal from './confirmation-modal';
import MonitorPeriod from './monitor-period';
import PatientInfo from './patient-info';
import SensorInfo from './sensor-info';
import { migrateForm, validateForm } from './utils';

const PatientForm = ({ patient, onSubmit: handleSubmit, onCancel }) => {
  const t = useTranslate('patient');
  const algorithms = useAlgorithms();
  const editMode = !!patient;
  const [openConfirm, setOpenConfirm] = useState(false);

  const initialForm = migrateForm(patient, algorithms);
  const { form, onChanges, validations, onSubmit, submitting, error } = useForm(
    {
      initialForm,
      validates: validateForm(t, algorithms),
      apiSubmit: form => {
        return handleSubmit(form, patient).then(onCancel);
      }
    }
  );

  const onPreSubmit = () => {
    // If the user edits a patient and changes the 'start time' to 'Not Defined Yet',
    // the user must be aware and agree with the action of deleting the current value of 'end time' as well.
    if (
      editMode &&
      initialForm.startTimeType !== 'not-defined-yet' &&
      form.startTimeType === 'not-defined-yet'
    ) {
      setOpenConfirm(true);
    } else {
      onSubmit();
    }
  };

  // If the user sets the value of the 'start time' field to 'Not Defined yet'
  // the 'end time' field must be changed to 'Not Defined yet' and deactivated.
  const prevForm = usePrevious(form);
  if (
    form.startTimeType !== prevForm.startTimeType &&
    form.startTimeType === 'not-defined-yet' &&
    form.endTimeType !== 'not-defined-yet'
  ) {
    onChanges.endTimeType('not-defined-yet');
  }

  // If the user sets the value of 'start time' after 'end time', auto reset the 'end time'
  if (
    form.startTimeValue !== prevForm.startTimeValue &&
    form.endTimeValue &&
    moment(form.endTimeValue).isBefore(moment(form.startTimeValue))
  ) {
    onChanges.endTimeValue('');
  }

  const onDisConfirm = () => {
    onChanges.startTimeType(initialForm.startTimeType);
    onChanges.startTimeValue(initialForm.startTimeValue);
    onChanges.endTimeType(initialForm.endTimeType);
    onChanges.endTimeValue(initialForm.endTimeValue);
    setOpenConfirm(false);
  };

  return (
    <>
      <Form onSubmit={onPreSubmit} error={error || !!validations}>
        <PatientInfo
          form={form}
          onChanges={onChanges}
          validations={validations}
        />

        <SensorInfo
          form={form}
          onChanges={onChanges}
          algorithms={algorithms}
          validations={validations}
        />

        <MonitorPeriod
          form={form}
          onChanges={onChanges}
          validations={validations}
        />

        {error && (
          <div style={{ marginTop: '2rem' }}>
            <ErrorMessage error={error} />
          </div>
        )}

        <Form.Group style={{ marginTop: '2rem' }}>
          <Form.Button
            type="button"
            onClick={onCancel}
            content={t('common:cancel')}
            style={{ margin: 0 }}
          />
          <Form.Button
            type="submit"
            color="blue"
            loading={submitting}
            content={t('common:submit')}
          />
        </Form.Group>
      </Form>

      <ConfirmationModal
        open={openConfirm}
        onCancel={onDisConfirm}
        onConfirm={onSubmit}
      />
    </>
  );
};

export default PatientForm;
