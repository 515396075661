import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';
import Table from 'components/table';
import { columns } from './configs';

const ICompDepartmentTable = ({ fetching, error, hospital, departments }) => {
  const navigate = useNavigate();

  const onRowClick = useCallback(
    department => {
      const path = routes.iCompPatients(
        hospital.id,
        department.id,
        department.project.id
      );
      navigate(path);
    },
    [hospital, navigate]
  );

  return (
    <Table
      data-cy="departments__list"
      fetching={fetching}
      data={departments}
      error={error}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

ICompDepartmentTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  hospital: PropTypes.object,
  departments: PropTypes.array
};

export default ICompDepartmentTable;
