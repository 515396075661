import {
  Duration,
  EndTime,
  Headers,
  MeasurementMethod,
  Patient,
  SensorName,
  StartTime
} from '../elements';

export const columns = [
  {
    name: 'patient',
    header: Headers.patient,
    cell: Patient
  },
  {
    name: 'start-time',
    header: Headers.startTime,
    cell: StartTime
  },
  {
    name: 'end-time',
    header: Headers.endTime,
    cell: EndTime
  },
  {
    name: 'duration',
    header: Headers.duration,
    cell: Duration
  },
  {
    name: 'sensor-name',
    header: Headers.sensorName,
    cell: SensorName
  },
  {
    name: 'measurement-method',
    header: Headers.measurementMethod,
    cell: MeasurementMethod
  }
];

const sensorCells = ['sensor-name'];
export const isSensorCell = cellName => sensorCells.includes(cellName);
