import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fragment from './fragment';

class ISpinePatientDashboardDelete extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({ submitting: true });
    this.props.onSubmit(this.props.data).catch(error => {
      this.setState({ submitting: false, error });
    });
  }

  render() {
    return (
      <Fragment
        {...this.state}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onCancel={this.props.onCancel}
      />
    );
  }
}

ISpinePatientDashboardDelete.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ISpinePatientDashboardDelete;
