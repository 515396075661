import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import Dash from 'components/dash';
import { getDuration } from './utils';

const UNITS = ['year', 'month', 'day', 'hour', 'minute', 'second'];

const Duration = ({ from, to, level }) => {
  const t = useTranslate('duration');

  if (!from) {
    return <Dash />;
  }

  const duration = getDuration(from, to);

  if (!duration.year && duration.month && duration.day) {
    const month = t('month', { count: duration.month });
    const day = t('day', { count: duration.day });
    return <span>{`${month}, ${day}`}</span>;
  }

  if (
    level === 2 &&
    !duration.year &&
    !duration.month &&
    !duration.day &&
    duration.hour &&
    duration.minute
  ) {
    const hour = t('hour', { count: duration.hour });
    const minute = t('minute', { count: duration.minute });
    return <span>{`${hour}, ${minute}`}</span>;
  }

  const unit = UNITS.find(item => duration[item]);

  if (!unit) {
    return <span>{t('second', { count: 0 })}</span>;
  }

  return <span>{t(unit, { count: duration[unit] })}</span>;
};

Duration.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  level: PropTypes.oneOf([1, 2])
};

Duration.defaultProps = {
  level: 1
};

export default React.memo(Duration);
