import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  name: <Translate ns="admin" i18nKey="nearby-sensor.name" />,
  status: <Translate ns="admin" i18nKey="nearby-sensor.status" />,
  delivery: <Translate ns="admin" i18nKey="nearby-sensor.delivery" />,
  lastSynced: <Translate ns="admin" i18nKey="nearby-sensor.last-synced" />,
  lastOnline: <Translate ns="admin" i18nKey="nearby-sensor.last-online" />,
  runtime: <Translate ns="admin" i18nKey="nearby-sensor.runtime" />,
  control: <Translate ns="admin" i18nKey="nearby-sensor.control" />
};
