import { createSorter } from 'utils/sort';

const orders = {
  'generation-date': data => data.queue_time,
  'due-date': data => data.expire_time
};

const sorters = {
  'generation-date': createSorter(orders['generation-date']),
  'due-date': createSorter(orders['due-date'])
};

export const filterData = (data, sort, descOrder) => {
  const sorter = sorters[sort];
  let result1 = data.filter(item => orders[sort](item));
  const result2 = data.filter(item => !orders[sort](item));
  result1 = result1.sort(sorter);
  if (descOrder) {
    result1.reverse();
  }
  const result = [...result1, ...result2];
  return result;
};
