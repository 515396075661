import React from 'react';
import { useTranslate } from 'intl';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Header, Input, Radio } from 'semantic-ui-react';
import styles from './styles.module.scss';

const SORT_VALUES = [
  'create-date',
  'sensor-name',
  'patient',
  'start-time',
  'end-time',
  'duration',
  'measurement-method'
];

const BrowseDataHeader = ({
  sort,
  onChangeSort,
  descOrder,
  toggleDescOrder,
  search,
  onChangeSearch,
  setOpenDataExport
}) => {
  const t = useTranslate('browse-data');

  return (
    <>
      <Header as="h2">{t('header')}</Header>

      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.export}>
            <Button icon primary onClick={() => setOpenDataExport(v => !v)}>
              <FontAwesomeIcon icon={faFileExport} />
              {t('data-export')}
            </Button>
          </div>
          <div className={styles.dropdown}>
            <label>{`${t('common:sort-by')}: `}</label>
            <Dropdown
              selection
              options={SORT_VALUES.map(item => ({
                key: item,
                value: item,
                text: t(`sort-by/${item}`)
              }))}
              value={sort}
              onChange={(e, { value }) => onChangeSort(value)}
            />
          </div>
          <div className={styles.toggle}>
            <Radio toggle checked={descOrder} onChange={toggleDescOrder} />
            <label>
              {descOrder ? t('common:order/desc') : t('common:order/asc')}
            </label>
          </div>
        </div>

        <Input
          value={search}
          onChange={(e, { value }) => onChangeSearch(value)}
          placeholder={t('common:search-by-name')}
        />
      </div>
    </>
  );
};

export default BrowseDataHeader;
