import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'components/table';
import { columns } from './configs';

const ProjectDashboardTable = props => {
  const params = useParams();
  const navigate = useNavigate();

  const onRowClick = useCallback(
    project => {
      const { organizationId } = params;
      const view = project.views[0];
      const path = view.route(organizationId, project.id);

      navigate(path);
    },
    [params, navigate]
  );

  return <Table {...props} columns={columns} onRowClick={onRowClick} />;
};

ProjectDashboardTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default ProjectDashboardTable;
