import React from 'react';
import { useFetch } from 'hooks';
import { Container } from 'semantic-ui-react';
import { fetchMonitorPatients } from 'api/monitor';
import Empty from './empty';
import Header from './header';
import Loader from './loader';
import styles from './styles.module.css';
import Table from './table';

const MonitorFetcher = ({ mToken }) => {
  const { fetching, data, error } = useFetch({
    source: mToken,
    apiFetch: fetchMonitorPatients,
    refresh: 30000
  });

  if (fetching) return <Loader />;

  if (!data.patients) return <Empty deviceId={data.deviceId} />;

  return (
    <Container>
      <Header
        projectName={data.projectName}
        deviceId={data.deviceId}
        lastUpdate={data.lastUpdate}
      />
      <div className={styles.body}>
        <Table fetching={fetching} data={data.patients} error={error} />
      </div>
    </Container>
  );
};

export default MonitorFetcher;
