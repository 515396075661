import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const TextField = ({ 'data-cy': dataCy, children }) => (
  <div className={styles.main} data-cy={dataCy}>
    {children}
  </div>
);

TextField.propTypes = {
  children: PropTypes.node
};

export default TextField;
