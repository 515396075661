import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/table';
import TableBody from './body';
import { columns } from './configs';

const BrowseDataTable = ({
  fetching,
  error,
  data,
  selectable,
  selectedData,
  onSelect
}) => {
  return (
    <Table
      fetching={fetching}
      error={error}
      data={data}
      columns={columns}
      CustomBody={TableBody}
      selectable={selectable}
      selectedData={selectedData}
      onSelect={onSelect}
    />
  );
};

BrowseDataTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array.isRequired,
  selectable: PropTypes.bool.isRequired,
  selectedData: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default BrowseDataTable;
