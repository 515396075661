import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nNotificationLog } from './normalize';
import type { NotificationLog, NotificationLogRaw } from './types';

interface Params extends Pick<RouteParams, 'organizationId' | 'projectId'> {
  subjectId: string;
  content: string;
}

export const sendNotification = async ({
  organizationId,
  projectId,
  subjectId,
  content
}: Params): Promise<NotificationLog> => {
  const response: { notification: NotificationLogRaw } = await axiosClient
    .post('/api/1.0/patients/send_notification', {
      org_id: organizationId,
      project_id: projectId,
      patient_id: subjectId,
      method: 'sms',
      content
    })
    .then(response => response.data.value);
  return nNotificationLog(response.notification);
};
