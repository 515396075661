import React from 'react';
import styles from './styles.module.css';

const Group = ({ children }) => {
  return (
    <div className={`${styles.group} ${styles.row}`}>
      {React.Children.toArray(children).map((element, index) => (
        <div key={index} className={styles.item}>
          {element}
        </div>
      ))}
    </div>
  );
};

export default Group;
