import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from 'hooks';
import { Table as SemanticTable } from 'semantic-ui-react';
import NormalBody from './body';
import NormalHeader from './header';
import PreBody from './pre-body';
import useTableReducer from './use-table-reducer';

export { SelectCell } from './body';
export { getMinMax } from './utils';

const Table = ({
  fetching: _fetching,
  data: _data,
  error,
  // ---
  columns,
  // ---
  structured,
  selectable,
  selectableData,
  selectedData,
  onSelect,
  onRowClick,
  CustomHeader,
  CustomBody,
  ...otherProps
}) => {
  const { fetching, data, sorted, direction, onUpdate, onSort } =
    useTableReducer({
      fetching: _fetching,
      data: _data,
      columns
    });

  const prevData = usePrevious(_data);
  const prevFetching = usePrevious(_fetching);

  useEffect(() => {
    if (_fetching !== prevFetching) {
      onUpdate({ fetching: _fetching });
    }
    if (_data !== prevData) {
      onUpdate({ data: _data });
    }
  }, [prevFetching, prevData, _fetching, _data, onUpdate]);

  const sortable = useMemo(
    () => !!columns.find(item => item.sortable),
    [columns]
  );

  const Header = CustomHeader || NormalHeader;
  const Body = CustomBody || NormalBody;

  return (
    <SemanticTable
      unstackable
      compact
      celled
      structured={structured}
      attached="top"
      sortable={sortable}
      {...otherProps}
    >
      <SemanticTable.Header>
        <Header
          columns={columns}
          sorted={sorted}
          direction={direction}
          onSort={onSort}
          data={data}
          selectable={selectable}
          selectableData={selectableData}
          selectedData={selectedData}
          onSelect={onSelect}
        />
      </SemanticTable.Header>
      <SemanticTable.Body>
        <PreBody
          fetching={fetching}
          error={error}
          empty={data.length === 0}
          colSpan={columns.length}
          render={() => (
            <Body
              columns={columns}
              data={data}
              onRowClick={onRowClick}
              selectable={selectable}
              selectedData={selectedData}
              onSelect={onSelect}
            />
          )}
        />
      </SemanticTable.Body>
    </SemanticTable>
  );
};

Table.propTypes = {
  fetching: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  error: PropTypes.object,
  // ---
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      header: PropTypes.node,
      cell: PropTypes.func,
      sortable: PropTypes.bool,
      sorter: PropTypes.func
    })
  ).isRequired,
  // ---
  onRowClick: PropTypes.func,
  // ---
  structured: PropTypes.bool,
  CustomHeader: PropTypes.func,
  CustomBody: PropTypes.func
};

Table.defaultProps = {
  selectedData: []
};

export default Table;
