import React from 'react';
import styles from './styles.module.css';

const Field = ({ label, children }) => {
  return (
    <div className={`${styles.field} ${styles.row}`}>
      <div className={styles.label}>
        {label}
        {`: `}
      </div>
      <div className={styles.value}>{children}</div>
    </div>
  );
};

export default Field;
