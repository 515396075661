import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'contexts';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Loader } from 'semantic-ui-react';
import { clearAuthToken } from 'api/auth-utils';
import { logout } from 'api/authenticate';

const Logout = () => {
  const t = useTranslate('logout');
  const [done, setDone] = useState(false);
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    logout()
      .catch(() => {})
      .then(() => {
        clearAuthToken();
        setUser(undefined);
        setDone(true);
      });
  }, []);

  if (done) {
    return <Navigate to={routes.login()} />;
  }

  return <Loader active>{t('logging-out')}</Loader>;
};

export default Logout;
