import { axiosClient } from 'api/axios-client';
import { nGateway } from './normalize';
import type { GatewayRaw, GatewayView } from './types';

export const fetchGateways = async (view: GatewayView) => {
  const params = {
    hours: view === 'all24h' ? 24 : 24 * 7,
    for_site: view === 'site' ? 413911 : 0
  };

  const { gateways }: { gateways: GatewayRaw[] } = await axiosClient
    .get('/gwapi/1.0/adminx/gateways', { params })
    .then(response => response.data.value);

  return gateways.map((item, index) => ({
    ...nGateway(item),
    idx: index + 1
  }));
};
