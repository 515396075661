/* eslint-disable dot-notation */
import moment from 'moment';
import { getFullName } from 'utils/patient';
import { createSearchRegex } from 'utils/search';
import { createSorter } from 'utils/sort';

const getCreateDateOrder = data => data.idx;

const getSensorNameOrder = data =>
  data.sensors?.length ? data.sensors[0].name : null;

const getPatientOrder = data => getFullName(data);

const getStartTimeOrder = data => data.startTime;

const getEndTimeOrder = data => data.endTime;

const today = moment();
const getDurationOrder = data => {
  if (!data.startTime) return 0;
  // If the start time is past the current day,
  // show the duration from the start day until the current day
  // (even if there is no end time set).
  return moment(data.startTime).isBefore(today)
    ? moment(today).diff(data.startTime)
    : moment(data.endTime).diff(moment(data.startTime));
};

const getMeasurementMethodOrder = data => data.measurementMethod;

const orders = {
  'create-date': getCreateDateOrder,
  'sensor-name': getSensorNameOrder,
  patient: getPatientOrder,
  'start-time': getStartTimeOrder,
  'end-time': getEndTimeOrder,
  duration: getDurationOrder,
  'measurement-method': getMeasurementMethodOrder
};

const sorters = {
  'create-date': createSorter(orders['create-date']),
  'sensor-name': createSorter(orders['sensor-name']),
  patient: createSorter(orders['patient']),
  'start-time': createSorter(orders['start-time']),
  'end-time': createSorter(orders['end-time']),
  duration: createSorter(orders['duration']),
  'measurement-method': createSorter(orders['measurement-method'])
};

const statusFilters = {
  'not-started-yet': ['status/measurement_ex/not_started'],
  'in-progress': [
    'status/measurement_ex/in_progress_ok',
    'status/measurement_ex/in_progress_pending',
    'status/measurement_ex/in_progress_warning',
    'status/measurement_ex/in_progress_warning_severe',
    'status/measurement_ex/in_progress_sensor_off'
  ],
  completed: [
    'status/measurement_ex/completed_ok',
    'status/measurement_ex/completed_pending'
  ]
};

const filterData = ({ data, sort, descOrder, search, filter }) => {
  let result = data.slice();

  if (search) {
    const searchRegex = createSearchRegex(search);
    result = result.filter(item =>
      `${item.firstName} ${item.lastName}`.match(searchRegex)
    );
  }

  if (sort) {
    const sorter = sorters[sort];
    let result1 = result.filter(item => orders[sort](item));
    const result2 = result.filter(item => !orders[sort](item));
    result1 = result1.sort(sorter);
    if (descOrder) {
      result1.reverse();
    }
    result = [...result1, ...result2];
  }

  if (filter.length) {
    const status = filter.reduce(
      (prev, curr) => prev.concat(...statusFilters[curr]),
      []
    );
    result = result.filter(item => status.includes(item.measurementStatusEx));
  }

  return result;
};

export default { filterData };
