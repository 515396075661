import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import TimeAgo from 'components/time-ago';

const getLastOnlineSortValue = data => {
  if (data.expired) return +Infinity;
  const syncAgo = moment.utc() - moment.utc(data.lastSynced);
  const syncing = data.lastSynced && syncAgo < 60000;
  return syncing ? +Infinity : new Date(data.lastSeen).getTime();
};

const LastOnline = ({ data }) => {
  if (data.expired) {
    return <span>N/A</span>;
  }

  const syncAgo = moment.utc() - moment.utc(data.lastSynced);
  const syncing = data.lastSynced && syncAgo < 60000;
  return syncing ? (
    <span>
      <Icon name="circle" color="green" /> Syncing Now
    </span>
  ) : (
    <TimeAgo value={data.lastSeen} ago />
  );
};

LastOnline.propTypes = {
  data: PropTypes.object.isRequired
};

export { getLastOnlineSortValue };
export default LastOnline;
