import React from 'react';
import { FormatDate } from 'intl';
import ProjectClass from './project-class';
import Status from './status';

const Name = ({ data }) => <span>{data.name}</span>;

const Created = ({ data }) => <FormatDate value={data.created} />;

export { Name, ProjectClass, Created, Status };
