import { axiosClient } from 'api/axios-client';
import type {
  ChangePasswordForm,
  ResetPasswordForm,
  VerificationCodedForm
} from './types';

export const changePassword = async ({
  currentPassword,
  newPassword,
  newPassword2
}: ChangePasswordForm) => {
  if (newPassword !== newPassword2) {
    return Promise.reject({
      type: 'intl',
      message: 'passwords-not-match'
    });
  }
  const payload = {
    old_password: currentPassword,
    new_password: newPassword
  };
  return axiosClient.post('/api/1.0/user/profile/edit_password', payload);
};

export const forgotPassword = async (email: string) =>
  axiosClient.post('/api/1.0/user/password/reset/email', { email });

export const verifyConfirmationCode = async (form: VerificationCodedForm) => {
  return new Promise(resolve => {
    const confirmationCode = `${form.code1}${form.code2}${form.code3}${form.code4}${form.code5}`;
    setTimeout(() => {
      resolve(confirmationCode);
    }, 2000);
  });
};

export const resetPassword = async ({
  form,
  token
}: {
  form: ResetPasswordForm;
  token: string;
}) => {
  if (form.newPassword !== form.newPassword2) {
    return Promise.reject({
      type: 'intl',
      message: 'passwords-not-match'
    });
  }
  return axiosClient.post('/api/1.0/user/password/reset', {
    new_password: form.newPassword,
    reset_token: token
  });
};
