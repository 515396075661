import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'hooks';
import routes from 'routes';
import Fragment from './fragment';

const ICompPatientDashboardTable = props => {
  const params = useParams();
  const navigate = useNavigate();

  const [open, toggleOpen] = useToggle(false);
  const patientRef = useRef({});

  const { hospitalId, departmentId, projectId } = params;

  const onRowClick = patient => {
    const { id: patientId, viewed } = patient;
    patientRef.current = patient;

    if (viewed) {
      const iCompPatientPlotsPath = routes.iCompPatientPlots(
        hospitalId,
        departmentId,
        projectId,
        patientId
      );
      navigate(iCompPatientPlotsPath);
    } else {
      toggleOpen();
    }
  };

  const onLogAccess = () => {
    const patientId = patientRef.current.id;
    const iCompPatientPlotsPath = routes.iCompPatientPlots(
      hospitalId,
      departmentId,
      projectId,
      patientId
    );
    navigate(iCompPatientPlotsPath);
  };

  return (
    <Fragment
      {...props}
      onRowClick={onRowClick}
      // ---
      open={open}
      onClose={toggleOpen}
      patient={patientRef.current}
      onLogAccess={onLogAccess}
    />
  );
};

export default ICompPatientDashboardTable;
