import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchStreamOptions } from 'api/simple-sensors';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';
import Fragment from './fragment';

const SimpleSensorPlotsContainer = props => {
  const { organizationId, projectId } = useParams();

  const {
    fetching,
    data: streamOptions = [],
    error
  } = useFetch({
    apiFetch: fetchStreamOptions,
    source: JSON.stringify({ organizationId, projectId }),
    isJsonSource: true
  });

  if (fetching) {
    return <LoadingSection />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return <Fragment {...props} streamOptions={streamOptions} />;
};

export default SimpleSensorPlotsContainer;
