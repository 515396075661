import React, { useState } from 'react';
import { useTranslate } from 'intl';
import { useExportController } from 'fragments/export-controller/context';
import { Button } from 'semantic-ui-react';
import StaticBox from '../../static-box';
import EmptyWarningModal from './empty-warning-modal';

const PdfReport = ({ selectedData }) => {
  const t = useTranslate('browse-data');
  const { open } = useExportController();
  const [openEmptyWarningModal, setOpenEmptyWarningModal] = useState(false);

  const onGenerateExport = () => {
    if (!selectedData.length) {
      setOpenEmptyWarningModal(true);
    } else {
      open(true);
    }
  };

  return (
    <>
      <StaticBox
        header={t('pdf-report.header')}
        listTitle={t('pdf-report.list-title')}
        listItems={[
          t('pdf-report.activity-measurement'),
          t('pdf-report.whole-period-of-monitoring'),
          t('pdf-report.1h-interval'),
          t('pdf-report.pdf-format')
        ]}
        button={
          <Button primary onClick={onGenerateExport}>
            {t('pdf-report.generate-report')}
          </Button>
        }
      />

      <EmptyWarningModal
        open={openEmptyWarningModal}
        onClose={() => setOpenEmptyWarningModal(false)}
      />
    </>
  );
};

export default PdfReport;
