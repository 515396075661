import React from 'react';
import {
  Headers,
  PatientName,
  StartTime,
  EndTime,
  Sensor,
  PatientStatus,
  Actions
} from '../elements';

const baseColumns = [
  {
    name: 'patientName',
    header: Headers.patientName,
    cell: PatientName
  },
  {
    name: 'startTime',
    header: Headers.startTime,
    cell: StartTime
  },
  {
    name: 'endTime',
    header: Headers.endTime,
    cell: EndTime
  },
  {
    name: 'sensor',
    header: Headers.sensor,
    cell: Sensor
  },
  {
    name: 'status',
    header: Headers.patientStatus,
    cell: PatientStatus
  }
];

export const getColumns = ({
  isOpenBulkExportMode,
  setExportData,
  setEditData
}) => {
  const columns = [
    ...baseColumns,
    {
      name: 'actions',
      header: Headers.actions,
      cell: ({ data }) => (
        <Actions
          data={data}
          isOpenBulkExportMode={isOpenBulkExportMode}
          setExportData={setExportData}
          setEditData={setEditData}
        />
      )
    }
  ];

  return columns;
};
