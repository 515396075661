import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { SensorSelect } from 'fragments/sensor';
import { Form, Input } from 'semantic-ui-react';
import DatePicker from 'components/date-picker';
import ErrorMessage from 'components/error-message';

const today = moment().format();

const PatientForm = ({ data, onSubmit: handleSubmit, onCancel }) => {
  const t = useTranslate('hospital-patient');

  const editMode = !!data;

  const { form, onChanges, onSubmit, submitting, error } = useForm({
    initialForm: {
      patient: data?.patient.name || '',
      sensor: data?.sensor.id || '',
      startTime: data?.startTime || today,
      endTime: data?.endTime || ''
    },
    apiSubmit: payload => handleSubmit(payload, data).then(onCancel)
  });

  return (
    <Form onSubmit={onSubmit} error={!!error}>
      <Form.Field required>
        <label>{t('patient-name')}</label>
        <Input
          placeholder={t('patient-name')}
          required
          value={form.patient}
          onChange={(event, { value }) => onChanges.patient(value)}
        />
      </Form.Field>

      <Form.Group widths="equal">
        <Form.Field required>
          <label>{t('sensor')}</label>
          <SensorSelect
            placeholder={t('sensor')}
            disabled={editMode}
            value={form.sensor}
            onChange={(event, { value }) => onChanges.sensor(value)}
            renderStatus
            required
          />
        </Form.Field>
        <Form.Field />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>{t('start-time')}</label>
          <DatePicker
            type="date-time"
            timeIntervals={15}
            value={form.startTime}
            onChange={(event, { value }) => onChanges.startTime(value)}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('end-time')}</label>
          <DatePicker
            type="date-time"
            timeIntervals={15}
            isClearable
            minDate={form.startTime || undefined}
            value={form.endTime}
            onChange={(event, { value }) => onChanges.endTime(value)}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group style={{ marginTop: '2rem' }}>
        <Form.Button
          type="button"
          onClick={onCancel}
          content={t('common:cancel')}
          style={{ margin: 0 }}
        />
        <Form.Button
          type="submit"
          color="blue"
          loading={submitting}
          content={t('common:submit')}
        />
      </Form.Group>

      <ErrorMessage error={error} />
    </Form>
  );
};

PatientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default PatientForm;
