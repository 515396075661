import { axiosClient } from 'api/axios-client';
import type { ScopesResponse } from './types';
import { filterScopes } from './utils';

let promise: Promise<ScopesResponse>;

export const fetchScopes = async () => {
  if (!promise) {
    promise = axiosClient
      .get('/api/1.0/access_scopes/list')
      .then(response => response.data.value);
  }

  const { scopes } = await promise;
  return filterScopes(scopes);
};
