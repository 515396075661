import moment from 'moment';
import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nICompPlot } from './normalize';
import type { ICompPlotStream, ICompPlotValueRaw } from './types';

const streamValues = {
  capacitance: 'raw:cap/stretch/scalar',
  capacitance6h: 'raw:cap/stretch/scalar',
  capacitance6hadjust: 'raw:cap/stretch/scalar',
  capacitance24h: 'raw:cap/stretch/scalar',
  capacitanceDayNight: 'raw:cap/stretch/scalar',
  temperature: 'raw:temp/acc/scalar'
};

const transformValues = {
  capacitance: 'average_15m',
  capacitance6h: 'icomp_average_6h',
  capacitance6hadjust: 'icomp_average_6h_adjust',
  capacitance24h: 'icomp_average_24h',
  capacitanceDayNight: 'icomp_average_24h_daynight',
  temperature: 'average_15m'
};

export const fetchPlot = async (
  {
    departmentId,
    projectId,
    stream,
    track,
    measurement,
    startDate,
    endDate
  }: Pick<RouteParams, 'departmentId' | 'projectId'> & {
    stream: ICompPlotStream;
    track: string;
    measurement: string;
    startDate: string;
    endDate: string;
  },
  setCancel: CancelExecutor
) => {
  const streamValue = streamValues[stream];
  const startTime = moment(startDate).startOf('day');
  const endTime = moment(endDate).add(1, 'days').startOf('day');

  const params = {
    org_id: departmentId,
    project_id: projectId,
    ...(measurement === 'all'
      ? {
          measurement_track_id: track
        }
      : {
          measurement_id: measurement
        }),
    transform: transformValues[stream],
    streams: streamValue.split(':')[1],
    start_time: startTime.format(),
    end_time: endTime.format()
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const { values }: { values: ICompPlotValueRaw[] } = await axiosClient
    .get('/api/1.0/measurement/data/raw', options)
    .then(response => response.data.value.data);

  return {
    stream,
    startDate,
    endDate,
    values: nICompPlot({
      values,
      endDate,
      stream
    })
  };
};
