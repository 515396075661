import React from 'react';
import PropTypes from 'prop-types';
import { useFilter, useSearch } from 'hooks';
import Header from './header';
import Table from './table';
import utils from './utils';

const SimpleSensorDashboardFragment = ({ fetching, error, data }) => {
  const [filter, onFilter] = useFilter('sensor_status/all');
  const [search, onSearch] = useSearch({ uppercase: true });
  return (
    <React.Fragment>
      <Header
        search={search}
        onSearch={onSearch}
        filter={filter}
        onFilter={onFilter}
      />
      <Table
        fetching={fetching}
        error={error}
        data={utils.filterData({ data, search, filter })}
      />
    </React.Fragment>
  );
};

SimpleSensorDashboardFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default SimpleSensorDashboardFragment;
