import React from 'react';
import { Translate } from 'intl';

export * from './patient-name';
export * from './time';
export * from './patient-status';
export * from './sensors';
export * from './actions';

export const Headers = {
  patient: <Translate ns="patient" i18nKey="patient" />,
  startTime: <Translate ns="patient" i18nKey="start-time" />,
  endTime: <Translate ns="patient" i18nKey="end-time" />,
  sensorName: <Translate ns="patient" i18nKey="sensor-name" />,
  sensorData: <Translate ns="patient" i18nKey="sensor-memory-status" />,
  sensorLastOnline: <Translate ns="patient" i18nKey="sensor-last-online" />,
  actions: <Translate ns="patient" i18nKey="actions" />,
  status: <Translate ns="patient" i18nKey="status" />
};
