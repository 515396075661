import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

const WithConfirmFragment = ({
  open,
  onOpen,
  onConfirm,
  onCancel,
  config,
  Component,
  ...otherProps
}) => {
  const t = useTranslate('common');

  return (
    <React.Fragment>
      <Component {...otherProps} onClick={onOpen} />
      <Modal open={open} size="tiny">
        <Header>
          {config.headerIcon && <Icon name={config.headerIcon} />}
          <Header.Content>
            {config.headerText || t('confirmation')}
          </Header.Content>
        </Header>
        <Modal.Content>
          <p>{config.bodyText || t('are-you-sure')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>{config.cancelText || t('cancel')}</Button>
          <Button
            icon
            labelPosition="right"
            color={config.confirmColor || 'blue'}
            onClick={onConfirm}
          >
            <Icon name={config.confirmIcon || 'checkmark'} />
            {config.confirmText || t('ok')}
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

WithConfirmFragment.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // ---
  config: PropTypes.shape({
    headerIcon: PropTypes.string,
    headerText: PropTypes.node,
    // ---
    bodyText: PropTypes.node,
    // ---
    cancelText: PropTypes.node,
    // ---
    confirmIcon: PropTypes.string,
    confirmText: PropTypes.node,
    confirmColor: PropTypes.string
  }).isRequired,
  Component: PropTypes.func.isRequired
};

export default WithConfirmFragment;
