import { axiosClient } from 'api/axios-client';
import type {
  ExportQueueEntry,
  ExportQueueEntryCommand,
  ExportQueueStatus
} from './types';

export * from './types';

// Fixes incorrect UTC timezone from API response
// Issue: https://github.com/sensinno/sens-cloud-backend/issues/1302#issuecomment-1617620164
export const normalizeExportQueueEntry = (
  item: ExportQueueEntry
): ExportQueueEntry => ({
  ...item,
  queue_time: `${item.queue_time}Z`,
  expire_time: `${item.expire_time}Z`
});

export const fetchExportQueueStatus = async (): Promise<ExportQueueEntry[]> => {
  const response: ExportQueueStatus = await axiosClient
    .get('/api/1.0/export/queue_status')
    .then(response => response.data.value);
  return response.queue_entries.map(normalizeExportQueueEntry);
};

export const commandExportQueue = async (payload: ExportQueueEntryCommand) => {
  return axiosClient.post('/api/1.0/export/queue_command', payload);
};
