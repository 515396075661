import React from 'react';
import { useTranslate } from 'intl';
import { Icon } from 'semantic-ui-react';
import styles from './styles.module.css';

const colors = {
  'status/measurement_ex/not_started': 'grey',
  'status/measurement_ex/in_progress_ok': 'mediumSeaGreen',
  'status/measurement_ex/in_progress_pending': 'gold',
  'status/measurement_ex/in_progress_warning': '#ff7f50',
  'status/measurement_ex/in_progress_warning_severe': '#ff7f50',
  'status/measurement_ex/in_progress_sensor_off': '#ff7f50',
  'status/measurement_ex/completed_ok': 'steelBlue',
  'status/measurement_ex/completed_pending': 'gold'
};

const notes = {
  'status/measurement_ex/in_progress_ok': 'status_note/ok',
  'status/measurement_ex/in_progress_pending': 'status_note/need_to_sync_data',
  'status/measurement_ex/in_progress_warning': 'status_note/need_to_sync_data',
  'status/measurement_ex/in_progress_warning_severe':
    'status_note/need_to_sync_data',
  'status/measurement_ex/in_progress_sensor_off': 'status_note/turn_on_sensor',
  'status/measurement_ex/completed_pending': 'status_note/need_to_sync_data'
};

const warnings = {
  'status/measurement_ex/in_progress_warning':
    'status_warning/sensor_memory_almost_full',
  'status/measurement_ex/in_progress_warning_severe':
    'status_warning/sensor_memory_full',
  'status/measurement_ex/in_progress_sensor_off': 'status_warning/sensor_is_off'
};

export const PatientStatus = ({ data }) => {
  const t = useTranslate('patient');
  const status = data.measurementStatusEx;
  const note = notes[status];
  const warning = warnings[status];

  return (
    <div>
      <div>
        <Icon name="circle" style={{ color: colors[status] }} />
        {` `}
        {t(status)}
      </div>

      {note && <div className={styles.note}>{t(note)}</div>}

      {warning && <div className={styles.warning}>{t(warning)}</div>}
    </div>
  );
};

export const StatusDetails = ({ data }) => {
  const t = useTranslate('patient');
  const status = data.measurementStatusEx;
  const note = notes[status];
  const warning = warnings[status];

  return (
    <div className={styles.main2}>
      <div>
        <Icon name="circle" style={{ color: colors[status] }} />
        {` `}
        {t(status)}
        {note && (
          <span>
            {` - `}
            {t(note)}
          </span>
        )}
      </div>

      {warning && <div className={styles.warning2}>{t(warning)}</div>}
    </div>
  );
};
