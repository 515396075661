import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { fetchGateway } from 'api/admin/gateways';
import Fragment from './fragment';

const GatewayDetails = () => {
  const params = useParams();

  const { fetching, data, error } = useFetch({
    source: params.gatewayId,
    apiFetch: fetchGateway
  });

  return <Fragment fetching={fetching} data={data} error={error} />;
};

export default GatewayDetails;
