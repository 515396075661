/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'intl';
import * as Sentry from '@sentry/browser';
import { Container, Message } from 'semantic-ui-react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <Container style={{ marginTop: '1rem' }}>
          <Message error>
            <Message.Header>
              <Translate ns="error" i18nKey="other-error" />
            </Message.Header>
            <Message.Content>{this.state.error.toString()}</Message.Content>
          </Message>
        </Container>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
