import { axiosClient } from 'api/axios-client';
import type { SensorRaw } from 'api/sensors';
import type { RouteParams } from 'app/types';
import { nNfaSubject } from './normalize';
import type { NFAForm, NFASubject, NFASubjectRaw } from './types';
import { transformEditPhoneNumber, transformEditTime } from './utils';

export const editSubject = async ({
  params: { organizationId, projectId },
  subject,
  form
}: {
  params: Pick<RouteParams, 'organizationId' | 'projectId'>;
  subject: NFASubject;
  form: NFAForm;
}) => {
  const { sensors }: { sensors: SensorRaw[] } = await axiosClient
    .get('/api/1.0/sensors', {
      params: {
        org_id: organizationId,
        project_id: projectId
      },
      cache: true
    })
    .then(response => response.data.value);

  const { patient }: { patient: NFASubjectRaw } = await axiosClient
    .post('/api/1.0/patients/edit2', {
      org_id: organizationId,
      project_id: projectId,
      patient_id: subject.id,
      first_name: form.subjectId,
      timezone: form.timezone,
      phone_number: transformEditPhoneNumber(form, subject),
      ...(!!form.measurementMethod && {
        sensor_id: form.sensorThigh
      }),
      ...(form.measurementMethod === '2-sensors' && {
        sensor2_id: form.sensorChest
      }),
      start_time: transformEditTime(form.startTime, subject.startTime),
      end_time: transformEditTime(form.endTime, subject.endTime),
      meta: {
        expected_start_time: form.expectedStartTime,
        note: form.note
      }
    })
    .then(response => response.data.value);

  return nNfaSubject(patient, sensors);
};
