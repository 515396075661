import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import Menu from 'fragments/menu';
import { Container } from 'semantic-ui-react';
import styles from '../styles.module.scss';
import AdminNavbar from './admin-navbar';

const SensAdmin = ({ children }) => (
  <div>
    <Menu homeUrl={routes.organizations()} />
    <AdminNavbar />
    <Container className={styles.container}>{children}</Container>
  </div>
);

SensAdmin.propTypes = {
  children: PropTypes.node.isRequired
};

export default SensAdmin;
