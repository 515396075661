import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Form, Grid, Header, Modal } from 'semantic-ui-react';
import { editPatient } from 'api/icomp-patients';
import Box from 'components/box';
import CprInput from 'components/cpr-input';
import ErrorMessage from 'components/error-message';
import PhoneInput from 'components/phone-input';

const EditPatientModal = ({ patient, isOpen, onClose, onCompleted }) => {
  const t = useTranslate('icomp-patient');
  const params = useParams();
  const { form, submitting, error, onChanges, onSubmit, onReset } = useForm({
    initialForm: {
      cpr: patient.cpr,
      phone: patient.phone,
      firstName: patient.firstName,
      lastName: patient.lastName
    },
    apiSubmit: form =>
      editPatient({
        form,
        params,
        patient
      }).then(onCompleted)
  });

  return (
    <Modal size="small" open={isOpen} onUnmount={onReset}>
      <div style={{ padding: '2em 0' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" textAlign="center">
                {t('edit-patient')}
                <Header.Subheader>{patient.name}</Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer="14" tablet="16">
              <Form onSubmit={onSubmit}>
                <Form.Group widths="2">
                  <CprInput
                    label={t('cpr')}
                    value={form.cpr}
                    onChange={(event, { value }) => onChanges.cpr(value)}
                    data-cy="patient-details__cpr-input"
                    required
                  />
                  <Form.Field>
                    <label>{t('phone')}</label>
                    <PhoneInput
                      value={form.phone}
                      onChange={(event, { value }) => onChanges.phone(value)}
                      data-cy="patient-details__phone-input"
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="2">
                  <Form.Input
                    label={t('first-name')}
                    placeholder={t('first-name')}
                    data-cy="patient-details__first-name-input"
                    value={form.firstName}
                    onChange={(event, { value }) => onChanges.firstName(value)}
                    required
                  />
                  <Form.Input
                    label={t('last-name')}
                    placeholder={t('last-name')}
                    data-cy="patient-details__last-name-input"
                    value={form.lastName}
                    onChange={(event, { value }) => onChanges.lastName(value)}
                    required
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem' }}>
                  <Form.Button
                    type="button"
                    onClick={onClose}
                    data-cy="edit-patient__cancel-button"
                    style={{ margin: 0, width: '6rem' }}
                  >
                    {t('common:cancel')}
                  </Form.Button>
                  <Form.Button
                    type="submit"
                    color="blue"
                    data-cy="edit-patient__submit-button"
                    loading={submitting}
                    disabled={submitting}
                    style={{ margin: 0, width: '6rem' }}
                  >
                    {t('common:ok')}
                  </Form.Button>
                </Form.Group>
              </Form>

              {error && (
                <Box mt="2rem">
                  <ErrorMessage error={error} />
                </Box>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Modal>
  );
};

EditPatientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  onCompleted: PropTypes.func.isRequired
};

export default EditPatientModal;
