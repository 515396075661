import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, useToggleOpen } from 'hooks';
import {
  BulkExportEmptyWarningModal,
  BulkExportTrigger
} from 'fragments/measurement-bulk-export';
import { fetchSubjects } from 'api/nfa-subjects';
import NFABulkExportModal from './bulk-export-modal';
import Header from './header';
import SubjectCreateModal from './subject-create-modal';
import SubjectDeleteModal from './subject-delete-modal';
import SubjectEditModal from './subject-edit-modal';
import SubjectSendNotifModal from './subject-send-notif-modal';
import Table from './table';
import { filterData } from './utils';

/* eslint-disable no-unused-vars */
const NFASubjectsDashboard = () => {
  const { organizationId, projectId } = useParams();
  const {
    fetching,
    data: subjects = [],
    error,
    onUpdate
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId }),
    isJsonSource: true,
    apiFetch: fetchSubjects
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState([]);
  const [sortAccessor, setSortAccessor] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [sendNofiData, setSendNotifData] = useState(null);
  const [bulkExportData, setBulkExportData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);

  const [isOpenCreateModal, onOpenCreateModal, onCloseCreateModal] =
    useToggleOpen(false);
  const [
    isOpenBulkExportMode,
    onOpenBulkExportMode,
    onCloseBulkExportMode,
    onToggleBulkExportMode
  ] = useToggleOpen(false);
  const [isOpenBulkExportModal, onOpenBulkExportModal, onCloseBulkExportModal] =
    useToggleOpen(false);
  const [
    isOpenBulkExportEmptyWarningModal,
    onOpenBulkExportEmptyWarningModal,
    onCloseBulkExportEmptyWarningModal
  ] = useToggleOpen(false);

  const onAddSuccess = item => {
    const nextData = subjects.concat(item);
    onUpdate(nextData);
  };
  const onEditSuccess = item => {
    const nextData = subjects.slice();
    const index = nextData.findIndex(i => i.id === item.id);
    nextData[index] = item;
    onUpdate(nextData);
    setEditData(null);
  };
  const onDeleteSuccess = item => {
    const nextData = subjects.slice();
    const index = nextData.findIndex(i => i.id === item.id);
    nextData.splice(index, 1);
    onUpdate(nextData);
    setDeleteData(null);
  };

  return (
    <>
      <Header
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        sortAccessor={sortAccessor}
        setSortAccessor={setSortAccessor}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        onOpenCreateModal={onOpenCreateModal}
        onToggleBulkExportMode={onToggleBulkExportMode}
      />

      {isOpenBulkExportMode && (
        <BulkExportTrigger
          onClick={() => {
            if (bulkExportData.length === 0) {
              onOpenBulkExportEmptyWarningModal(true);
            } else {
              onOpenBulkExportModal(true);
            }
          }}
        />
      )}

      <Table
        fetching={fetching}
        error={error}
        data={filterData({
          data: subjects,
          sortAccessor,
          sortDirection,
          searchQuery,
          filterStatus
        })}
        isOpenBulkExportMode={isOpenBulkExportMode}
        bulkExportData={bulkExportData}
        setBulkExportData={setBulkExportData}
        setSendNotifData={setSendNotifData}
        setEditData={setEditData}
        setDeleteData={setDeleteData}
      />

      {isOpenCreateModal && (
        <SubjectCreateModal
          onClose={onCloseCreateModal}
          onSuccess={onAddSuccess}
        />
      )}

      {editData && (
        <SubjectEditModal
          subject={editData}
          onSuccess={onEditSuccess}
          onClose={() => setEditData(null)}
        />
      )}

      {deleteData && (
        <SubjectDeleteModal
          data={deleteData}
          onSuccess={onDeleteSuccess}
          onCancel={() => setDeleteData(null)}
        />
      )}

      {sendNofiData && (
        <SubjectSendNotifModal
          data={sendNofiData}
          onClose={() => setSendNotifData(null)}
        />
      )}

      {isOpenBulkExportEmptyWarningModal && (
        <BulkExportEmptyWarningModal
          open={isOpenBulkExportEmptyWarningModal}
          onClose={onCloseBulkExportEmptyWarningModal}
        />
      )}

      {bulkExportData && (
        <NFABulkExportModal
          subjects={bulkExportData}
          open={isOpenBulkExportModal}
          onClose={() => {
            onCloseBulkExportMode();
            onCloseBulkExportModal();
            setBulkExportData([]);
          }}
        />
      )}
    </>
  );
};

export default NFASubjectsDashboard;
