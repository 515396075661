import React from 'react';
import moment from 'moment';
import { useTranslate } from 'intl';
import { Icon } from 'semantic-ui-react';

const getDuration = value => {
  const now = moment();
  const target = moment(value);
  const diff = now.diff(target);
  const duration = moment.duration(diff);
  return {
    year: duration.get('year'),
    month: duration.get('month'),
    day: duration.get('day'),
    hour: duration.get('hour'),
    minute: duration.get('minute')
  };
};

const LastData = ({ sensor }) => {
  const t = useTranslate('time-ago');

  if (sensor.expired) {
    return <span>N/A</span>;
  }

  /* todo update this to use real sensor state in string format */
  if (sensor.runStateCurrent === 0) {
    return <span>Slukket</span>;
  }

  const syncAgo = moment.utc() - moment.utc(sensor.lastSyncedAt);
  const syncing = sensor.lastSyncedAt && syncAgo < 60000;
  if (syncing) {
    return (
      <span>
        <Icon name="circle" color="green" /> Syncing Now
      </span>
    );
  }

  const duration = getDuration(sensor.lastSynced);

  // Time >= 12 months -> Display year and month
  if (duration.year > 0) {
    return (
      <span className="color-red">
        {t('year', { count: duration.year })}
        {`, `}
        {t('month', { count: duration.month })}
      </span>
    );
  }

  // Time < 12 months -> Display month and day
  if (duration.month > 0) {
    return (
      <span className="color-red">
        {t('month', { count: duration.month })}
        {`, `}
        {t('day', { count: duration.day })}
      </span>
    );
  }

  // Time < 7 days -> Display day and hour
  if (duration.day > 0) {
    return (
      <span className={duration.day > 2 ? 'color-red' : 'color-yellow'}>
        {t('day', { count: duration.day })}
        {`, `}
        {t('hour', { count: duration.hour })}
      </span>
    );
  }

  // Time < 24 hours -> Display hour and minute
  if (duration.hour > 0) {
    return (
      <span className="color-green">
        {t('hour', { count: duration.hour })}
        {`, `}
        {t('minute', { count: duration.minute })}
      </span>
    );
  }

  // Time < 1 hour -> Display minutes
  return (
    <span className="color-green">
      {t('minute', { count: duration.minute })}
    </span>
  );
};

export default LastData;
