import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown } from 'semantic-ui-react';

const options = [
  'sensor_status/available',
  'sensor_status/running-ok',
  'sensor_status/expired',
  'sensor_status/deep-sleep',
  'sensor_status/waking-up',
  'sensor_status/putting-to-sleep',
  'sensor_status/all'
];

const SensorStatusDropdown = ({ value, onChange, disabled }) => {
  const t = useTranslate('sensor');

  return (
    <Dropdown
      value={value}
      onChange={onChange}
      selection
      disabled={disabled}
      options={options.map(item => ({
        text: t(item),
        value: item
      }))}
    />
  );
};

SensorStatusDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default SensorStatusDropdown;
