import React from 'react';
import styles from './styles.module.css';

export const Patient = ({ data }) => (
  <span>
    <span>{data.firstName}</span>
    <br />
    <span className={styles.smaller}>{data.lastName}</span>
  </span>
);
