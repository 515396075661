import {
  Description,
  Headers,
  Name,
  NumOfSensors,
  NumOfUsers
} from '../elements';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'description',
    header: Headers.description,
    cell: Description,
    sortable: true
  },
  {
    name: 'num-of-sensors',
    header: Headers.numOfSensors,
    cell: NumOfSensors,
    sortable: true
  },
  {
    name: 'num-of-users',
    header: Headers.numOfUsers,
    cell: NumOfUsers,
    sortable: true
  }
];
