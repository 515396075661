import {
  Delivery,
  Headers,
  LastOnline,
  LastSynced,
  Name,
  Runtime,
  Status
} from '../elements';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status
  },
  {
    name: 'delivery',
    header: Headers.delivery,
    cell: Delivery
  },
  {
    name: 'lastSynced',
    header: Headers.lastSynced,
    cell: LastSynced
  },
  {
    name: 'lastOnline',
    header: Headers.lastOnline,
    cell: LastOnline
  },
  {
    name: 'runtime',
    header: Headers.runtime,
    cell: Runtime
  }
];
