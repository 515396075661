import React from 'react';
import { useForm, useToggle } from 'hooks';
import { useTranslate } from 'intl';
import {
  ButtonCancel,
  ButtonGroup,
  ButtonOk,
  Modal
} from 'fragments/icomp-modal';
import { Button, Form, Icon, Input } from 'semantic-ui-react';
import { addDepartment } from 'api/icomp-departments';
import Box from 'components/box';

const AddDepartment = ({ hospital }) => {
  const t = useTranslate('icomp-admin-department');

  const [open, toggleOpen] = useToggle();
  const { form, submitting, error, onChanges, onSubmit, onResetForm } = useForm(
    {
      initialForm: {
        name: '',
        description: ''
      },
      apiSubmit: form => {
        return addDepartment({ hospitalId: hospital.id, form }).then(() => {
          window.location.reload();
        });
      }
    },
    [hospital.id]
  );

  return (
    <React.Fragment>
      <Button primary icon labelPosition="right" onClick={toggleOpen}>
        {t('add-department')}
        <Icon name="add" />
      </Button>
      {open && (
        <Modal
          open
          title={t('add-department')}
          body={
            <Form onSubmit={onSubmit}>
              <Form.Field required>
                <label>{t('name')}</label>
                <Input
                  placeholder={t('name')}
                  required
                  value={form.name}
                  onChange={(event, { value }) => onChanges.name(value)}
                />
              </Form.Field>
              <Form.Field required>
                <label>{t('description')}</label>
                <Input
                  placeholder={t('description')}
                  required
                  value={form.description}
                  onChange={(event, { value }) => onChanges.description(value)}
                />
              </Form.Field>
              <Box mt="2.5rem">
                <ButtonGroup>
                  <ButtonCancel onClick={toggleOpen} />
                  <ButtonOk type="submit" loading={submitting}>
                    {t('common:create')}
                  </ButtonOk>
                </ButtonGroup>
              </Box>
            </Form>
          }
          error={error}
          onUnmount={onResetForm}
        />
      )}
    </React.Fragment>
  );
};

export default AddDepartment;
