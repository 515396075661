import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import utils1 from 'components/plot/victory/capacitance/utils';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Line } from 'components/plot/victory/elements';
import ChartConfig from '../chart-config';
import Dividers from '../dividers';
import utils2 from '../utils';
import { normalize, transform } from './transform';

const utils = { ...utils1, ...utils2 };

const ICompCapacitanceDayNight = ({
  startDate,
  endDate,
  values,
  measurements
}) => {
  const t = useTranslate('plot');
  const [unit, setUnit] = useState('pF');
  const [yRange, setYRange] = useState('full');

  const { dataDay, dataNight, dataAll } = normalize(values, unit);
  const { daySolid, dayDashed, nightSolid, nightDashed } = transform({
    dataDay,
    dataNight
  });

  const diff = utils.getDiff(startDate, endDate);

  const domain = {
    x: utils.getXDomain(diff, startDate, endDate),
    y: utils.getYDomainCapacitance(dataAll, yRange)
  };

  const xTicks = utils.getXTicks(diff, domain);
  const xTickFormat = utils.getXTickFormat(diff);
  const xLabel = diff > 1 ? '' : t('time-in-hours');

  const yStep = utils.getYStep(domain.y);
  const yTicks = utils.getYTicks(domain, yStep);

  return (
    <div>
      <ChartConfig
        name="capacitance-day-night"
        unit={unit}
        setUnit={setUnit}
        yRange={yRange}
        setYRange={setYRange}
      />

      <Chart domain={domain} width={800} height={400}>
        {AxisX({ tickValues: xTicks, label: xLabel, tickFormat: xTickFormat })}
        {AxisY({ tickValues: yTicks, label: unit })}

        {Line({
          data: daySolid,
          stroke: '#FF0000'
        })}
        {Line({
          data: dayDashed,
          style: {
            data: {
              strokeDasharray: '1,1',
              strokeWidth: 1,
              strokeColor: '#FF0000'
            }
          }
        })}

        {Line({
          data: nightSolid,
          stroke: '#0000FF'
        })}
        {Line({
          data: nightDashed,
          style: {
            data: {
              strokeDasharray: '1,1',
              strokeWidth: 1,
              strokeColor: '#0000FF'
            }
          }
        })}

        {Dividers({ data: measurements })}
      </Chart>
    </div>
  );
};

ICompCapacitanceDayNight.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  measurements: PropTypes.array.isRequired
};

export default ICompCapacitanceDayNight;
