import React from 'react';
import PropTypes from 'prop-types';
import ExportDay from './day';
import ExportHour from './hour';

const TypeMap = {
  day: ExportDay,
  hour: ExportHour
};

const MeasurementPlotsExport = ({ type, ...otherProps }) => {
  const Type = TypeMap[type];
  return <Type {...otherProps} />;
};

MeasurementPlotsExport.propTypes = {
  type: PropTypes.oneOf(['day', 'hour']).isRequired,
  // ---
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired
};

export default MeasurementPlotsExport;
