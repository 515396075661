import React from 'react';
import { useTranslate } from 'intl';
import { Header } from 'semantic-ui-react';

const ICompDepartmentDashboardEmpty = () => {
  const t = useTranslate('icomp-department');

  return (
    <Header as="h2" textAlign="center">
      {t('no-hospitals-title')}
      <Header.Subheader>{t('no-hospitals-description1')}</Header.Subheader>
      <Header.Subheader>{t('no-hospitals-description2')}</Header.Subheader>
    </Header>
  );
};

export default ICompDepartmentDashboardEmpty;
