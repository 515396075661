import React from 'react';
import moment from 'moment';
import { FormatDate } from 'intl';
import Dash from 'components/dash';

export const DiaryUpdated = ({ data: subject }) => {
  if (!subject.diaryUpdated) {
    return <Dash />;
  }

  const diffDays = moment().diff(subject.diaryUpdated, 'days');
  let color;
  if (subject.status === 'done') {
    color = 'black';
  } else if (diffDays < 2) {
    color = 'green';
  } else if (diffDays < 3) {
    color = 'yellow';
  } else {
    color = 'red';
  }

  return (
    <span className={`color-${color}`}>
      <FormatDate value={subject.diaryUpdated} />
    </span>
  );
};
