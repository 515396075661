import { axiosClient } from 'api/axios-client';
import type { SensorRaw } from 'api/sensors';
import type { RouteParams } from 'app/types';
import { nActivePatient } from './normalize';
import type { ActivePatientForm, ActivePatientRaw } from './types';
import { getEndTime, getStartTime } from './utils';

export const createPatient = async ({
  params,
  form
}: {
  params: Pick<RouteParams, 'projectId'>;
  form: ActivePatientForm;
}) => {
  if (!form.measurementMethod) {
    return Promise.reject({
      type: 'intl',
      message: 'measurement-method-is-required'
    });
  }

  if (!form.sensorThigh && !form.sensorChest) {
    return Promise.reject({
      type: 'intl',
      message: 'sensor-is-required'
    });
  }

  if (form.startTimeType === 'specific-time' && !form.startTimeValue) {
    return Promise.reject({
      type: 'intl',
      message: 'start-time-is-required'
    });
  }

  const startTime = getStartTime(form);
  const endTime = getEndTime(form);
  const payload = {
    project_id: params.projectId,
    first_name: form.firstName,
    last_name: form.lastName,
    timezone: form.timezone,
    phone_number: form.phoneNumber || null,
    alg_profile: form.measurementMethod,
    sensor_id: form.sensorThigh,
    sensor2_id: form.sensorChest || null,
    meta: {
      start_time_type: form.startTimeType,
      end_time_type: form.endTimeType,
      end_time_duration: form.endTimeDuration
    },
    start_time: startTime,
    end_time: endTime
  };

  const {
    patient,
    sensors
  }: { patient: ActivePatientRaw; sensors: SensorRaw[] } = await axiosClient
    .post('/api/1.0/patient/create2', payload)
    .then(response => response.data.value);

  return nActivePatient(patient, sensors);
};
