import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';

const Control = ({ data, submitting, onToggle }) => {
  const t = useTranslate('sensor');

  const props = {
    basic: true,
    compact: true,
    style: { width: '12rem' },
    onClick: onToggle.bind(null, data),
    content: data.runStateRequested === 0 ? t('wakeup') : t('put-to-sleep'),
    color: data.runStateRequested === 0 ? 'green' : 'orange',
    disabled: submitting
  };
  if (data.changed) {
    props.icon = 'undo';
    props.labelPosition = 'right';
    props.active = true;
  }
  return data.expired ? null : <Button {...props} />;
};

Control.propTypes = {
  data: PropTypes.object.isRequired,
  // ---
  submitting: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default Control;
