import { useCallback, useState } from 'react';

const useFilter = initialValue => {
  const [filter, setFilter] = useState(initialValue);
  const onFilter = useCallback((e, { value }) => {
    setFilter(value);
  }, []);
  return [filter, onFilter];
};

export default useFilter;
