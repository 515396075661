import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/table';
import { getColumns } from './configs';

const ISpinePatientDashboardTable = ({
  fetching,
  error,
  data,
  editMode,
  onEdit,
  onDelete,
  onRowClick
}) => {
  const props = {
    fetching,
    error,
    data,
    columns: getColumns({ editMode, onEdit, onDelete })
  };
  if (!editMode) {
    props.onRowClick = onRowClick;
  }
  return <Table {...props} />;
};

ISpinePatientDashboardTable.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array.isRequired,
  // ---
  onRowClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ISpinePatientDashboardTable;
