import React from 'react';
import { useParams } from 'react-router-dom';
import { useEventHandler, useFetch } from 'hooks';
import { fetchNearbySensor } from 'api/admin/nearby-sensors';
import Fragment from './fragment';

const NearbySensorDetails = () => {
  const params = useParams();
  const [view, setView] = useEventHandler('3h');

  const { fetching, data, error } = useFetch({
    source: JSON.stringify({
      gatewayId: params.gatewayId,
      sensorId: params.sensorId,
      view
    }),
    isJsonSource: true,
    apiFetch: fetchNearbySensor
  });

  return (
    <Fragment
      view={view}
      setView={setView}
      fetching={fetching}
      data={data}
      error={error}
    />
  );
};

export default NearbySensorDetails;
