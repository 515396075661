import React, { useCallback, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ProjectContext } from 'contexts';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { Icon, Menu } from 'semantic-ui-react';
import { fetchProject } from 'api/projects';

const SensNavbar = () => {
  const t = useTranslate('menu');

  const { setProject } = useContext(ProjectContext);
  const onFetch = useCallback(
    (...params) => {
      return fetchProject(...params).then(response => {
        setProject(response);
        return response;
      });
    },
    [setProject]
  );

  const { organizationId, projectId } = useParams();
  const { fetching, data } = useFetch({
    source: JSON.stringify({
      organizationId,
      projectId
    }),
    isJsonSource: true,
    apiFetch: onFetch
  });

  if (!projectId) return null;

  if (fetching || !data?.views) return null;

  return (
    <>
      {data.views.map(item => {
        return (
          <Menu.Item
            key={item.name}
            as={NavLink}
            to={item.route(organizationId, projectId)}
            disabled={!projectId}
          >
            <Icon name={item.icon} />
            {t(item.name)}
          </Menu.Item>
        );
      })}
    </>
  );
};

export default SensNavbar;
