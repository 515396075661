import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import { endTreatment } from 'api/icomp-patients';
import Confirmation from './confirmation';

const EndTrack = ({ patientDetails }) => {
  const t = useTranslate('icomp-patient');
  const params = useParams();
  const [open, toggleOpen] = useToggle(false);
  const { patient, activeMeasurement } = patientDetails;
  const { track } = activeMeasurement || {};

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const onEndtrack = useCallback(() => {
    setSubmitting(true);
    return endTreatment({ params, patient })
      .then(() => {
        window.location.reload();
      })
      .catch(setError);
  }, [params, patient]);

  return (
    <React.Fragment>
      <Button
        primary
        disabled={!track || track.status === 'closed'}
        onClick={toggleOpen}
        data-cy="patient-details__end-track-button"
      >
        {t('end-track')}
      </Button>
      {open && (
        <Confirmation
          onConfirm={onEndtrack}
          onCancel={toggleOpen}
          submitting={submitting}
          error={error}
        />
      )}
    </React.Fragment>
  );
};

EndTrack.propTypes = {
  patientDetails: PropTypes.object.isRequired
};

export default EndTrack;
