import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Line, ZoomIn, ZoomOut } from '../elements';
import { useZoom } from '../hooks';
import utils from '../utils/hour';

const PostureAngleHour = ({ date, streams }) => {
  const t = useTranslate('plot');
  const { zoomId, onZoomIn, onZoomOut } = useZoom();

  const zoomed = zoomId !== undefined;

  const domain = {
    x: utils.getXDomain(date, zoomId),
    y: [-1, 1]
  };

  const xTicks = utils.getXTicks(domain, zoomed);
  const yTicks = [-1, -0.5, 0, 0.5, 1];

  const values = streams;
  const dataX = values['angle/x/scalar'].map((e, i) => ({
    x: values.ts[i],
    y: e
  }));
  const dataY = values['angle/y/scalar'].map((e, i) => ({
    x: values.ts[i],
    y: e
  }));
  const dataZ = values['angle/z/scalar'].map((e, i) => ({
    x: values.ts[i],
    y: e
  }));
  const dataIntens = values['activity/intensity/count'].map((e, i) => ({
    x: values.ts[i],
    y: e / 100
  }));

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        zoomed,
        label: zoomed ? t('time-in-seconds') : t('time-in-minutes')
      })}
      {AxisY({ tickValues: yTicks, label: 'A' })}

      {Line({ data: dataX, stroke: '#FF0000' })}
      {Line({ data: dataY, stroke: '#00FF00' })}
      {Line({ data: dataZ, stroke: '#0000FF' })}
      {Line({ data: dataIntens, stroke: '#AAAAAA' })}

      {zoomed
        ? ZoomOut({
            domain,
            onSelectTick: onZoomOut
          })
        : ZoomIn({
            range: 60 * 4,
            xstep: 60 * 1000,
            xcount: 60,
            domain,
            onSelectTick: onZoomIn
          })}
    </Chart>
  );
};

PostureAngleHour.propTypes = {
  date: PropTypes.string.isRequired,
  streams: PropTypes.object.isRequired
};

export default PostureAngleHour;
