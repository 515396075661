import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Grid, Header, Input } from 'semantic-ui-react';

const ICompPatientDashboardFilter = ({ filter, onFilter }) => {
  const t = useTranslate('icomp-patient');

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={6}>
          <Header as="h3">{t('num-of-active-patients')}</Header>
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Input
            data-cy="patients__filter-by-cpr"
            value={filter.cpr}
            onChange={onFilter.cpr}
            placeholder={t('filter-by-cpr')}
            style={{ marginRight: '1rem' }}
          />
          <Input
            data-cy="patients__filter-by-name"
            value={filter.name}
            onChange={onFilter.name}
            placeholder={t('filter-by-name')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ICompPatientDashboardFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilter: PropTypes.object.isRequired
};

export default ICompPatientDashboardFilter;
