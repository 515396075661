import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import { extractPhoneNumber } from 'utils/patient';
import styles from './styles.module.scss';
import { getCountryCodes, lockPhoneNumber } from './utils';

const PhoneInput = ({
  countryCodeList,
  value: phone,
  onChange: onChangePhone,
  locked,
  onToggleLocked,
  required,
  'data-cy': dataCy
}) => {
  const countryCodes = getCountryCodes(countryCodeList);
  const [countryCode, setCountryCode] = useState(() => {
    return (
      extractPhoneNumber(phone).countryCode ||
      countryCodes.find(item => item === '+45') ||
      countryCodes[0]
    );
  });
  const [phoneNumber, setPhoneNumber] = useState(() => {
    return extractPhoneNumber(phone).number;
  });

  const onChangeCountryCode = (event, { value: countryCode }) => {
    setCountryCode(countryCode);
    onChangePhone(event, { value: `${countryCode}${phoneNumber}` });
  };

  const onChangePhoneNumber = (event, { value: phoneNumber }) => {
    setPhoneNumber(phoneNumber);
    onChangePhone(event, { value: `${countryCode}${phoneNumber}` });
  };

  const lockable = locked !== undefined && onToggleLocked !== undefined;

  return (
    <div className={styles.wrapper}>
      <div className={styles.countryCode}>
        <Dropdown
          selection
          value={countryCode}
          onChange={onChangeCountryCode}
          options={countryCodes.map(code => ({
            key: code,
            value: code,
            text: code
          }))}
          style={{ minWidth: 'auto', width: '100%' }}
          disabled={locked}
          data-cy={`${dataCy}-country-code`}
        />
      </div>
      <div className={styles.phoneNumber}>
        <Input
          pattern="^\d\d*$"
          placeholder="123454343"
          value={locked ? lockPhoneNumber(phoneNumber) : phoneNumber}
          onChange={onChangePhoneNumber}
          required={required}
          disabled={locked}
          data-cy={`${dataCy}-phone-number`}
        />
      </div>
      {lockable && (
        <div className={styles.lock}>
          <Button
            icon
            primary
            disabled={!phoneNumber}
            type="button"
            onClick={() => {
              /* 
                If the user clicks again to unlock the phone number,
                the phone number field (area code + number) must be cleared,
                and the user must be able to enter a value again */
              if (locked) {
                onChangeCountryCode(null, {
                  value:
                    countryCodes.find(item => item === '+45') || countryCodes[0]
                });
                onChangePhoneNumber(null, { value: '' });
              }
              onToggleLocked(!locked);
            }}
          >
            <FontAwesomeIcon icon={locked ? faLock : faUnlock} />
          </Button>
        </div>
      )}
    </div>
  );
};

PhoneInput.defaultProps = {
  countryCodeList: 'SENS'
};

PhoneInput.propTypes = {
  countryCodeList: PropTypes.oneOf(['SENS', 'SENS-2', 'NFA', 'NFA-2']),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  locked: PropTypes.bool,
  onToggleLocked: PropTypes.func,
  required: PropTypes.bool
};

export default PhoneInput;
