import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { exportAveragePdf } from 'api/measurements';
import { useBulkExport } from './use-bulk-export';

const ExportAveragePdf = ({ patients, onCompleted, disabled, children }) => {
  const [exporting, onExport] = useBulkExport({
    exportAction: 'modal',
    apiExport: exportAveragePdf,
    patients,
    onCompleted
  });

  return (
    <Button
      primary
      compact
      icon="download"
      content={children}
      loading={exporting}
      disabled={disabled}
      onClick={onExport}
    />
  );
};

ExportAveragePdf.propTypes = {
  patients: PropTypes.array.isRequired
};

export default ExportAveragePdf;
