import React from 'react';
import Body from 'containers/admin/report/dashboard';
import Layout from 'layouts/sens-admin';

const PageAdminReports = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageAdminReports;
