import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import Dash from 'components/dash';
import { getDisplay } from './utils';

const TimeAgo = ({ value, ago }) => {
  const t = useTranslate('time-ago');

  if (!value) {
    return ago ? <span>{t('never')}</span> : <Dash />;
  }

  const display = getDisplay(value);

  if (display.length === 0) {
    return <span>{ago ? t('now') : t('none')}</span>;
  }

  const text = display
    .map(item => {
      const { unit, value } = item;
      return t(unit, { count: value });
    })
    .join(', ');
  const surfix = ago ? t('ago') : '';

  return <span>{`${text} ${surfix}`}</span>;
};

TimeAgo.defaultProps = {
  ago: false
};

TimeAgo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ago: PropTypes.bool
};

export default TimeAgo;
