import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nAlgorithm } from './normalize';
import type { AlgorithmRaw } from './types';

export const fetchAlgorithms = async ({
  organizationId,
  projectId
}: Pick<RouteParams, 'organizationId' | 'projectId'>) => {
  const options = {
    params: {
      org_id: organizationId,
      project_id: projectId
    }
  };

  const { algorithms }: { algorithms: AlgorithmRaw[] } = await axiosClient
    .get('/api/1.0/projects/algorithms', options)
    .then(response => response.data.value);

  return algorithms.map(nAlgorithm);
};
