import { Headers, LastSynced, Name, Status } from 'fragments/sensor';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status,
    sortable: true
  },
  {
    name: 'lastSynced',
    header: Headers.lastSynced,
    cell: LastSynced
  }
];
