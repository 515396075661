import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Dropdown, Grid, Header, Input } from 'semantic-ui-react';

const DepartmentListHeader = ({
  hospitals,
  hospital,
  setHospital,
  search,
  onSearch
}) => {
  const t = useTranslate('icomp-admin-department');

  const options = hospitals
    ? hospitals
        .filter(h => h.roles && h.roles.includes('role/admin'))
        .map(h => ({ key: h.id, text: h.name, value: h.id }))
    : [];

  const onSelectHospital = useCallback(
    (event, { value }) => {
      setHospital(hospitals.find(h => h.id === value));
    },
    [hospitals, setHospital]
  );

  return (
    <Grid>
      <Grid.Row verticalAlign="bottom">
        <Grid.Column width={6}>
          <Header as="h4">{t('select-hospital')}</Header>
          <Dropdown
            placeholder={t('select-hospital')}
            selection
            options={options}
            value={hospital.id}
            onChange={onSelectHospital}
          />
        </Grid.Column>
        <Grid.Column width={10} textAlign="right">
          <Input
            value={search}
            onChange={onSearch}
            placeholder={t('common:search-by-name')}
            style={{ verticalAlign: 'top' }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DepartmentListHeader.propTypes = {
  hospitals: PropTypes.array,
  hospital: PropTypes.object,
  setHospital: PropTypes.func,
  // ---
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default DepartmentListHeader;
