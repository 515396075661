import moment from 'moment';
import type { ActivePatientForm } from './types';

export const getStartTime = ({
  startTimeType,
  startTimeValue
}: Pick<ActivePatientForm, 'startTimeType' | 'startTimeValue'>) => {
  switch (startTimeType) {
    case 'now':
      return moment().toISOString();

    case 'specific-time':
      return startTimeValue;

    case 'not-defined-yet':
    default:
      return null;
  }
};

export const getEndTime = ({
  startTimeType,
  startTimeValue,
  endTimeType,
  endTimeValue,
  endTimeDuration
}: Pick<
  ActivePatientForm,
  | 'startTimeType'
  | 'startTimeValue'
  | 'endTimeType'
  | 'endTimeDuration'
  | 'endTimeValue'
>) => {
  switch (endTimeType) {
    case 'now':
      return moment().toISOString();

    case 'specific-time':
      return endTimeValue;

    case 'duration': {
      const startTime = getStartTime({ startTimeType, startTimeValue });
      return startTime
        ? moment(startTime).add(endTimeDuration, 'days').toISOString()
        : null;
    }

    case 'not-defined-yet':
    default:
      return null;
  }
};

// To delete start-time or end-time (Setting not defined yet)
// the start_time or end_time field in the API request
// should include an empty string ""
export const getOverrideTime = (newTime: string | null, oldTime: string) => {
  if (oldTime && newTime === null) return '';
  return newTime;
};
