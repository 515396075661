import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { Divider, Header, Image, Segment } from 'semantic-ui-react';
import { fetchUser } from 'api/icomp-users';
import Flex from 'components/flex';
import LoadingSection from 'components/loading-section';
import Delete from './delete';
import icon from './icon.png';
import Info1 from './info1';
import Info2 from './info2';

const ICompAdminUserProfile = () => {
  const { hospitalId, departmentId, userId } = useParams();
  const scopeId = departmentId || hospitalId;

  const { fetching, data: user = {} } = useFetch({
    source: JSON.stringify({ scopeId, userId }),
    isJsonSource: true,
    apiFetch: fetchUser
  });

  return (
    <div
      style={{
        maxWidth: '66rem',
        margin: '6rem auto'
      }}
    >
      {fetching ? (
        <LoadingSection />
      ) : (
        <Segment
          padded="very"
          placeholder
          style={{ minHeight: '30rem', justifyContent: 'flex-start' }}
        >
          <Flex flex>
            <Flex grow="auto">
              <Image src={icon} />
            </Flex>

            <Flex grow={1} style={{ marginLeft: '3rem' }}>
              <Header as="h2" textAlign="left">
                {user.name}
              </Header>

              <Divider section />

              <Segment basic clearing style={{ padding: 0, margin: 0 }}>
                <Info1 user={user} />
              </Segment>

              <Divider section />

              <Segment basic clearing style={{ padding: 0, margin: 0 }}>
                <Info2 user={user} />
              </Segment>

              <Segment
                basic
                clearing
                style={{ padding: 0, margin: 0, marginTop: '8rem' }}
              >
                <Delete user={user} />
              </Segment>
            </Flex>
          </Flex>
        </Segment>
      )}
    </div>
  );
};

export default ICompAdminUserProfile;
