import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button, Icon } from 'semantic-ui-react';
import SelectDateModal from './modal';

const ExportDay = ({ date, stream }) => {
  const t = useTranslate('simple-sensor');
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button basic onClick={() => setOpen(true)}>
        <Icon name="download" />
        {t('daily-rythm-report')}
      </Button>
      {open && (
        <SelectDateModal
          date={date}
          stream={stream}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

ExportDay.propTypes = {
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired
};

export default ExportDay;
