import React from 'react';
import PropTypes from 'prop-types';
import { VictoryArea } from 'victory';

const style = {
  data: {
    fill: '#000000',
    fillOpacity: 0.0,
    stroke: '#000000',
    strokeWidth: 0
  }
};

const ZoomOut = ({ domain, onSelectTick }) => (
  <VictoryArea
    data={[
      { x: domain.x[0], y: domain.y[1], y0: domain.y[0] },
      { x: domain.x[1], y: domain.y[1], y0: domain.y[0] }
    ]}
    style={style}
    events={[
      {
        target: 'data',
        eventHandlers: {
          onClick: onSelectTick
        }
      }
    ]}
  />
);

ZoomOut.propTypes = {
  domain: PropTypes.shape({
    x: PropTypes.array.isRequired,
    y: PropTypes.array.isRequired
  }).isRequired,
  onSelectTick: PropTypes.func.isRequired
};

export default ZoomOut;
