import React from 'react';
import { useTranslate } from 'intl';
import { Icon } from 'semantic-ui-react';
import Dash from 'components/dash';

export { SensorName, SensorData } from './sensors';
export { StartTime } from './start-time';
export { DiaryUpdated } from './diary-updated';
export { EditButton } from './edit-button';
export { SendNotifButton } from './send-notif-button';
export { Actions } from './actions';

export const SubjectID = ({ data }) => {
  return <span>{data.name}</span>;
};

export const PhoneNumber = ({ data }) => {
  if (!data.phoneNumber) return <Dash />;
  return <span>{data.phoneNumber}</span>;
};

const colors = {
  'status/not_started': 'grey',
  'status/started': 'mediumSeaGreen',
  'status/completed': 'steelBlue'
};

export const Status = ({ data: { status } }) => {
  const t = useTranslate('nfa-subjects');

  return status ? (
    <div>
      <Icon name="circle" style={{ color: colors[status] }} />
      {` `}
      {t(status)}
    </div>
  ) : (
    <Dash />
  );
};
