/* eslint-disable */
import moment from 'moment';

const TS = {
  DAY: 24 * 60 * 60 * 1000,
  HOUR: 60 * 60 * 1000
};

const getXDomain = date => {
  const timestamp = moment(date).startOf('day').valueOf();
  return [timestamp, timestamp + TS.DAY];
};

const getXTicks = domain => {
  const ticks = [];
  const x = domain.x[0];
  for (let i = 0; i <= 24; i++) {
    ticks.push(x + i * TS.HOUR);
  }
  return ticks;
};

const getYTicks = (domain, step) => {
  const ticks = [];
  for (let i = domain.y[0]; i <= domain.y[1]; i += step) {
    ticks.push(i);
  }
  return ticks;
};

export default {
  getXDomain,
  getXTicks,
  getYTicks
};
