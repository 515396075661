import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import { Header, Segment } from 'semantic-ui-react';
import { fetchPatient } from 'api/patients';
import ErrorMessage from 'components/error-message';
import LoadingSection from 'components/loading-section';
import { withAlgorithms } from '../dashboard/algorithms-context';
import Info from './info';
import Plot from './plot';

const PatientDetails = () => {
  const t = useTranslate('patient');
  const { organizationId, projectId, patientId } = useParams();
  const {
    fetching,
    data: patient,
    error,
    onUpdate
  } = useFetch({
    source: JSON.stringify({ organizationId, projectId, patientId }),
    isJsonSource: true,
    apiFetch: fetchPatient,
    refresh: 20000
  });

  if (fetching) return <LoadingSection />;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div>
      <Header as="h2">{t('patient-details')}</Header>
      <Segment>
        <Info patient={patient} onUpdate={onUpdate} />
      </Segment>
      <Segment>
        <Plot patient={patient} />
      </Segment>
    </div>
  );
};

export default withAlgorithms(PatientDetails);
