import React from 'react';
import { useSearch } from 'hooks';
import Header from './header';
import Table from './table';
import utils from './utils';

const DepartmentList = ({
  fetching,
  error,
  hospitals,
  hospital,
  setHospital
}) => {
  const [search, onSearch] = useSearch({ uppercase: true });

  const departments = utils.filterData({
    all: hospital.departments || [],
    search
  });

  return (
    <React.Fragment>
      <Header
        hospitals={hospitals}
        hospital={hospital}
        setHospital={setHospital}
        search={search}
        onSearch={onSearch}
      />
      <Table
        fetching={fetching}
        error={error}
        hospital={hospital}
        departments={departments}
      />
    </React.Fragment>
  );
};

export default DepartmentList;
