import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Modal } from 'semantic-ui-react';
import Form from '../form';

const ISpinePatientDashboardAdd = ({ onSubmit }) => {
  const t = useTranslate('ispine-patient');
  const [open, toggleOpen] = useToggle(false);

  return (
    <Modal
      open={open}
      size="small"
      trigger={
        <Button
          icon="add"
          color="blue"
          style={{ marginTop: '1px', marginRight: '0', verticalAlign: 'top' }}
          onClick={toggleOpen}
        />
      }
    >
      <Modal.Header>{t('add-patient')}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit} onCancel={toggleOpen} />
      </Modal.Content>
    </Modal>
  );
};

ISpinePatientDashboardAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ISpinePatientDashboardAdd;
