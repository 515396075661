import React from 'react';
import PropTypes from 'prop-types';
import { getVendor } from 'utils/vendor';

const logos = {
  sens: '/logo/sens-logo.png',
  icomp: '/logo/icomp-logo.png',
  demos: '/logo/brane-logo.png',
  nfa: '/logo/nfa-logo.png',
  fibion: '/logo/fibion-logo.png'
};

const widths = {
  medium: '9rem',
  large: '12rem'
};

const Logo = ({ size }) => {
  const vendor = getVendor();
  return (
    <img
      alt="Logo"
      src={`${process.env.PUBLIC_URL}${logos[vendor]}`}
      style={{ display: 'inline-block', width: widths[size] }}
    />
  );
};

Logo.defaultProps = {
  size: 'medium'
};

Logo.propTypes = {
  size: PropTypes.oneOf(['medium', 'large'])
};

export default Logo;
