import React from 'react';
import PropTypes from 'prop-types';
import { Translate, useTranslate } from 'intl';
import { Button as SButton } from 'semantic-ui-react';
import ErrorMessage from 'components/error-message';
import { withConfirm } from 'components/hoc-components';

const ButtonSleep = withConfirm(SButton, {
  headerText: (
    <Translate ns="sensor" i18nKey="put-to-sleep-confirmation-title" />
  ),
  bodyText: (
    <Translate ns="sensor" i18nKey="put-to-sleep-confirmation-description" />
  ),
  confirmColor: 'blue'
});

const ButtonWakeUp = withConfirm(SButton, {
  headerText: <Translate ns="sensor" i18nKey="wakeup-confirmation-title" />,
  bodyText: <Translate ns="sensor" i18nKey="wakeup-confirmation-description" />,
  confirmColor: 'green'
});

const SensorDetailsControlFragment = ({
  data: { status, runStateRequested },
  submitting,
  error,
  onToggle
}) => {
  const t = useTranslate('sensor');
  const Button = runStateRequested === 0 ? ButtonWakeUp : ButtonSleep;

  return (
    <div>
      <p>
        {t(`confirmation/${status}`)}
        <Button
          basic
          loading={submitting}
          disabled={submitting}
          color={runStateRequested === 0 ? 'green' : 'blue'}
          onConfirm={onToggle}
          style={{ marginLeft: '1rem' }}
        >
          {runStateRequested === 0 ? t('wakeup-cta') : t('put-to-sleep-cta')}
        </Button>
      </p>

      <ErrorMessage error={error} />
    </div>
  );
};

SensorDetailsControlFragment.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  // ---
  error: PropTypes.object
};

export default SensorDetailsControlFragment;
