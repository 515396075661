import { createSorter } from 'utils/sort';
import {
  CustomHeader,
  Days,
  Headers,
  LastMobilised,
  Name,
  Action,
  SensorName,
  TodayActivity,
  TodaySteps,
  YesterdayActivity,
  YesterdaySteps,
  getActionOrder
} from './elements';

export const columns = [
  {
    name: 'name',
    header: Headers.name,
    cell: Name,
    sortable: true,
    sorter: createSorter(data => `${data.firstName} ${data.lastName}`)
  },
  {
    name: 'sensor-name',
    header: Headers['sensor-name'],
    cell: SensorName,
    sortable: true,
    align: 'center',
    sorter: createSorter(data => data.sensorName)
  },
  {
    name: 'days',
    header: Headers.days,
    cell: Days,
    sortable: true,
    align: 'center',
    sorter: createSorter(data => data.days)
  },
  {
    name: 'today-activity',
    header: Headers['today-activity'],
    cell: TodayActivity,
    sortable: true,
    align: 'center',
    sorter: createSorter(data => data.todayActive)
  },
  {
    name: 'yesterday-activity',
    header: Headers['yesterday-activity'],
    cell: YesterdayActivity,
    sortable: true,
    align: 'center',
    sorter: createSorter(data => data.yesterdayActive)
  },
  {
    name: 'today-steps',
    header: Headers['today-steps'],
    cell: TodaySteps,
    sortable: true,
    align: 'center',
    sorter: createSorter(data => data.todaySteps)
  },
  {
    name: 'yesterday-steps',
    header: Headers['yesterday-steps'],
    cell: YesterdaySteps,
    sortable: true,
    align: 'center',
    sorter: createSorter(data => data.yesterdaySteps)
  },
  {
    name: 'last-mobilised',
    header: Headers['last-mobilised'],
    cell: LastMobilised,
    sortable: true,
    align: 'center',
    sorter: createSorter(data => data.lastMobilizedTime)
  },
  {
    name: 'action',
    header: Headers.action,
    cell: Action,
    sortable: true,
    align: 'center',
    sorter: createSorter(getActionOrder)
  }
];

export { CustomHeader };
