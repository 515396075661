import React from 'react';
import { Header } from 'semantic-ui-react';
import styles from './styles.module.css';

const Section = ({ title, children }) => {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Header>{title}</Header>
      </div>
      {children}
    </div>
  );
};

export default Section;
