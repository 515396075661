// This must be the first line
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import App from './app';
import './polyfills';
import './styles.css';

// Setup Sentry
if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DNS,
    release: process.env.REACT_APP_SENTRY_RELEASE
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
