import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import utils1 from 'components/plot/victory/capacitance/utils';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import {
  AxisX,
  AxisY,
  Chart,
  Line,
  Scatter
} from 'components/plot/victory/elements';
import ChartConfig from './chart-config';
import Dividers from './dividers';
import utils2 from './utils';

const utils = { ...utils1, ...utils2 };

const ICompCapacitance24h = ({ startDate, endDate, values, measurements }) => {
  const t = useTranslate('plot');
  const [unit, setUnit] = useState('pF');
  const [yRange, setYRange] = useState('full');

  const { ts, v } = values.streams;

  const data = ts.map((t, i) => ({
    x: t,
    y: unit === 'pF' ? v[i] : v[i] * 0.8
  }));

  const diff = utils.getDiff(startDate, endDate);

  const domain = {
    x: utils.getXDomain(diff, startDate, endDate),
    y: utils.getYDomainCapacitance(data, yRange)
  };

  const xTicks = utils.getXTicks(diff, domain);
  const xTickFormat = utils.getXTickFormat(diff);
  const xLabel = diff > 1 ? '' : t('time-in-hours');

  const yStep = utils.getYStep(domain.y);
  const yTicks = utils.getYTicks(domain, yStep, unit);

  return (
    <div>
      <ChartConfig
        name="capacitance-24h"
        unit={unit}
        setUnit={setUnit}
        yRange={yRange}
        setYRange={setYRange}
      />
      <Chart domain={domain} width={800} height={400}>
        {AxisX({ tickValues: xTicks, label: xLabel, tickFormat: xTickFormat })}
        {AxisY({ tickValues: yTicks, label: unit })}
        {Line({ data, stroke: '#ff0000' })}
        {Scatter({ data, stroke: '#ff0000' })}
        {Dividers({ data: measurements })}
      </Chart>
    </div>
  );
};

ICompCapacitance24h.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  measurements: PropTypes.array.isRequired
};

export default ICompCapacitance24h;
