import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import Timezone from 'components/timezone';
import ChangeName from './change-name';
import ChangePassword from './change-password';
import ChangePhone from './change-phone';
import ChangeTimezone from './change-timezone';
import { Loading, Row, Success, Wrapper } from './elements';

const SettingsFragment = ({
  fetching,
  data,
  edit,
  onToggle,
  success,
  onSuccess,
  onDismiss
}) => {
  const t = useTranslate('settings');

  if (fetching) {
    return (
      <Wrapper>
        <Row label={t('email')}>
          <Loading />
        </Row>
        <Row label={t('phone')}>
          <Loading />
        </Row>
        <Row label={t('name')}>
          <Loading />
        </Row>
        <Row label={t('timezone')}>
          <Loading />
        </Row>
        <Row label={t('password')}>
          <Loading />
        </Row>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row label={t('email')}>{data.email}</Row>
      <Row label={t('phone')}>
        {edit.phone ? (
          <ChangePhone
            defaultValue={data.phone}
            onSuccess={onSuccess.phone}
            onCancel={onToggle.phone}
          />
        ) : (
          <div>
            {data.phone}
            <Button
              basic
              compact
              icon="edit outline"
              size="small"
              style={{ marginLeft: '1rem' }}
              onClick={onToggle.phone}
            />
            <Success
              visible={success.phone}
              onDismiss={onDismiss.phone}
              message={t('change-phone-success')}
            />
          </div>
        )}
      </Row>
      <Row label={t('name')}>
        {edit.name ? (
          <ChangeName
            defaultFirstName={data.firstName}
            defaultLastName={data.lastName}
            onSuccess={onSuccess.name}
            onCancel={onToggle.name}
          />
        ) : (
          <div>
            {`${data.firstName} ${data.lastName}`}
            <Button
              basic
              compact
              icon="edit outline"
              size="small"
              style={{ marginLeft: '1rem' }}
              onClick={onToggle.name}
            />
            <Success
              visible={success.name}
              onDismiss={onDismiss.name}
              message={t('change-name-success')}
            />
          </div>
        )}
      </Row>
      <Row label={t('timezone')}>
        {edit.timezone ? (
          <ChangeTimezone
            defaultValue={data.timezone}
            onSuccess={onSuccess.timezone}
            onCancel={onToggle.timezone}
          />
        ) : (
          <div>
            <Timezone value={data.timezone} />
            <Button
              basic
              compact
              icon="edit outline"
              size="small"
              style={{ marginLeft: '1rem' }}
              onClick={onToggle.timezone}
            />
            <Success
              visible={success.timezone}
              onDismiss={onDismiss.timezone}
              message={t('change-timezone-success')}
            />
          </div>
        )}
      </Row>
      <Row label={t('password')}>
        {edit.password ? (
          <ChangePassword
            onSuccess={onSuccess.password}
            onCancel={onToggle.password}
          />
        ) : (
          <div>
            <Button basic color="blue" onClick={onToggle.password}>
              {t('change-password')}
            </Button>
            <Success
              visible={success.password}
              onDismiss={onDismiss.password}
              message={t('change-password-success')}
            />
          </div>
        )}
      </Row>
    </Wrapper>
  );
};

SettingsFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  data: PropTypes.object,
  // ---
  edit: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
  // ---
  onToggle: PropTypes.object.isRequired,
  onSuccess: PropTypes.object.isRequired,
  onDismiss: PropTypes.object.isRequired
};

export default SettingsFragment;
