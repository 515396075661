import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import ColoredDatePicker from 'components/colored-date-picker';
import styles from './styles.module.css';

const PlotDateSelect = ({
  value,
  onChange,
  minDate,
  maxDate,
  dayStatistics,
  disabled
}) => {
  const handleChange = useCallback(
    (event, { value }) => {
      const nextDate = moment(value).startOf('day').format();
      onChange(nextDate);
    },
    [onChange]
  );

  const onAdd = useCallback(
    quantity => {
      const nextDate = moment(value).add(quantity, 'day').format();
      onChange(nextDate);
    },
    [value, onChange]
  );

  const onClickPrev = useCallback(() => onAdd(-1), [onAdd]);
  const onClickNext = useCallback(() => onAdd(1), [onAdd]);

  const disabledPrev = minDate ? moment(value).isSameOrBefore(minDate) : false;
  const disabledNext = maxDate ? moment(value).isSameOrAfter(maxDate) : false;

  return (
    <div className={styles.main}>
      <Button
        basic
        icon="left arrow"
        disabled={disabled || disabledPrev}
        onClick={onClickPrev}
      />
      <div className={styles.picker}>
        <ColoredDatePicker
          value={value}
          onChange={handleChange}
          // ---
          minDate={minDate}
          maxDate={maxDate}
          // ---
          dayStatistics={dayStatistics}
          // ---
          disabled={disabled}
        />
      </div>
      <Button
        basic
        icon="right arrow"
        disabled={disabled || disabledNext}
        onClick={onClickNext}
      />
    </div>
  );
};

PlotDateSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // ---
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  dayStatistics: PropTypes.object,
  // ---
  disabled: PropTypes.bool
};

export default PlotDateSelect;
