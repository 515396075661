import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Flex = ({
  flex,
  direction,
  justify,
  align,
  grow,
  children,
  ...otherProps
}) => {
  return (
    <div
      className={`
        ${flex ? styles.flex : ''}
        ${direction ? styles[`direction-${direction}`] : ''}
        ${justify ? styles[`justify-${justify}`] : ''}
        ${align ? styles[`align-${align}`] : ''}
        ${grow ? styles[`grow-${grow}`] : ''}
      `}
      {...otherProps}
    >
      {children}
    </div>
  );
};

Flex.propTypes = {
  flex: PropTypes.bool,
  direciton: PropTypes.oneOf(['column', 'column-reverse']),
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between'
  ]),
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  grow: PropTypes.oneOf([1, 2, 3, 'auto']),
  children: PropTypes.node
};

export default Flex;
