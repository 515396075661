import React from 'react';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import WebAppVersion from 'fragments/web-app-version';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { forgotPassword } from 'api/user';
import ErrorMessage from 'components/error-message';
import Success from './success';

const ForgotPassword = () => {
  const t = useTranslate('forgot-password');

  const {
    form,
    onChanges,
    onSubmit,
    submitting,
    success,
    error,
    onClearError
  } = useForm({
    initialForm: { email: '' },
    apiSubmit: form => forgotPassword(form.email)
  });

  if (success) {
    return <Success />;
  }

  return (
    <div className="forgot-password-form">
      <style>{`body > div,body > div > div,body > div > div > div.forgot-password-form {height: 100%;}`}</style>
      <Grid
        textAlign="center"
        style={{ height: '100%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="blue" textAlign="center">
            {t('forgot-password')}
            <Header.Subheader style={{ marginTop: '1rem' }}>
              {t('forgot-password-instruction')}
            </Header.Subheader>
          </Header>

          <Form size="large" onSubmit={onSubmit}>
            <Segment stacked>
              <Form.Input
                fluid
                required
                icon="user"
                iconPosition="left"
                placeholder={t('email')}
                type="email"
                value={form.email}
                onChange={(event, { value }) => onChanges.email(value)}
              />
              <Button
                type="submit"
                fluid
                color="blue"
                size="large"
                loading={submitting}
                content={t('reset-password')}
              />
            </Segment>
          </Form>

          <ErrorMessage
            error={error}
            onDismiss={onClearError}
            attached="bottom"
          />

          <WebAppVersion />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
