import React from 'react';
import { useMatch } from 'react-router-dom';
import Body from 'containers/monitor/dashboard';

const PageMonitorEnglish = props => {
  const match = useMatch('/monitor/healthcare/en/:uuid/:overrideToday/*');
  const { uuid, overrideToday } = match?.params || {};
  return (
    <Body language="en" uuid={uuid} overrideToday={overrideToday} {...props} />
  );
};

export default PageMonitorEnglish;
