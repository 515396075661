import { useCallback, useState } from 'react';

const useToggle = initialValue => {
  const [value, setValue] = useState(initialValue || false);
  const onToggle = useCallback(() => {
    setValue(v => !v);
  }, []);
  return [value, onToggle];
};

export default useToggle;
