import React from 'react';
import PropTypes from 'prop-types';
import { useFilter, useSearch } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Segment } from 'semantic-ui-react';
import Header from './header';
import TableControl from './table-control';
import TableInfo from './table-info';
import utils from './utils';

const SensorDashboardFragment = ({
  fetching,
  error,
  data,
  controlMode,
  onToggleControlMode,
  onUpdateData
}) => {
  const t = useTranslate('sensor');
  const [filter, onFilter] = useFilter('sensor_status/available');
  const [search, onSearch] = useSearch({ uppercase: true });

  return (
    <React.Fragment>
      <Header
        search={search}
        onSearch={onSearch}
        filter={filter}
        onFilter={onFilter}
        disabled={controlMode}
      />
      {controlMode ? (
        <TableControl
          fetching={fetching}
          error={error}
          data={utils.filterData({ data, search, filter })}
          onExit={onToggleControlMode}
          onUpdate={onUpdateData}
        />
      ) : (
        <TableInfo
          fetching={fetching}
          error={error}
          data={utils.filterData({ data, search, filter })}
        />
      )}

      {!controlMode && (
        <Segment basic clearing style={{ padding: '0' }}>
          <Button
            content={t('control-mode')}
            icon="setting"
            color="blue"
            floated="right"
            onClick={onToggleControlMode}
          />
        </Segment>
      )}
    </React.Fragment>
  );
};

SensorDashboardFragment.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array,
  // ---
  controlMode: PropTypes.bool.isRequired,
  onToggleControlMode: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired
};

export default SensorDashboardFragment;
