import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFetch } from 'hooks';
import { fetchSensors } from 'api/sensors';
import { getSensorParams } from 'utils/vendor';

const SensorDisplay = ({ sensorId, render }) => {
  const params = useParams();
  const { data = [] } = useFetch({
    source: JSON.stringify({ ...getSensorParams(params), cache: true }),
    isJsonSource: true,
    apiFetch: fetchSensors
  });
  const sensor = data.find(item => item.id === sensorId);
  return render(sensor);
};

SensorDisplay.propTypes = {
  sensorId: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired
};

export default SensorDisplay;
