import React, { useState } from 'react';
import { useToggleOpen } from 'hooks';
import { useTranslate } from 'intl';
import { faCircleStop } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { commandExportQueue } from 'api/export';
import { useExportController } from '../../context';

const CancelFile = ({ data }) => {
  const t = useTranslate('export-controller');
  const [isOpenConfirmModal, onOpenConfirmModal, onCloseConfirmModal] =
    useToggleOpen(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const { removeExportEntry } = useExportController();

  const onCancel = () => {
    setIsCancelling(true);
    commandExportQueue({ resource_uuid: data.uuid, command: 'cancel' })
      .then(() => {
        removeExportEntry(data);
        setIsCancelling(false);
      })
      .catch(() => {
        setIsCancelling(false);
      });
  };

  return (
    <Modal
      size="tiny"
      open={isOpenConfirmModal}
      trigger={
        <Popup
          trigger={
            <Button
              icon
              style={{ margin: 0, width: 38, height: 38 }}
              onClick={onOpenConfirmModal}
            >
              <FontAwesomeIcon icon={faCircleStop} />
            </Button>
          }
          content={t('cancel-file/tooltip')}
        />
      }
    >
      <Modal.Header>{t('cancel-file/confirm-title')}</Modal.Header>
      <Modal.Content>
        <p style={{ fontSize: 'larger' }}>{t('cancel-file/confirm-desc')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseConfirmModal}>{t('common:cancel')}</Button>
        <Button negative loading={isCancelling} onClick={onCancel}>
          <Icon name="checkmark" />
          {t('common:ok')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CancelFile;
