import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const Name = ({ data }) => {
  const t = useTranslate('external-patient');

  return (
    <div>
      <div>{data.name}</div>
      <div style={{ fontSize: 'smaller' }}>
        {t('day', { count: data.admittedTime })}
      </div>
    </div>
  );
};

Name.propTypes = {
  data: PropTypes.object.isRequired
};

export default Name;
