/* eslint-disable dot-notation */
import moment from 'moment';
import { getFullName } from 'utils/patient';
import { createSearchRegex } from 'utils/search';
import { createSorter } from 'utils/sort';

const getCreateDateOrder = data => data.idx;

const getSensorNameOrder = data =>
  data.sensors?.length ? data.sensors[0].name : null;

const getPatientOrder = data => getFullName(data);

const getStartTimeOrder = data => data.startTime;

const getEndTimeOrder = data => data.endTime;

const getDurationOrder = data => {
  if (!data.startTime) return 0;

  if (data.endTime) return moment(data.endTime).diff(moment(data.startTime));

  return moment().diff(data.startTime);
};

const statusOrders = [
  'status/measurement_ex/not_started', // Gray
  'status/measurement_ex/completed_ok', // Blue
  'status/measurement_ex/in_progress_ok', // Green
  'status/measurement_ex/in_progress_pending', // Yellow
  'status/measurement_ex/completed_pending', // Yellow
  'status/measurement_ex/in_progress_warning', // Orange
  'status/measurement_ex/in_progress_warning_severe', // Orange
  'status/measurement_ex/in_progress_sensor_off' // Orange
];
const getStatusOrder = data =>
  statusOrders.indexOf(data.measurementStatusEx) + 1;

const orders = {
  'create-date': getCreateDateOrder,
  'sensor-name': getSensorNameOrder,
  patient: getPatientOrder,
  'start-time': getStartTimeOrder,
  'end-time': getEndTimeOrder,
  duration: getDurationOrder,
  status: getStatusOrder
};

const sorters = {
  'create-date': createSorter(orders['create-date']),
  'sensor-name': createSorter(orders['sensor-name']),
  patient: createSorter(orders['patient']),
  'start-time': createSorter(orders['start-time']),
  'end-time': createSorter(orders['end-time']),
  duration: createSorter(orders['duration']),
  status: createSorter(orders['status'])
};

const statusFilters = {
  'not-started-yet': ['status/measurement_ex/not_started'],
  'in-progress': [
    'status/measurement_ex/in_progress_ok',
    'status/measurement_ex/in_progress_pending',
    'status/measurement_ex/in_progress_warning',
    'status/measurement_ex/in_progress_warning_severe',
    'status/measurement_ex/in_progress_sensor_off'
  ],
  completed: [
    'status/measurement_ex/completed_ok',
    'status/measurement_ex/completed_pending'
  ]
};

export const filterData = ({
  data,
  sortAccessor,
  sortDirection,
  searchQuery,
  filterStatus
}) => {
  let result = data.slice();

  if (searchQuery) {
    const searchRegex = createSearchRegex(searchQuery);
    result = result.filter(item =>
      `${item.firstName} ${item.lastName}`.match(searchRegex)
    );
  }

  if (sortAccessor) {
    const sorter = sorters[sortAccessor];
    let result1 = result.filter(item => orders[sortAccessor](item));
    const result2 = result.filter(item => !orders[sortAccessor](item));
    result1 = result1.sort(sorter);
    if (sortDirection === 'desc') {
      result1.reverse();
    }
    result = [...result1, ...result2];
  }

  if (filterStatus.length) {
    const status = filterStatus.reduce(
      (prev, curr) => prev.concat(...statusFilters[curr]),
      []
    );
    result = result.filter(item => status.includes(item.measurementStatusEx));
  }

  return result;
};

export const isDisabledExport = patient =>
  patient.measurementStatusEx === 'status/measurement_ex/not_started';
