import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate } from 'intl';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { deleteSubject } from 'api/nfa-subjects';

const SubjectDeleteModal = ({ data: subject, onSuccess, onCancel }) => {
  const t = useTranslate('nfa-subjects');
  const params = useParams();
  const [deleting, setDeleting] = useState(false);

  const onConfirm = async () => {
    setDeleting(true);
    deleteSubject({ params, subject })
      .then(() => {
        setDeleting(false);
        onSuccess(subject);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  return (
    <Modal open size="tiny">
      <Header>
        <Icon name="trash alternate outline" />
        <Header.Content>{t('delete-subject')}</Header.Content>
      </Header>
      <Modal.Content>
        <p>{t('delete-subject-confirmation')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{t('common:cancel')}</Button>
        <Button
          icon
          labelPosition="right"
          color="red"
          loading={deleting}
          onClick={onConfirm}
        >
          <Icon name={'checkmark'} />
          {t('common:delete')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SubjectDeleteModal;
