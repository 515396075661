import React from 'react';
import Body from 'containers/icomp-admin/user-registration';
import Layout from 'layouts/icomp-admin';

const PageICompAdminUserRegistration = props => (
  <Layout>
    <Body {...props} />
  </Layout>
);

export default PageICompAdminUserRegistration;
