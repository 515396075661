import axios from 'axios';
import { axiosClient } from 'api/axios-client';
import type { CancelExecutor } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { nHospitalPatientMeasurement } from './normalize';
import type { HospitalPatientMeasurementRaw } from './types';

export const fetchPatients = async (
  {
    organizationId,
    projectId
  }: Pick<RouteParams, 'organizationId' | 'projectId'>,
  setCancel: CancelExecutor
) => {
  const params = {
    org_id: organizationId,
    project_id: projectId
  };
  const cancelToken = new axios.CancelToken(setCancel);
  const options = { params, cancelToken };

  const { measurements }: { measurements: HospitalPatientMeasurementRaw[] } =
    await axiosClient
      .get('/api/1.0/measurements', options)
      .then(response => response.data.value);

  return measurements.map((item, index) => ({
    ...nHospitalPatientMeasurement(item),
    idx: index + 1
  }));
};
