import React from 'react';
import { useTranslate } from 'intl';
import { Dropdown, Form } from 'semantic-ui-react';
import Section from './section';
import SensorSelect from './sensor-select';
import { getSensorPlaces } from './utils';

const SensorInfo = ({ form, onChanges, algorithms, validations }) => {
  const t = useTranslate('patient');

  return (
    <Section title={t('form.sensor-info')}>
      <Form.Group widths={2}>
        <Form.Field
          label={t('form.measurement-method')}
          control={Dropdown}
          placeholder={t('form.measurement-method')}
          selection
          options={algorithms.map(algorithm => ({
            key: algorithm.name,
            text: t(`alg_profile:${algorithm.name}`),
            value: algorithm.name
          }))}
          value={form.measurementMethod}
          onChange={(event, { value }) => onChanges.measurementMethod(value)}
          error={
            validations?.measurementMethod
              ? { content: validations?.measurementMethod }
              : false
          }
        />
      </Form.Group>

      {getSensorPlaces(form, algorithms).map(place => {
        switch (place) {
          case 'person/thigh':
            return (
              <SensorSelect
                key={place}
                label={t('form.sensor-thigh')}
                placeholder={t('form.sensor-thigh')}
                value={form.sensorThigh}
                onChange={(event, { value }) => onChanges.sensorThigh(value)}
                error={
                  validations?.sensorThigh
                    ? { content: validations?.sensorThigh }
                    : false
                }
              />
            );
          case 'person/chest':
            return (
              <SensorSelect
                key={place}
                label={t('form.sensor-chest')}
                placeholder={t('form.sensor-chest')}
                value={form.sensorChest}
                onChange={(event, { value }) => onChanges.sensorChest(value)}
                error={
                  validations?.sensorChest
                    ? { content: validations?.sensorChest }
                    : false
                }
              />
            );
          case 'person/back':
            return (
              <SensorSelect
                key={place}
                label={t('form.sensor-back')}
                placeholder={t('form.sensor-back')}
                value={form.sensorThigh}
                onChange={(event, { value }) => onChanges.sensorThigh(value)}
              />
            );
          default:
            return null;
        }
      })}
    </Section>
  );
};

export default SensorInfo;
