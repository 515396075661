import React, { useEffect } from 'react';
import Layout from 'layouts/sens';

const ResetPage = () => {
  useEffect(() => {
    localStorage.clear();
    window.location.replace('/');
  });

  return (
    <Layout>
      <p>Resetting, please wait</p>
    </Layout>
  );
};

export default ResetPage;
