import React from 'react';
import { useToggle } from 'hooks';
import { useTranslate } from 'intl';
import NewTreatmentModal from 'fragments/icomp-patient/new-treatment-modal';
import { Button, Modal } from 'semantic-ui-react';

const NewTrack = ({ patientDetails }) => {
  const t = useTranslate('icomp-patient');
  const [open, toggleOpen] = useToggle(false);

  const { patient, activeMeasurement } = patientDetails;
  const { track } = activeMeasurement || {};
  const label =
    track && track.status !== 'closed' ? t('new-treatment') : t('new-track');

  return (
    <Modal
      open={open}
      size="small"
      trigger={
        <Button
          primary
          data-cy="patient-details__new-treatment-button"
          onClick={toggleOpen}
        >
          {label}
        </Button>
      }
    >
      <div style={{ padding: '2em 0' }}>
        <NewTreatmentModal
          header={label}
          patient={patient}
          treatment={activeMeasurement}
          onSubmit={() => window.location.reload()}
          onCancel={toggleOpen}
        />
      </div>
    </Modal>
  );
};

export default NewTrack;
