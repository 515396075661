import { createSearchRegex } from 'utils/search';

const filterByStatus = ({ data, filter }) => {
  if (filter === 'sensor_status/all') return data;
  if (filter === 'sensor_status/available') {
    return data.filter(item => item.status !== 'sensor_status/expired');
  }
  return data.filter(item => item.status === filter);
};

const filterBySearchQuery = ({ data, search }) => {
  if (!search) return data;
  const searchRegex = createSearchRegex(search);
  return data.filter(item => item.name.match(searchRegex));
};

const filterData = ({ data, search, filter }) => {
  const data1 = filterByStatus({ data, filter });
  const data2 = filterBySearchQuery({ data: data1, search });
  return data2;
};

export default { filterData };
