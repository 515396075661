const getBaseCountryCodes = list => {
  switch (list) {
    case 'SENS-2':
      return ['+45', '+82'];
    case 'NFA':
      return [
        '+45',
        '+1',
        '+31',
        '+32',
        '+32',
        '+33',
        '+34',
        '+39',
        '+41',
        '+43',
        '+44',
        '+45',
        '+46',
        '+47',
        '+48',
        '+49',
        '+61',
        '+64',
        '+65',
        '+354',
        '+358',
        '+372'
      ];
    case 'NFA-2':
      return [
        '+1',
        '+31',
        '+32',
        '+33',
        '+34',
        '+39',
        '+41',
        '+43',
        '+44',
        '+45',
        '+46',
        '+47',
        '+48',
        '+49',
        '+61',
        '+64',
        '+65',
        '+354',
        '+358',
        '+372'
      ];
    case 'SENS':
    default:
      return ['+45'];
  }
};

export const getCountryCodes = list => {
  const baseCountryCodes = getBaseCountryCodes(list);
  if (process.env.NODE_ENV === 'development') {
    return [...baseCountryCodes, '+99'];
  }
  return baseCountryCodes;
};

export const lockPhoneNumber = phoneNumber => {
  const start = phoneNumber.slice(0, -2).replace(/./g, '*');
  const end = phoneNumber.slice(-2);
  return `${start}${end}`;
};
