import React, { useState } from 'react';
import { useToggleOpen } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { commandExportQueue } from 'api/export';
import { useExportController } from '../../context';

const DeleteFile = ({ data }) => {
  const t = useTranslate('export-controller');
  const [isOpenConfirmModal, onOpenConfirmModal, onCloseConfirmModal] =
    useToggleOpen(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { removeExportEntry } = useExportController();

  const onDelete = () => {
    setIsDeleting(true);
    commandExportQueue({ resource_uuid: data.uuid, command: 'remove' })
      .then(() => {
        removeExportEntry(data);
        setIsDeleting(false);
      })
      .catch(() => {
        setIsDeleting(false);
      });
  };

  return (
    <Modal
      size="tiny"
      open={isOpenConfirmModal}
      trigger={
        <Popup
          trigger={
            <Button
              icon="trash alternate"
              style={{ margin: 0, width: 38, height: 38 }}
              onClick={onOpenConfirmModal}
            />
          }
          content={t('delete-file/tooltip')}
        />
      }
    >
      <Modal.Header>{t('delete-file/confirm-title')}</Modal.Header>
      <Modal.Content>
        <p style={{ fontSize: 'larger' }}>{t('delete-file/confirm-desc')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseConfirmModal}>{t('common:cancel')}</Button>
        <Button negative loading={isDeleting} onClick={onDelete}>
          <Icon name="checkmark" />
          {t('common:ok')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteFile;
