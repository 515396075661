import type { CancelExecutor } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import { fetchMeasurements } from './fetch-measurements';

export const fetchMeasurement = async (
  {
    organizationId,
    projectId,
    measurementId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'measurementId'>,
  setCancel: CancelExecutor
) =>
  fetchMeasurements({ organizationId, projectId }, setCancel).then(response => {
    const data = response.find(item => item.id === measurementId);
    return data;
  });
