import React from 'react';
import PropTypes from 'prop-types';
import { LastOnline, Name } from 'fragments/sensor';

const Sensor = ({ data }) => {
  return (
    <div>
      <div>
        <Name data={data.sensor} />
      </div>
      <div style={{ fontSize: 'smaller' }}>
        <LastOnline data={data.sensor} />
      </div>
    </div>
  );
};

Sensor.propTypes = {
  data: PropTypes.object.isRequired
};

export default Sensor;
