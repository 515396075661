import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { useTranslate } from 'intl';
import routes from 'routes';
import {
  Button,
  Divider,
  Header,
  Icon,
  Image,
  Segment
} from 'semantic-ui-react';
import { fetchDepartment } from 'api/icomp-departments';
import LoadingSection from 'components/loading-section';
import EditDepartment from './edit-department';
import icon from './icon.png';
import styles from './styles.module.css';

const ICompAdminDepartmentProfile = () => {
  const t = useTranslate('icomp-admin-department');
  const params = useParams();
  const { hospitalId, departmentId } = params;
  const { fetching, data: department } = useFetch({
    source: JSON.stringify({ hospitalId, departmentId }),
    isJsonSource: true,
    apiFetch: fetchDepartment
  });

  return fetching ? (
    <LoadingSection />
  ) : (
    <div className={styles.main}>
      <Segment
        padded="very"
        placeholder
        style={{ minHeight: '30rem', justifyContent: 'flex-start' }}
      >
        <div className={styles.row}>
          <div className={styles.left}>
            <Image src={icon} />
          </div>
          <div className={styles.right}>
            <Header as="h2" textAlign="left">
              {department.name}
            </Header>

            <Divider section />

            <Segment basic clearing style={{ padding: 0, margin: 0 }}>
              <div className={styles.field}>
                <div className={styles.label}>{t('name')}:</div>
                <div className={styles.value}>{department.name}</div>
                <div className={styles.button}>
                  <EditDepartment department={department} />
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>{t('description')}:</div>
                <div className={styles.value}>{department.description}</div>
              </div>
            </Segment>

            <Divider section />

            <Segment basic clearing style={{ padding: 0, margin: 0 }}>
              <div className={styles.field}>
                <div className={styles.label}>{t('num-of-sensors')}:</div>
                <div className={styles.value}>{department.numSensors}</div>
                <div className={styles.button}>
                  <Button
                    icon
                    labelPosition="left"
                    primary
                    style={{ width: '13rem' }}
                    as={Link}
                    to={routes.iCompAdminDepartmentSensorOverview(
                      hospitalId,
                      departmentId
                    )}
                  >
                    <Icon name="edit" />
                    {t('manage-sensors')}
                  </Button>
                </div>
              </div>
            </Segment>

            <Divider section />

            <Segment basic clearing style={{ padding: 0, margin: 0 }}>
              <div className={styles.field}>
                <div className={styles.label}>
                  {t('num-of-active-patients')}:
                </div>
                <div className={styles.value}>{department.numUsers}</div>
                <div className={styles.button}>
                  <Button
                    icon
                    labelPosition="left"
                    primary
                    style={{ width: '13rem' }}
                    as={Link}
                    to={routes.iCompAdminDepartmentUserOverview(
                      hospitalId,
                      departmentId
                    )}
                  >
                    <Icon name="edit" />
                    {t('manage-users')}
                  </Button>
                </div>
              </div>
            </Segment>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default ICompAdminDepartmentProfile;
