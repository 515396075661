import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { exportFibionMeasurement } from 'api/measurements';
import { useBulkExport } from './use-bulk-export';

const ExportFibionMeasurement = ({ patients, onCompleted, children }) => {
  const [exporting, onExport] = useBulkExport({
    exportAction: 'url',
    apiExport: exportFibionMeasurement,
    patients,
    onCompleted
  });

  return (
    <Button
      primary
      compact
      icon="book"
      content={children}
      loading={exporting}
      onClick={onExport}
    />
  );
};

ExportFibionMeasurement.propTypes = {
  patients: PropTypes.array.isRequired
};

export default ExportFibionMeasurement;
