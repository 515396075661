import routes from 'routes';
import { getVendor } from 'utils/vendor';
import type { ProjectRaw } from './types';

const vendor = getVendor();

const configs = {
  sensors: {
    name: 'sensors',
    icon: 'ticket',
    route: routes.sensors
  },
  simpleSensors: {
    name: 'simple-sensors',
    icon: 'address card',
    route: routes.simpleSensors
  },
  patients: {
    name: 'patients',
    icon: 'users',
    route: routes.patients
  },
  browseData: {
    name: 'browse-data',
    icon: 'chart bar',
    route: routes.browseData
  },
  adminPatients: {
    name: 'admin-patients',
    icon: 'users',
    route: routes.patients
  },
  iSpinePatients: {
    name: 'ispine-patients',
    icon: 'users',
    route: routes.iSpinePatients
  },
  hospitalPatients: {
    name: 'hospital-patients',
    icon: 'users',
    route: routes.hospitalPatients
  },
  nfaSubjects: {
    name: 'nfa-subjects',
    icon: 'chart bar',
    route: routes.nfaSubjects
  },
  nfaSensors: {
    name: 'nfa-sensors',
    icon: 'ticket',
    route: routes.sensors
  }
};

const nProjectViews = (raw: ProjectRaw) => {
  switch (vendor) {
    case 'icomp':
      // iCompression project will be handled by iCompressionHospitals
      // set default view here to prevent crash if login iCompresion account in sens vendor
      return [configs.sensors];
    case 'demos':
      if (
        raw.roles.includes('role/demosadmin') ||
        raw.roles.includes('role/siteadmin')
      ) {
        return [configs.simpleSensors, configs.sensors];
      } else {
        return [configs.simpleSensors];
      }
    case 'nfa':
    case 'sens':
    default:
      switch (raw.project_class) {
        case 'project/hospital1': {
          return raw.roles.includes('role/siteadmin')
            ? [configs.adminPatients, configs.hospitalPatients, configs.sensors]
            : [configs.hospitalPatients, configs.sensors];
        }
        case 'project/ispine': {
          return raw.roles.includes('role/siteadmin')
            ? [configs.adminPatients, configs.iSpinePatients, configs.sensors]
            : [configs.iSpinePatients, configs.sensors];
        }
        case 'project/nfa': {
          return raw.roles.includes('role/siteadmin')
            ? [configs.nfaSubjects, configs.nfaSensors, configs.adminPatients]
            : [configs.nfaSubjects, configs.nfaSensors];
        }
        case 'project/simple': {
          return raw.roles.includes('role/siteadmin')
            ? [configs.adminPatients, configs.sensors]
            : [configs.sensors];
        }
        case 'project/twosensor':
        case 'project/standard':
        default: {
          // Temporary hide Browse Data page
          // return raw.roles.includes('role/siteadmin')
          //   ? [configs.sensors, configs.patients, configs.browseData]
          //   : [configs.sensors, configs.patients, configs.browseData];
          return raw.roles.includes('role/siteadmin')
            ? [configs.sensors, configs.patients]
            : [configs.sensors, configs.patients];
        }
      }
  }
};

const nProjectStatus = (raw: number) => {
  switch (raw) {
    case 1:
      return 'open';
    case 2:
      return 'closed';
    case 3:
      return 'archived';
    case 4:
      return 'deleted';
    default:
      throw new Error('Invalid Status');
  }
};

export const nProject = (raw: ProjectRaw) => ({
  id: raw.id,
  name: raw.name,
  created: raw.created,
  status: nProjectStatus(raw.status),
  class: raw.project_class,
  views: nProjectViews(raw),
  raw
});
