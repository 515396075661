import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'contexts';
import routes from 'routes';
import { getAuthToken } from 'api/auth-utils';
import { fetchUserProfile } from 'api/user';
import LoadingSection from 'components/loading-section';
import UnauthorizedMessage from './unauthorized-message';

const RequireAuth = ({ children }) => {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(!user);

  // Load user profile
  useEffect(() => {
    if (!user) {
      fetchUserProfile()
        .then(setUser)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, setUser]);

  // Check Auth-Token
  if (!getAuthToken()) {
    return <Navigate to={routes.login()} replace />;
  }

  // Show loading indicator while the fetching of user profile is not finished yet
  if (loading) return <LoadingSection height="20rem" />;

  // Handle invalid Auth-Token that makes `/api/1.0/user/profile` got 401 error
  if (!user) return <UnauthorizedMessage />;

  return children;
};

export default RequireAuth;
