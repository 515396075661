import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate } from 'intl';
import routes from 'routes';
import { Headers, LastSeen, Name } from 'fragments/sensor';
import { Icon, List, Popup } from 'semantic-ui-react';
import styles from './styles.module.css';

const stopPropagation = event => event.stopPropagation();

export const Sensor = ({ data }) => {
  const t = useTranslate('hospital-patient');
  const { organizationId, projectId } = useParams();
  const { sensor } = data;
  const path = routes.sensorDetails(organizationId, projectId, sensor.id);

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        <List>
          <List.Item style={{ whiteSpace: 'nowrap' }}>
            <b>
              {Headers.name}
              {`: `}
            </b>
            <Name data={sensor} />
          </List.Item>

          <List.Item style={{ whiteSpace: 'nowrap' }}>
            <b>
              {Headers.lastSeen}
              {`: `}
            </b>
            <LastSeen data={sensor} />
          </List.Item>
        </List>
      </div>

      <div className={styles.button}>
        <Popup
          trigger={
            <a
              href={path}
              target="_blank"
              rel="noreferrer noopener"
              onClick={stopPropagation}
            >
              <Icon name="info circle" size="large" />
            </a>
          }
        >
          {t('common:view-sensor-details')}
        </Popup>
      </div>
    </div>
  );
};
