import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from '../utils';
import Fragment from './fragment';

function withConfirm(WrappedComponent, config = {}) {
  class WithConfirm extends Component {
    constructor(props) {
      super(props);
      this.state = { open: false };
      this.onOpen = this.onOpen.bind(this);
      this.onConfirm = this.onConfirm.bind(this);
      this.onCancel = this.onCancel.bind(this);
    }

    onOpen() {
      this.setState({ open: true });
    }

    onConfirm() {
      this.setState({ open: false });
      this.props.onConfirm();
    }

    onCancel() {
      this.setState({ open: false });
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    }

    render() {
      return (
        <Fragment
          {...this.props}
          {...this.state}
          onOpen={this.onOpen}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          Component={WrappedComponent}
          config={config}
        />
      );
    }
  }

  WithConfirm.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func
  };

  WithConfirm.displayName = `WithConfirm(${getDisplayName(WrappedComponent)})`;

  return WithConfirm;
}

export default withConfirm;
