import React from 'react';
import { Translate } from 'intl';

export const Headers = {
  patient: <Translate ns="ispine-patient" i18nKey="patient" />,
  sensor: <Translate ns="ispine-patient" i18nKey="sensor" />,
  phone: <Translate ns="ispine-patient" i18nKey="phone" />,
  monitoring: <Translate ns="ispine-patient" i18nKey="monitoring" />,
  feedback: <Translate ns="ispine-patient" i18nKey="feedback" />,
  latestEvent: <Translate ns="ispine-patient" i18nKey="latest-event" />,
  export: <Translate ns="ispine-patient" i18nKey="export" />,
  actions: <Translate ns="ispine-patient" i18nKey="edit-or-delete" />
};
