import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Icon, Message } from 'semantic-ui-react';

export const RawErrorMessage = ({ error }) => {
  const t = useTranslate('error');
  return (
    <span>{error.type === 'intl' ? t(error.message) : error.message}</span>
  );
};

const ErrorMessage = ({ error, onDismiss, ...otherProps }) => {
  if (!error) {
    return null;
  }

  return (
    <Message error onDismiss={onDismiss} {...otherProps}>
      <Icon name="warning circle" />
      <RawErrorMessage error={error} />
    </Message>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object,
  onDismiss: PropTypes.func
};

export default ErrorMessage;
