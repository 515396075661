import React from 'react';
import { FormatDate } from 'intl';

const Time = ({ data }) => <FormatDate value={data.time} />;

const Trigger = ({ data }) => <span>{data.trigger}</span>;

const Sensors = ({ data }) => (
  <div>
    {data.sensors.map(item => (
      <div key={item.name}>
        {item.action === 4 ? `${item.name} (SYNC)` : item.name}
      </div>
    ))}
  </div>
);

const Status = ({ data }) => <span>{data.status}</span>;

export { Time, Trigger, Sensors, Status };
