import React from 'react';
import { useTranslate } from 'intl';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup, Button } from 'semantic-ui-react';

export const SendNotifButton = ({ data, setSendNotifData }) => {
  const t = useTranslate('nfa-subjects');

  return (
    <Popup
      content={t('reminder-manager')}
      trigger={
        <Button
          icon
          disabled={!data.phoneNumber || data.phoneLocked}
          onClick={() => setSendNotifData(data)}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      }
    />
  );
};
