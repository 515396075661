import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const getPlatform = platform => {
  switch (platform) {
    case 0:
      return 'ios';
    case 1:
      return 'android';
    default:
      return 'other';
  }
};

const Platform = ({ data }) => {
  const t = useTranslate('sens-admin-gateway');

  return (
    <div>
      <div>{t(`gateway.platform-${getPlatform(data.platform)}`)} </div>
      {data.version && (
        <div style={{ fontSize: 'smaller' }}>{`v${data.version}`}</div>
      )}
    </div>
  );
};

Platform.propTypes = {
  data: PropTypes.shape({
    platform: PropTypes.number.isRequired,
    version: PropTypes.string
  }).isRequired
};

export default Platform;
