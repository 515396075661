import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';

const Power = ({ data }) => {
  const t = useTranslate('sens-admin-gateway');

  return (
    <div>
      <div>{data.powerState ? t('power-powered') : t('power-not-powered')}</div>
      {data.batteryLevel && (
        <div style={{ fontSize: 'smaller' }}>{`${data.batteryLevel}%`}</div>
      )}
    </div>
  );
};

Power.propTypes = {
  data: PropTypes.shape({
    powerState: PropTypes.bool,
    batteryLevel: PropTypes.string
  }).isRequired
};

export default Power;
