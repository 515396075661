import React from 'react';
import { useTranslate } from 'intl';
import { Name as SensorName } from 'fragments/sensor';
import { Header } from 'semantic-ui-react';
import styles from './styles.module.css';

const PlotName = ({ stream, sensor }) => {
  const t = useTranslate('patient');
  const [, streamValue] = stream.split(':');
  return (
    <div className={styles.main}>
      <Header as="h2" style={{ margin: 0 }}>
        {t(`alg_profile:${streamValue}`)}
      </Header>

      {sensor && (
        <p className={styles.sensor}>
          {t('sensor')}: <SensorName data={sensor} />
        </p>
      )}
    </div>
  );
};

export default PlotName;
