import React from 'react';
import {
  SubjectID,
  DiaryUpdated,
  Headers,
  PhoneNumber,
  SensorName,
  SensorData,
  StartTime,
  Status,
  Actions
} from '../elements';

const baseColumns = [
  {
    name: 'subject-id',
    header: Headers.subjectId,
    cell: SubjectID
  },
  {
    name: 'phone-number',
    header: Headers.phoneNumber,
    cell: PhoneNumber
  },
  {
    name: 'sensor-name',
    header: Headers.sensorName,
    cell: SensorName
  },
  {
    name: 'sensor-data',
    header: Headers.sensorData,
    cell: SensorData
  },
  {
    name: 'start-time',
    header: Headers.startTime,
    cell: StartTime
  },
  {
    name: 'status',
    header: Headers.status,
    cell: Status
  },
  {
    name: 'diary-updated',
    header: Headers.diaryUpdated,
    cell: DiaryUpdated
  }
];

const sensorCells = ['sensor-name', 'sensor-data'];
export const isSensorCell = cellName => sensorCells.includes(cellName);

export const getColumns = ({
  isOpenBulkExportMode,
  setSendNotifData,
  setEditData,
  setDeleteData
}) => {
  if (isOpenBulkExportMode) return baseColumns;

  const columns = [
    ...baseColumns,
    {
      name: 'actions',
      header: Headers.actions,
      cell: ({ data }) => (
        <Actions
          data={data}
          setSendNotifData={setSendNotifData}
          setEditData={setEditData}
          setDeleteData={setDeleteData}
        />
      ),
      align: 'center'
    }
  ];
  return columns;
};
