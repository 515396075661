import { axiosClient } from 'api/axios-client';
import type { RouteParams } from 'app/types';
import type { ICompPatient } from '../patients';

export const endTreatment = async ({
  params,
  patient
}: {
  params: Pick<RouteParams, 'departmentId'>;
  patient: ICompPatient;
}) => {
  const payload = {
    org_id: params.departmentId,
    patient_id: patient.id
  };
  return axiosClient.post('/api/1.0/icompression/measurement/close', payload);
};
