import React from 'react';
import PropTypes from 'prop-types';
import Plot from '../plot';

const Yesterday = ({ data }) => {
  return <Plot data={data.yesterday} />;
};

Yesterday.propTypes = {
  data: PropTypes.object.isRequired
};

export default Yesterday;
