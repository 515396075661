import { nICompDepartment } from 'api/icomp-departments';
import type { ICompHospitalRaw } from './types';

export const nHospital = (raw: ICompHospitalRaw) => ({
  id: raw.id,
  name: raw.name,
  roles: raw.roles,
  departments: raw.departments.map((item, index) => ({
    ...nICompDepartment(item),
    idx: index + 1
  })),
  raw
});
