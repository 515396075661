import React from 'react';
import { useTranslate } from 'intl';
import { Button } from 'semantic-ui-react';
import StaticBox from '../../static-box';

const ActivityData = ({ onSetupActivity }) => {
  const t = useTranslate('browse-data');

  return (
    <StaticBox
      header={t('activity-data.header')}
      listTitle={t('activity-data.list-title')}
      listItems={[
        t('activity-data.summarize-data'),
        t('activity-data.file-type'),
        t('activity-data.timestamp-format')
      ]}
      button={
        <Button primary onClick={onSetupActivity}>
          {t('activity-data.setup-report')}
        </Button>
      }
    />
  );
};

export default ActivityData;
