import moment from 'moment';

const today = moment().startOf('day');

export const getInitialTrack = ({ location, tracks }) => {
  if (location.state) {
    return location.state.track;
  }
  if (tracks && tracks.length) {
    return tracks[tracks.length - 1].id;
  }
  return '';
};

export const getInitialMeasurement = ({ track, tracks }) => {
  const trackItem = tracks.find(item => item.id === track);
  if (!trackItem) return 'all';

  const { measurements } = trackItem;
  const treatment = measurements.length
    ? measurements[measurements.length - 1].id
    : 'all';

  return treatment;
};

const getDates = (startTime, endTime) => {
  const startDateObj = moment(startTime).startOf('day');

  let endDateObj;
  if (endTime) {
    endDateObj =
      startDateObj.diff(endDateObj, 'month') > 1
        ? moment(startDateObj).add(1, 'month')
        : moment(endTime).startOf('day');
  } else {
    endDateObj = moment(startDateObj).add(1, 'month');
  }
  if (endDateObj.isAfter(today)) {
    endDateObj = today;
  }

  const startDate = startDateObj.format();
  const endDate = endDateObj.format();

  return { startDate, endDate };
};

export const getInitialDates = ({ track, measurement, tracks }) => {
  const trackItem = tracks.find(item => item.id === track) || {};
  if (measurement === 'all') {
    const { startTime, endTime } = trackItem;
    return getDates(startTime, endTime);
  } else {
    const measurementItem = trackItem.measurements.find(
      item => item.id === measurement
    );
    const { startTime, endTime } = measurementItem;
    return getDates(startTime, endTime);
  }
};
